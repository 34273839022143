import React, { Component, createRef, Fragment } from 'react';

import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import SpinnerCarga from './../utils/spinner';
import LoadingModal from './../utils/loadModal';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import config from './../../config/config'
import { ObtenerUsuario, ValidarUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from './../utils/utils'
import FotoPredeterminada from './foto.png';
// Bootstrap
import { Modal } from 'react-bootstrap';

class configPersonal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valorSelect: "Todos",
            MostrarModalAgregar: false,
            AccionTrabajador: "",
            MostrarModalUsuario: false,
            AccionUsuario: "",
            MostrarModalFotografia: false,
            valuetipoDoc: "",
            rows: [],
            trabajador: null,
            listaTipoUsuario: [],
            listaAreas: [],
            loadData: false,
            cantidadElementos: 0,
            filasPorPagina: 20,
            nombre_buscar: '',
            paginaInicio: 1,
            abrirToast: false,
            mensajeToast: 'Notificacion de Ejemplo',
            tipotoast: 'success',
            verPass: false,
            fotografiaTrabajador: '',
            nombretrabajadorFotografia: '',
            nombrearchivofotografia: '',
            OcultarLoading: true,
            idproyecto: null,
            listaProyectosActivos: []

        };
        this.headCells = [
            { id: 'Usuario' },
            { id: 'Acciones' },
            { id: 'Nombres' },
            { id: 'Area' },
            { id: 'Teléf.' },
            { id: 'Tipo' },
            { id:'Proyecto'},
            { id: 'Estado' },
            { id: 'Foto' },
        ];
        this.inputBuscar = createRef();
        // Referencias Modal Usuario
        this.nombreUsuario = createRef();
        this.contra1Usuario = createRef();
        this.estadoUsuario = createRef();
        this.tipoUsuario = createRef();
        this.botoGuardarUsuario = createRef();
        this.botonCambiarPass = createRef();
        this.botonResetearUsuario = createRef();
        // Referencias FotografiaUsuario
        this.imagenFile = createRef();
        this.inputFile = createRef();
        // Referencias Trabajador
        this.inputCodigoTrabajador = createRef();
        this.inputNombreTrabajador = createRef();
        this.inputDireccionTrabajador = createRef();
        this.selectTipoDocumento = createRef();
        this.inputNroDocumento = createRef();
        this.inputCelularEmpresa = createRef();
        this.inputCelularPersonal = createRef();
        this.inputSuministroAsociadoTrabajador = createRef();
        this.inputArea = createRef();
        this.selectFiltroArea = createRef();
        this.botonModificarTrabajador = createRef();
        this.botonGuardarTrabajador = createRef();
        this.selectProyectoTrabajador = createRef();
    }

    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PERSONAL, permisos)) {
                    //Obtener Token
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    await this.BuscarAreas();
                    await this.BuscarTrabajadores();

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {

            this.props.history.push('/login');
        }

    }
    BuscarTrabajadores = () => {
        if (ValidarUsuario()) {
            let url = '';
            let cadena = String(this.state.nombretrabajador);

            if (cadena.trim() !== '') {
                if(this.state.idproyecto){
                    url = `${config.endPoint}api/trabajador/listarTrabajadores/${this.state.idproyecto}/${this.state.nombre_buscar}`;
                }else{
                    url = `${config.endPoint}api/trabajador/listarTrabajadoresTodos/${this.state.nombre_buscar}`;
                }
            }
            else {
                if(this.state.idproyecto){
                    url = `${config.endPoint}api/trabajador/listarTrabajadoresTodos`;
                }else{

                }
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                
                if (data) {
                    
                    await this.setState({ rows: data.content, cantidadElementos: data.count, loadData: true });
                    await this.filtrarAreas();
                    this.Paginacion();
                }
            }).catch((error) => {
                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });

        }
        else {

            this.props.history.push('/login')
        }
    }
    filtrarAreas = () => {
        if (ValidarUsuario()) {
            let arrayTemporal = [];
            if (this.selectFiltroArea.current.value !== "TODOS") {
                arrayTemporal = this.state.rows.filter(x => x.area === this.selectFiltroArea.current.value);
                this.setState({ rows: arrayTemporal, cantidadElementos: arrayTemporal.length });
            }
        } else {
            this.props.history.push('/login')
        }
    }
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.rows.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.paginaInicio) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.rows.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.rows[x]);
            }
        }
        await this.setState({ rows: nuevoArray, LoadData: true });
    }
    BuscarTiposUsuario = (idproyecto) => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/tipousuario/listarproyecto/${idproyecto}`;
            // if(idproyecto){
            // }else{
            //     url = `${config.endPoint}api/tipousuario/listarproyectoTodo`;                
            // }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                if (data.content) {
                    this.setState({ listaTipoUsuario: data.content });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });

        }
        else {

            this.props.history.push('/login');
        }
    }
    BuscarAreas = () => {
        if (ValidarUsuario()) {
            let url ="";

            if(this.state.idproyecto){
                url = `${config.endPoint}api/trabajador/listarareasTodo/${this.state.idproyecto}`;
            }else{
                url = `${config.endPoint}api/trabajador/listarareas`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                if (data.content) {
                    this.setState({ listaAreas: data.content });
                }
                else {
                    this.setState({ listaAreas: [] });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });

        }
        else {

            this.props.history.push('/login');
        }
    }
    BuscarTrabajador = (idtrabajador) => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/buscartrabajador/${idtrabajador}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    
                    if (data.content) {
                        this.setState({ trabajador: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ trabajador: null });
                        this.setState({ abrirToast: true, mensajeToast: data.messaje, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }

        });

    }
    CrearTrabajador = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajador/agregar`;
            let Nuevotrabajador = {
                codigoTrabajador: this.inputCodigoTrabajador.current.value,
                nombretrabajador: this.inputNombreTrabajador.current.value,
                direcciontrabajador: this.inputDireccionTrabajador.current.value,
                documentoIdentidad: this.inputNroDocumento.current.value,
                tipoDocumento: this.selectTipoDocumento.current.value,
                suministroReferencia: this.inputSuministroAsociadoTrabajador.current.value,
                numeroCelularPersonal: this.inputCelularPersonal.current.value,
                numerocelularEmpresa: this.inputCelularEmpresa.current.value,
                area: this.inputArea.current.value.toUpperCase(),
                idproyecto:this.state.idproyecto?this.state.idproyecto:this.selectProyectoTrabajador.current.value,
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(Nuevotrabajador)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Trabajador Agregado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarTrabajadores();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch((error) => {
                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    ModificarTrabajador = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajador/actualizar`;
            let Nuevotrabajador = {
                idtrabajador: this.state.trabajador.idtrabajador,
                codigoTrabajador: this.inputCodigoTrabajador.current.value,
                nombretrabajador: this.inputNombreTrabajador.current.value,
                direcciontrabajador: this.inputDireccionTrabajador.current.value,
                documentoIdentidad: this.inputNroDocumento.current.value,
                tipoDocumento: this.selectTipoDocumento.current.value,
                suministroReferencia: this.inputSuministroAsociadoTrabajador.current.value,
                numeroCelularPersonal: this.inputCelularPersonal.current.value,
                numerocelularEmpresa: this.inputCelularEmpresa.current.value,
                area: this.inputArea.current.value.toUpperCase(),
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(Nuevotrabajador)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Trabajador Actualizado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarTrabajadores();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                }

            }).catch((error) => {
                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    HabilitarModificarTrabajador = () => {
        this.setState({ AccionTrabajador: 'Ver' });
        this.Deshabilitarcontroles(false);
        this.botonModificarTrabajador.current.disabled = true;
        this.botonGuardarTrabajador.current.disabled = false;
        
    }
    GuardarTrabajador = (ev) => {
        ev.preventDefault();
        switch (this.state.AccionTrabajador) {
            case 'Agregar':
                this.CrearTrabajador();
                break;
            case 'Ver':
                this.ModificarTrabajador();
                break;
            default:
            // this.setState({ abrirToast: true, mensajeToast: "Ninguna Acción Establecida previamente", tipotoast: 'error' });
        }
    }
    // Procedimientos Usuario
    GuardarUsuario = () => {
        if (this.RevisarControlesUsuario()) {
            switch (this.state.AccionUsuario) {
                case 'Ver':
                    
                    this.ModificarUsuario();
                    break;
                case 'Nuevo':
                    
                    this.CrearUsuario();
                    break;
                case 'Password':
                    
                    this.CambiarPassword();
                    break;
                default:
                    
                    this.setState({ abrirToast: true, mensajeToast: "Ninguna Acción Establecida previamente", tipotoast: 'error' });

            }
        }

    }
    RevisarControlesUsuario = () => {
        let usuario_ = this.nombreUsuario.current.value;
        let contra_ = this.contra1Usuario.current.value
        let estado_ = this.estadoUsuario.current.value
        let tipo_ = this.tipoUsuario.current.value

        if (usuario_.trim() === '') {
            this.setState({ abrirToast: true, mensajeToast: "Falta Nombre de Usuario", tipotoast: 'error' });
            return false
        }
        if (contra_.trim() === '') {
            this.setState({ abrirToast: true, mensajeToast: "Falta la Contraseña", tipotoast: 'error' });
            return false
        }
        if (estado_.trim() === '') {
            this.setState({ abrirToast: true, mensajeToast: "Falta el Estado", tipotoast: 'error' });
            return false
        }
        if (tipo_.trim() === '') {
            this.setState({ abrirToast: true, mensajeToast: "Falta el Tipo de Usuario", tipotoast: 'error' });
            return false
        }
        return true;
    }
    CrearUsuario = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/usuario/agregar`
            let nuevoUsuario = {
                idtrabajador: this.state.trabajador.idtrabajador,
                usuario: this.nombreUsuario.current.value,
                password: this.contra1Usuario.current.value,
                tiposesion: this.tipoUsuario.current.value.toUpperCase(),
                estado: this.estadoUsuario.current.value.toUpperCase(),
                idtipousuario: this.tipoUsuario.current.value,
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoUsuario)
            };
            fetch(url, headers).then((resp2) => {
                return resp2.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Usuario Creado Correctamente", tipotoast: 'success' });
                    this.CerrarModalUsuario();
                    this.BuscarTrabajadores();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    ModificarUsuario = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajador/actualizar`;
            let usuarioModif = {
                idtrabajador: this.state.trabajador.idtrabajador,
                usuario: this.nombreUsuario.current.value,
                tiposesion: this.tipoUsuario.current.value.toUpperCase(),
                estado: this.estadoUsuario.current.value.toUpperCase(),
                idtipousuario: this.tipoUsuario.current.value,
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(usuarioModif)
            };

            fetch(url, headers).then((resp2) => {
                return resp2.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Usuario Modificado Correctamente", tipotoast: 'success' });
                    this.CerrarModalUsuario();
                    this.BuscarTrabajadores();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    CambiarPassword = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/usuario/modificarcontra`
            let nuevoUsuario = {
                idtrabajador: this.state.trabajador.idtrabajador,
                new_password: this.contra1Usuario.current.value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoUsuario)
            };

            fetch(url, headers).then((resp2) => {
                return resp2.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Contraseña cambiada Correctamente", tipotoast: 'success' });
                    this.CerrarModalUsuario();
                    this.BuscarTrabajadores();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    // Generales
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), paginaInicio: 1 });
        await this.BuscarTrabajadores();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, paginaInicio: (parseInt(nuevaPagina) + 1) });
        await this.BuscarTrabajadores();
    }
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value === '') {
            await this.setState({ nombre_buscar: this.inputBuscar.current.value, paginaInicio: 1 });
            await this.BuscarTrabajadores();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ nombre_buscar: this.inputBuscar.current.value, paginaInicio: 1 });
            await this.BuscarTrabajadores();
        }
    }
    BuscarTrabajadorNombre = async () => {
        await this.setState({ nombre_buscar: this.inputBuscar.current.value });
        await this.BuscarTrabajadores();
    }
    // Modales
    AbrirModalUsuario = async (row, accion) => {

        this.setState({ OcultarLoading: false });
        await this.BuscarTiposUsuario(row.idproyecto);
        await this.BuscarTrabajador(row.idtrabajador).then(() => {
            this.setState({ MostrarModalUsuario: true, AccionUsuario: accion });
            if (this.state.trabajador.usuario) {
                // Si el trabajador ya cuenta con usuario
                this.nombreUsuario.current.value = this.state.trabajador.usuario;
                this.estadoUsuario.current.value = this.state.trabajador.estado
                this.tipoUsuario.current.value = this.state.trabajador.idtipousuario
                this.contra1Usuario.current.value = '************'

                // Deshabilitar Controler            
                this.nombreUsuario.current.readOnly = true;
                this.contra1Usuario.current.readOnly = true;
                // this.estadoUsuario.current.disabled = true;
                // this.tipoUsuario.current.disabled = true;
                // this.botoGuardarUsuario.current.disabled=true
                this.setState({ OcultarLoading: true });
            }
            else {
                this.botonResetearUsuario.current.hidden = true;
                this.botonCambiarPass.current.hidden = true
                this.setState({ OcultarLoading: true });
            }

        }).catch((error) => {
            if (error) {

                this.props.history.push('/login');
            }
        });
    }
    CerrarModalUsuario = () => {
        this.setState({ MostrarModalUsuario: false, AccionUsuario: "" });
    }
    Deshabilitarcontroles = (value) => {
        // Deshabilitando controles
        this.inputCodigoTrabajador.current.readOnly = value;
        this.inputNombreTrabajador.current.readOnly = value;
        this.inputDireccionTrabajador.current.readOnly = value;
        this.inputNroDocumento.current.readOnly = value;
        this.selectTipoDocumento.current.disabled = value;
        this.inputSuministroAsociadoTrabajador.current.readOnly = value;
        this.inputCelularPersonal.current.readOnly = value;
        this.inputCelularEmpresa.current.readOnly = value;
        this.inputArea.current.readOnly = value;
    }
    AbrirModalAgregar = async (idtrabajador, accion) => {
        this.BuscarListaProyectosActivos();

        this.setState({ OcultarLoading: false });
        await this.setState({ MostrarModalAgregar: true, AccionTrabajador: accion });
        await this.BuscarAreas();
        if (accion === "Ver") {
            await this.BuscarTrabajador(idtrabajador).then(() => {
                // LLenar Datos de Trabajador              
                         
                this.inputCodigoTrabajador.current.value = this.state.trabajador.codigoTrabajador;
                this.inputNombreTrabajador.current.value = this.state.trabajador.nombretrabajador;
                this.inputDireccionTrabajador.current.value = this.state.trabajador.direcciontrabajador;
                this.inputNroDocumento.current.value = this.state.trabajador.documentoIdentidad;
                this.selectTipoDocumento.current.value = this.state.trabajador.tipoDocumento.toUpperCase();
                this.inputSuministroAsociadoTrabajador.current.value = this.state.trabajador.suministroReferencia;
                this.inputCelularPersonal.current.value = this.state.trabajador.numeroCelularPersonal;
                this.inputCelularEmpresa.current.value = this.state.trabajador.numerocelularEmpresa;
                this.inputArea.current.value = this.state.trabajador.area;
                if(this.state.idproyecto==null){
                    this.selectProyectoTrabajador.current.value=this.state.trabajador.idproyecto;
                    this.selectProyectoTrabajador.current.disabled=true;
                }

                this.Deshabilitarcontroles(true);
                this.botonGuardarTrabajador.current.disabled = true;
                this.setState({ OcultarLoading: true });

            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }
        else {
            this.botonModificarTrabajador.current.hidden = true;
            this.setState({ OcultarLoading: true });
        }
    }
    CerrarModalAgregar = () => {
        this.setState({ MostrarModalAgregar: false, AccionTrabajador: "" });
    }
    AbrirModalFotografia = async (idtrabajador, fotografia) => {
        this.setState({ OcultarLoading: false });
        await this.setState({ fotografiaTrabajador: '' })
        await this.BuscarTrabajador(idtrabajador).then(() => {
            this.setState({ MostrarModalFotografia: true });
            if (fotografia) {
                this.setState({
                    fotografiaTrabajador: `${config.endPoint}api/trabajador/buscarfoto/${fotografia}`,
                    nombretrabajadorFotografia: this.state.trabajador.nombretrabajador,
                    nombrearchivofotografia: fotografia
                });
                this.setState({ OcultarLoading: true });
            }
            else {
                this.setState({
                    fotografiaTrabajador: FotoPredeterminada,
                    nombretrabajadorFotografia: this.state.trabajador.nombretrabajador,
                    nombrearchivofotografia: ''
                });
                this.setState({ OcultarLoading: true });
            }
        }).catch((error) => {
            if (error) {

                this.props.history.push('/login');
            }
        });
    }
    CerrarModalFotografia = () => {
        this.setState({ MostrarModalFotografia: false });
    }
    GuardarFotografia = async () => {
        if (ValidarUsuario()) {
            if (this.inputFile.current.files.length > 0) {
                await this.setState({ OcultarLoading: false });
                this.inputFile.current.classList.remove('is-invalid')
                let url = `${config.endPoint}api/trabajador/subirfotografia`;
                let formData = new FormData();
                formData.append('file', this.inputFile.current.files[0], this.inputFile.current.files[0].name);
                formData.append('idtrabajador', this.state.trabajador.idtrabajador)
                
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: formData
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json()
                }).then((data) => {
                    if (data !== null) {
                        this.setState({ abrirToast: true, mensajeToast: "Fotografia Actualizada", tipotoast: 'success' });
                        this.CerrarModalFotografia();
                        this.BuscarTrabajadores();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
                });
                this.setState({ OcultarLoading: true });
            }
            else {
                this.inputFile.current.classList.add('is-invalid')
            }
        }
        else {

            this.props.history.push('/login');
        }
    }
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    VerContra = () => {
        if (this.state.verPass) {
            this.contra1Usuario.current.type = 'text';
            this.setState({ verPass: false });
        }
        else {
            this.contra1Usuario.current.type = 'password'
            this.setState({ verPass: true });
        }
    }
    AccionCambiarPassword = () => {
        this.setState({ AccionUsuario: 'Password' })
        this.contra1Usuario.current.value = ''
        this.contra1Usuario.current.readOnly = false;
        this.estadoUsuario.current.disabled = true;
        this.tipoUsuario.current.disabled = true;
    }
    AccionResetearPassword = () => {
        this.setState({ AccionUsuario: 'Nuevo' })
        this.nombreUsuario.current.value = '';
        this.estadoUsuario.current.value = ''
        this.tipoUsuario.current.value = ''
        this.contra1Usuario.current.value = ''

        this.nombreUsuario.current.readOnly = false;
        this.contra1Usuario.current.readOnly = false;
        this.estadoUsuario.current.disabled = false;
        this.tipoUsuario.current.disabled = false;

        this.botonResetearUsuario.current.hidden = true;
        this.botonCambiarPass.current.hidden = true

    }
    CargarFoto = async () => {
        await this.setState({ fotografiaTrabajador: '' })
        if (this.inputFile.current.files.length > 0) {
            this.setState({ fotografiaTrabajador: URL.createObjectURL(this.inputFile.current.files[0]), nombrearchivofotografia: this.inputFile.current.files[0].name });
        }
    }
    //Buscar Lista Proyectos Activos
    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {                    
                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }


    render() {

        return (
            <Fragment>
                <NavBar />
                <TituloPagina titulo="CONFIGURACION - TRABAJADOR" regresar={() => { this.props.history.push('/configuracion') }} />

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row my-1">
                        <div className="col-md-3 mb-1 mb-md-0">
                            <select onChange={() => { this.BuscarTrabajadores() }} ref={this.selectFiltroArea} className="form-select form-select-sm" >
                                <option value="TODOS">Todos</option>
                                {
                                    this.state.listaAreas.map((row, index) => {
                                        return (<option key={index} value={row.area.toUpperCase()}>{row.area.toUpperCase()}</option>);
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-8 col-md-3">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Nombre Trabajador"></input>
                        </div>
                        <div className="col-4 col-md-3 ">
                            <button onClick={() => { this.BuscarTrabajadorNombre() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>
                        <div className="col-12 col-md-3 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregar(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload p-1"></i> Agregar</button>
                            {/* <button onClick={() => { this.props.history.push("/configuracion") }} className=" btn btn-outline-secondary m-1 p-1" style={{ width: '130px' }}><i className="fas fa-arrow-left p-1"></i> Regresar</button> */}
                        </div>
                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ? <>
                                {/* Tabla Aqui */}
                                <TableContainer>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow className="fondoTituloTabla">
                                                {
                                                    this.headCells.map((head, index) => {
                                                        return (
                                                            <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                        );
                                                    })
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rows.map((row, index) => {
                                                return (
                                                    <TableRow key={row.idtrabajador}>
                                                        <TableCell>{(row.usuario === null) ?
                                                            <i onClick={() => { this.AbrirModalUsuario(row, "Nuevo") }} title={`Usuario ${row.estado}`} className="fas fa-user-slash iconoAcciones estadoInactivo" ></i> :
                                                            <><i onClick={() => { this.AbrirModalUsuario(row, "Ver") }} title={`Usuario ${row.estado}`} className="fas fa-user iconoAcciones estadoActivo" >
                                                            </i><span className="mx-1">{row.usuario}</span></>}</TableCell>
                                                        <TableCell>
                                                            <i onClick={() => { this.AbrirModalAgregar(row.idtrabajador, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            {/* <i onClick={() => { this.AbrirModalAgregar(row.idtrabajador, "Modificar") }} title="Modificar" className="fas fa-edit  mx-2 iconoAcciones" ></i> */}
                                                        </TableCell>
                                                        <TableCell>{row.nombretrabajador}</TableCell>
                                                        <TableCell>{row.area}</TableCell>
                                                        <TableCell>{row.numerocelularEmpresa}</TableCell>
                                                        <TableCell>{row.t_tipousuario != null ? row.t_tipousuario.tipoUsuario : ''}</TableCell>
                                                        <TableCell>{row.t_proyecto.nombreproyecto}</TableCell>
                                                        
                                                        <TableCell>{row.estado === 'ACTIVO' ? <span className="estadoActivo">{row.estado}</span > : <span className="estadoInactivo">{row.estado}</span>}</TableCell>
                                                        <TableCell>{row.fotografia != null ?
                                                            <i onClick={() => { this.AbrirModalFotografia(row.idtrabajador, row.fotografia) }} title="Fotografia Usuario" className="fas fa-camera iconoAcciones estadoActivo" ></i> :
                                                            <i onClick={() => { this.AbrirModalFotografia(row.idtrabajador, row.fotografia) }} title="Fotografia Usuario" className="fas fa-camera iconoAcciones estadoInactivo" ></i>}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[20, 30, 50]}
                                    component="div"
                                    count={this.state.cantidadElementos}
                                    rowsPerPage={this.state.filasPorPagina}
                                    page={this.state.paginaInicio - 1}
                                    onChangePage={this.CambiarPagina}
                                    onChangeRowsPerPage={this.CambioFilasPorPagina}
                                    labelRowsPerPage={"Filas Por Pagina"}
                                />

                            </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>
                {/* Ventanas Modales */}
                {/* Modal Trabajador */}
                <Modal
                    show={this.state.MostrarModalAgregar}
                    onHide={() => { this.CerrarModalAgregar() }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header >
                        <Modal.Title>Personal - {this.state.AccionTrabajador}</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={(ev) => { this.GuardarTrabajador(ev) }}>
                        <Modal.Body>

                            <div className="container">
                                <div className="row my-1">
                                    {/* Proyecto */}
                                    {
                                        this.state.idproyecto == null ?
                                            <div className="col-md-6">
                                                <small className="text-primary">Proyecto</small>
                                                <select ref={this.selectProyectoTrabajador} className="form-select form-select-sm" required >
                                                    <option value="" disabled>Seleccione un Proyecto</option>
                                                    {
                                                        this.state.listaProyectosActivos.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            : <></>
                                    }

                                    <div className="col-md-6">
                                        <small className="text-primary">Código</small>
                                        <input type="text" ref={this.inputCodigoTrabajador} className="form-control form-control-sm" placeholder="Codigo" required></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Nombre</small>
                                        <input type="text" ref={this.inputNombreTrabajador} className="form-control form-control-sm" placeholder="Nombre" required></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Dirección</small>
                                        <input type="text" ref={this.inputDireccionTrabajador} className="form-control form-control-sm" placeholder="Direccion" required></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo de Documento</small>
                                        <select ref={this.selectTipoDocumento} className="form-select form-select-sm" required >
                                            <option value="DNI">DNI</option>
                                            <option value="CE">CE</option>
                                            <option value="PASAPORTE">Pasaporte</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Nro. Documento</small>
                                        <input type="text" ref={this.inputNroDocumento} className="form-control form-control-sm" placeholder="Nro. Documento" required></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Celular Empresa</small>
                                        <input type="tel" ref={this.inputCelularEmpresa} className="form-control form-control-sm" placeholder="Celular Empresa"></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Celular Personal</small>
                                        <input type="tel" ref={this.inputCelularPersonal} className="form-control form-control-sm" placeholder="Celular Personal"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Suministro Asociado</small>
                                        <input ref={this.inputSuministroAsociadoTrabajador} className="form-control form-control-sm" placeholder="Suministro Asociado"></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Area</small>
                                        <input ref={this.inputArea} required type="" className="form-control form-control-sm" list="listaAreas" placeholder="Ingresa el Area ..."></input>
                                        <datalist id="listaAreas">
                                            {
                                                this.state.listaAreas.map((row, index) => {
                                                    return (<option key={index} value={row.area.toUpperCase()} />);
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid" >
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        <button ref={this.botonModificarTrabajador} onClick={() => { this.HabilitarModificarTrabajador() }} className="btn btn-outline-primary mx-1">Modificar</button>
                                    </div>
                                    <div className="col-7 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregar() }} className="btn btn-outline-secondary mx-1">Cancelar</button>
                                        <button ref={this.botonGuardarTrabajador} className="btn btn-outline-primary mx-1">Guardar</button>
                                    </div>
                                </div>

                            </div>

                        </Modal.Footer>
                    </form>
                </Modal>

                {/* Modal Usuario */}
                <Modal
                    show={this.state.MostrarModalUsuario}
                    onHide={() => { this.CerrarModalUsuario() }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header >
                        <Modal.Title>
                            Usuario - {this.state.AccionUsuario}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="container">
                                <div className="row my-1">
                                    <div className="col">
                                        <span>{this.state.trabajador ? this.state.trabajador.nombretrabajador : ''}</span>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Usuario</small>
                                        <input ref={this.nombreUsuario} className="form-control form-control-sm" placeholder="Ingrese el Usuario"></input>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <div onClick={() => { this.AccionResetearPassword() }} ref={this.botonResetearUsuario} className="iconoSalir">
                                            <i className="fas fa-eraser"></i>
                                            <span className="mx-1 ">Resetear usuario</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Contraseña</small>
                                        <div className="d-flex align-items-center">
                                            <input ref={this.contra1Usuario} type="password" className="form-control form-control-sm" placeholder="Ingrese una contraseña"></input>
                                            {this.state.verPass ?
                                                <i onClick={() => { this.VerContra() }} className="far fa-eye iconoAcciones align-items mx-1"></i> :
                                                <i onClick={() => { this.VerContra() }} className="far fa-eye-slash iconoAcciones align-items mx-1"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.estadoUsuario} className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo Usuario</small>
                                        <select ref={this.tipoUsuario} className="form-select form-select-sm" >
                                            {
                                                this.state.listaTipoUsuario.map((row, index) => {
                                                    return (
                                                        <option key={row.idtipousuario} value={`${row.idtipousuario}`}>{row.tipoUsuario}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-md-6 ">
                                    <button onClick={() => { this.AccionCambiarPassword() }} ref={this.botonCambiarPass} className="btn btn-outline-primary">Cambiar Contraseña</button>
                                </div>
                                <div className="col-6 col-md-6  text-end">
                                    <button onClick={() => { this.CerrarModalUsuario() }} className="btn btn-outline-secondary mx-1">Cancelar</button>
                                    <button ref={this.botoGuardarUsuario} onClick={() => { this.GuardarUsuario() }} className="btn btn-primary mx-1">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Imagen */}
                <Modal
                    show={this.state.MostrarModalFotografia}
                    onHide={() => { this.CerrarModalFotografia() }}
                >
                    <Modal.Header >
                        <Modal.Title>Fotografia Trabajador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="container">
                                <div className="row">
                                    <div className="col text-center">
                                        <span>{this.state.nombretrabajadorFotografia}</span>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <div className="text-center overflow-auto">
                                            <img onClick={() => { window.open(`${this.state.fotografiaTrabajador}`, '_blank'); }} ref={this.imagenFile} style={{ width: '200px', cursor: 'pointer' }} alt='Foto Trabajador' src={this.state.fotografiaTrabajador} className="rounded" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col text-center">
                                        <span>{this.state.nombrearchivofotografia}</span>
                                    </div>
                                </div> */}
                                <div className="row my-1">
                                    <div className="col-md-12">
                                        <small className="text-primary">Fotografia</small>
                                        <input accept="image/*" onChange={() => { this.CargarFoto() }} ref={this.inputFile} className="form-control form-control-sm" id="formFileSm" type="file"></input>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalFotografia() }} className="btn btn-outline-secondary mx-1">Cancelar</button>
                            <button onClick={() => { this.GuardarFotografia() }} className="btn btn-primary mx-1">Guardar</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Toast Notification */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                


            </Fragment>
        );
    }
}

export default configPersonal;