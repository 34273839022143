import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import SpinnerCarga from './../utils/spinner';
import LoadingModal from './../utils/loadModal';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// Bootstrap
import { Modal } from 'react-bootstrap';
import config from './../../config/config'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from './../utils/utils'
import { rhumbDistance } from '@turf/turf';

class configActividad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            MostrarModalAgregar: false,
            AccionActividad: "",
            Actividades: [],
            TipoActividad: [],
            Actividad: null,
            cantidadElementos: 0,
            filasPorPagina: 20,
            pagina: 1,
            loadData: false,
            nombrebuscar: '',
            abrirToast: false,
            idproyecto: null,
            listaProyectosActivos: []
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Codigo' },
            { id: 'Descripción' },
            { id: 'Tipo' },
            { id: 'Proyecto' },
            { id: 'Estado' }
        ];

        //Referencias Actividad
        this.inputBuscar = createRef();
        // Referencias Modal Actividad
        this.inputCodigoActividad = createRef();
        this.inputDescripcionActividad = createRef();
        this.inputAbrevActividad = createRef();
        this.inputPrecioActividad = createRef();
        this.inputTipoActividad = createRef();
        this.selectEstadoActividad = createRef();
        this.buttonModificar = createRef();
        this.botonGuardar = createRef();
        this.selectProyecto=createRef();

        this.datalistTipoActividad = createRef();

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_ACTIVIDADES, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    await this.BuscarActividades();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarActividades = () => {
        if (ValidarUsuario()) {
            let url = '';
            if (this.state.nombrebuscar.trim() !== '') {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/baseactividad/listarTodo/paginacion/${this.state.idproyecto}/${this.state.filasPorPagina}/${this.state.pagina}/${this.state.nombrebuscar}`;
                } else {
                    url = `${config.endPoint}api/baseactividad/listar/paginacion/${this.state.filasPorPagina}/${this.state.pagina}/${this.state.nombrebuscar}`;
                }
            }
            else {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/baseactividad/listarTodo/paginacion/${this.state.idproyecto}/${this.state.filasPorPagina}/${this.state.pagina}`;
                } else {
                    url = `${config.endPoint}api/baseactividad/listar/paginacion/${this.state.filasPorPagina}/${this.state.pagina}`;
                }
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                
                if (data.content) {
                    this.setState({ Actividades: data.content, cantidadElementos: data.count, loadData: true });
                }
                else {
                    this.setState({ Actividades: [], cantidadElementos: data.count, loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }
    BuscarActividad = (idbaseactividad) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/baseactividad/${idbaseactividad}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((resp) => {
                    return resp.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ Actividad: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ Actividad: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false)
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false)
                })

            }
            else {
                reject(true);
            }
        });

    }
    BuscarTipoActividad = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = ``;
                if(this.state.idproyecto){
                    url = `${config.endPoint}api/baseactividad/tipoactividad/${this.state.idproyecto}`;
                }else{
                    url = `${config.endPoint}api/baseactividad/tipoactividad`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ TipoActividad: data.content });
                        // console.log(data.content);
                        resolve(true);
                    }
                    else {
                        this.setState({ TipoActividad: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    BuscarActividadesNombre = async () => {
        await this.setState({ nombrebuscar: this.inputBuscar.current.value });
        await this.BuscarActividades();
    }

    DeshabilitarControles = (valor) => {
        this.inputCodigoActividad.current.readOnly = valor;
        this.inputDescripcionActividad.current.readOnly = valor;
        this.inputTipoActividad.current.readOnly = valor;
        this.inputAbrevActividad.current.readOnly = valor;
        this.inputPrecioActividad.current.readOnly = valor;
        this.selectEstadoActividad.current.disabled = valor;
    }

    AbrirModalAgregar = async (idactividad, accion) => {
        this.BuscarListaProyectosActivos();
        this.setState({ OcultarLoading: false });
        await this.BuscarTipoActividad().then(async () => {
            if (accion === "Ver") {
                await this.setState({ MostrarModalAgregar: true, AccionActividad: accion });
                await this.BuscarActividad(idactividad).then(() => {
                    this.inputCodigoActividad.current.value = this.state.Actividad.codigoActividad;
                    this.inputDescripcionActividad.current.value = this.state.Actividad.descripcion;
                    this.inputTipoActividad.current.value = this.state.Actividad.tipo;
                    this.inputAbrevActividad.current.value = this.state.Actividad.unidad;
                    this.inputPrecioActividad.current.value = this.state.Actividad.precio;
                    this.selectEstadoActividad.current.value = this.state.Actividad.estado.toUpperCase();
                    if(this.state.idproyecto==null){
                        this.selectProyecto.current.value=this.state.Actividad.idproyecto;
                        this.selectProyecto.current.disabled=true;
                    }
                    this.DeshabilitarControles(true);
                    this.botonGuardar.current.disabled = true;
                    this.setState({ OcultarLoading: true });
                }).catch((error) => {
                    if (error) {
                        this.props.history.push('/login');
                    }
                });
            }
            else {
                await this.setState({ MostrarModalAgregar: true, AccionActividad: accion });
                this.buttonModificar.current.hidden = true;
                this.setState({ OcultarLoading: true });
            }
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });
    }
    ModificarActividad = () => {
        this.DeshabilitarControles(false);
        this.buttonModificar.current.disabled = true
        this.botonGuardar.current.disabled = false;
    }
    ValidarDatos = () => {
        try {
            let numero = parseFloat(this.inputPrecioActividad.current.value);
            if (isNaN(numero)) {
                this.setState({ mensajeToast: "El valor ingresado para Precio no es Válido", abrirToast: true, tipotoast: 'error' });
                this.inputPrecioActividad.current.classList.add('is-invalid');
                return false;
            }
            else {

                this.inputPrecioActividad.current.classList.remove('is-invalid');
                this.inputPrecioActividad.current.value = numero;
                return true;
            }
        } catch (error) {
            // console.error(error);
            // this.setState({ mensajeToast: error, abrirToast: true, tipotoast: 'error' });            
        }
    }

    GuardarActividad = (ev) => {
        ev.preventDefault();
        if (this.ValidarDatos()) {
            switch (this.state.AccionActividad) {
                case 'Agregar':
                    this.CrearActividad();
                    break;
                case 'Ver':
                    this.ActualizarActividad();
                    break;
                default:
            }
        }
    }
    CrearActividad = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/baseactividad/agregar`;
            let actividad_ = {
                codigoActividad: this.inputCodigoActividad.current.value,
                descripcion: this.inputDescripcionActividad.current.value,
                unidad: this.inputAbrevActividad.current.value.toUpperCase(),
                precio: this.inputPrecioActividad.current.value,
                tipo: this.inputTipoActividad.current.value.toUpperCase(),
                estado: this.selectEstadoActividad.current.value,
                idproyecto:this.state.idproyecto?this.state.idproyecto:this.selectProyecto.current.value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(actividad_)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Actividad Agregada Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarActividades();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarActividad = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/baseactividad/modificar`;
            let actividad_ = {
                idBaseActividad: this.state.Actividad.idBaseActividad,
                codigoActividad: this.inputCodigoActividad.current.value,
                descripcion: this.inputDescripcionActividad.current.value,
                unidad: this.inputAbrevActividad.current.value.toUpperCase(),
                precio: this.inputPrecioActividad.current.value,
                tipo: this.inputTipoActividad.current.value.toUpperCase(),
                estado: this.selectEstadoActividad.current.value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(actividad_)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Actividad Actualizada Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarActividades();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    CerrarModalAgregar = () => {
        this.setState({ MostrarModalAgregar: false, AccionActividad: "" });
    }
    //*************************************************
    //Funciones InputBuscar
    //*************************************************
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value === '') {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value, pagina: 1 });
            await this.BuscarActividades();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value, pagina: 1 });
            await this.BuscarActividades();
        }
    }

    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarActividades();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarActividades();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    //*************************************************
    // Funciones Listar 
    //*************************************************
    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    // console.log(data);
                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                {/* ************************************************* */}
                {/* NAVBAR Y TITULO */}
                {/* ************************************************* */}
                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - ACTIVIDADES" regresar={() => { this.props.history.push('/configuracion') }} />
                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className=" col-8 col-md-4">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Actividad"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.BuscarActividadesNombre() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregar(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                            {/* <button onClick={() => { this.props.history.push("/configuracion") }} className=" btn btn-outline-secondary m-1 p-1" style={{ width: '130px' }}><i className="fas fa-arrow-left p-1"></i> Regresar</button> */}
                        </div>
                    </div>

                    {/* ************************************************* */}
                    {/* TABLA DE ACTIVIDADES */}
                    {/* ************************************************* */}

                    <div className="row p-1">
                        {/* Tabla Aqui */}
                        {
                            this.state.loadData ?
                                <>
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead className="fondoTituloTabla">
                                                <TableRow>
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.Actividades.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregar(row.idBaseActividad, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                                {/* <i onClick={() => { this.AbrirModalAgregar(row.Nombres, "Modificar") }} title="Modificar" className="fas fa-pencil-alt  mx-2 iconoAcciones" ></i> */}
                                                            </TableCell>
                                                            <TableCell>{row.codigoActividad}</TableCell>
                                                            <TableCell>{row.descripcion}</TableCell>
                                                            <TableCell>{row.tipo}</TableCell>
                                                            <TableCell>{row.t_proyecto.nombreproyecto}</TableCell>
                                                            <TableCell>{row.estado.toUpperCase() === 'ACTIVO' ?
                                                                <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </> :
                                <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* ************************************************* */}
                {/* CUADRO MODAL AGREGAR/MODIFICAR ACTIVIDAD */}
                {/* ************************************************* */}
                {/* Modal Agregar */}
                <Modal
                    show={this.state.MostrarModalAgregar}
                    onHide={() => { this.CerrarModalAgregar() }}
                    backdrop="static"
                    keyboard={false}
                >
                    <form onSubmit={(ev) => { this.GuardarActividad(ev) }}>
                        <Modal.Header>
                            <Modal.Title>Actividad - {this.state.AccionActividad}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Codigo Actividad</small>
                                        <input ref={this.inputCodigoActividad} required className="form-control form-control-sm " placeholder="Ingrese el Codigo de Actividad"></input>
                                    </div>
                                    {
                                        this.state.idproyecto == null ?
                                            <div className="col-md-6">
                                                <small className="text-primary">Proyecto</small>
                                                <select ref={this.selectProyecto} className="form-select form-select-sm" required >
                                                    <option value="" disabled>Seleccione un Proyecto</option>
                                                    {
                                                        this.state.listaProyectosActivos.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            : <></>
                                    }

                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDescripcionActividad} required type="" className="form-control form-control-sm " placeholder="Ingrese una Descripción"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Tipo Actividad</small>
                                        <input ref={this.inputTipoActividad} required type="" className="form-control form-control-sm" list="listaOpciones" placeholder="Ingrese el tipo de Actividad"></input>
                                        <datalist ref={this.datalistTipoActividad} id="listaOpciones">
                                            {/* Map lista tipoActividades */}
                                            {this.state.TipoActividad.map((row, index) => {
                                                return (<option key={index} value={row.tipo.toUpperCase()}></option>)
                                            })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Abrev. Actividad</small>
                                        <input ref={this.inputAbrevActividad} required type="" className="form-control form-control-sm " placeholder="Ingrese una Abrev."></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Precio Actividad</small>
                                        <input ref={this.inputPrecioActividad} required type="" className="form-control form-control-sm " placeholder="Ingrese una Precio."></input>
                                    </div>

                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.selectEstadoActividad} required className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        <button onClick={() => { this.ModificarActividad() }} ref={this.buttonModificar} className="btn btn-sm btn-outline-primary">Modificar</button>
                                    </div>
                                    <div className="col-7 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregar() }} className="btn btn-sm btn-outline-secondary mx-1">Cancelar</button>
                                        <button ref={this.botonGuardar} type="submit" className="btn btn-sm btn-outline-primary mx-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>


            </Fragment>
        );
    }
}

export default configActividad;