import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos, CreateJsonToExcel, ObtenerDetalleToken } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
// import socketIOClient from 'socket.io-client';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal, Container, Row, Col, Carousel, Form, Button ,Card} from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';
class Combustible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            colorIcon: '',
            LoadData: false,
            mensaCargaActualizacion: '',
            MostrarPorcentajeCarga: false,
            cantidadElementos: 0,
            filasPorPagina: 50,
            pagina: 1,
            ListaPaginacion: [],
            DataCombustibles: [],
            MostrarModalFotos: false,
            ListaFotosCombustible: [],
            MostrarModalDetalle: false,
            detalleCombustible: {},
            detalletrabajadorCombustible: {},
            listaFotosDetallaCombustible: [],
            MostrarModalReporteFechas: false,
            idproyecto:null,
        };

        this.inputFechaRegistro = createRef();
        this.inputValorBuscar = createRef();
        this.inputFechaInicioReporteFecha = createRef();
        this.inputFechaFinReporteFecha = createRef();
    }
    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {

                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_SEGURIDAD_COMBUSTIBLE, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if(UsuarioStorage.token){
                        let DetalleToken=JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if(DetalleToken.idproyecto){
                            this.setState({idproyecto:DetalleToken.idproyecto});                            
                        }                        
                    }


                    this.inputFechaRegistro.current.value = moment().format('YYYY-MM-DD');
                    await this.setState({ fechaRegistro: this.inputFechaRegistro.current.value });

                    await this.BuscarCombustiblesFecha();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }
            });
        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }

    BuscarCombustiblesFecha = async () => {
        if (ValidarUsuario()) {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            if(this.state.idproyecto){
                url = `${config.endPoint}api/Combustible/listarquery/${this.state.idproyecto}`;
            }else{
                url = `${config.endPoint}api/Combustible/listarquery`;
            }            

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    fecha: this.inputFechaRegistro.current.value,
                    nombretrabajador: this.inputValorBuscar.current.value.trim()
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ DataCombustibles: data.content, cantidadElementos: data.content.length });
                    await this.Paginacion();
                }
                else {
                    this.setState({ DataCombustibles: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {

                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarFotosCombustible = (idCombustible) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/Combustible/foto/listarfotos/${idCombustible}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    console.log(data);
                    if (data.content) {
                        this.setState({ ListaFotosCombustible: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaFotosCombustible: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarDetalleCombustible = (idCombustible) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/Combustible/detalle/${idCombustible}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    console.log(data);
                    if (data.content) {
                        this.setState({ detalleCombustible: data.content, detalletrabajadorCombustible: data.content.t_trabajador, listaFotosDetallaCombustible: data.content.t_foto_combustibles });
                        resolve(true);
                    }
                    else {
                        this.setState({ detalleCombustible: {}, detalletrabajadorCombustible: {}, listaFotosDetallaCombustible: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }


    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.DataCombustibles.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.DataCombustibles.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.DataCombustibles[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });
    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarCombustiblesFecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarCombustiblesFecha();
    }
    //*************************************************
    //Funciones para Controles
    //*************************************************    
    CambiarFecha = async () => {
        await this.setState({ fechaRegistro: this.inputFechaRegistro.current.value, LoadData: false });
        //await localStorage.setItem(config.storageDateName, this.state.fechaRegistro);
        await this.BuscarCombustiblesFecha();
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.BuscarCombustiblesFecha();
        }
    }
    AbrirMaps = (lat, lng) => {
        let _lat = parseFloat(lat);
        let _lng = parseFloat(lng);

        // console.log(_lat);
        if (_lat !== 0 && !isNaN(_lat)) {
            let url = `http://maps.google.com/?q=${_lat},${_lng}`;
            window.open(url, '_blank');
        } else {
            this.setState({ mensajeToast: "Ubicacion GPS no valida.", abrirToast: true, tipotoast: 'warning' });
        }

    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.BuscarCombustiblesFecha();
        }
    }
    //*************************************************
    //Funciones para Modales
    //*************************************************
    // Modal Fotos
    AbrirModalFotos = (idCombustible) => {

        this.BuscarFotosCombustible(idCombustible).then((respuesta) => {
            if (respuesta) {
                this.setState({ MostrarModalFotos: true });
            }
        })
    }
    CerrarModalFotos = () => {
        this.setState({ MostrarModalFotos: false });
    }
    // Modal Detalle Combustible
    AbrirModalDetalleCombustible = (idCombustible) => {
        this.BuscarDetalleCombustible(idCombustible).then((respuesta) => {
            if (respuesta) {
                this.setState({ MostrarModalDetalle: true });
            }
        })
    }
    CerrarModalDetalleCombustible = () => {
        this.setState({ MostrarModalDetalle: false });
    }
    //Modal Reporte Fechas
    AbrirModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: true });
    }
    CerrarModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: false });
    }
    //*************************************************
    //Reportes
    //*************************************************
    DescargarReporteFecha = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = ``;
            if(this.state.idproyecto){
                url = `${config.endPoint}api/Combustible/reporte/buscarfecha/${this.state.idproyecto}/${this.inputFechaRegistro.current.value}`;
            }else{
                url = `${config.endPoint}api/Combustible/reporte/buscarfecha/${this.inputFechaRegistro.current.value}`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Resumen_${this.inputFechaRegistro.current.value}`, `Registro_${this.inputFechaRegistro.current.value}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ValidarRangosFecha = () => {
        let error = 0;
        console.log("validar datos");
        if (this.inputFechaInicioReporteFecha.current.value.trim() == '') {
            this.inputFechaInicioReporteFecha.current.classList.add('is-invalid');
            error++;
        }
        else {
            this.inputFechaInicioReporteFecha.current.classList.remove('is-invalid');
        }

        if (this.inputFechaFinReporteFecha.current.value.trim() == '') {
            this.inputFechaFinReporteFecha.current.classList.add('is-invalid');
            error++
        } else {
            this.inputFechaFinReporteFecha.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false;
        }
        return true;
    }

    DescargarReporteRangoFecha = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            console.log("ingresar rango fechas");
            if (this.ValidarRangosFecha()) {
                let url = ``;
                if(this.state.idproyecto){
                    url = `${config.endPoint}api/Combustible/reporte/buscarrangofecha/${this.state.idproyecto}/${this.inputFechaInicioReporteFecha.current.value}/${this.inputFechaFinReporteFecha.current.value}`;
                }else{
                    url = `${config.endPoint}api/Combustible/reporte/buscarrangofecha/${this.inputFechaInicioReporteFecha.current.value}/${this.inputFechaFinReporteFecha.current.value}`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        if (data.content.length > 0) {
                            await CreateJsonToExcel(data.content, `Resumen_${this.inputFechaInicioReporteFecha.current.value}_${this.inputFechaFinReporteFecha.current.value}`, `Registro_${this.inputFechaInicioReporteFecha.current.value}_${this.inputFechaFinReporteFecha.current.value}`);
                            this.CerrarModalReporteRangoFechas();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch((error) => {
                    console.log(error);
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`Registro Recarga Combustible`} regresar={() => { this.props.history.push('/seguridad') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <Container className='border mt-1'>
                    <Row className='mt-1'>
                        <Col sm={12} md={3}>
                            <input ref={this.inputFechaRegistro} onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ></input>
                        </Col>
                        <Col sm={12} md={3} className='mt-md-0 mt-1'>
                            <input ref={this.inputValorBuscar} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} className="form-control form-control-sm" placeholder={`Buscar trabajador`}></input>
                        </Col>
                        <Col sm={12} md={3} className='mt-md-0 mt-1'>
                            <Button onClick={() => { this.AbrirModalDetalleCombustible() }} size='sm'>Agregar Registro</Button>
                        </Col>
                        <Col sm={12} md={3} className='text-end mt-md-0 mt-1'>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.DescargarReporteFecha() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Descargar Resumen actual</small></li>
                                    <li onClick={() => { this.AbrirModalReporteRangoFechas() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Descargar Resumen Fechas</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    {/* <li>
                                        <Link to={{ pathname: `/monitoreo/mapa/${this.state.fechaRegistro}` }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                    </li> */}
                                    <li onClick={() => { this.BuscarCombustiblesFecha() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li>

                                </ul>
                            </div>
                        </Col>

                    </Row>

                    <Row className='mt-2'>
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell>Ver</TableCell>
                                                    <TableCell>Fecha</TableCell>
                                                    <TableCell>Placa</TableCell>
                                                    <TableCell>Tipo Combustible</TableCell>
                                                    <TableCell>Conductor</TableCell>
                                                    {/* <TableCell>Grifo</TableCell> */}
                                                    <TableCell>Galones Abastecidos</TableCell>
                                                    <TableCell>Foto</TableCell>
                                                    <TableCell>Proyecto</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalDetalleCombustible(row.idregistrocombustible) }} title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                            </TableCell>

                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.fecha} ${row.hora}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.placa}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.tipo_combustible}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.nombretrabajador}</TableCell>
                                                            {/* <TableCell style={{ fontSize: '0.75em' }}>{row.grifo}</TableCell> */}
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.galons_abastecidos}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                {
                                                                    row.foto > 0 ?
                                                                        <i onClick={() => { this.AbrirModalFotos(row.idregistrocombustible) }} title="Ver" className="fas fa-camera iconoAcciones text-primary" ></i>
                                                                        :
                                                                        <></>
                                                                }
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.nombreproyecto}</TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />
                        }
                    </Row>
                </Container>



                {/* Modal Fotos*/}
                <Modal
                    show={this.state.MostrarModalFotos}
                    onHide={() => { this.CerrarModalFotos() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <div className="container" >
                            <div className="row" style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {this.state.ListaFotosCombustible.map((foto, index) => {
                                        return (
                                            <Carousel.Item key={index} >
                                                <div >
                                                    <img
                                                        className="d-block m-auto"
                                                        src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                        alt={`Imagen ${index + 1}`}
                                                        height='550'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                    />
                                                    <div className="row ">
                                                        <div className="col-6 col-md-6 text-light">
                                                            <span>{`Foto ${index + 1} de ${this.state.ListaFotosCombustible.length}`}</span>
                                                        </div>
                                                        <div className="col-6 col-md-6 text-end text-light">
                                                            <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>

                                        );
                                    })}
                                </Carousel>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal Detale */}
                <Modal
                    show={this.state.MostrarModalDetalle}
                    onHide={() => { this.CerrarModalDetalleCombustible() }}
                    keyboard={false}
                    size="md"
                    centered >
                    <Modal.Header closeButton>
                        Detalle Combustible
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.detalleCombustible ?
                                <Container>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Fecha</small>
                                            <Form.Control size='sm' disabled value={`${this.state.detalleCombustible.fecha} ${this.state.detalleCombustible.hora}`}></Form.Control>
                                        </Col>                                        
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Placa</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.placa}></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Km al Abastecer</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.km_al_abastecer}></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Tipo de combustible</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.tipo_combustible}></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Galones Abastecidos</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.galons_abastecidos}></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col >
                                            <small className='text-primary'>Trabajador</small>
                                            <Form.Control size='sm' disabled value={this.state.detalletrabajadorCombustible.nombretrabajador}></Form.Control>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col >
                                            <small className='text-primary'>Destino</small>
                                            <Form.Control size='sm' disabled></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <small className='text-primary'>Grifo</small>
                                            <Form.Control size='sm' disabled></Form.Control>
                                            <span>GPS</span>
                                        </Col>
                                    </Row> */}
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Factura</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.factura}></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Monto Factura</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.monto_factura}></Form.Control>
                                        </Col>
                                    </Row>
                                    {/* <Row >
                                        <Col >
                                            <small className='text-primary'>Galones Abastecidos</small>
                                            <Form.Control size='sm' disabled></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Monto Factura</small>
                                            <Form.Control size='sm' disabled></Form.Control>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col >
                                            <small className='text-primary'>Observaciones</small>
                                            <Form.Control size='sm' disabled value={this.state.detalleCombustible.observaciones}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr></hr>

                                    <Row>
                                        <Col className='text-center' >
                                            {
                                                this.state.listaFotosDetallaCombustible.map((row, index) => {
                                                    return (
                                                        <Container key={index}>
                                                            <Row className='mt-1'>
                                                                <Col>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <div className='mt-1 mb-1'>
                                                                                <small className='text-primary'>{`Fotografia ${index + 1}`}</small>
                                                                                <img
                                                                                    className="d-block m-auto"
                                                                                    src={`${config.endPoint}api/foto/DownloadFileServer/${row.foto_url}`}
                                                                                    alt={`Imagen `}
                                                                                    height='250'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${row.foto_url}`, '_blank'); }}
                                                                                />
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>                                    
                                </Container>
                                : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.CerrarModalDetalleCombustible() }} variant='outline-primary'>Cerrar</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Reporte Rango Fecha */}
                <Modal
                    show={this.state.MostrarModalReporteFechas}
                    onHide={() => {
                        this.CerrarModalReporteRangoFechas()
                    }}
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">

                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Inicio</small>
                                    <input ref={this.inputFechaInicioReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Fin</small>
                                    <input ref={this.inputFechaFinReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarReporteRangoFecha() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>
                </Modal>


            </Fragment>
        );
    }
}

export default Combustible;