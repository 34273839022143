import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos, CreateJsonToExcel, listarPermisosTipoTrabajo } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
// import socketIOClient from 'socket.io-client';

import { Stack } from '@mui/material';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal, Container, Row, Col, Carousel, Form, Button } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';

class Proyecto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            LoadData: false,
            cantidadElementos: 0,
            filasPorPagina: 100,
            pagina: 1,
            ListaPaginacion: [],                        
            MostrarModalProyecto: false,
            MostrarModalProyectoModificar: false,
            DataProyectos: [],                       
            ProyectoActual: {},
            AccionActual: '',
        };
        
        //Ref 
        this.inputValorBuscar = createRef();
        this.inputNombreProyecto = createRef();
        this.inputDescripcionProyecto = createRef();
        this.selectEstadoProyecto = createRef();
        this.inputObservacionProyecto = createRef();
    }
    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {

                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROYETO, permisos)) {
                    this.BuscarListaProyecto();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }
            });
        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    //Registro Epps
    ValidarControlsProyecto = () => {
        let error = 0;
        if (this.inputNombreProyecto.current.value == '') {
            this.inputNombreProyecto.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputNombreProyecto.current.classList.remove('is-invalid');
        }

        if (this.inputDescripcionProyecto.current.value == '') {
            this.inputDescripcionProyecto.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputDescripcionProyecto.current.classList.remove('is-invalid');
        }

        if (this.selectEstadoProyecto.current.value == '') {
            this.selectEstadoProyecto.current.classList.add('is-invalid');
            error++;
        } else {
            this.selectEstadoProyecto.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    AgregarProyecto = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarControlsProyecto()) {
                let url = '';
                url = `${config.endPoint}api/Proyecto/agregar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }, body: JSON.stringify({
                        nombreproyecto: this.inputNombreProyecto.current.value.toUpperCase(),
                        descripcionproyecto: this.inputDescripcionProyecto.current.value,
                        estado: this.selectEstadoProyecto.current.value,

                    })
                };

                // console.log(headers);

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', MostrarModalProyecto: false });
                        this.BuscarListaProyecto();
                    } else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error' });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ModificarProyecto = async () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/modificar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }, body: JSON.stringify({
                    idproyecto: this.state.ProyectoActual.idproyecto,
                    descripcionproyecto: this.inputDescripcionProyecto.current.value,
                    estado: this.selectEstadoProyecto.current.value,
                })
            };

            // console.log(headers);

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', MostrarModalProyectoModificar: false });
                    this.BuscarListaProyecto();
                } else {
                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error' });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaProyecto = async () => {
        if (ValidarUsuario()) {
            this.setState({ LoadData: false });
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            url = `${config.endPoint}api/Proyecto/listarquery`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    valorbuscar: this.inputValorBuscar.current.value
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data.content);
                if (data.content) {
                    await this.setState({ DataProyectos: data.content, cantidadElementos: data.content.length });
                    await this.Paginacion();
                }
                else {
                    this.setState({ DataEpps: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', LoadData: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.DataProyectos.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.DataProyectos.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.DataProyectos[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });

    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarEppsFecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarEppsFecha();
    }
    //*************************************************
    //Funciones para Controles
    //*************************************************    
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.BuscarListaProyecto();
        }
    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.BuscarListaProyecto();
        }
    }
    //*************************************************
    //Funciones para Modales
    //*************************************************
    // Modal Detalle Epps
    AbrirModalProyecto = () => {        
        this.setState({ MostrarModalProyecto: true });
    }
    CerrarModalProyecto = () => {
        this.setState({ MostrarModalProyecto: false });
    }



    //Modales 
    //Registro Epps Modificar
    AbrirModalProyectoModificar = async (row) => {
        await this.setState({ MostrarModalProyectoModificar: true, ProyectoActual: row, AccionActual: '' });
        this.inputNombreProyecto.current.value = row.nombreproyecto;
        this.inputDescripcionProyecto.current.value = row.descripcionproyecto;
        this.selectEstadoProyecto.current.value = row.estado;

        this.inputNombreProyecto.current.disabled = true;
        this.selectEstadoProyecto.current.disabled = true;
        this.inputDescripcionProyecto.current.disabled = true;
    }
    CerrarModalProyectoModificar = () => {
        this.setState({ MostrarModalProyectoModificar: false });
    }
    HabilitarModificarProyecto = (estado) => {
        if (estado) {
            this.setState({ AccionActual: 'MODIFICAR_REG_EPPS' });
        } else {
            this.setState({ AccionActual: '' });
        }
        this.inputDescripcionProyecto.current.disabled = !estado;
        this.selectEstadoProyecto.current.disabled = !estado;        
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    //*************************************************
    // Funciones de Pantalla
    //*************************************************

    componentDidUpdate() {
        window.addEventListener('resize', this.CambioSizeWindows);
    }
    CambioSizeWindows = () => {
        this.setState({ innerHeight: window.innerHeight, innerWidth: window.innerWidth });
    }


    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`Configuración - Proyectos`} regresar={() => { this.props.history.push('/configuracion') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <Container className='border mt-1'>
                    <Row className='mt-1'>
                        {/* <Col sm={12} md={4}>
                    <input ref={this.inputfechaProgramacion} onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ></input>
                </Col> */}
                        <Col sm={12} md={3} className='mt-md-0 mt-1'>
                            <input ref={this.inputValorBuscar} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} className="form-control form-control-sm" placeholder={`Buscar Proyecto`}></input>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1 '}>
                            <Button onClick={() => { this.BuscarListaProyecto() }} size='sm'>Buscar</Button>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1 text-end'}>
                            <Button onClick={() => { this.AbrirModalProyecto() }} size='sm'>Agregar Proyecto</Button>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'mt-2 text-end' : 'mt-md-0 mt-1 text-end'}>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    {/* <li onClick={() => { this.DescargarReporteFecha() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Descargar Resumen actual</small></li>
                                <li><hr className="dropdown-divider" /></li> */}
                                    <li onClick={() => { this.BuscarListaProyecto() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li>
                                    {/* <li><hr className="dropdown-divider" /></li>
                                <li onClick={() => { this.AbrirModalMaestroEpps() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Ver Maestro Epp's</small></li> */}

                                </ul>
                            </div>
                        </Col>

                    </Row>

                    <Row className='mt-2'>
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell>Ver</TableCell>
                                                    <TableCell>Fecha Registro</TableCell>
                                                    <TableCell>Nombre Proyecto</TableCell>
                                                    <TableCell>Descripcion</TableCell>                                                    
                                                    <TableCell>Estado</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {

                                                    return (

                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalProyectoModificar(row) }} title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${moment(row.fechacreacion).format('YYYY-MM-DD hh-mm:ss')}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.nombreproyecto}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.descripcionproyecto}`}</TableCell>                                                                                                                        
                                                            <TableCell style={{ fontSize: '0.75em' }} className={row.estado == 'ACTIVO' ? 'text-primary' : 'text-danger'}>{`${row.estado}`}</TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </Row>
                </Container>

                {/* Modal Proyecto Modificar */}
                <Modal
                    show={this.state.MostrarModalProyectoModificar}
                    onHide={() => { this.CerrarModalProyectoModificar() }}
                    keyboard={false}
                    size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Modificar Registro
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Nombre Proyecto</small>
                                    <Form.Control ref={this.inputNombreProyecto} size='sm' placeholder='Ingrese el nombre del proyecto'></Form.Control>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Descripcion Proyecto</small>
                                    <Form.Control ref={this.inputDescripcionProyecto} size='sm' placeholder='Ingrese la descripcion del proyecto'></Form.Control>
                                </Col>
                            </Row>                            
                            <Row>
                                <Col>
                                    <small className='text-primary'>Estado</small>
                                    <Form.Select ref={this.selectEstadoProyecto}>
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    {
                                        this.state.AccionActual == 'MODIFICAR_REG_EPPS' ?
                                            <Button size='sm' onClick={() => { this.CerrarModalProyectoModificar(false) }} variant='outline-primary'>Cancelar</Button>
                                            : <Button size='sm' onClick={() => { this.HabilitarModificarProyecto(true) }} variant='outline-primary'>Modificar</Button>
                                    }
                                </Col>
                                <Col className='text-end'>
                                    {
                                        this.state.AccionActual == 'MODIFICAR_REG_EPPS' ?
                                            <Button size='sm' onClick={() => { this.ModificarProyecto() }} variant='primary'>Guardar</Button>
                                            : <></>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Agregar Proyecto */}
                <Modal
                    show={this.state.MostrarModalProyecto}
                    onHide={() => { this.CerrarModalProyecto() }}
                    keyboard={false}
                    size="md"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Agregar Registro
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Nombre Proyecto</small>
                                    <Form.Control ref={this.inputNombreProyecto} size='sm' list='dataTrabajadores' placeholder='Ingrese el nombre del proyecto'></Form.Control>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Descripción Proyecto</small>
                                    <Form.Control ref={this.inputDescripcionProyecto} size='sm' list='dataTrabajadores' placeholder='Ingrese la descripción del proyecto'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Estado</small>
                                    <Form.Select ref={this.selectEstadoProyecto}>
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>

                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.AgregarProyecto() }} variant='primary'>Agregar Registro</Button>
                        </div>
                    </Modal.Footer>
                </Modal>       

               

            </Fragment>
        );
    }
}

export default Proyecto;