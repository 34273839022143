 const config={
    // endPoint:'http://192.168.1.14:8080/',    
    endPoint:'https://backend-siscomer-operaciones-comerciales.360softsolutionsapp.com/',                 
    splitFileRows:500,
    ApiKeyAmazon:'',
    storageName:'siscomer-webtoken',
    storageDateName:'siscomer-datetime-program',
    storageDateIniReporte:'siscomer-datetime-inicio-report',
    storageDateFinReporte:'siscomer-datetime-fin-report',
    storageFilterTrabajo:'siscomer-filter-work',
    MessageErrorServer:'Error en el Servidor,ERROR_CONNECTION ',
    cantidadFotosTamañoMaximo:1000,
    aplicationVersion:'Siscomer v2.2.6',
}
export default config;
