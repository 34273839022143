import React, { Component, Fragment, createRef, useRef } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken, ReadExcelfileToJson } from '../../utils/utils'
import config from './../../../config/config';

import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//ReactBootstral
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';

class configBasesAp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            permisos: [],
            mostrarModalCargarArchivos: false,
            mostrarModalEliminarArchivos: false,
            columns: [
                {
                    field: 'nombrearchivo', headerName: 'Archivo Base', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <Container>
                                <Row >

                                    <Col>
                                        <Form.Label>{`${cellValues.row.nombrearchivo}`}</Form.Label>
                                    </Col>
                                    <Col>
                                        <Spinner animation="border" size="sm" className={this.state.loadDataPostes ? "" : "visually-hidden"} />
                                    </Col>
                                </Row>
                            </Container>
                        );
                    }
                },
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Cargar elemento">
                                    <IconButton onClick={() => {
                                        this.AbrirModalSubirArchivo();
                                    }}>
                                        <PublishIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Ver detalle">
                                    <IconButton onClick={() => {
                                        this.props.history.push(`/configuracion/basesap/detalle/${cellValues.row.nombrearchivo}`)
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Eliminar">
                                    <IconButton onClick={() => {
                                        this.AbrirModalElminarArchivos();
                                    }}>
                                        <DeleteForeverIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'fechamodificacion', headerName: 'Fecha Modificación', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'cantelementos', headerName: 'Cant. Elementos', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rows: [
                { id: 1, nombrearchivo: 'POSTES', acciones: '', fechamodificacion: 'Ultima Carga 10/01/2024 10:25:16', cantelementos: '125,256 elementos' },
                // { id: 2, nombrearchivo: 'Suministros', acciones: '', fechamodificacion: 'Ultima Carga 10/01/2024 10:25:16', cantelementos: '452,256 elementos' },
                // { id: 3, nombrearchivo: 'Otros', acciones: '', fechamodificacion: 'Ultima Carga 10/01/2024 10:25:16', cantelementos: '25,256 elementos' },
            ],
            mostrarLoadCargarArchivo: false,
            infoDataPostes: {
                cantidad: 0,
                fechaCarga: '',
                PeriodoCarga: ''
            },
            infoDataSuministros: {
                cantidad: 0,
                fechaCarga: '',
                periodo: ''
            },
            loadDataPostes: false,
            loadDataSuministros: false,

        };
        // Referencias
        this.inputFileCargarArchivo = createRef();
        this.inputTextPeriodo = createRef();
        this.inputSelectTipoArchivo = createRef();
    }


    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Acciones Aqui
                    this.BuscarUltimoArchivoCargaBasePostes();
                    this.BuscarUltimoArchivoCargaGpsSuministros();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // CARGAR BASE POSTES
    //***************************************************** */
    ValidarModalCargaArchivosPostes = () => {
        let cantErrores = 0;

        if (this.inputSelectTipoArchivo.current.value === "") {
            this.inputSelectTipoArchivo.current.classList.add("is-invalid");
            cantErrores++;
        } else {
            this.inputSelectTipoArchivo.current.classList.remove("is-invalid");
        }

        if (this.inputTextPeriodo.current.value === "") {
            this.inputTextPeriodo.current.classList.add("is-invalid");
            cantErrores++;
        } else {
            this.inputTextPeriodo.current.classList.remove("is-invalid");
        }

        if (this.inputFileCargarArchivo.current.files.length == 0) {
            this.inputFileCargarArchivo.current.classList.add("is-invalid");
            cantErrores++
        } else {
            this.inputFileCargarArchivo.current.classList.remove("is-invalid");
        }

        if (cantErrores > 0) {
            return false
        }
        return true;
    }

    CargarArchivos = async () => {
        if (this.ValidarModalCargaArchivosPostes()) {
            switch (this.inputSelectTipoArchivo.current.value) {
                case "BASE_POSTES":
                    if (this.inputFileCargarArchivo.current.files.length > 0) {
                        this.inputFileCargarArchivo.current.classList.remove('is-invalid');
                        this.setState({ mostrarLoadCargarArchivo: true });

                        //Obtener usuario Storage
                        let UsuarioStorage = ObtenerUsuario();
                        UsuarioStorage = JSON.parse(UsuarioStorage);

                        // Leer Archivo Excel
                        let DataExcel = null;
                        await ReadExcelfileToJson(this.inputFileCargarArchivo.current.files[0]).then(async (respuesta) => {
                            DataExcel = respuesta;
                        }).catch((error) => {
                            DataExcel = [];
                            this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
                        });

                        // Fecha actual
                        const fechaactual = new Date();

                        // Buscando Elementos Necesarios
                        if (DataExcel.length > 0) {
                            let arrayDatos = [];

                            for (let x = 0; x < DataExcel.length; x++) {
                                let nuevaFila = {
                                    codNod: '',
                                    codSed: '',
                                    sistemaElectrico: '',
                                    simEst: '',
                                    etiEst: '',
                                    sed: '',
                                    EtiEAPotencia: '',
                                    CanLamPorEBT: '',
                                    CentroTransformacion: '',
                                    AlimentadorMT: '',
                                    Distrito: '',
                                    Direccion: '',
                                    CantidadLamparasPorSed: '',
                                    Modular: '',
                                    PotInstalada: '',
                                    TotLampPormodular: '',
                                    Localidad: '',
                                    PeriodoCarga: '',
                                    fechaCarga: '',
                                    lat: 0,
                                    lng: 0,
                                    idproyecto: 0,
                                    idtrabajador: 0
                                };
                                for (var clave in DataExcel[x]) {
                                    //Cargar Tipo Trabajo elegido                                    

                                    //Verificando Clave Existe
                                    if (DataExcel[x].hasOwnProperty(clave)) {
                                        //Verificando si la Clave es Cabecera o Material 
                                        switch (clave) {
                                            case 'cIdNod':
                                                nuevaFila.codNod = DataExcel[x][clave];
                                                break;
                                            case 'cIdSED':
                                                nuevaFila.codSed = DataExcel[x][clave];
                                                break;
                                            case 'SistemaElectrico':
                                                nuevaFila.sistemaElectrico = DataExcel[x][clave];
                                                break;
                                            case 'SimEST':
                                                nuevaFila.simEst = DataExcel[x][clave];
                                                break;
                                            case 'EtiEST':
                                                nuevaFila.etiEst = DataExcel[x][clave];
                                                break;
                                            case 'SED':
                                                nuevaFila.sed = DataExcel[x][clave];
                                                break;
                                            case 'EtiEAPotencia':
                                                nuevaFila.EtiEAPotencia = DataExcel[x][clave];
                                                break;
                                            case 'CanLamPorEBT':
                                                nuevaFila.CanLamPorEBT = DataExcel[x][clave];
                                                break;
                                            case 'CentroTransformacion':
                                                nuevaFila.CentroTransformacion = DataExcel[x][clave];
                                                break;
                                            case 'AlimentadorMT':
                                                nuevaFila.AlimentadorMT = DataExcel[x][clave];
                                                break;
                                            case 'Distrito':
                                                nuevaFila.Distrito = DataExcel[x][clave];
                                                break;
                                            case 'Direccion':
                                                nuevaFila.Direccion = DataExcel[x][clave];
                                                break;
                                            case 'CantidadLamparasPorSed':
                                                nuevaFila.CantidadLamparasPorSed = DataExcel[x][clave];
                                                break;
                                            case 'Modular':
                                                nuevaFila.Modular = DataExcel[x][clave];
                                                break;
                                            case 'PotInstalada':
                                                nuevaFila.PotInstalada = DataExcel[x][clave];
                                                break;
                                            case 'TotLampPorModular':
                                                nuevaFila.TotLampPormodular = DataExcel[x][clave];
                                                break;
                                            case 'Localidad':
                                                nuevaFila.Localidad = DataExcel[x][clave];
                                                break;
                                            case 'lat':
                                                let _valorLat = parseFloat(DataExcel[x][clave]);
                                                if (isNaN(_valorLat) || _valorLat == 0) {
                                                    nuevaFila.lat = 0;
                                                }
                                                else {
                                                    nuevaFila.lat = _valorLat;
                                                }
                                                break;
                                            case 'lng':
                                                let _valorLng = parseFloat(DataExcel[x][clave]);
                                                if (isNaN(_valorLng) || _valorLng == 0) {
                                                    nuevaFila.lng = 0;
                                                }
                                                else {
                                                    nuevaFila.lng = _valorLng;
                                                }
                                                break;
                                        }
                                    }
                                    nuevaFila.PeriodoCarga = this.inputTextPeriodo.current.value;
                                    nuevaFila.fechaCarga = fechaactual;
                                    nuevaFila.idproyecto = this.state.idproyecto;
                                    nuevaFila.idtrabajador = UsuarioStorage.id;
                                }
                                arrayDatos.push(nuevaFila);
                            }

                            if (arrayDatos.length > 0) {
                                // Buscar elementos dusplicados - Dividir Elementos en segmentos                    
                                let tamanioDivision = config.splitFileRows;
                                let result = new Array(Math.ceil(arrayDatos.length / tamanioDivision)).fill().map(_ => arrayDatos.splice(0, tamanioDivision));
                                // console.log(result);
                                this.SubirArchivoPostes(result);
                            }
                        }
                        else {
                            this.setState({ mensajeToast: "No se encontraron registros en el archivo", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                        }

                    }
                    else {
                        this.inputFileSubirArchivo.current.classList.add('is-invalid');
                    }

                    break;
                case "GPS_SUMINISTROS":

                    if (this.inputFileCargarArchivo.current.files.length > 0) {
                        this.inputFileCargarArchivo.current.classList.remove('is-invalid');
                        this.setState({ mostrarLoadCargarArchivo: true });

                        //Obtener usuario Storage
                        let UsuarioStorage = ObtenerUsuario();
                        UsuarioStorage = JSON.parse(UsuarioStorage);

                        // Leer Archivo Excel
                        let DataExcel = null;
                        await ReadExcelfileToJson(this.inputFileCargarArchivo.current.files[0]).then(async (respuesta) => {
                            DataExcel = respuesta;
                        }).catch((error) => {
                            DataExcel = [];
                            this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
                        });

                        // Fecha actual
                        const fechaactual = new Date();

                        // Buscando Elementos Necesarios
                        if (DataExcel.length > 0) {
                            let arrayDatos = [];

                            for (let x = 0; x < DataExcel.length; x++) {
                                let nuevaFila = {
                                    contrato: '',
                                    nim: '',
                                    tipo: '',
                                    nombres: '',
                                    direccion: '',
                                    sed: '',
                                    periodo: '',
                                    lat: 0,
                                    lng: 0,
                                    idproyecto: 0,
                                };
                                for (var clave in DataExcel[x]) {
                                    //Cargar Tipo Trabajo elegido                                    

                                    //Verificando Clave Existe
                                    if (DataExcel[x].hasOwnProperty(clave)) {
                                        //Verificando si la Clave es Cabecera o Material 
                                        switch (clave) {
                                            case 'CONTRATO':
                                                nuevaFila.contrato = DataExcel[x][clave];
                                                break;
                                            case 'NIM':
                                                nuevaFila.nim = DataExcel[x][clave];
                                                break;
                                            case 'TIPO':
                                                nuevaFila.tipo = DataExcel[x][clave];
                                                break;
                                            case 'NOMBRES':
                                                nuevaFila.nombres = DataExcel[x][clave];
                                                break;
                                            case 'DIRECCION':
                                                nuevaFila.direccion = DataExcel[x][clave];
                                                break;
                                            case 'SED':
                                                nuevaFila.sed = DataExcel[x][clave];
                                                break;
                                            case 'GPS1':                                                
                                                let _valorLat = parseFloat(DataExcel[x][clave]);
                                                if (isNaN(_valorLat) || _valorLat == 0) {
                                                    nuevaFila.lat = 0;
                                                }
                                                else {
                                                    nuevaFila.lat = _valorLat;
                                                }
                                                break;
                                            case 'GPS 2':
                                                let _valorLng = parseFloat(DataExcel[x][clave]);
                                                if (isNaN(_valorLng) || _valorLng == 0) {
                                                    nuevaFila.lng = 0;
                                                }
                                                else {
                                                    nuevaFila.lng = _valorLng;
                                                }
                                                break;
                                        }
                                    }
                                    nuevaFila.periodo = this.inputTextPeriodo.current.value;
                                    nuevaFila.fechaCarga = fechaactual;
                                    nuevaFila.idproyecto = this.state.idproyecto;
                                    nuevaFila.idtrabajador = UsuarioStorage.id;

                                }
                                arrayDatos.push(nuevaFila);
                            }

                            if (arrayDatos.length > 0) {
                                // Buscar elementos dusplicados - Dividir Elementos en segmentos                    
                                let tamanioDivision = config.splitFileRows;
                                let result = new Array(Math.ceil(arrayDatos.length / tamanioDivision)).fill().map(_ => arrayDatos.splice(0, tamanioDivision));
                                
                                this.SubirArchivoGpsSuministros(result);
                            }
                        }
                        else {
                            this.setState({ mensajeToast: "No se encontraron registros en el archivo", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                        }
                    }
                    else {
                        this.inputFileSubirArchivo.current.classList.add('is-invalid');
                    }
                    break;
                case "":
                    this.setState({ abrirToast: true, mensajeToast: "No se elecciono un elemento valido.", tipotoast: 'error' });
                    break;
            }

        } else {
            this.setState({ abrirToast: true, mensajeToast: "Faltan completar datos para realizar esta acción.", tipotoast: 'error' });
        }
    }

    SubirArchivoPostes = (baseCargar) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basepostesap/cargarbase`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ base: baseCargar })
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: `Se cargaron ${data.content} elementos.`, tipotoast: 'success' });
                    this.CerrarModalSubirArchivo();
                    this.BuscarUltimoArchivoCargaBasePostes();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({ mostrarLoadCargarArchivo: false });
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', mostrarLoadCargarArchivo: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    SubirArchivoGpsSuministros = (baseCargar) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basesuministros/cargarbase`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ base: baseCargar })
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: `Se cargaron ${data.content} elementos.`, tipotoast: 'success' });
                    this.CerrarModalSubirArchivo();                    
                    this.BuscarUltimoArchivoCargaGpsSuministros();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({ mostrarLoadCargarArchivo: false });
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', mostrarLoadCargarArchivo: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    //***************************************************** */
    // Inicio de Procedimientos
    //***************************************************** */
    BuscarUltimoArchivoCargaBasePostes = async () => {
        if (ValidarUsuario()) {
            await this.setState({ loadDataPostes: true });
            let url = '';
            url = `${config.endPoint}api/basepostesap/listarultimabasecarga/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    this.setState({ infoDataPostes: data.content[0], loadDataPostes: false });
                }
                else {
                    this.setState({ infoDataPostes: { cantidad: 0, fechaCarga: '', PeriodoCarga: '' }, loadDataPostes: false });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', loadDataPostes: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarUltimoArchivoCargaGpsSuministros = async () => {
        if (ValidarUsuario()) {
            await this.setState({ loadDataSuministros: true });
            let url = '';
            url = `${config.endPoint}api/basesuministros/listarultimabasecarga/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    this.setState({ infoDataSuministros: data.content[0], loadDataSuministros: false });
                }
                else {
                    this.setState({ infoDataSuministros: { cantidad: 0, fechaCarga: '', periodo: '' }, loadDataSuministros: false });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', loadDataSuministros: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalSubirArchivo = () => {
        this.setState({ mostrarModalCargarArchivos: true,mostrarLoadCargarArchivo:false });
    }
    CerrarModalSubirArchivo = () => {
        this.setState({ mostrarModalCargarArchivos: false,mostrarLoadCargarArchivo:false });
    }
    AbrirModalElminarArchivos = () => {
        this.setState({ mostrarModalEliminarArchivos: true });
    }
    CerrarModalElminarArchivos = () => {
        this.setState({ mostrarModalEliminarArchivos: false });
    }
    
    // ********************************************************
    // Toast Notificaciones
    // ********************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast} color={this.state.tipotoast}>
                        {/* <AlertTitle>{``}</AlertTitle> */}
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo="Configuracion - Bases AP" regresar={() => { this.props.history.push('/configuracion') }} />
                <Container className='mt-2 border'>
                    <Row className='my-1'>
                        <Col className='text-end'>
                            <Button size='sm' onClick={() => { this.AbrirModalSubirArchivo(); }}>
                                Cargar Archivo
                            </Button>
                        </Col>
                    </Row>
                    <Row >
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow className="fondoTituloTabla">
                                        <TableCell style={{ color: 'aliceblue' }} >{`Acciones`}</TableCell>
                                        <TableCell style={{ color: 'aliceblue' }} >{`Archivo`}</TableCell>
                                        <TableCell style={{ color: 'aliceblue' }} >{`Fecha Carga`}</TableCell>
                                        <TableCell style={{ color: 'aliceblue' }} >{`Cantidad`}</TableCell>
                                        <TableCell style={{ color: 'aliceblue' }} >{`Periodo Carga`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* POSTES */}
                                    <TableRow >
                                        <TableCell>
                                            <Tooltip title="Ver detalle">
                                                <IconButton onClick={() => {
                                                    // this.props.history.push(`/configuracion/basesap/detalle/${`BASE_POSTES`}`)
                                                }}>
                                                    <VisibilityIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Eliminar">
                                                <IconButton onClick={() => {
                                                    this.AbrirModalElminarArchivos();
                                                }}>
                                                    <DeleteForeverIcon fontSize='small' color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {`BASE_POSTES`}
                                            {
                                                this.state.loadDataPostes ?
                                                    <Spinner animation="border" size="sm" className='mx-2' />
                                                    : <></>
                                            }
                                        </TableCell>
                                        <TableCell>{`Ultima Carga ${this.state.infoDataPostes.fechaCarga ? moment(this.state.infoDataPostes.fechaCarga).format("DD/MM/YYYY hh:mm:ss") : ""}`}</TableCell>
                                        <TableCell>{`${this.state.infoDataPostes.cantidad} elementos encotrados.`}</TableCell>
                                        <TableCell>{`${this.state.infoDataPostes.PeriodoCarga}`}</TableCell>
                                    </TableRow>

                                    {/* POSTES */}
                                    <TableRow >
                                        <TableCell>
                                            <Tooltip title="Ver detalle">
                                                <IconButton onClick={() => {
                                                    // this.props.history.push(`/configuracion/basesap/detalle/${`GPS_SUMINISTROS`}`)
                                                }}>
                                                    <VisibilityIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Eliminar">
                                                <IconButton onClick={() => {
                                                    this.AbrirModalElminarArchivos();
                                                }}>
                                                    <DeleteForeverIcon fontSize='small' color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {`GPS_SUMINISTROS`}
                                            {
                                                this.state.loadDataSuministros ?
                                                    <Spinner animation="border" size="sm" className='mx-2' />
                                                    : <></>
                                            }
                                        </TableCell>
                                        <TableCell>{`Ultima Carga ${this.state.infoDataSuministros.fechaCarga ? moment(this.state.infoDataSuministros.fechaCarga).format("DD/MM/YYYY hh:mm:ss") : ""}`}</TableCell>
                                        <TableCell>{`${this.state.infoDataSuministros.cantidad} elementos encotrados.`}</TableCell>
                                        <TableCell>{`${this.state.infoDataSuministros.periodo}`}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Row>
                </Container>

                {/* Modal cargarBases */}
                <Modal
                    show={this.state.mostrarModalCargarArchivos}
                    onHide={() => { this.CerrarModalSubirArchivo() }}
                    size='lg'
                    backdrop="static"
                    keyboard={false}
                // centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cargar Bases</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className='text-primary'>Seleccione el Tipo de Archivo</Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='text-primary'>Ingrese el Periodo</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Select size='sm' ref={this.inputSelectTipoArchivo}>
                                        <option value="">Selecciona un elemento.</option>
                                        <option value="BASE_POSTES">BASE_POSTES</option>
                                        <option value="GPS_SUMINISTROS">GPS_SUMINISTROS</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control ref={this.inputTextPeriodo} type='text' size='sm' placeholder='Ingresa el periodo. Ejm. 202401'></Form.Control>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Selecciona el archivo a cargar</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Control ref={this.inputFileCargarArchivo} type="file" size='sm' />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Button disabled={this.state.mostrarLoadCargarArchivo ? true : false} variant="outline-primary" size='sm' onClick={() => { this.CargarArchivos() }}>Cargar</Button>
                                </Col>
                            </Row>
                            <Row className='mt-2 mb-2'>
                                <Col>
                                    <small className='text-secondary'>Ojo: El sistema verificara el archivo cargado y agregara los registros en base al criterio correspondiente , en caso el valor ya exista sera actualizado para la informacion del ultimo archivo cargado.</small>
                                </Col>
                            </Row>
                            {
                                this.state.mostrarLoadCargarArchivo ?
                                    <Row >
                                        <Col className="text-center align-items-center">
                                            <Spinner animation="border" variant="primary" />
                                            <Form.Label className='mx-3'>Subiendo Archivo....</Form.Label>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                        </Container>
                    </Modal.Body>
                </Modal>

                {/* Modal Eliminar Archivo */}
                <Modal
                    show={this.state.mostrarModalEliminarArchivos}
                    onHide={() => { this.CerrarModalElminarArchivos() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>Eliminar Archivos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Selecciona el archivo a cargar</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Select size='sm'>
                                        <option>Seleccionar Elemento</option>
                                        <option value="1">Elemento1</option>
                                        <option value="2">Elemento2</option>
                                        <option value="3">Elemento3</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-secondary'>
                                        Seleccione el nombre del archivo cargado para ser eliminado. Una ves eliminado los registros no pueden ser recuperados.
                                    </small>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-secondary'>Cerrar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary'>Eliminar Seleccionado</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>


            </Fragment>
        );
    }
}

export default configBasesAp;