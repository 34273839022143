import React, { Component, createRef, Fragment } from 'react';
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SpinnerCarga from './../utils/spinner'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from '../utils/utils';
import config from './../../config/config';
import moment from 'moment';

// import socketIOClient from 'socket.io-client';

ChartJS.register(...registerables);
class estadistica extends Component {


    constructor(props) {
        super(props);
        this.state = {
            LoadData: false,
            TiposTrabajoFechaProgramada: [],
            fechaprogramacion: '',
            tipoTrabajoSeleccionado: 'TODOS',
            ListaProgramacion: [],
            etiquetasResumen: [],
            dataResumenEjecutado: [],
            dataResumenNoEjecutado: [],
            dataResumenImpedimento: [],
            dataResumenProgramado: [],
            dataGeneral: {},
            dataTecnicos: {
                labels: ['Alejandro', 'Juan Cacya', 'Enrrique', 'roberto', 'Maria', 'ana luisa', 'Reanto'],
                datasets: [
                    {
                        type: 'line',
                        label: 'Total Programado',
                        borderColor: 'rgba(54, 162, 235)',
                        borderWidth: 1,
                        fill: false,
                        data: [85, 66, 45, 29, 37, 115, 96],
                    },
                    {
                        type: 'bar',
                        label: 'Ejecutado',
                        backgroundColor: 'rgba(54, 162, 235, 0.3)',
                        borderColor: 'rgba(54, 162, 235, 0.9)',
                        data: [86, 60, 20, 20, 37, 115, 83],
                        // borderColor: 'white',
                        borderWidth: 1,
                    },
                    {
                        type: 'bar',
                        label: 'Faltante',
                        backgroundColor: 'rgba(255, 99, 132,0.2)',
                        borderColor: 'rgba(255, 99, 132,0.9)',
                        borderWidth: 1,
                        data: [1, 6, 15, 9, 0, 0, 13],
                    },
                    {
                        type: 'bar',
                        label: 'Impedimento',
                        backgroundColor: 'rgba(255, 99, 132,0.2)',
                        borderColor: 'rgba(255, 99, 132,0.9)',
                        borderWidth: 1,
                        data: [1, 6, 15, 9, 0, 0, 13],
                    },
                ],
            },
            // dataBarHorizontal: {
            //     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            //     datasets: [
            //         {
            //             label: '# of Votes',
            //             data: [12, 19, 3, 5, 2, 3],
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(54, 162, 235, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(153, 102, 255, 0.2)',
            //                 'rgba(255, 159, 64, 0.2)',
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)',
            //             ],
            //             borderWidth: 1,
            //         },
            //     ],
            // },
            dataPie: {},
            idproyecto: null,
        };

        //Referencias
        this.inputfechaProgramacion = createRef();
        this.selectTipoTrabajo = createRef();

        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICA, permisos)) {

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    let fechaStorage = await localStorage.getItem(config.storageDateName);
                    if (fechaStorage) {
                        this.inputfechaProgramacion.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: fechaStorage });
                    } else {
                        this.inputfechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
                    }

                    this.buscarTiposTrabajosFechaProgramada();
                    this.buscarEstadisticasProgramacion();
                    // this.SocketConect();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // SocketConect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.buscarEstadisticasProgramacion();
    //     });
    // }
    // componentWillUnmount(){
    //     this.socket.close();
    // }

    buscarEstadisticasProgramacion = async () => {
        if (ValidarUsuario()) {

            // let fecha = moment(this.inputfechaProgramacion.current.value).format('YYYY-MM-DD');
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            let url = '';

            if (this.state.tipoTrabajoSeleccionado === 'TODOS') {
                // url = `${config.endPoint}api/trabajosuministro/estadisticas/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
                url = `${config.endPoint}api/trabajosuministro/estadisticasV2/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
            } else {
                // url = `${config.endPoint}api/trabajosuministro/estadisticas/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.tipoTrabajoSeleccionado}`;
                url = `${config.endPoint}api/trabajosuministro/estadisticasV2/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.tipoTrabajoSeleccionado}`;
            }

            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    await this.setState({ ListaProgramacion: data.content });
                }
                else {
                    this.setState({ ListaProgramacion: [] });
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'info' });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

            this.procesarEstadisticas();
        }
        else {
            this.props.history.push('/login');
        }
    }
    procesarEstadisticas = async () => {
        let arrayEtiquetas = [];
        let arrayProgramados = [];
        let arrayEjecutados = [];
        let arrayNoEjecutados = [];
        let arrayImpedimento = [];

        let arrayPieResumen = [];

        let cantProgramado = 0;
        let cantEjecutados = 0;
        let cantNoEjecutados = 0;
        let cantImpedimento = 0;

        // console.log(this.state.ListaProgramacion);
        if (this.state.ListaProgramacion.length <= 0) {
            this.setState({ mensajeToast: "No se encontraron datos de trabajos Programados para esta Fecha o Tipo de Trabajo", abrirToast: true, tipotoast: 'info', LoadData: true });
        } else {
            for (let x = 0; x < this.state.ListaProgramacion.length; x++) {
                // console.log(this.state.ListaProgramacion[x]);
                if (this.state.tipoTrabajoSeleccionado === "TODOS") {
                    arrayEtiquetas.push(this.state.ListaProgramacion[x].tipotrabajo);
                } else {
                    arrayEtiquetas.push(this.state.ListaProgramacion[x].nombretrabajador);
                }

                arrayProgramados.push(this.state.ListaProgramacion[x].Programado);
                cantProgramado = cantProgramado + parseFloat(this.state.ListaProgramacion[x].Programado);

                arrayEjecutados.push(this.state.ListaProgramacion[x].EJECUTADO);
                cantEjecutados = cantEjecutados + parseFloat(this.state.ListaProgramacion[x].EJECUTADO);
                arrayNoEjecutados.push(this.state.ListaProgramacion[x].NOEJECUTADO);
                cantNoEjecutados = cantNoEjecutados + parseFloat(this.state.ListaProgramacion[x].NOEJECUTADO);
                arrayImpedimento.push(this.state.ListaProgramacion[x].IMPEDIMENTO);
                cantImpedimento = cantImpedimento + parseFloat(this.state.ListaProgramacion[x].IMPEDIMENTO);
            }
            arrayPieResumen.push(cantNoEjecutados);
            arrayPieResumen.push(cantEjecutados);
            arrayPieResumen.push(cantImpedimento);

            await this.setState({
                etiquetasResumen: arrayEtiquetas,
                dataResumenProgramado: arrayProgramados,
                dataResumenEjecutado: arrayEjecutados,
                dataResumenNoEjecutado: arrayNoEjecutados,
                dataResumenImpedimento: arrayImpedimento,
            });

            this.setState({
                dataGeneral: {
                    // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
                    labels: this.state.etiquetasResumen,
                    datasets: [
                        {
                            type: 'line',
                            label: 'Total Programado',
                            borderColor: 'rgba(54, 162, 235)',
                            borderWidth: 1,
                            fill: false,
                            data: this.state.dataResumenProgramado
                            // data: [85, 66, 45, 29, 37, 115, 96],
                        },
                        {
                            type: 'bar',
                            label: 'Faltante',
                            backgroundColor: 'rgba(54, 162, 235,0.2)',
                            borderColor: 'rgba(54, 162, 235,0.9)',
                            borderWidth: 1,
                            // data: [1, 6, 15, 9, 0, 0, 13],
                            data: this.state.dataResumenNoEjecutado
                        },
                        {
                            type: 'bar',
                            label: 'Ejecutado',
                            backgroundColor: 'rgba(75, 192, 192,0.2)',
                            borderColor: 'rgba(75, 192, 192,0.9)',
                            borderWidth: 1,
                            data: this.state.dataResumenEjecutado
                            // data: [86, 60, 20, 20, 37, 115, 83],
                            // borderColor: 'white',
                        },
                        {
                            type: 'bar',
                            label: 'Impedimento',
                            backgroundColor: 'rgba(255, 99, 132,0.2)',
                            borderColor: 'rgba(255, 99, 132,0.9)',
                            borderWidth: 1,
                            // data: [1, 6, 15, 9, 0, 0, 13],
                            data: this.state.dataResumenImpedimento
                        },
                    ],
                },
                dataPie: {
                    labels: [
                        `No Ejecutado (${cantNoEjecutados}) - ${Math.round((cantNoEjecutados / (cantProgramado)) * 100)} %`,
                        `Ejecutado (${cantEjecutados}) - ${Math.round((cantEjecutados / (cantProgramado)) * 100)} %`,
                        `Impedimento (${cantImpedimento}) - ${Math.round((cantImpedimento / (cantProgramado)) * 100)} %`
                    ],
                    datasets: [
                        {
                            label: 'Resumen',
                            // data: [1, 85],
                            data: arrayPieResumen,
                            backgroundColor: [
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                            ],
                            borderColor: [
                                'rgba(54, 162, 235, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(255, 99, 132, 1)',
                            ],

                            borderWidth: 1,
                        },
                    ],
                }
                , LoadData: true
            });

        }


    }

    buscarTiposTrabajosFechaProgramada = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/listarProgramadp/${this.state.idproyecto}/${this.state.fechaprogramacion}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TiposTrabajoFechaProgramada: data.content });
                }
                else {
                    this.setState({ TiposTrabajoFechaProgramada: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    elegirArea = async () => {
        await this.setState({ tipoTrabajoSeleccionado: this.selectTipoTrabajo.current.value, LoadData: false });
        this.buscarEstadisticasProgramacion();
    }
    cambiarFecha = async () => {
        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value, LoadData: false });
        await localStorage.setItem(config.storageDateName, this.state.fechaprogramacion);
        this.selectTipoTrabajo.current.value = 'TODOS';
        this.buscarTiposTrabajosFechaProgramada();
        this.buscarEstadisticasProgramacion();
    }

    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant="filled" severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="ESTADISTICAS" regresar={() => { this.props.history.push('/operacionesComerciales') }}></TituloPagina>

                <div className="container mt-1">
                    <div className="row my-1">
                        <div className="col-md-3 ">
                            <input onChange={() => { this.cambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input>
                        </div>

                        {/* //Filtro por areas */}
                        <div className=" col-md-3 mt-1 mt-md-0">
                            <select onChange={() => { this.elegirArea() }} ref={this.selectTipoTrabajo} className="form-select form-select-sm" >
                                <option value="TODOS">Todas  las Areas</option>
                                {
                                    this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                        return (<option key={index} value={row.tipotrabajo}>{row.tipotrabajo}</option>);
                                    })
                                }

                            </select>
                        </div>
                        {/* BOTON ACTUALIZAR */}
                        <div className="col-md-6 mt-1 mt-md-0 text-end">
                            <button onClick={() => { this.buscarEstadisticasProgramacion() }} type="button" className="btn btn-sm btn-outline-success">
                                <i className="fas fa-sync-alt mx-1"></i>
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>

                {
                    this.state.LoadData ?
                        <>
                            {/* TODAS LAS AREAS */}
                            {
                                this.state.tipoTrabajoSeleccionado === 'TODOS' ?
                                    <div className="container border my-1">
                                        {/* Contenido  */}
                                        <div className="row mt-1 mx-1 rounded" style={{ backgroundColor: 'rgb(34,134,195)' }}>
                                            <div className="col-md3 py-1">
                                                <span className="text-light"> <strong>TODAS LAS AREAS (RESUMEN) </strong>{this.state.fechaprogramacion}</span>
                                            </div>
                                        </div>

                                        <div className="row p-1">
                                            <div className="col">
                                                {/* <Bar data={{
                                                    label:['data1','data2'],
                                                    datasets:[1,2,3]
                                                }} options={{

                                                }}></Bar> */}
                                                <Bar data={this.state.dataGeneral}
                                                    // options={
                                                    //     {
                                                    //         responsive: true,
                                                    //         maintainAspectRatio: false,
                                                    //         plugins: {
                                                    //             legend: {
                                                    //                 position: 'top',
                                                    //             },
                                                    //             title: {
                                                    //                 display: true,
                                                    //                 text: 'Resumen Lecturas',
                                                    //             }
                                                    //         }
                                                    //     }
                                                    // }
                                                ></Bar>
                                            </div>

                                        </div>

                                    </div>

                                    : <></>
                            }

                            {/* rESUMEN POR AREA */}
                            {
                                this.state.tipoTrabajoSeleccionado !== 'TODOS' ?
                                    <div className="container border ">
                                        {/* Resumen por areas */}
                                        <div className="row mt-1 mx-1 rounded" style={{ backgroundColor: 'rgb(34,134,195)' }}>
                                            <div className="col-md3 ">
                                                <span className="text-light"><strong>{this.state.tipoTrabajoSeleccionado}</strong> {this.state.fechaprogramacion}</span>
                                            </div>
                                        </div>

                                        <div className="row mt-1 m-1 row justify-content-center">
                                            <div className="col-md-12 col-lg-9 border text-center p-1 my-1">
                                                <div className="fst-normal text-secondary fs-4 ">
                                                    {`Resumen ${this.state.tipoTrabajoSeleccionado} por Trabajador`}
                                                </div>
                                                <Bar data={this.state.dataGeneral} ></Bar>
                                            </div>
                                            <div className="col-8 col-md-6 col-lg-3 border text-center p-1 my-1">
                                                <div className="fst-normal text-secondary fs-4 ">
                                                    Estadistica General del Área
                                                </div>
                                                <Pie data={this.state.dataPie} ></Pie>
                                            </div>
                                        </div>

                                    </div>
                                    : <></>
                            }

                        </>

                        : <SpinnerCarga />
                }

            </Fragment>
        );
    }
}

export default estadistica;