import React, { Component, Fragment, createRef } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../utils/utils'

import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';
import LoadingModal from '../../utils/loadModal';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
import { Snackbar } from '@material-ui/core'
// Material Icons Elements
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';

//ReactBootstral
import AlertBootstrap from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../../config/config';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';

var zip = require('jszip')();

class reporteAp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            pageSizeGridModalDetalleFiltro: 100,
            OcultarLoading: true,
            permisos: [],
            objmenu: null,
            fechaReporteInicio: '',
            fechaReporteFin: '',
            actividadSeleccionada: '',
            mostrarModalDescargarReporte: false,
            mensajeTituloModalAgregar: '',
            mostrarFiltroFechas: true,
            mostrarFiltroAlimentador: false,
            mostrarFiltroTrabjaador: false,
            mostrarFiltroLista: false,
            arrayListaAlimentadoresMt: [],
            arrayInspeccionesApReporte: [],
            arrayBaseElementosAlimentadorMT: [],
            arraListaTecnicos: [],
            arrayBaseReporteExcelInspeccionesAP: [],
            arrayBaseReporteExcelInspeccionesApEjecutados: [],
            mostrarAlertErrorReporteInspeccionAp: false,
            habilitarAlimentadorMt: false,
            mostrarSpinnerDescargarExcel: false,
            mostrarSpinnerDescargarPDF: false,
            columns: [
                // { field: 'colum1', headerName: 'AAAAAAAAA', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'etiEst', headerName: 'etiEst', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'Modular', headerName: 'Modular', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'AlimentadorMT', headerName: 'AlimentadorMT', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Direccion', headerName: 'Dirección', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'fechaejecucion', headerName: 'Fecha Ejecución', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rows: []
        };

        //Referencias
        this.selecTipoActividad = createRef();
        this.inputFechaInicio = createRef();
        this.inputFechaFin = createRef();
        this.selectAlimentadorMt = createRef();

    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Agregar Fecha de Reporte
                    let fechaInicioReporteStorage = await localStorage.getItem(config.storageDateIniReporte);
                    let fechaFinReporteStorage = await localStorage.getItem(config.storageDateFinReporte);

                    let fechaReporteAgregar = moment().format('YYYY-MM-DD');

                    if (fechaInicioReporteStorage) {
                        this.inputFechaInicio.current.value = moment(fechaInicioReporteStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaReporteInicio: fechaInicioReporteStorage });
                    } else {
                        this.inputFechaInicio.current.value = fechaReporteAgregar;
                        await this.setState({ fechaReporteInicio: fechaReporteAgregar });
                    }

                    if (fechaFinReporteStorage) {
                        this.inputFechaFin.current.value = moment(fechaFinReporteStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaReporteFin: fechaFinReporteStorage });
                    } else {
                        this.inputFechaFin.current.value = fechaReporteAgregar;
                        await this.setState({ fechaReporteFin: fechaReporteAgregar });
                    }

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Prociemdimientos Reporte Inspecciones AP
    //***************************************************** */
    BuscarListaALimentadores_ReporteInspeccionesAP = () => {
        switch (this.selecTipoActividad.current.value) {
            case 'INSPECCIONES_AP':
                if (ValidarUsuario()) {
                    this.setState({ OcultarLoading: false });
                    // Reasignar a los suministros Seleccionados
                    let url = `${config.endPoint}api/inspeccionesAp/Reportes/${this.state.idproyecto}/${this.inputFechaInicio.current.value}/${this.inputFechaFin.current.value}`;

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                        })
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        // console.log(data);
                        if (data.content) {
                            this.setState({ arrayListaAlimentadoresMt: data.content, OcultarLoading: true });
                        }
                        else {
                            this.setState({ arrayListaAlimentadoresMt: [], OcultarLoading: true })
                        }
                    }).catch(() => {
                        this.setState({ arrayListaAlimentadoresMt: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    });
                }
                else {
                    this.props.history.push('/login');
                }

                break;
            case 'DEFICIENCIAS_AP':
                break;
            case 'RECLAMOS_AP':
                break;
            default:
                break
        }
    }
    BuscarBasePostesAp_Filtro = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                this.setState({ OcultarLoading: false });
                // Reasignar a los suministros Seleccionados
                let url = `${config.endPoint}api/basepostesap/repoprte/buscarAlimentadorMt/${this.state.idproyecto}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        AlimentadorMT: this.selectAlimentadorMt.current.value
                    })
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.content) {
                        this.setState({ arrayBaseElementosAlimentadorMT: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ arrayBaseElementosAlimentadorMT: [] })
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ arrayBaseElementosAlimentadorMT: [] });
                    reject(true);
                });
            }
            else {
                this.props.history.push('/login');
            }
        });
    }
    BuscarListaTecnicos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                this.setState({ OcultarLoading: false });
                // Reasignar a los suministros Seleccionados
                let url = `${config.endPoint}api/trabajador/listarTodosTrabajadores_inspeccionesAP/${this.state.idproyecto}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log("Lista Trabajadores");
                    // console.log(data);
                    if (data.content) {
                        this.setState({ arraListaTecnicos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ arraListaTecnicos: [] })
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ arraListaTecnicos: [] });
                    reject(true);
                });
            }
            else {
                this.props.history.push('/login');
            }
        });
    }
    Buscar_InspeccionesAP_Filtro = async () => {
        if (ValidarUsuario()) {
            await this.BuscarBasePostesAp_Filtro();
            await this.BuscarListaTecnicos();

            //Buscar Elementos Base Alimentador

            this.setState({ OcultarLoading: false });
            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/ReportesInspeccionAp/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    fechainicio: this.inputFechaInicio.current.value,
                    fechafin: this.inputFechaFin.current.value,
                    AlimentadorMT: this.selectAlimentadorMt.current.value
                })
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.content) {
                    this.setState({ arrayInspeccionesApReporte: data.content, rows: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ arrayInspeccionesApReporte: [], rows: [], OcultarLoading: true })
                }
            }).catch(() => {
                this.setState({ arrayInspeccionesApReporte: [], rows: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Descargar Fotos , Reportes, Excel
    //***************************************************** */
    DescargarFotosInspeccionAP = async () => {

        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')

            let url = `${config.endPoint}api/fotoInspeccionAp/descargar/listarfotosFechas`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idproyecto: this.state.idproyecto,
                    fechainicio: this.inputFechaInicio.current.value,
                    fechafin: this.inputFechaFin.current.value,
                })
            };

            console.log(headers);

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                console.log(data);
                if (data.content.length > 0) {
                    this.DecargarPorPartes(data.content);
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No se encontraron archivos para este filtro.", tipotoast: 'info', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    DecargarPorPartes = async (listadatos) => {

        // let valorDivision = config.cantidadLimiteArchivoDescargaFotos;
        // console.log(listadatos);
        // let descargarFotoRotulada = this.checkRotularFotoDescarga.current.checked;
        let valorDivision = config.cantidadFotosTamañoMaximo;
        if (listadatos.length > valorDivision) {
            if (window.confirm(`La cantidad de Fotografias ${listadatos.length} que desea descargar supera los ${valorDivision} archivos, esto podria generar un colapso en el sistema.\nLa fotos seran descargadas en partes para mejorar el rendimiendo. \n¿Desea continuar con la descarga de archivos por partes?`)) {
                //Dividir en partes de 1000                
                let listas = Math.ceil(listadatos.length / valorDivision);
                let inicio = 0;
                let fin = 0;
                let cantidad = 0;
                for (let x = 1; x <= listas; x++) {
                    fin = x * valorDivision;
                    for (let y = inicio; y < fin; y++) {
                        if (y < listadatos.length) {
                            //Descargar Archivos                            
                            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas`, mostrarModalDescargarReporte: false });
                            // console.log(listadatos[y].foto_url);                           

                            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[y].foto_url}`).then(async (respuesta) => {
                                cantidad++;
                                await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                                const arraybuffer = await respuesta.arrayBuffer();

                                zip.file(`${listadatos[y].foto_url}`, arraybuffer);
                            }

                            ).catch((error) => {
                                console.log(error);
                            });
                        }
                    }
                    //Generar el Archivo Zip
                    await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                        saveAs(contenido, `Fotos_${this.state.fechaReporteInicio}_${this.state.fechaReporteFin}.zip`);
                    });
                    //Reiniciar el zip
                    zip = require('jszip')();

                    inicio = fin;
                }
                await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }

        } else {
            let cantidad = 0;
            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas`, mostrarModalDescargarReporte: false });

            for (let x = 0; x < listadatos.length; x++) {
                await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[x].foto_url}`).then(async (respuesta) => {
                    cantidad++;
                    await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                    const arraybuffer = await respuesta.arrayBuffer();
                    zip.file(`${listadatos[x].foto_url}`, arraybuffer);

                }
                ).catch((error) => {
                    console.log(error);
                });
            }
            //Generar el Archivo Zip
            await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                saveAs(contenido, `Fotos_${this.state.fechaReporteInicio}_${this.state.fechaReporteFin}.zip`);
            });
            //Reiniciar el zip
            zip = require('jszip')();
            await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
        }
    }
    BuscarFotosPDF_InspeccionesAP = async () => {
        // Obtener Datos de Servicio
        let usuarioLocalstorage = localStorage.getItem(config.storageName)
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);

        if (ValidarUsuario()) {
            try {
                await this.setState({ OcultarLoading: false });

                //Descarga de Fotos segun reporte
                let url = `${config.endPoint}api/fotoInspeccionAp/descargar/listarfotosFechas_reportepdf`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idproyecto: this.state.idproyecto,
                        fechainicio: this.inputFechaInicio.current.value,
                        fechafin: this.inputFechaFin.current.value,
                        AlimentadorMT: this.selectAlimentadorMt.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    console.log(data);
                    if (data.content) {
                        this.GenerarPDF_InspeccionesAP(data.content);

                    } else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
                this.setState({ OcultarLoading: true });

            } catch (error) {
                this.setState({ mensajeToast: error.toString(), abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }
        } else {
            this.props.history.push('/login');
        }

    }
    BuscarFotosPDF_InspeccionesAP_v2 = () => {
        if (ValidarUsuario()) {
            let listaFotosReportePDF = [];
            for (let index = 0; index < this.state.arrayBaseReporteExcelInspeccionesAP.length; index++) {
                if (this.state.arrayBaseReporteExcelInspeccionesAP[index].idInspeccionAP) {
                    // console.log("Base Excel");
                    // console.log(this.state.arrayBaseReporteExcelInspeccionesAP[index]);
                    // Buscar Fotos
                    let inspeccionAPBuscado = this.state.arrayInspeccionesApReporte.find((insp) => insp.id === this.state.arrayBaseReporteExcelInspeccionesAP[index].idInspeccionAP);
                    let fotosInspeccionAprobado = inspeccionAPBuscado.t_fotoInspeccionesAps;
                    // console.log("Inspeccion Encontrado");
                    // console.log(inspeccionAPBuscado);
                    for (let x = 0; x < fotosInspeccionAprobado.length; x++) {
                        if (fotosInspeccionAprobado[x].revisado) {
                            // console.log("Foto inspeccion");
                            // console.log(fotosInspeccionAprobado[x]);

                            let nelemento = {
                                idinspeccionesAp: inspeccionAPBuscado.id,
                                etiEst: inspeccionAPBuscado.etiEst,
                                poste_ingresado: inspeccionAPBuscado.poste_ingresado,
                                modular_ingresado: inspeccionAPBuscado.modular_ingresado,
                                AlimentadorMT: inspeccionAPBuscado.AlimentadorMT,
                                idfotoInspeccionesAp: fotosInspeccionAprobado[x].idfotoInspeccionesAp,
                                fecha: fotosInspeccionAprobado[x].fecha,
                                hora: fotosInspeccionAprobado[x].hora,
                                foto_url: fotosInspeccionAprobado[x].foto_url,
                                revisado: fotosInspeccionAprobado[x].revisado,
                                ITEM: this.state.arrayBaseReporteExcelInspeccionesAP[index].ITEM
                            }
                            listaFotosReportePDF.push(nelemento);
                        }
                    }

                }
            }
            // console.log(listaFotosReportePDF);
            this.GenerarPDF_InspeccionesAP(listaFotosReportePDF)
        }
        else {
            this.props.history.push('/login');
        }
    }
    GenerarPDF_InspeccionesAP = async (lista_fotos) => {
        const doc = new jsPDF();
        //Llenar Data
        // // Titulo
        doc.setFontSize(14);
        doc.setFont("verdana", "bold");
        doc.setTextColor(8, 98, 167)
        // doc.text(`REPORTE ${usuarioLocalstorage.proyecto} - SUMINISTRO Nº ${data.content.suministro}`, 30, 10, { maxWidth: 180 });        
        // Agregar pagina si es mayor a 3   
        let posicionFoto = 0;
        for (let x = 0; x < lista_fotos.length; x++) {

            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `Agregado - ${Math.round(((x + 1) / lista_fotos.length) * 100)} %`, mostrarSpinnerDescargarPDF: true });

            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${lista_fotos[x].foto_url}`).then(async (respuesta) => {

                if (posicionFoto > 3) {
                    doc.addPage();
                    posicionFoto = 0;
                }

                const arraybuffer = await respuesta.arrayBuffer();
                const base64 = await btoa(
                    new Uint8Array(arraybuffer)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                // Obteniendo Informacion de imagen
                const blob = new Blob([arraybuffer], { type: 'image/jpeg' })
                const img = new Image()
                img.src = await URL.createObjectURL(blob)

                let AnchoImagenNuevo;
                await img.decode().then(() => {
                    let width = img.width;
                    let height = img.height;
                    // let girar=width>height?true:false;

                    if (width > height) {
                        AnchoImagenNuevo = (120 / width) * height;
                    } else {
                        AnchoImagenNuevo = (120 / height) * width;
                    }
                    //Verificar Si alto es mayor que Ancho
                    // doc.setFont("courier","normal");
                    doc.setFont("times", "normal");
                    // doc.setFontType("normal");
                    doc.setFontSize(12);
                    doc.setTextColor(0, 0, 0)

                    switch (posicionFoto) {
                        case 0:
                            //Agregando Nro Pagina     
                            doc.addImage(base64, 'JPG', 20, 10, AnchoImagenNuevo, 120);
                            doc.text(`${lista_fotos[x].ITEM} - ${lista_fotos[x].etiEst}`, 30, 135);
                            posicionFoto++;
                            break;
                        case 1:
                            doc.addImage(base64, 'JPG', 110, 10, AnchoImagenNuevo, 120);
                            doc.text(`${lista_fotos[x].ITEM} - ${lista_fotos[x].etiEst}`, 125, 135);
                            posicionFoto++;
                            break;
                        case 2:
                            doc.addImage(base64, 'JPG', 20, 140, AnchoImagenNuevo, 120);
                            doc.text(`${lista_fotos[x].ITEM} - ${lista_fotos[x].etiEst}`, 30, 265);
                            posicionFoto++;
                            break;
                        case 3:
                            doc.addImage(base64, 'JPG', 110, 140, AnchoImagenNuevo, 120);
                            doc.text(`${lista_fotos[x].ITEM} - ${lista_fotos[x].etiEst}`, 125, 265);
                            posicionFoto++;
                            break;
                    }
                });
            }
            ).catch((error) => {
                // console.log(error);
            });
        }
        const bufferPdf = doc.output("blob");
        saveAs(bufferPdf, `reporte_${this.selectAlimentadorMt.current.value}.pdf`);
        await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false, mostrarSpinnerDescargarPDF: false });

    }
    DescargarArchivoExcel_InspeccionesAP = async () => {
        if (ValidarUsuario()) {
            let faltaArchivos = 0;
            if (this.state.arrayInspeccionesApReporte.length <= 0) {
                faltaArchivos++;
            }

            if (this.state.arrayBaseElementosAlimentadorMT.length <= 0) {
                faltaArchivos++;
            }

            if (faltaArchivos > 0) {
                this.setState({ mensajeToast: "No se encontraron datos o no se hizo la busqueda de datos.", abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            } else {
                this.setState({ mostrarSpinnerDescargarExcel: true });
                // let dataGenerar = await this.GenerarDataArchivoExcel();
                // console.log(dataGenerar);

                let url = `${config.endPoint}api/inspeccionesAp/descargar/reporteexcel/${this.state.idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idproyecto: this.state.idproyecto,
                        baseDatos: this.state.arrayBaseReporteExcelInspeccionesAP
                    })
                };
                let archivoEncontrado = false;
                fetch(url, headers).then((respuesta) => {
                    if (respuesta.status == 200) {
                        archivoEncontrado = true;
                        this.setState({ mostrarSpinnerDescargarExcel: false });
                        return respuesta.blob();
                    } else {
                        this.setState({ mensajeToast: `StatusCode: ${respuesta.status} No se genero archivo, error al ejecutar el procedimiento`, abrirToast: true, tipotoast: 'error', mostrarSpinnerDescargarExcel: true });
                        return respuesta.json();
                    }
                }).then(async (data) => {
                    if (archivoEncontrado) {
                        saveAs(data, "Archivo.xlsx");
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', mostrarSpinnerDescargarExcel: true, MostrarSnackDescargaFotos: false });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    DescargarArchivoExcelEjecutados_InspeccionesAP = async () => {
        if (ValidarUsuario()) {
            let faltaArchivos = 0;
            if (this.state.arrayInspeccionesApReporte.length <= 0) {
                faltaArchivos++;
            }

            if (this.state.arrayBaseElementosAlimentadorMT.length <= 0) {
                faltaArchivos++;
            }

            if (faltaArchivos > 0) {
                this.setState({ mensajeToast: "No se encontraron datos o no se hizo la busqueda de datos.", abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            } else {
                this.setState({ mostrarSpinnerDescargarExcel: true });                

                let url = `${config.endPoint}api/inspeccionesAp/descargar/reporteexcel/${this.state.idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        idproyecto: this.state.idproyecto,
                        baseDatos: this.state.arrayBaseReporteExcelInspeccionesApEjecutados
                    })
                };
                let archivoEncontrado = false;
                fetch(url, headers).then((respuesta) => {
                    if (respuesta.status == 200) {
                        archivoEncontrado = true;
                        this.setState({ mostrarSpinnerDescargarExcel: false });
                        return respuesta.blob();
                    } else {
                        this.setState({ mensajeToast: `StatusCode: ${respuesta.status} No se genero archivo, error al ejecutar el procedimiento`, abrirToast: true, tipotoast: 'error', mostrarSpinnerDescargarExcel: true });
                        return respuesta.json();
                    }
                }).then(async (data) => {
                    if (archivoEncontrado) {
                        saveAs(data, "Archivo.xlsx");
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', mostrarSpinnerDescargarExcel: true, MostrarSnackDescargaFotos: false });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    GenerarDataArchivoExcel = () => {
        try {
            let arrayBase = [];
            this.state.arrayBaseElementosAlimentadorMT.forEach((elem, index) => {

                let fechaEjecucion = '';
                let actividadesInspeccionAp = null;
                let tecnicoInspeccion = '';
                let observacionInspeccion = '';
                let reportepdf = false;
                let idinspeccionApAdd = null;

                // Buscar Fecha
                let inspeccionAPBuscado = this.state.arrayInspeccionesApReporte.find((insp) => insp.etiEst === elem.etiEst);

                if (inspeccionAPBuscado) {
                    // console.log(inspeccionAPBuscado);
                    fechaEjecucion = inspeccionAPBuscado.fechaejecucion;
                    actividadesInspeccionAp = inspeccionAPBuscado.t_actividadInspeccionAPs;

                    observacionInspeccion = inspeccionAPBuscado.observacion ? inspeccionAPBuscado.observacion : '';
                    //Buscar Nombre Trabajador asociado a
                    let trabajadorBuscado = this.state.arraListaTecnicos.find((trab) => trab.idtrabajador == inspeccionAPBuscado.usuarioregistra);
                    if (trabajadorBuscado) {
                        tecnicoInspeccion = trabajadorBuscado.nombretrabajador;
                    }
                    //Agregar Posicion de Index
                    inspeccionAPBuscado.posicion = index + 1;
                    reportepdf = true;
                    idinspeccionApAdd = inspeccionAPBuscado.id;

                } else {
                    //Buscar Nombre de tecnico asociado a Modular
                    let inspeccionAPFind = this.state.arrayInspeccionesApReporte.find((trab) => trab.Modular == elem.Modular);
                    //Buscar Trabajador
                    if (inspeccionAPFind) {
                        //Buscar TRabajador Asociado a Modular
                        let trabajadorBuscado = this.state.arraListaTecnicos.find((trab) => trab.idtrabajador == inspeccionAPFind.usuarioregistra);

                        if (trabajadorBuscado) {
                            tecnicoInspeccion = trabajadorBuscado.nombretrabajador;
                            fechaEjecucion = inspeccionAPFind.fechaejecucion
                        }
                    }
                }



                //Buscar Actividad
                let DT1 = '';
                let DT2 = '';
                let DT3 = '';
                let DT4 = '';
                let DT5 = '';
                if (actividadesInspeccionAp) {
                    // console.log(actividadesInspeccionAp);
                    actividadesInspeccionAp.forEach((eleAct, index) => {
                        switch (eleAct.codigo) {
                            case 'DT1':
                                DT1 = eleAct.cantidad;
                                break;
                            case 'DT2':
                                DT2 = eleAct.cantidad;
                                break;
                            case 'DT3':
                                DT3 = eleAct.cantidad;
                                break;
                            case 'DT4':
                                DT4 = eleAct.cantidad;
                                break;
                            case 'DT5':
                                DT5 = eleAct.cantidad;
                                break;
                        }
                    });
                }

                let elmTemp = {
                    'ITEM': index + 1,
                    'NIVEL_DE_ACTIVIDAD': 'Alumbrado Público',
                    'ACTIVIDAD': 'AP-011',
                    'CANTIDAD': 1,
                    'VALOR': 0.84,
                    'TECNICO': tecnicoInspeccion,
                    'FECHA': fechaEjecucion,
                    'DISTRITO': elem.Distrito,
                    'ALIMENTADOR': elem.AlimentadorMT,
                    'SED': elem.sed,
                    'Codigo_Estructura': elem.codNod,
                    'EBT/EMT/SED': elem.etiEst,
                    'TIPO': elem.simEst,
                    'DT1': DT1,
                    'DT2': DT2,
                    'DT3': DT3,
                    'DT4': DT4,
                    'DT5': DT5,
                    'MARCA': '',
                    'MODELO': '',
                    'OBSERVACION': observacionInspeccion,
                    'REPORTE_PDF': reportepdf,
                    'idInspeccionAP': idinspeccionApAdd,
                }
                arrayBase.push(elmTemp);
            });


            // console.log(arrayBase);
            // console.log(this.state.arrayInspeccionesApReporte);
            this.setState({ arrayBaseReporteExcelInspeccionesAP: arrayBase });
            // return arrayBase;

        } catch (error) {
            this.setState({ mensajeToast: error.toString(), abrirToast: true, tipotoast: 'error', OcultarLoading: true });
        }
    }
    GenerarDataArchivoExcelEjecutados = () => {
        try {
            let arrayBase = [];

            // console.log(this.state.arrayInspeccionesApReporte);
            this.state.arrayInspeccionesApReporte.forEach((inspeccionAPBuscado, index) => {
                // console.log(inspeccionAPBuscado);
                let fechaEjecucion = '';
                let actividadesInspeccionAp = null;
                let tecnicoInspeccion = '';
                let observacionInspeccion = '';
                let reportepdf = false;
                let idinspeccionApAdd = null;

                // console.log(inspeccionAPBuscado);

                fechaEjecucion = inspeccionAPBuscado.fechaejecucion;
                actividadesInspeccionAp = inspeccionAPBuscado.t_actividadInspeccionAPs;

                observacionInspeccion = inspeccionAPBuscado.observacion ? inspeccionAPBuscado.observacion : '';
                //Buscar Nombre Trabajador asociado a
                let trabajadorBuscado = this.state.arraListaTecnicos.find((trab) => trab.idtrabajador == inspeccionAPBuscado.usuarioregistra);
                if (trabajadorBuscado) {
                    tecnicoInspeccion = trabajadorBuscado.nombretrabajador;
                }
                //Agregar Posicion de Index
                inspeccionAPBuscado.posicion = index + 1;
                reportepdf = true;
                idinspeccionApAdd = inspeccionAPBuscado.id;

                //Buscar Actividad
                let DT1 = '';
                let DT2 = '';
                let DT3 = '';
                let DT4 = '';
                let DT5 = '';
                if (actividadesInspeccionAp) {
                    // console.log(actividadesInspeccionAp);
                    actividadesInspeccionAp.forEach((eleAct, index) => {
                        switch (eleAct.codigo) {
                            case 'DT1':
                                DT1 = eleAct.cantidad;
                                break;
                            case 'DT2':
                                DT2 = eleAct.cantidad;
                                break;
                            case 'DT3':
                                DT3 = eleAct.cantidad;
                                break;
                            case 'DT4':
                                DT4 = eleAct.cantidad;
                                break;
                            case 'DT5':
                                DT5 = eleAct.cantidad;
                                break;
                        }
                    });
                }

                let elmTemp = {
                    'ITEM': index + 1,
                    'NIVEL_DE_ACTIVIDAD': 'Alumbrado Público',
                    'ACTIVIDAD': 'AP-011',
                    'CANTIDAD': 1,
                    'VALOR': 0.84,
                    'TECNICO': tecnicoInspeccion,
                    'FECHA': fechaEjecucion,
                    'DISTRITO': inspeccionAPBuscado.Distrito,
                    'ALIMENTADOR': inspeccionAPBuscado.AlimentadorMT,
                    'SED': inspeccionAPBuscado.sed,
                    'Codigo_Estructura': inspeccionAPBuscado.codNod,
                    'EBT/EMT/SED': inspeccionAPBuscado.etiEst,
                    'TIPO': inspeccionAPBuscado.simEst,
                    'DT1': DT1,
                    'DT2': DT2,
                    'DT3': DT3,
                    'DT4': DT4,
                    'DT5': DT5,
                    'MARCA': '',
                    'MODELO': '',
                    'OBSERVACION': observacionInspeccion,
                    'REPORTE_PDF': reportepdf,
                    'idInspeccionAP': idinspeccionApAdd,
                }
                arrayBase.push(elmTemp);
            });

            // console.log(arrayBase);
            this.setState({ arrayBaseReporteExcelInspeccionesApEjecutados: arrayBase });


            // this.state.arrayBaseElementosAlimentadorMT.forEach((elem, index) => {

            //     let fechaEjecucion = '';
            //     let actividadesInspeccionAp = null;
            //     let tecnicoInspeccion = '';
            //     let observacionInspeccion = '';
            //     let reportepdf = false;
            //     let idinspeccionApAdd = null;

            //     // Buscar Fecha
            //     let inspeccionAPBuscado = this.state.arrayInspeccionesApReporte.find((insp) => insp.etiEst === elem.etiEst);

            //     if (inspeccionAPBuscado) {
            //         // console.log(inspeccionAPBuscado);
            //         fechaEjecucion = inspeccionAPBuscado.fechaejecucion;
            //         actividadesInspeccionAp = inspeccionAPBuscado.t_actividadInspeccionAPs;

            //         observacionInspeccion = inspeccionAPBuscado.observacion ? inspeccionAPBuscado.observacion : '';
            //         //Buscar Nombre Trabajador asociado a
            //         let trabajadorBuscado = this.state.arraListaTecnicos.find((trab) => trab.idtrabajador == inspeccionAPBuscado.usuarioregistra);
            //         if (trabajadorBuscado) {
            //             tecnicoInspeccion = trabajadorBuscado.nombretrabajador;
            //         }
            //         //Agregar Posicion de Index
            //         inspeccionAPBuscado.posicion = index + 1;
            //         reportepdf = true;
            //         idinspeccionApAdd = inspeccionAPBuscado.id;

            //     } else {
            //         //Buscar Nombre de tecnico asociado a Modular
            //         let inspeccionAPFind = this.state.arrayInspeccionesApReporte.find((trab) => trab.Modular == elem.Modular);
            //         //Buscar Trabajador
            //         if (inspeccionAPFind) {
            //             //Buscar TRabajador Asociado a Modular
            //             let trabajadorBuscado = this.state.arraListaTecnicos.find((trab) => trab.idtrabajador == inspeccionAPFind.usuarioregistra);

            //             if (trabajadorBuscado) {
            //                 tecnicoInspeccion = trabajadorBuscado.nombretrabajador;
            //                 fechaEjecucion = inspeccionAPFind.fechaejecucion
            //             }
            //         }
            //     }



            //     //Buscar Actividad
            //     let DT1 = '';
            //     let DT2 = '';
            //     let DT3 = '';
            //     let DT4 = '';
            //     let DT5 = '';
            //     if (actividadesInspeccionAp) {
            //         // console.log(actividadesInspeccionAp);
            //         actividadesInspeccionAp.forEach((eleAct, index) => {
            //             switch (eleAct.codigo) {
            //                 case 'DT1':
            //                     DT1 = eleAct.cantidad;
            //                     break;
            //                 case 'DT2':
            //                     DT2 = eleAct.cantidad;
            //                     break;
            //                 case 'DT3':
            //                     DT3 = eleAct.cantidad;
            //                     break;
            //                 case 'DT4':
            //                     DT4 = eleAct.cantidad;
            //                     break;
            //                 case 'DT5':
            //                     DT5 = eleAct.cantidad;
            //                     break;
            //             }
            //         });
            //     }

            //     let elmTemp = {
            //         'ITEM': index + 1,
            //         'NIVEL_DE_ACTIVIDAD': 'Alumbrado Público',
            //         'ACTIVIDAD': 'AP-011',
            //         'CANTIDAD': 1,
            //         'VALOR': 0.84,
            //         'TECNICO': tecnicoInspeccion,
            //         'FECHA': fechaEjecucion,
            //         'DISTRITO': elem.Distrito,
            //         'ALIMENTADOR': elem.AlimentadorMT,
            //         'SED': elem.sed,
            //         'Codigo_Estructura': elem.codNod,
            //         'EBT/EMT/SED': elem.etiEst,
            //         'TIPO': elem.simEst,
            //         'DT1': DT1,
            //         'DT2': DT2,
            //         'DT3': DT3,
            //         'DT4': DT4,
            //         'DT5': DT5,
            //         'MARCA': '',
            //         'MODELO': '',
            //         'OBSERVACION': observacionInspeccion,
            //         'REPORTE_PDF': reportepdf,
            //         'idInspeccionAP': idinspeccionApAdd,
            //     }
            //     arrayBase.push(elmTemp);
            // });            
            // this.setState({ arrayBaseReporteExcelInspeccionesAP: arrayBase });            

        } catch (error) {
            this.setState({ mensajeToast: error.toString(), abrirToast: true, tipotoast: 'error', OcultarLoading: true });
        }
    }

    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalDescargarReporte = () => {
        // console.log(this.state.arrayInspeccionesApReporte);
        // console.log(this.state.arrayBaseElementosAlimentadorMT);
        this.setState({ mostrarModalDescargarReporte: true, arrayBaseReporteExcelInspeccionesAP: [] });
    }
    CerrarModalDescargarReporte = () => {
        this.setState({ mostrarModalDescargarReporte: false });
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };

    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };

    //***************************************************** */
    //Cambiar Fechas
    //***************************************************** */
    CambiarFechaInicioReporte = async (ev) => {
        await this.setState({ fechaInicioReporteStorage: this.inputFechaInicio.current.value });
        localStorage.setItem(config.storageDateIniReporte, this.inputFechaInicio.current.value);
        this.BuscarListaALimentadores_ReporteInspeccionesAP();
    }
    CambiarFechaFinReporte = async (ev) => {
        await this.setState({ fechaFinReporteStorage: this.inputFechaFin.current.value });
        localStorage.setItem(config.storageDateFinReporte, this.inputFechaFin.current.value);
        this.BuscarListaALimentadores_ReporteInspeccionesAP();
    }
    CambiarTipoActividad = () => {
        this.setState({ actividadSeleccionada: this.selecTipoActividad.current.value });
        switch (this.selecTipoActividad.current.value) {
            case 'INSPECCIONES_AP':
                this.setState({
                    mostrarFiltroAlimentador: true,
                });
                this.BuscarListaALimentadores_ReporteInspeccionesAP();
                break;
            case 'DEFICIENCIAS_AP':
                break;
            case 'RECLAMOS_AP':
                break;
            default:
                this.setState({
                    mostrarFiltroAlimentador: false,
                    mostrarFiltroTrabjaador: false,
                    mostrarFiltroLista: false,
                });
                break
        }
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <NavBar />
                <TituloPagina titulo={`Configuracion - Actividades AP `} regresar={() => { this.props.history.push('/alumbradoPublico') }} />
                <Container className='my-2  border rounded'>
                    <Row className='mt-1'>
                        <Col md={8}>
                            <InputGroup size='sm' >
                                <InputGroup.Checkbox checked disabled />
                                <InputGroup.Text>Tipo Actividad :</InputGroup.Text>
                                <Form.Select ref={this.selecTipoActividad} onChange={() => { this.CambiarTipoActividad() }} placeholder='Seleccione elemento' size='sm'>
                                    <option value="">Elija un elemento</option>
                                    <option value="INSPECCIONES_AP">INSPECCIONES AP</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col className='text-end '>
                            <Button size='sm' onClick={() => { this.AbrirModalDescargarReporte() }}>
                                <i className="fas fa-download mx-2"></i>
                                Descargar
                            </Button>
                        </Col>
                    </Row>

                    {
                        this.state.mostrarFiltroFechas ?
                            <Row className='mt-1'>
                                <Col md={8}>
                                    <InputGroup size='sm' className=''>
                                        <InputGroup.Checkbox checked disabled />
                                        <InputGroup.Text>Fecha Inicio :</InputGroup.Text>
                                        <Form.Control onChange={(ev) => { this.CambiarFechaInicioReporte(ev) }} ref={this.inputFechaInicio} type='date' size='sm'></Form.Control>

                                        <InputGroup.Text>Fecha Fin :</InputGroup.Text>
                                        <Form.Control onChange={(ev) => { this.CambiarFechaFinReporte(ev) }} ref={this.inputFechaFin} type='date' size='sm'></Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                            : <></>
                    }
                    {
                        this.state.mostrarFiltroAlimentador ?
                            <Row className='mt-1'>
                                <Col md={8}>
                                    <InputGroup size='sm' className=''>
                                        <InputGroup.Checkbox defaultChecked={this.state.habilitarAlimentadorMt} onChange={(ev) => { this.setState({ habilitarAlimentadorMt: ev.currentTarget.checked }) }} />
                                        <InputGroup.Text>Alimentador :</InputGroup.Text>
                                        <Form.Select disabled={!this.state.habilitarAlimentadorMt} ref={this.selectAlimentadorMt} placeholder='Seleccione elemento' size='sm'>
                                            <option value="">Elija un elemento</option>
                                            {
                                                this.state.arrayListaAlimentadoresMt.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.AlimentadorMT}>{`${row.AlimentadorMT}`}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Button variant="primary" size='sm' onClick={() => {
                                        this.Buscar_InspeccionesAP_Filtro()
                                    }}>
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                            : <></>
                    }
                    {
                        this.state.mostrarFiltroTrabjaador ?
                            <Row className='mt-1'>
                                <Col md={8}>
                                    <InputGroup size='sm' className=''>
                                        <InputGroup.Checkbox />
                                        <InputGroup.Text>Trabajador :</InputGroup.Text>
                                        <Form.Select placeholder='Seleccione elemento' size='sm'>
                                            <option value="">Elija un elemento</option>
                                            <option value="e1">elemento1</option>
                                            <option value="e2">elemento2</option>
                                            <option value="e3">elemento3</option>
                                            <option value="e4">elemento4</option>
                                        </Form.Select>
                                        {/* <Button variant="primary" id="button-addon2">
                                    <i className="fas fa-ellipsis-h mx-2 " ></i>
                                </Button> */}
                                    </InputGroup>
                                </Col>
                            </Row>
                            : <></>
                    }
                    {
                        this.setState.mostrarFiltroLista ?
                            <Row>
                                <Col md={8} className='mt-1'>
                                    <InputGroup size='sm' >
                                        <InputGroup.Checkbox />
                                        <InputGroup.Text>Lista Filtros :</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Ingrese el valor de filtros."
                                        />
                                        <Button variant="primary" >
                                            <i className="fas fa-ellipsis-h mx-2 " ></i>
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            : <></>
                    }
                    <Row>
                        <Col>
                            <small>{`Se encontraro ${this.state.arrayBaseElementosAlimentadorMT.length} elementos para generar el reporte actual.`}</small>

                            {/* <AlertBootstrap show={this.state.mostrarAlertFiltroInspecciones} variant="success" dismissible onClose={() => { this.setState({ mostrarAlertFiltroInspecciones: false }) }}>
                                <Container fluid>
                                    <Row>

                                        <Col sm={12} md={9}>
                                            <span>Se encontraron </span><AlertBootstrap.Link href="#" onClick={(e) => { e.preventDefault(); this.BuscarDetalleBusquedaInspeccionesFiltro(); }}>{`${this.state.elementosEncontradosFiltroInspecciones.cantidad} elementos`}</AlertBootstrap.Link>
                                            <span classList="mx-1">{` registros para la busqueda actual . ¿Desea agregar estos elementos?`}</span>
                                        </Col>
                                        <Col sm={12} md={3} className='text-center'>
                                            <Button size='sm' variant='success' onClick={() => { this.ActualizarPeriodosActuales() }}>
                                                <i className="fas fa-plus"></i>
                                                Agregar Elementos Encontrados
                                            </Button>

                                        </Col>
                                    </Row>
                                </Container>
                            </AlertBootstrap> */}
                        </Col>
                    </Row>

                    {/* Tabla de Tados */}
                    <Row className='mb-2'>
                        <div className='mt-1' style={{ height: `500px`, width: '100%' }}>
                            <DataGrid
                                density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                rowsPerPageOptions={[20, 50, 100, 200]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                                onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal Descarga Fotos */}
                <Modal
                    show={this.state.mostrarModalDescargarReporte}
                    onHide={() => { this.CerrarModalDescargarReporte() }}
                    size='md'
                    backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Descargar Reportes`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            {
                                this.state.actividadSeleccionada == "INSPECCIONES_AP" ?
                                    <div className='p-2'>
                                        <Row className='mb-2'>
                                            <Col className='text-center'>
                                                <Form.Label className='text-primary fs-5 fw-bold text-decoration-underline'>Inspecciones AP</Form.Label>
                                                {/* <Divider></Divider> */}
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col>
                                                <Form.Label className='text-secondary'>FOTOS</Form.Label>
                                                <Divider></Divider>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                this.state.actividadSeleccionada == "INSPECCIONES_AP" ?
                                                    <>
                                                        <Col md={12} className='my-1 d-grid gap-1'>
                                                            <Button variant='outline-primary' onClick={() => { this.DescargarFotosInspeccionAP() }}>Descargar Fotos Inspeccion</Button>
                                                        </Col>

                                                    </>
                                                    : <></>
                                            }

                                        </Row>

                                        {
                                            this.state.arrayInspeccionesApReporte.length > 0 && this.state.arrayBaseElementosAlimentadorMT.length > 0 ?

                                                this.state.arrayBaseReporteExcelInspeccionesAP.length > 0 ?
                                                    <>
                                                        <Row className='mb-2'>
                                                            <Col>
                                                                <Form.Label className='text-secondary'>Reportes PDF</Form.Label>
                                                                <Divider></Divider>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {
                                                                this.state.actividadSeleccionada == "INSPECCIONES_AP" ?
                                                                    <>
                                                                        <Col md={12} className='my-1 d-grid gap-1'>
                                                                            <Button disabled={this.state.mostrarSpinnerDescargarPDF} variant='outline-primary' onClick={() => { this.BuscarFotosPDF_InspeccionesAP_v2() }}>
                                                                                {
                                                                                    this.state.mostrarSpinnerDescargarPDF ?
                                                                                        <Spinner size='sm' className='mx-2'></Spinner>
                                                                                        : <></>
                                                                                }
                                                                                Descargar Reporte PDF
                                                                            </Button>
                                                                        </Col>
                                                                    </>
                                                                    : <></>
                                                            }
                                                        </Row>
                                                        <Row className='Reportes Excel'>
                                                            <Col>
                                                                <Form.Label className='text-secondary '>Reportes Excel(xls)</Form.Label>
                                                                <Divider></Divider>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            {
                                                                this.state.actividadSeleccionada == "INSPECCIONES_AP" ?
                                                                    <>
                                                                        <Col md={12} className='my-1 d-grid gap-1'>
                                                                            <Button disabled={this.state.mostrarSpinnerDescargarExcel} variant='outline-primary' onClick={() => { this.DescargarArchivoExcel_InspeccionesAP() }}>
                                                                                {
                                                                                    this.state.mostrarSpinnerDescargarExcel ?
                                                                                        <Spinner size='sm' className='mx-2'></Spinner>
                                                                                        : <></>
                                                                                }
                                                                                Descargar Excel Reporte Inspecciones AP
                                                                            </Button>
                                                                        </Col>

                                                                        <Col md={12} className='my-1 d-grid gap-1'>
                                                                            <Button disabled={this.state.mostrarSpinnerDescargarExcel} variant='outline-secondary' onClick={() => { this.DescargarArchivoExcelEjecutados_InspeccionesAP() }}>
                                                                                {
                                                                                    this.state.mostrarSpinnerDescargarExcel ?
                                                                                        <Spinner size='sm' className='mx-2'></Spinner>
                                                                                        : <></>
                                                                                }
                                                                                Descargar Excel Solo Ejecutados
                                                                            </Button>
                                                                        </Col>
                                                                    </>
                                                                    : <></>
                                                            }
                                                        </Row>
                                                    </>
                                                    : <>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label className='text-secondary'>
                                                                    {`Para Descargar los archivos (Excel y PDF)  genere el reporte correspondiente, de clic en Generar reporte.`}
                                                                </Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className='text-center'>
                                                                <Button size='sm' onClick={() => {
                                                                    this.GenerarDataArchivoExcel();
                                                                    this.GenerarDataArchivoExcelEjecutados()
                                                                }}>Generar Reporte inspeccion AP</Button>
                                                            </Col>
                                                        </Row>
                                                    </>

                                                :
                                                <AlertBootstrap variant="danger" >
                                                    <AlertBootstrap.Heading>Reportes</AlertBootstrap.Heading>
                                                    <span classList="mx-1">{`No se realizo la busqueda del filtro actual, realice un filtro presione el  boton Buscar y vuelva a intentar abrir nuevamente esta ventana.`}</span>
                                                </AlertBootstrap>
                                        }

                                    </div>
                                    : <></>
                            }
                            {
                                this.state.actividadSeleccionada == "" ?
                                    <AlertBootstrap variant="danger" >
                                        <span classList="mx-1">{`Debe selecionar el tipo de actividad para poder realizar la descarga de reportes.`}</span>
                                    </AlertBootstrap>
                                    : <></>
                            }

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant='outline-secondary' size='sm' onClick={() => { this.CerrarModalDescargarReporte() }}>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default reporteAp;