import React, { Component, Fragment, createRef } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import { Link } from 'react-router-dom'
// import socketIOClient from 'socket.io-client';

import moment from 'moment/moment';


import { Modal, Carousel, FormControl } from 'react-bootstrap';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import SpinnerCarga from './../utils/spinner'
import LoadingModal from './../utils/loadModal'
import config from './../../config/config'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken, CreateJsonToExcel } from '../utils/utils'


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


//Crear zip y guardar
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
// import {autoTable} from 'jspdf-autotable';

var zip = require('jszip')();
var sizeOf = require('buffer-image-size');
// import PdfVista from '../utils/pdfvista';
// var pdf = require('html-pdf');

class monitoreoTrabajador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostarfotos: false,
            mostrarModalDetalleSuministro: false,
            suministroActual: {},
            fechaprogramacion: '',
            idtrabajador: '',
            DatosTrabajador: { nombretrabajador: 'Cargando...', Tipo: '...', fechaProgramacion: '...' },
            loadData: false,
            tipofiltro: 'Todos',
            valorBuscar: '',
            ListaBusqueda: [],
            ListaSuministros: [],
            ListaFotosTrabajoSuministro: [],
            cantidadElementos: 0,
            filasPorPagina: 100,
            pagina: 1,
            OcultarLoading: true,
            MostrarSnackDescargaFotos: false,
            mensajeDescargaFotos: '',
            idproyecto: null,
            MostrarModalVerPDF: false,
            permisos: [],
            modificarActualizacionDatos: false,
            errorEstadoTrabajoSuministro: '',
            errorEstadoInspeccion: '',
            errorDocumentoEncuesta: '',
            errorNombresEncuesta: '',
            errorCorreoEncuesta: '',
            errorCelularEncuesta: '',
            errorTelefonoFijoEncuesta: '',
            errorProvinciaEncuesta: '',
            errorDistritoEncuesta: '',
            errorObservacionTrabajo: '',
            mostrarModalSubirFotos: false,
            mostrarModalLiberarSuministro: false,

            ListaFotosCargar: [],
            trabajoActual: {},
            cantidadFotosSubidas: 0,
            cantidadFotosActualizadas: 0,
            cantidadFotosError: 0,
            mostrarCargarFotos: false
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Suministro' },
            { id: 'Cliente' },
            { id: 'Dirección' },
            { id: 'Tipo' },
            // { id: 'Ejecutor ' },
            { id: 'Estado' },
            { id: 'Foto ' },
            { id: 'Ejecutado' },
        ];

        // Referencias
        this.selectTipoFiltro = createRef();
        this.inputBuscarFiltro = createRef();
        this.selectFiltroEstados = createRef();

        //Referencias Suministro
        this.selectEstadoTrabajoSuministro = createRef();
        this.selectEstadoInspeccion = createRef();
        this.inputEstado = createRef();
        this.inputEstadoInspeccion = createRef();
        this.inputDocumentoEncuesta = createRef();
        this.inputNombresEncuesta = createRef();
        this.inputCorreoEncuesta = createRef();
        this.inputCelularEncuesta = createRef();
        this.inputTelefonoFijoEncuesta = createRef();
        this.inputProvinciaEncuesta = createRef();
        this.inputDistritoEncuesta = createRef();
        this.inputObservacionEncuesta = createRef();

        //Referencias Cargar Fotos
        this.inputSubirFotos = createRef();
        this.inputcheckRotularFotografias = createRef();

        //Variable controlador  Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});

    }

    async componentDidMount() {
        // Tamaño de Pantalla
        // console.log(window.outerHeight);

        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                this.setState({ permisos: permisos });
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }


                    await this.setState({ fechaprogramacion: this.props.match.params.fecha, idtrabajador: this.props.match.params.idtrabajador })
                    await this.BuscarDetalleTrabajo();
                    await this.BuscarSuministrosProgramados();
                    // await this.SocketConect();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // SocketConect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);
    //         this.BuscarSuministrosProgramados();
    //     });
    // }

    // componentWillUnmount() {
    //     this.socket.close();
    // }

    //Descargar Fotos
    DescargarFotosProgramacion = async () => {

        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')

            let url = `${config.endPoint}api/movil/listafotosProyecto/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            let cantidad = 0;

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content.length > 0) {
                    this.DecargarPorPartes(data.content);
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No se encontraron archivos para este filtro.", tipotoast: 'info', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                    // this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    DecargarPorPartes = async (listadatos) => {

        // let valorDivision = config.cantidadLimiteArchivoDescargaFotos;
        // console.log(listadatos);
        // let descargarFotoRotulada = this.checkRotularFotoDescarga.current.checked;
        let valorDivision = config.cantidadFotosTamañoMaximo;
        if (listadatos.length > valorDivision) {
            if (window.confirm(`La cantidad de Fotografias ${listadatos.length} que desea descargar supera los ${valorDivision} archivos, esto podria generar un colapso en el sistema.\nLa fotos seran descargadas en partes para mejorar el rendimiendo. \n¿Desea continuar con la descarga de archivos por partes?`)) {
                //Dividir en partes de 1000                
                let listas = Math.ceil(listadatos.length / valorDivision);
                let inicio = 0;
                let fin = 0;
                let cantidad = 0;
                for (let x = 1; x <= listas; x++) {
                    fin = x * valorDivision;
                    for (let y = inicio; y < fin; y++) {
                        if (y < listadatos.length) {
                            //Descargar Archivos
                            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                            // console.log(listadatos[y].foto_url);                           

                            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[y].foto_url}`).then(async (respuesta) => {
                                cantidad++;
                                await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                                const arraybuffer = await respuesta.arrayBuffer();
                                // let nombreFoto = `FI_${listadatos[x].suministro.toString().padStart(11, '0')}_${moment(listadatos[x].fecha).format('YYYYMMDD')}_H${listadatos[x].hora.toString().replaceAll(':', '')}_00`;
                                zip.file(`${listadatos[y].fechaProgramacion.trim()}/${listadatos[y].tipotrabajo.trim()}/${listadatos[y].trabajadorejecuta !== null ? listadatos[y].trabajadorejecuta.trim() : listadatos[y].trabajadorprogramado.trim()}/${listadatos[y].suministro.trim()}/${listadatos[y].foto_url}`, arraybuffer);
                                // zip.file(`${listadatos[y].fechaProgramacion.trim()}/${listadatos[y].tipotrabajo.trim()}/${listadatos[y].trabajadorejecuta !== null ? listadatos[y].trabajadorejecuta.trim() : listadatos[y].trabajadorprogramado.trim()}/${listadatos[y].suministro.trim()}/${nombreFoto}.jpg`, arraybuffer);
                                // zip.file(`${listadatos[y].fechaProgramacion.trim()}/${nombreFoto}.jpg`, arraybuffer);
                            }

                            ).catch((error) => {
                                console.log(error);
                            });
                        }
                    }
                    //Generar el Archivo Zip
                    await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                        saveAs(contenido, `Fotos_${this.state.fechaprogramacion}_Part${x}.zip`);
                    });
                    //Reiniciar el zip
                    zip = require('jszip')();

                    inicio = fin;
                }
                await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }

        } else {
            let cantidad = 0;
            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });

            for (let x = 0; x < listadatos.length; x++) {
                await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[x].foto_url}`).then(async (respuesta) => {
                    cantidad++;
                    await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                    const arraybuffer = await respuesta.arrayBuffer();

                    // let nombreFoto = `FI_${listadatos[x].suministro.toString().padStart(11, '0')}_${moment(listadatos[x].fecha).format('YYYYMMDD')}_H${listadatos[x].hora.toString().replaceAll(':', '')}_00`;
                    zip.file(`${listadatos[x].fechaProgramacion.trim()}/${listadatos[x].tipotrabajo.trim()}/${listadatos[x].trabajadorejecuta !== null ? listadatos[x].trabajadorejecuta.trim() : listadatos[x].trabajadorprogramado.trim()}/${listadatos[x].suministro.trim()}/${listadatos[x].foto_url}`, arraybuffer);
                    // zip.file(`${listadatos[x].fechaProgramacion.trim()}/${listadatos[x].tipotrabajo.trim()}/${listadatos[x].trabajadorejecuta !== null ? listadatos[x].trabajadorejecuta.trim() : listadatos[x].trabajadorprogramado.trim()}/${listadatos[x].suministro.trim()}/${nombreFoto}.jpg`, arraybuffer);
                    // zip.file(`${listadatos[x].fechaProgramacion.trim()}/${nombreFoto}.jpg`, arraybuffer);
                }
                ).catch((error) => {
                    console.log(error);
                });
            }
            //Generar el Archivo Zip
            await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                saveAs(contenido, `Fotos_${this.state.fechaprogramacion}.zip`);
            });
            //Reiniciar el zip
            zip = require('jszip')();
            await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
        }
    }
    DescargarReporteTrabajador = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteReporteTrabajador/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte_${this.state.DatosTrabajador.nombretrabajador}_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    DescargarReporteActualizacionDatos = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteActualizacionDatos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte_${this.state.DatosTrabajador.nombretrabajador}_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    DescargarReporteActividadesTrabajador = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteActividadesTrabajador/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte_Actividades_${this.state.DatosTrabajador.nombretrabajador}_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarDetalleTrabajo = () => {
        let url = `${config.endPoint}api/trabajosuministro/detallejornada/trabajadorProyecto/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };
        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content.length > 0) {
                await this.setState({ DatosTrabajador: data.content[0] });
            }
        }).catch(() => {
            this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
        });
    }
    BuscarSuministrosProgramados = () => {
        if (ValidarUsuario()) {
            let url = "";
            if (this.state.valorBuscar.trim() !== '') {
                switch (this.state.tipofiltro) {
                    case "Todos":
                        url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}}`;
                        break;
                    case "Suministro":
                        url = `${config.endPoint}api/trabajosuministro/listar/trabajosfiltroSuministro/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}/${this.state.valorBuscar}`
                        break;
                }
            }
            else {
                url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            }

            console.log(url);



            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    // Buscar Fotos Duplicadas
                    let _listaTrabajos = data.content.rows;
                    // console.log("iniciar Revision Fotos");
                    for (let x = 0; x < _listaTrabajos.length - 1; x++) {
                        let listafotosTrabajo = _listaTrabajos[x].t_fotos;
                        // Limpiar Duplicados
                        let arrayFotos = [];
                        for (let n = 0; n < listafotosTrabajo.length; n++) {
                            let existeFoto = false;
                            for (let y = 0; y < arrayFotos.length; y++) {
                                if (listafotosTrabajo[n].foto_url === arrayFotos[y].foto_url) {
                                    existeFoto = true;
                                    break;
                                }
                            }
                            if (!existeFoto) {
                                // Agregar Foto
                                arrayFotos.push(listafotosTrabajo[n]);
                            }
                        }
                        _listaTrabajos[x].t_fotos = arrayFotos;
                    }
                    await this.setState({ ListaSuministros: data.content.rows, cantidadElementos: data.content.rows.length, LoadData: true });
                }
                else {
                    await this.setState({ ListaSuministros: [], cantidadElementos: 0, LoadData: true });
                }
                await this.filtroEstadosTrabajos();
                await this.Paginacion();
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    filtroEstadosTrabajos = () => {
        // console.log(this.state.ListaSuministros);
        let arrayTemp = [];
        switch (this.selectFiltroEstados.current.value) {
            case 'EJECUTADO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado !== 'PROGRAMADO' && x.estado !== 'NO EJECUTADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'NOEJECUTADO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'NO EJECUTADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'PENDIENTES':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'PROGRAMADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'IMPEDIMENTO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'IMPEDIMENTO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
        }

    }
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.ListaSuministros.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.ListaSuministros.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.ListaSuministros[x]);
            }
        }
        await this.setState({ ListaSuministros: nuevoArray, LoadData: true });
    }
    CambioSelectTipo = async () => {
        await this.setState({ tipofiltro: this.selectTipoFiltro.current.value });
        // await this.BuscarSuministrosProgramados();
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarSuministrosProgramados();
        }
    }
    changefiltrobuscar = async (ev) => {
        if (ev.target.value === '') {
            // console.log("vacio");
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            this.BuscarSuministrosProgramados();
        }
    }
    elegirEstadoSuministro = async () => {
        await this.setState({ LoadData: false, pagina: 1 });
        this.BuscarSuministrosProgramados()
    }
    finalizarJornadaTrabajo = () => {
        if (window.confirm('Todos los trabajos pendietes pasaran a estado NO EJECUTADO\n¿Estas Seguro de Finalizar y cerrar todos los trabajos para esta fecha de programación?')) {
            // console.log("Confirmacion finalizar trabajos");
            let url = `${config.endPoint}api/trabajosuministro/finalizarjornada/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.content) {
                    this.setState({ mensajeToast: `Se finalizaron ${data.content} trabajos.`, abrirToast: true, tipotoast: 'success' });
                    await this.setState({ LoadData: false, pagina: 1 });
                    this.BuscarSuministrosProgramados();
                } else {
                    this.setState({ mensajeToast: `No actualizado,`, abrirToast: true, tipotoast: 'info' });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
    }
    // Ventanas Modales
    AbrirModalFotos = async (trabajoSuministro) => {
        await this.setState({ ListaFotosTrabajoSuministro: [] });
        if (trabajoSuministro.t_fotos.length > 0) {
            // Llenar Lista de fotos
            await this.setState({ ListaFotosTrabajoSuministro: trabajoSuministro.t_fotos });
            await this.setState({ mostarfotos: true });
        }
        else {
            this.setState({ mensajeToast: "No se encontraron fotos para este suministro", abrirToast: true, tipotoast: 'info' });
        }
    }
    CerrarModalFotos = () => {
        this.setState({ mostarfotos: false });
    }
    // Modal Detalle suministro
    AbrirModalDetalleSuministro = (trabajosuministro) => {
        this.BuscarTrabajoSuministro(trabajosuministro.idTrabajosuministro).then(() => {
            this.setState({ mostrarModalDetalleSuministro: true, OcultarLoading: true });
        }).catch(() => {
            this.setState({ OcultarLoading: true });
        });
    }
    CerrarModalDetalleSuministro = () => {
        this.setState({ mostrarModalDetalleSuministro: false });
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarSuministrosProgramados();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarSuministrosProgramados();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    MostrarModalVerPdf = () => {
        console.log(this.state.suministroActual);
        this.setState({ MostrarModalVerPDF: true })
    }
    CerrarModalMostrarPDF = () => {
        this.setState({ MostrarModalVerPDF: false })
    }
    //*************************************************
    // Abrir Maps
    //*************************************************
    AbrirMaps = (lat, lng) => {
        let _lat = parseFloat(lat);
        let _lng = parseFloat(lng);

        // console.log(_lat);
        if (_lat !== 0 && !isNaN(_lat)) {
            let url = `http://maps.google.com/?q=${_lat},${_lng}`;
            window.open(url, '_blank');
        } else {
            this.setState({ mensajeToast: "Ubicacion GPS no valida.", abrirToast: true, tipotoast: 'warning' });
        }
    }
    GenerarPDF = async () => {
        // Obtener Datos de Servicio
        let usuarioLocalstorage = localStorage.getItem(config.storageName)
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);


        if (ValidarUsuario()) {
            const doc = new jsPDF();
            try {
                await this.setState({ OcultarLoading: false });


                let url = `${config.endPoint}api/trabajosuministro/buscarid/${this.state.suministroActual.idTrabajosuministro}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        // console.log(data.content);



                        //Llenar Data
                        // // Titulo
                        doc.setFontSize(14);
                        doc.setFont("verdana", "bold");
                        doc.setTextColor(8, 98, 167)
                        doc.text(`REPORTE ${usuarioLocalstorage.proyecto} - SUMINISTRO Nº ${data.content.suministro}`, 30, 10, { maxWidth: 180 });
                        //Linea
                        doc.setLineWidth(0.5);
                        doc.setDrawColor(8, 98, 167);
                        doc.line(15, 12, 200, 12)

                        //Data
                        doc.setTextColor(0, 0, 0)
                        doc.setFontSize(10);
                        doc.setFont("verdana", "normal");

                        doc.text("DNI/RUC:", 20, 18);
                        doc.text(data.content.dniIngresado, 70, 18, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 22, 190, 22)

                        doc.text("NOMBRES:", 20, 26);
                        // doc.text(data.content.nombresIngresado, 70, 26, { maxWidth: 120 });
                        doc.text(data.content.nombreCliente, 70, 26, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 30, 190, 30)

                        doc.text("DIRECCIÓN:", 20, 34);
                        doc.text(data.content.direccionCliente, 70, 34, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 38, 190, 38)

                        doc.text("NUM.CELULAR:", 20, 42);
                        doc.text(data.content.nroCelularIngresado, 70, 42, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 46, 190, 46)

                        doc.text("NUM. FIJO:", 20, 50);
                        doc.text(data.content.telefonoFijoIngresado, 70, 50, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 54, 190, 54)

                        doc.text("CORREO ELECTRÓNICO:", 20, 58);
                        doc.text(data.content.correoIngresado, 70, 58, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 62, 190, 62)

                        doc.text("DISTRITO:", 20, 66);
                        // doc.text(data.content.distritoIgresado, 70, 66, { maxWidth: 120 });
                        doc.text(data.content.distrito, 70, 66, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 70, 190, 70)

                        doc.text("PROVINCIA:", 20, 74);
                        doc.text(data.content.ProvinciaIngresado, 70, 74, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 78, 190, 78)

                        doc.text("ESTADO.:", 20, 82);
                        doc.text(data.content.estado, 70, 82, { maxWidth: 120 });

                        doc.text("SUB ESTADO.:", 110, 82);
                        doc.text(data.content.EstadoEncuestaIngresado, 140, 82, { maxWidth: 120 });

                        doc.setLineWidth(0.2);
                        doc.setDrawColor(169, 169, 169);
                        doc.line(18, 86, 190, 86)

                        doc.text("OBS.:", 20, 90);
                        doc.text(data.content.observacion_ejecucion, 70, 90, { maxWidth: 120 });

                        doc.setLineWidth(0.5);
                        doc.setDrawColor(8, 98, 167);
                        doc.line(15, 105, 200, 105)

                        doc.setFontSize(14);
                        doc.setFont("verdana", "bold");
                        doc.setTextColor(8, 98, 167)
                        doc.text("Fotos", 20, 110, { maxWidth: 180 });
                        // console.log("Iniciar Agregar Imagenes");  

                        //Agregando Nro Pagina     
                        doc.setTextColor(0, 0, 0)
                        doc.setFontSize(10);
                        doc.setFont("verdana", "normal");
                        doc.text("Pág. 1", 190, 293, { maxWidth: 180 });

                        //Agregando Fotografias

                        // Agregar pagina si es mayor a 3                        

                        for (let x = 0; x < data.content.t_fotos.length; x++) {

                            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${data.content.t_fotos[x].foto_url}`).then(async (respuesta) => {

                                const arraybuffer = await respuesta.arrayBuffer();
                                const base64 = await btoa(
                                    new Uint8Array(arraybuffer)
                                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                                );
                                // Obteniendo Informacion de imagen
                                const blob = new Blob([arraybuffer], { type: 'image/jpeg' })
                                const img = new Image()
                                img.src = await URL.createObjectURL(blob)

                                let AnchoImagenNuevo;
                                await img.decode().then(() => {
                                    let width = img.width;
                                    let height = img.height;
                                    // let girar=width>height?true:false;

                                    if (width > height) {
                                        AnchoImagenNuevo = (80 / width) * height;
                                    } else {
                                        AnchoImagenNuevo = (80 / height) * width;
                                    }
                                    //Verificar Si alto es mayor que Ancho
                                    switch (x) {
                                        case 0:

                                            doc.addImage(base64, 'JPG', 20, 120, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 115);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 118);
                                            break;
                                        case 1:
                                            doc.addImage(base64, 'JPG', 110, 120, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 115);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 118);
                                            break;
                                        case 2:
                                            doc.addImage(base64, 'JPG', 20, 210, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 205);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 208);
                                            break;
                                        case 3:
                                            doc.addImage(base64, 'JPG', 110, 210, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 205);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 208);
                                            break;

                                        case 4:
                                            doc.addPage();
                                            //Agregando Nro Pagina     
                                            doc.setTextColor(0, 0, 0)
                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.text("Pág. 2", 190, 293, { maxWidth: 180 });

                                            doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                            break;
                                        case 5:
                                            doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                            break;
                                        case 6:
                                            doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                            break;
                                        case 7:
                                            doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                            break;
                                        case 8:
                                            doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                            break;
                                        case 9:
                                            doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                            break;
                                        case 10:
                                            doc.addPage();
                                            //Agregando Nro Pagina     
                                            doc.setTextColor(0, 0, 0)
                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.text("Pág. 3", 190, 293, { maxWidth: 180 });

                                            doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                            break;
                                        case 11:
                                            doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                            break;
                                        case 12:
                                            doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                            break;
                                        case 13:
                                            doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                            break;
                                        case 14:
                                            doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                            break;
                                        case 15:
                                            doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                            break;
                                        case 16:
                                            doc.addPage();
                                            //Agregando Nro Pagina     
                                            doc.setTextColor(0, 0, 0)
                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.text("Pág. 4", 190, 293, { maxWidth: 180 });

                                            doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                            break;
                                        case 17:
                                            doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                            break;
                                        case 18:
                                            doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                            break;
                                        case 19:
                                            doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                            break;
                                        case 20:
                                            doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                            break;
                                        case 21:
                                            doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                            doc.setFontSize(10);
                                            doc.setFont("verdana", "normal");
                                            doc.setTextColor(0, 0, 0);
                                            doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                            doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                            break;
                                    }
                                });
                            }
                            ).catch((error) => {
                                // console.log(error);
                            });
                        }

                        const bufferPdf = doc.output("blob");
                        saveAs(bufferPdf, `${data.content.suministro}.pdf`);

                    } else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
                this.setState({ OcultarLoading: true });

            } catch (error) {
                this.setState({ mensajeToast: error.toString(), abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }
        } else {
            this.props.history.push('/login');
        }

    }
    //*************************************************
    // Modificar Formulario
    //*************************************************
    AbrirModalLiberarSuministro = (row) => {
        this.setState({ mostrarModalLiberarSuministro: true, suministroActual: row })
    }
    CerrarModalLiberarSuministro = () => {
        this.setState({ mostrarModalLiberarSuministro: false, suministroActual: {} })
    }
    BuscarTrabajoSuministro = (idtrabajosuministro) => {
        this.setState({ OcultarLoading: false });
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministroV1/${idtrabajosuministro}`;
                // console.log(url);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data.content);
                    if (data.content) {
                        // this.setState({ suministroActual: data.content[0] });
                        this.setState({ suministroActual: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ suministroActual: {} });
                        this.setState({ abrirToast: true, mensajeToast: data.messaje, tipotoast: 'warning' });
                        resolve(false);
                    }
                    this.setState({ OcultarLoading: true });

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }

        });

    }
    BuscarDocumentoConsultaEnLinea = (documento) => {
        this.setState({ OcultarLoading: false, modificarActualizacionDatos: false });
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/movil/consultaEnLinea/${documento}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data.content);                
                    if (data.content) {
                        // this.setState({ suministroActual: data.content[0] });
                        //Almacenar Datos en Formulario
                        this.inputNombresEncuesta.current.value = data.content.nombres;
                        this.inputProvinciaEncuesta.current.value = data.content.provincia;
                        this.inputDistritoEncuesta.current.value = data.content.distrito;
                        resolve(true);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                    this.setState({ OcultarLoading: true, modificarActualizacionDatos: true });

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', OcultarLoading: true, modificarActualizacionDatos: true });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    KeyEnterInputBuscarEnLinea = async (ev) => {
        if (ev.key === "Enter") {
            await this.BuscarDocumentoConsultaEnLinea(this.inputDocumentoEncuesta.current.value.toString().trim()).then((respuesta) => {
                // if(respuesta){

                // }
                // this.setState({OcultarLoading:true});
            }).catch(() => {
                this.setState({ OcultarLoading: false, modificarActualizacionDatos: false });
            });
        }
    }
    ModificarFormulario = async (modificar) => {
        // console.log(modificar);
        await this.setState({ modificarActualizacionDatos: modificar });
        if (!modificar) {
            // Restaurar valores predeterminados
            // console.log(this.state.suministroActual);
            // this.selectEstadoTrabajoSuministro.current.value=this.state.suministroActual.estado;
            // this.selectEstadoInspeccion=this.state.suministroActual.EstadoEncuestaIngresado;
            // this.inputEstado.current.value=this.state.suministroActual.estado;
            // this.inputEstadoInspeccion.current.value=this.state.suministroActual.EstadoEncuestaIngresado;

            this.inputDocumentoEncuesta.current.value = this.state.suministroActual.dniIngresado;
            this.inputNombresEncuesta.current.value = this.state.suministroActual.nombresIngresado;
            this.inputCorreoEncuesta.current.value = this.state.suministroActual.correoIngresado;
            this.inputCelularEncuesta.current.value = this.state.suministroActual.nroCelularIngresado;
            this.inputTelefonoFijoEncuesta.current.value = this.state.suministroActual.telefonoFijoIngresado;
            this.inputProvinciaEncuesta.current.value = this.state.suministroActual.ProvinciaIngresado;
            this.inputDistritoEncuesta.current.value = this.state.suministroActual.distritoIgresado;
            this.inputObservacionEncuesta.current.value = this.state.suministroActual.observacion_ejecucion;

        }
    }
    ValidarModificarDatosEncuesta = () => {
        let error = 0;

        if (this.selectEstadoTrabajoSuministro.current.value == "") {
            error++;
            this.selectEstadoTrabajoSuministro.current.classList.add('is-invalid');
            this.setState({ errorEstadoTrabajoSuministro: "Seleccione el Estado" });
        } else {
            this.selectEstadoTrabajoSuministro.current.classList.remove('is-invalid');
            this.setState({ errorEstadoTrabajoSuministro: "" });
        }

        if (this.selectEstadoInspeccion.current.value == "") {
            error++;
            this.selectEstadoInspeccion.current.classList.add('is-invalid');
            this.setState({ errorEstadoInspeccion: "Seleccione el Estado de la Inspeccion" });
        } else {
            this.selectEstadoInspeccion.current.classList.remove('is-invalid');
            this.setState({ errorEstadoInspeccion: "" });
        }

        if (this.selectEstadoTrabajoSuministro.current.value == "EJECUTADO") {

            if (this.inputDocumentoEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputDocumentoEncuesta.current.classList.add('is-invalid');
                this.setState({ errorDocumentoEncuesta: "Falta el Numero DNI / RUC" });
            } else {
                this.inputDocumentoEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorDocumentoEncuesta: "" });
            }

            if (this.inputNombresEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputNombresEncuesta.current.classList.add('is-invalid');
                this.setState({ errorNombresEncuesta: "Falta el nombre." });
            } else {
                this.inputNombresEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorNombresEncuesta: "" });
            }

            if (this.inputCorreoEncuesta.current.value.toString().trim() == "") {
                this.inputCorreoEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorCorreoEncuesta: "" });
            } else {

                // Validar Expresiones Regulares
                let correoEvaluar = this.inputCorreoEncuesta.current.value.toString().trim();
                // let regExp = "^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+";
                var ExpRegEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

                if (correoEvaluar.match(ExpRegEmail)) {
                    this.inputCorreoEncuesta.current.classList.remove('is-invalid');
                    this.setState({ errorCorreoEncuesta: "" });

                } else {
                    error++;
                    this.inputCorreoEncuesta.current.classList.add('is-invalid');
                    this.setState({ errorCorreoEncuesta: "El correo ingresado no es válido, debe tener el formaro : correo@mail.com" });
                }
            }

            if (this.inputCelularEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputCelularEncuesta.current.classList.add('is-invalid');
                this.setState({ errorCelularEncuesta: "Falta ingresar el numero de celular." });
            } else {

                // Validar Expresiones Regulares
                let celularEvaluar = this.inputCelularEncuesta.current.value.toString().trim();
                var regExp = /^9\d{8}$/;

                if (celularEvaluar.match(regExp)) {
                    this.inputCelularEncuesta.current.classList.remove('is-invalid');
                    this.setState({ errorCelularEncuesta: "" });

                } else {
                    error++;
                    this.inputCelularEncuesta.current.classList.add('is-invalid');
                    this.setState({ errorCelularEncuesta: "El numero ingresado no es correcto." });
                }
            }

            if (this.inputTelefonoFijoEncuesta.current.value.toString().trim() == "") {

                this.inputTelefonoFijoEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorTelefonoFijoEncuesta: "" });
            } else {

                // Validar Expresiones Regulares
                let numeroEvaluar = this.inputTelefonoFijoEncuesta.current.value.toString().trim();
                let regExp = /^\d{6,7}$/;

                if (numeroEvaluar.match(regExp)) {
                    this.inputTelefonoFijoEncuesta.current.classList.remove('is-invalid');
                    this.setState({ errorTelefonoFijoEncuesta: "" });

                } else {
                    error++;
                    this.inputTelefonoFijoEncuesta.current.classList.add('is-invalid');
                    this.setState({ errorTelefonoFijoEncuesta: "El numero ingresado no es válido." });
                }
            }

            if (this.inputProvinciaEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputProvinciaEncuesta.current.classList.add('is-invalid');
                this.setState({ errorProvinciaEncuesta: "Falta ingresar la Provincia" });
            } else {
                this.inputProvinciaEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorProvinciaEncuesta: "" });
            }

            if (this.inputDistritoEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputDistritoEncuesta.current.classList.add('is-invalid');
                this.setState({ errorDistritoEncuesta: "Falta ingresar el Distrito" });
            } else {
                this.inputDistritoEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorDistritoEncuesta: "" });
            }

        } else {
            if (this.inputObservacionEncuesta.current.value.toString().trim() == "") {
                error++;
                this.inputObservacionEncuesta.current.classList.add('is-invalid');
                this.setState({ errorObservacionTrabajo: "Ingrese una observación." });
            } else {
                this.inputObservacionEncuesta.current.classList.remove('is-invalid');
                this.setState({ errorObservacionTrabajo: "" });
            }
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    ModificarTrabajoSuministro = () => {
        if (ValidarUsuario()) {
            if (this.ValidarModificarDatosEncuesta()) {
                // console.log("Todo Correcto");
                let url = `${config.endPoint}api/trabajosuministro/modificar`;
                let trabajoSuministro = {
                    idTrabajosuministro: this.state.suministroActual.idTrabajosuministro,
                    estado: this.selectEstadoTrabajoSuministro.current.value.toUpperCase(),
                    dniIngresado: this.inputDocumentoEncuesta.current.value.toUpperCase(),
                    nombresIngresado: this.inputNombresEncuesta.current.value.toUpperCase(),
                    nroCelularIngresado: this.inputCelularEncuesta.current.value.toUpperCase(),
                    correoIngresado: this.inputCorreoEncuesta.current.value.toUpperCase(),
                    telefonoFijoIngresado: this.inputTelefonoFijoEncuesta.current.value.toUpperCase(),
                    distritoIgresado: this.inputDistritoEncuesta.current.value.toUpperCase(),
                    ProvinciaIngresado: this.inputProvinciaEncuesta.current.value.toUpperCase(),
                    EstadoEncuestaIngresado: this.selectEstadoInspeccion.current.value.toUpperCase(),
                    observacion_ejecucion: this.inputObservacionEncuesta.current.value.toUpperCase()
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(trabajoSuministro)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json()
                }).then((data) => {

                    if (data.content) {
                        // this.CerrarModalAgregar();
                        //Buscar Trabajosuministro
                        this.BuscarSuministrosProgramados();
                        //Buscar Datos actualizados
                        this.BuscarTrabajoSuministro(this.state.suministroActual.idTrabajosuministro);

                        this.setState({ abrirToast: true, mensajeToast: "Se actualizo registros", tipotoast: 'success', modificarActualizacionDatos: false });
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                    }

                }).catch((error) => {

                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            } else {
                this.setState({ mensajeToast: "No se puede guardar informacion, corrija los errores que se muestran.", abrirToast: true, tipotoast: 'error' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    changeSelectEstadoTrabajosuministro = (ev) => {
        this.selectEstadoInspeccion.current.value = "";
        if (ev.target.value == "IMPEDIMENTO") {
            this.inputDocumentoEncuesta.current.value = "";
            this.inputNombresEncuesta.current.value = "";
            this.inputCorreoEncuesta.current.value = "";
            this.inputCelularEncuesta.current.value = "";
            this.inputTelefonoFijoEncuesta.current.value = "";
            this.inputProvinciaEncuesta.current.value = "";
            this.inputDistritoEncuesta.current.value = "";
        }
    }
    changeEstadoInspeccion = (ev) => {
        // console.log(ev.target.value);
        if (ev.target.value == "NO AUTORIZA" || ev.target.value == "USUARIO AUSENTE") {
            this.selectEstadoTrabajoSuministro.current.value = "IMPEDIMENTO";
        } else {
            this.selectEstadoTrabajoSuministro.current.value = "EJECUTADO";
        }
        // <option value="AUTORIZA TITULAR">AUTORIZA TITULAR</option>
        // <option value="AUTORIZA TERCERO">AUTORIZA TERCERO</option>
        // <option value="NO AUTORIZA">NO AUTORIZA</option>
        // <option value="DIRECCION ERRADA">DIRECCION ERRADA</option>
        // <option value="USUARIO AUSENTE">USUARIO AUSENTE</option>
    }
    //*************************************************
    // Funciones para subir Fotografias
    //*************************************************
    AbrirModalSubirFotos = (trabajo) => {
        // console.log(trabajo);
        this.setState({ mostrarModalSubirFotos: true, trabajoActual: trabajo, ListaFotosCargar: [] });
    }
    CerrarModalSubirFotos = () => {
        this.setState({ mostrarModalSubirFotos: false });
    }
    CargarFoto = async () => {
        let arrayfotossubir = [];
        if (this.inputSubirFotos.current.files.length > 0) {

            for (let x = 0; x < this.inputSubirFotos.current.files.length; x++) {
                // llenar array fotos locales                         
                // URL.createObjectURL(this.inputSubirFotos.current.files[x])       
                // console.log(this.inputSubirFotos.current.files[x]);
                let nuevaFoto = {
                    url: URL.createObjectURL(this.inputSubirFotos.current.files[x]),
                    nombre: `${this.state.trabajoActual.suministro}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('YYYYMMDD')}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('HHmmss')}.${this.inputSubirFotos.current.files[x].name.split(".").pop()}`,
                    fechaFoto: moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('YYYY-MM-DD'),
                    horaFoto: moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('HH:mm:ss'),
                };
                arrayfotossubir.push(nuevaFoto);

            }
            this.setState({ ListaFotosCargar: arrayfotossubir });
        }
    }
    SubirFotos = async () => {

        if (ValidarUsuario()) {
            if (this.inputSubirFotos.current.files.length > 0) {

                await this.setState({ cantidadFotosSubidas: 0, cantidadFotosError: 0, mostrarCargarFotos: true });

                this.inputSubirFotos.current.classList.remove('is-invalid')

                let url = "";

                if (this.inputcheckRotularFotografias.current.checked) {
                    url = `${config.endPoint}api/movil/cargar/fotosManual_rotulado`;
                } else {
                    url = `${config.endPoint}api/movil/cargar/fotosManual`;
                }


                // console.log(formData);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let cantidadElementossubidos = 0;
                let cantidadElementosactualizados = 0;
                let cantidadElementoserror = 0;

                for (let x = 0; x < this.inputSubirFotos.current.files.length; x++) {
                    let formData = new FormData();
                    formData.append('file', this.inputSubirFotos.current.files[x], `${this.state.trabajoActual.suministro}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('YYYYMMDD')}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('HHmmss')}.${this.inputSubirFotos.current.files[x].name.split(".").pop()}`);
                    formData.append('fecha', moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('YYYY-MM-DD'));
                    formData.append('hora', moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('HH:mm:ss'));
                    formData.append('foto_url', `${this.state.trabajoActual.suministro}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('YYYYMMDD')}_${moment(this.inputSubirFotos.current.files[x].lastModifiedDate).format('HHmmss')}.${this.inputSubirFotos.current.files[x].name.split(".").pop()}`);
                    formData.append('tipo', "MANUAL");
                    formData.append('lat', 0);
                    formData.append('lng', 0);
                    formData.append('estado', "0");
                    formData.append('idtrabajosuministro', this.state.trabajoActual.idTrabajosuministro);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: formData
                    };
                    // console.log(headers);

                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json()
                    }).then((data) => {
                        // console.log(data);
                        if (data.content !== null) {
                            // Foto cargada                            
                            // console.log("Foto Cargada");
                            if (data.message === "Foto Creado") {
                                cantidadElementossubidos = cantidadElementossubidos + 1;
                                this.setState({ cantidadFotosSubidas: cantidadElementossubidos })
                            } else {
                                cantidadElementosactualizados = cantidadElementosactualizados + 1;
                                this.setState({ cantidadFotosActualizadas: cantidadElementosactualizados })
                            }
                        }
                        else {
                            // Error al cargar foto                            
                            console.log("No Cargado");
                            cantidadElementoserror = cantidadElementoserror + 1;
                            this.setState({ cantidadFotosError: cantidadElementoserror })
                        }
                    }).catch(() => {
                        // Error al cargar foto                        
                        console.log("Error general");
                        cantidadElementoserror = cantidadElementoserror + 1;
                        this.setState({ cantidadFotosError: cantidadElementoserror })
                    });
                }
                if (cantidadElementossubidos > 0) {
                    this.ActualizarTrabajoSuministro(cantidadElementossubidos);
                }
                else {
                    this.setState({ mensajeToast: `Se actualizaron fotos.`, abrirToast: true, tipotoast: 'success' });
                }

                //Ocultar Loading Carga
                this.setState({ mostrarCargarFotos: false });
                this.CerrarModalSubirFotos();

                // Actualizar Lista de Suministros
                await this.BuscarDetalleTrabajo();
                await this.BuscarSuministrosProgramados();

            }
            else {
                this.inputSubirFotos.current.classList.add('is-invalid')
            }
        }
        else {
            this.props.history.push(`${config.rutaserver}/login`);
        }
    }
    ActualizarTrabajoSuministro = (cantidadElementossubidos) => {
        // Actualizar trabajo Suministro         

        let url = `${config.endPoint}api/trabajosuministro/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let trabajosuministro = {
            idTrabajosuministro: this.state.trabajoActual.idTrabajosuministro,
            fotosTomadas: cantidadElementossubidos + this.state.trabajoActual.t_fotos.length,
            // estado: 'EN EJECUCION',
            // lat_ejecucion: 0,
            // lng_ejecucion: 0
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(trabajosuministro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content !== null) {
                this.setState({ mensajeToast: `Se actualizo Trabajo.`, abrirToast: true, tipotoast: 'success' });
            }
            else {
                this.setState({ mensajeToast: `No se actualizo.`, abrirToast: true, tipotoast: 'success' });
            }
        }).catch((ERROR) => {
            console.log(ERROR);
            this.setState({ mensajeToast: ERROR, abrirToast: true, tipotoast: 'error' });
        });

    }
    // Liberar Suministro
    LiberarTrabajoSuministro = () => {
        if (ValidarUsuario()) {

            // console.log("Todo Correcto");
            let url = `${config.endPoint}api/trabajosuministro/modificar`;
            let trabajoSuministro = {
                idTrabajosuministro: this.state.suministroActual.idTrabajosuministro,
                estado: 'PROGRAMADO',
                idpersonalejecuta: null,
                lecturainspeccion: '',
                codigoinspeccion: '',
                fecha_ejecucion: null,
                hora_ejecucion: null
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(trabajoSuministro)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {

                if (data.content) {
                    // this.CerrarModalAgregar();
                    //Buscar Trabajosuministro
                    this.BuscarSuministrosProgramados();
                    this.setState({ abrirToast: true, mensajeToast: "Se actualizo registro", tipotoast: 'success', mostrarModalLiberarSuministro: false });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                }

            }).catch((error) => {

                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });


        }
        else {
            this.props.history.push('/login');
        }
    }
    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="MONITOREO-TRABAJADOR" regresar={() => { this.props.history.push('/monitoreo') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row bg-secondary text-light mx-1 mt-1 py-1 rounded">
                        <div className="col-md-8 ">
                            <span >{`${this.state.DatosTrabajador.nombretrabajador} - (${this.state.DatosTrabajador.Tipo})`}</span>
                        </div>
                        <div className="col-md-4 text-lg-end">
                            <span>{`Programación : ${this.state.DatosTrabajador.fechaProgramacion}`}</span>
                        </div>
                    </div>

                    {/* Opciones de busqueda */}
                    <div className="row mt-1">
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">
                            <select ref={this.selectFiltroEstados} onChange={() => { this.elegirEstadoSuministro() }} className="form-select form-select-sm" >
                                <option value="TODOS">Todos los Estados</option>
                                <option value="EJECUTADO">Ejecutados</option>
                                <option value="NOEJECUTADO">No Ejecutados</option>
                                <option value="PENDIENTES">Pendientes</option>
                                <option value="IMPEDIMENTO">Impedimentos</option>
                            </select>
                        </div>
                        <div className=" col-md-6  col-lg-3 mt-1 mt-md-0">
                            <select ref={this.selectTipoFiltro} onChange={() => { this.CambioSelectTipo() }} className="form-select form-select-sm" >
                                <option value="Todos">Todos los Suministros</option>
                                <option value="Suministro">Numero de Suministro</option>
                                {/* <option value="Supervisor">Supervisor</option>
                                            <option value="Estado">Estado</option> */}
                            </select>
                        </div>
                        <div className="col-md-6 col-lg-3 mt-1 mt-lg-0">
                            {
                                this.state.tipofiltro !== "Todos" ?
                                    <>
                                        <input onChange={(ev) => { this.changefiltrobuscar(ev) }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                                    </>
                                    : <></>
                            }

                        </div>
                        {/* Opciones */}
                        <div className="col-md-6 col-lg-3 text-end mt-1 mt-lg-0">
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    {/* <li onClick={() => { this.finalizarJornadaTrabajo() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Finalizar Jornada</small></li> */}
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_FOTO, this.state.permisos) ?
                                            <li onClick={() => { this.DescargarFotosProgramacion() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Fotos</small></li>
                                            : <></>
                                    }
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                            <></> :
                                            <li onClick={() => { this.DescargarReporteTrabajador() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Reporte</small></li>

                                    }
                                    {/* {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                            <></> :
                                            <li onClick={() => { this.DescargarReporteActividadesTrabajador() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Reporte(Act+Mat)</small></li>
                                    } */}
                                    <li><hr className="dropdown-divider" /></li>
                                    {/* <li onClick={() => { window.confirm('¿Ver sum sin foto?') }}><small className="dropdown-item" ><i className="fas fa-exclamation-circle mx-2"></i>Mostrar Suministros Sin Foto</small></li>
                                    <li onClick={() => { window.confirm('¿Ver suministros con fotos faltantes ?') }}><small className="dropdown-item" ><i className="fas fa-photo-video mx-2"></i>Mostrar Suministros Falta Foto</small></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li> */}
                                    <li>
                                        <Link to={{ pathname: `/monitoreo/trabajador/mapa/${this.state.fechaprogramacion}/${this.state.idtrabajador}`, state: { nombretrabajador: this.state.DatosTrabajador.nombretrabajador } }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                    </li>
                                    {/* <li><hr className="dropdown-divider" /></li>
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_REPORTE, this.state.permisos) ?
                                            <li onClick={() => { this.DescargarReporteActualizacionDatos() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Reporte </small></li>
                                            : <></>
                                    } */}

                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Aqui inicia la Tabla */}

                    <div className="row p-1">
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader >
                                            <TableHead
                                            // className="fondoTituloTabla"
                                            >

                                                <TableRow>
                                                    <TableCell>Acciones</TableCell>
                                                    <TableCell>Suministro</TableCell>
                                                    <TableCell>Cliente</TableCell>
                                                    <TableCell>Dirección</TableCell>
                                                    <TableCell>Tipo</TableCell>
                                                    {
                                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_LECTURA_INSPECCION, this.state.permisos) ?
                                                            <TableCell>Codigo</TableCell>
                                                            : <></>
                                                    }
                                                    {
                                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_LECTURA_INSPECCION, this.state.permisos) ?
                                                            <TableCell>Lectura</TableCell>
                                                            : <></>
                                                    }
                                                    <TableCell>Estado</TableCell>
                                                    <TableCell>Foto</TableCell>
                                                    <TableCell>Ejecutado</TableCell>
                                                    {
                                                        ValidarPermisos(LIST_SCREENS.ACCERSS_OPCION_CARGAR_FOTO, this.state.permisos) ?
                                                            <>
                                                                <TableCell>Cargar</TableCell>
                                                            </>
                                                            : <></>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaSuministros.map((row, index) => {

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.8em' }}>
                                                                {/* <Link to={{
                                                                    pathname: `/monitoreo/suministro/${row.idTrabajosuministro}`,
                                                                    state: {
                                                                        DatosTrabajadorSuministro: this.state.DatosTrabajador
                                                                    }
                                                                }}>
                                                                    <i title={`Ver Suministro ${row.suministro}`} className="fas fa-eye  mx-2 iconoAcciones" style={{ fontSize: '1.5em' }}></i>
                                                                </Link> */}
                                                                <i onClick={() => { this.AbrirModalDetalleSuministro(row) }} title={`Ver Suministro ${row.suministro}`} className="fas fa-eye  mx-2 iconoAcciones" style={{ fontSize: '1.5em' }}></i>
                                                                {
                                                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_LIBERAR_TRABAJO, this.state.permisos) ?
                                                                        row.estado != 'PROGRAMADO' ?
                                                                            <i onClick={() => { this.AbrirModalLiberarSuministro(row) }} title={`Liberar Suministro ${row.suministro}`} className="fas fa-unlock  mx-2 iconoAcciones" style={{ fontSize: '1.5em' }}></i>
                                                                            : <></>
                                                                        : <></>
                                                                }
                                                            </TableCell >
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.suministro}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.nombreCliente}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.direccionCliente}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.tipo}</TableCell>
                                                            {
                                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_LECTURA_INSPECCION, this.state.permisos) ?
                                                                    <TableCell style={{ fontSize: '0.8em' }}>{row.codigoinspeccion}</TableCell>
                                                                    : <></>
                                                            }
                                                            {
                                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_LECTURA_INSPECCION, this.state.permisos) ?
                                                                    <TableCell style={{ fontSize: '0.8em' }}>{row.lecturainspeccion}</TableCell>
                                                                    : <></>
                                                            }
                                                            <TableCell style={{ fontSize: '0.8em' }}>{
                                                                row.estado === 'NO EJECUTADO' || row.estado === 'IMPEDIMENTO' ?
                                                                    <label className="text-danger">{row.estado}</label> :
                                                                    (row.estado === 'PROGRAMADO' ?
                                                                        <label >{row.estado}</label> :
                                                                        <label className="fw-bold text-success">{row.estado}</label>)

                                                            }
                                                            </TableCell>
                                                            {
                                                                row.fotosTomadas > row.t_fotos.length ?
                                                                    <TableCell style={{ fontSize: '0.8em' }}><i onClick={() => { this.AbrirModalFotos(row) }} className="fas fa-camera iconoAcciones text-danger"></i>{`${row.t_fotos.length}/${row.fotosTomadas}`}</TableCell>
                                                                    :
                                                                    <TableCell style={{ fontSize: '0.8em' }}><i onClick={() => { this.AbrirModalFotos(row) }} className="fas fa-camera iconoAcciones"></i>{`${row.t_fotos.length}/${row.fotosTomadas}`}</TableCell>
                                                            }
                                                            <TableCell style={{ fontSize: '0.8em' }}>{`${row.fecha_ejecucion ? row.fecha_ejecucion : ''} ${row.hora_ejecucion ? row.hora_ejecucion : ''}`}</TableCell>
                                                            {
                                                                ValidarPermisos(LIST_SCREENS.ACCERSS_OPCION_CARGAR_FOTO, this.state.permisos) ?
                                                                    <>
                                                                        <TableCell style={{ fontSize: '0.8em' }} title='Subir Foto'><i onClick={() => { this.AbrirModalSubirFotos(row) }} className="fas fa-upload iconoAcciones"></i></TableCell>
                                                                    </>
                                                                    : <></>
                                                            }
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[25, 50, 100, 200]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={(ev, npage) => { this.CambiarPagina(ev, npage) }}
                                        onChangeRowsPerPage={(ev) => { this.CambioFilasPorPagina(ev) }}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />

                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* Modal Fotos*/}

                <Modal
                    show={this.state.mostarfotos}
                    onHide={() => { this.CerrarModalFotos() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    {/* <Modal.Header>
                        <Modal.Title>Fotos</Modal.Title>
                    </Modal.Header> */}

                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <div className="container" >
                            <div className="row" style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {
                                        this.state.ListaFotosTrabajoSuministro.map((foto, index) => {
                                            return (
                                                <Carousel.Item key={index} >
                                                    <div >
                                                        <img
                                                            className="d-block m-auto"
                                                            src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                            alt={`Imagen ${index + 1}`}
                                                            height='550'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                        />
                                                        <div className="row ">
                                                            <div className="col-6 col-md-6 text-light">
                                                                <span>{`Foto ${index + 1} de ${this.state.ListaFotosTrabajoSuministro.length}`}</span>
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end text-light">
                                                                <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <Carousel.Caption>
                                                        <small>{foto.foto_url}</small>
                                                    </Carousel.Caption> */}
                                                </Carousel.Item>

                                            );
                                        })
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal Detalle Suministro*/}

                <Modal
                    show={this.state.mostrarModalDetalleSuministro}
                    onHide={() => { this.CerrarModalDetalleSuministro() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Header>
                        <Modal.Title>
                            <div>
                                <small className="text-primary">Suministro/Contrato: </small>
                                <label>{this.state.suministroActual.suministro}</label>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        {/* Datos de Cliente */}
                        <div className="container fluid">
                            <div className=" border rounded p-1">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Cliente</small>
                                    </div>
                                    <div className='col'>

                                        <div className="col text-end">
                                            <i onClick={() => { this.GenerarPDF() }} className="fas fa-file-pdf fa-2x " style={{ color: 'rgb(230,83,97)', cursor: 'pointer' }}></i>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.nombreCliente}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Dirección</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.direccionCliente}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">SED</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.subEstacion}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Suministro/Ruta</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.suministro_ruta}</label>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                {/* <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Serie Medidor</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.serie}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Fecha de Pago</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.fechaDePago}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* Datos de Trabajo */}
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <></> :
                                    <div className="border rounded p-1 mt-1">
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">OT</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{this.state.suministroActual.ot}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Orden Trabajo</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{this.state.suministroActual.tipoOt}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Detalle Trabajo</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.detalle}</label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Tipo Trabajo</small>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{this.state.suministroActual.tipo}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Potencia</small>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{this.state.suministroActual.potencia}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {/* Detalle ejecucion Trabajo */}
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <></> :
                                    <div className="border rounded border-success rounted p-1 mt-1">
                                        <div className="row">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small className="text-primary">Estado</small>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label>{this.state.suministroActual.estado}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small className="text-primary">Fecha Ejecución</small>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <label>{this.state.suministroActual.fecha_ejecucion} {this.state.suministroActual.hora_ejecucion}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <small className="text-primary">Observación Ejecución</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>{this.state.suministroActual.observacion_ejecucion}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {/* Detalle Ejecucion de trabajo Actualizacion Datos */}
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <div className="border rounded border-success rounted p-1 mt-1">
                                        <div className="row">
                                            <div className='row text-end'>
                                                <small className='text-muted'>{`Ejecutor: ${this.state.suministroActual.TrabajadorEjecuta}`}</small>
                                                {/* <div className='col'>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small className="text-primary">Estado</small>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            {/* <label>{this.state.suministroActual.estado}</label> */}
                                                            {
                                                                this.state.modificarActualizacionDatos ?
                                                                    <>
                                                                        <select onChange={(ev) => { this.changeSelectEstadoTrabajosuministro(ev) }} ref={this.selectEstadoTrabajoSuministro} className="form-select form-select-sm" defaultValue={this.state.suministroActual.estado}>
                                                                            {/* <option selected>Open this select menu</option> */}
                                                                            <option value="">Seleccione un elemento</option>
                                                                            <option value="EJECUTADO">EJECUTADO</option>
                                                                            <option value="IMPEDIMENTO">IMPEDIMENTO</option>
                                                                        </select>
                                                                        <small className='text-danger'>{this.state.errorEstadoTrabajoSuministro}</small>
                                                                    </>
                                                                    :
                                                                    <FormControl ref={this.inputEstado} type='text' size='sm' defaultValue={this.state.suministroActual.estado} disabled></FormControl>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <small className="text-primary">Fecha Ejecución</small>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <FormControl type='text' size='sm' defaultValue={`${this.state.suministroActual.fecha_ejecucion} ${this.state.suministroActual.hora_ejecucion}`} disabled></FormControl>
                                                            {/* <label>{this.state.suministroActual.fecha_ejecucion} {this.state.suministroActual.hora_ejecucion}</label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">Estado Inspeccion:</small>
                                                </div>
                                                <div className="col-9">
                                                    {
                                                        this.state.modificarActualizacionDatos ?
                                                            <>
                                                                <select onChange={(ev) => { this.changeEstadoInspeccion(ev) }} ref={this.selectEstadoInspeccion} className="form-select form-select-sm" defaultValue={this.state.suministroActual.EstadoEncuestaIngresado}>
                                                                    {/* <option selected>Open this select menu</option> */}
                                                                    <option value="">Seleccione un elemento</option>
                                                                    <option value="AUTORIZA TITULAR">AUTORIZA TITULAR</option>
                                                                    <option value="AUTORIZA TERCERO">AUTORIZA TERCERO</option>
                                                                    <option value="NO AUTORIZA">NO AUTORIZA</option>
                                                                    <option value="DIRECCION ERRADA">DIRECCION ERRADA</option>
                                                                    <option value="USUARIO AUSENTE">USUARIO AUSENTE</option>
                                                                </select>
                                                                <small className='text-danger'>{this.state.errorEstadoInspeccion}</small>
                                                            </>
                                                            :
                                                            <FormControl ref={this.inputEstadoInspeccion} type='text' size='sm' defaultValue={this.state.suministroActual.EstadoEncuestaIngresado} disabled></FormControl>
                                                    }
                                                    {/* <label>{this.state.suministroActual.EstadoEncuestaIngresado}</label> */}
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">DNI/RUC:</small>
                                                </div>
                                                <div className="col-9">
                                                    <FormControl onKeyPress={(ev) => { this.KeyEnterInputBuscarEnLinea(ev) }} ref={this.inputDocumentoEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.dniIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                    <small className='text-danger'>{this.state.errorDocumentoEncuesta}</small>
                                                    {/* <label>{}</label> */}
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">Nombres/ Razon Social:</small>
                                                </div>
                                                <div className="col-9">
                                                    <FormControl ref={this.inputNombresEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.nombresIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                    <small className='text-danger'>{this.state.errorNombresEncuesta}</small>
                                                    {/* <label>{this.state.suministroActual.nombresIngresado}</label> */}
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">Direcciòn:</small>
                                                </div>
                                                <div className="col-9">
                                                    <FormControl ref={this.inputDistritoEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.direccionIngresado} disabled></FormControl>
                                                    {/* <label>{this.state.suministroActual.direccionIngresado}</label> */}
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">Correo Electronico:</small>
                                                </div>
                                                <div className="col-9">
                                                    <FormControl ref={this.inputCorreoEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.correoIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                    <small className='text-danger'>{this.state.errorCorreoEncuesta}</small>
                                                    {/* <label>{this.state.suministroActual.correoIngresado}</label> */}
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className="col-4">
                                                            <small className="text-primary">Num. Celular:</small>
                                                        </div>
                                                        <div className="col-8">
                                                            <FormControl ref={this.inputCelularEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.nroCelularIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                            <small className='text-danger'>{this.state.errorCelularEncuesta}</small>
                                                            {/* <label>{this.state.suministroActual.nroCelularIngresado}</label> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className="col-4">
                                                            <small className="text-primary">Num. Fijo:</small>
                                                        </div>
                                                        <div className="col-8">
                                                            <FormControl ref={this.inputTelefonoFijoEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.telefonoFijoIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                            <small className='text-danger'>{this.state.errorTelefonoFijoEncuesta}</small>
                                                            {/* <label>{this.state.suministroActual.telefonoFijoIngresado}</label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className="col-4">
                                                            <small className="text-primary">Provincia:</small>
                                                        </div>
                                                        <div className="col-8">
                                                            <FormControl ref={this.inputProvinciaEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.ProvinciaIngresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                            <small className='text-danger'>{this.state.errorProvinciaEncuesta}</small>
                                                            {/* <label>{this.state.suministroActual.ProvinciaIngresado}</label> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col'>
                                                    <div className='row'>
                                                        <div className="col-4">
                                                            <small className="text-primary">Distrito:</small>
                                                        </div>
                                                        <div className="col-8">
                                                            <FormControl ref={this.inputDistritoEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.distritoIgresado} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                            <small className='text-danger'>{this.state.errorDistritoEncuesta}</small>
                                                            {/* <label>{this.state.suministroActual.distritoIgresado}</label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-1">
                                                <div className="col-3">
                                                    <small className="text-primary">GPS:</small>
                                                </div>
                                                <div className="col-4">
                                                    <FormControl type='text' size='sm' defaultValue={`${this.state.suministroActual.lat_ejecucion};${this.state.suministroActual.lng_ejecucion}`} disabled></FormControl>
                                                    {/* <label>{this.state.suministroActual.correoIngresado}</label> */}
                                                </div>
                                                <div className="col-4">
                                                    <span onClick={() => { this.AbrirMaps(this.state.suministroActual.lat_ejecucion, this.state.suministroActual.lng_ejecucion) }} className="text-primary text-decoration-underline pointer-link ">Ver en Maps</span>
                                                    {/* <label>{this.state.suministroActual.correoIngresado}</label> */}
                                                </div>
                                            </div>

                                            {/* Observaciones */}
                                            <div className="row mt-1">
                                                <div className="col">
                                                    <small className="text-primary">Observación</small>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col">
                                                    <FormControl ref={this.inputObservacionEncuesta} type='text' size='sm' defaultValue={this.state.suministroActual.observacion_ejecucion} disabled={!this.state.modificarActualizacionDatos}></FormControl>
                                                    <small className='text-danger'>{this.state.errorObservacionTrabajo}</small>
                                                    {/* <label>{this.state.suministroActual.observacion_ejecucion}</label> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/* Ejecucion Actividades */}
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <></>
                                    :
                                    <div className="border rounded border-success rounted p-1 mt-1">
                                        <div className="row  my-1">
                                            <div className="col">
                                                <label className="fw-bold">Actividades Ejecutadas</label>
                                            </div>
                                        </div>
                                        <div className="row border-bottom my-1 ">
                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                <TableContainer>
                                                    <Table size='small'>
                                                        <TableHead className="fondoTituloTabla">
                                                            <TableRow>
                                                                <TableCell style={{ color: 'aliceblue' }} >Actividad</TableCell>
                                                                <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                this.state.suministroActual.t_actividads ?
                                                                    this.state.suministroActual.t_actividads.map((actividad, index) => {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{actividad.descripcion}</TableCell>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{actividad.cantidad}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })
                                                                    : <></>
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {/* Ejecucion Materiales */}
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <></> :
                                    <div className="border rounded border-success rounted p-1 mt-1">
                                        <div className="row  my-1">
                                            <div className="col">
                                                <label className="fw-bold">Materiales Agregados</label>
                                            </div>
                                        </div>
                                        <div className="row border-bottom my-1 ">
                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                <TableContainer>
                                                    <Table size='small'>
                                                        <TableHead className="fondoTituloTabla">
                                                            <TableRow>
                                                                <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                                <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                this.state.suministroActual.t_materials ?
                                                                    this.state.suministroActual.t_materials.map((material, index) => {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{material.cantidad}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })
                                                                    : <></>
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className='mt-1 text-end'>
                            <div className=''>
                                {
                                    this.state.modificarActualizacionDatos ?
                                        <>
                                            <button onClick={() => { this.ModificarFormulario(false) }} className="btn btn-outline-primary mx-1 btn-sm">Cancelar</button>
                                            <button onClick={() => { this.ModificarTrabajoSuministro() }} className="btn btn-outline-primary mx-1 btn-sm">Guardar</button>
                                        </> :
                                        // ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_MODIFICAR_TRABAJO, this.state.permisos) ?
                                        //     <button onClick={() => { this.ModificarFormulario(true) }} className="btn btn-outline-primary mx-1 btn-sm">Modificar</button>
                                        // : 
                                        <></>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* Modal PDF */}
                <Modal
                    show={this.state.MostrarModalVerPDF}
                    onHide={() => { this.CerrarModalMostrarPDF() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Body >
                        {/* <PdfVista suministro={this.state.suministroActual}></PdfVista> */}
                        <div className='container'>
                            <div>
                                <h1>PDF Reporte</h1>
                            </div>
                            <hr></hr>
                            <div className='row'>
                                <div className='col'>
                                    <h3>What is Lorem Ipsum?</h3>
                                    <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</small>
                                </div>
                                <div className='col'>
                                    <h3>What is Lorem Ipsum?</h3>
                                    <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</small>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal Subir Fotos*/}

                <Modal
                    show={this.state.mostrarModalSubirFotos}
                    onHide={() => { this.CerrarModalSubirFotos() }}
                    keyboard={false}
                    size="lg"
                    centered
                    backdrop='static' >
                    <Modal.Header>
                        <Modal.Title>Cargar Fotos</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >

                        <div className="container">
                            <div className="row">
                                <small className='text-secondary'>
                                    Seleccione las fotos que desea cargar. Puede elegir mas de una foto.

                                </small>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-12">
                                    <small className="text-primary">Fotografia</small>
                                    <input accept="image/*" onChange={() => { this.CargarFoto() }} ref={this.inputSubirFotos} className="form-control form-control-sm" id="formFileSm" type="file" multiple></input>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className='col'>
                                    <div className="form-check">
                                        <input ref={this.inputcheckRotularFotografias} className="form-check-input" type="checkbox" id="flexCheckrotularFotografias" />
                                        <label className="form-check-label" for="flexCheckrotularFotografias">
                                            Rotular fotografias al Subir al Servidor.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.ListaFotosCargar.map((row, index) => {
                                    return (

                                        <div key={index} className='row border rounded m-1'>
                                            <div className='col-6 p-1'>
                                                <img className="d-block m-auto" src={row.url} height='200'></img>
                                            </div>
                                            <div className='col-6 p-1'>
                                                {/* Nombre Foto */}
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Nombre Foto Subir</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{row.nombre}</label>
                                                    </div>
                                                </div>
                                                {/* Nombre Fecha Foto */}
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Fecha Foto</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{row.fechaFoto}</label>
                                                    </div>
                                                </div>
                                                {/* Hora Foto */}
                                                <div className="row">
                                                    <div className="col">
                                                        <small className="text-primary">Hora Foto</small>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <label>{row.horaFoto}</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    )
                                })
                            }

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.mostrarCargarFotos ?
                                <div className="container">
                                    <div className='row'>
                                        <div className="text-center">
                                            <div className="spinner-border text-primary" role="status">
                                            </div>
                                            <span className="mx-1 pb-1">{`${this.state.cantidadFotosSubidas} Foto(s) subida(s), ${this.state.cantidadFotosActualizadas} Foto(s) actualizada(s),${this.state.cantidadFotosError} Foto(s) no subida(s)`}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <button onClick={() => { this.CerrarModalSubirFotos() }} className="btn btn-secondary mx-1">Cerrar</button>
                                    <button onClick={() => { this.SubirFotos() }} className="btn btn-primary mx-1">Cargar Fotos</button>
                                </div>
                        }
                    </Modal.Footer>
                </Modal>

                {/* Modal Liberar Trabajo */}
                <Modal
                    show={this.state.mostrarModalLiberarSuministro}
                    onHide={() => { this.CerrarModalLiberarSuministro() }}
                    keyboard={false}
                    size="md"
                    centered
                    backdrop='static' >
                    <Modal.Header>
                        <Modal.Title>Liberar</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Container>
                            <Row>
                                <Col>
                                    <label>{`¿Esta seguro de liberar el suministro? \nAl liberar se cambiara el estado del suministro a PROGRAMADO , las fotos permaneceran pero la data ingresada se borrara.`}</label>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <button onClick={() => { this.CerrarModalLiberarSuministro() }} className="btn btn-secondary mx-1 btn-sm">Cerrar</button>
                                </Col>
                                <Col className='text-end'>
                                    <button onClick={() => { this.LiberarTrabajoSuministro() }} className="btn btn-primary mx-1 btn-sm">Liberar</button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default monitoreoTrabajador;