import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos, CreateJsonToExcel, listarPermisosTipoTrabajo, ObtenerDetalleToken } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
import { Link, Switch } from 'react-router-dom';
// import socketIOClient from 'socket.io-client';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal, Form } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';

// import { CreateJsonToExcel } from './../utils/utils'
//Crear zip y guardar
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
var zip = require('jszip')();

class monitoreo extends Component {
    //Variables globales     
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            colorIcon: '',
            MostrarModalCambiarTrabajador: false,
            MostrarModalDescargar: false,
            MostrarModalReporteFechas: false,
            MostrarSnackDescargaFotos: false,
            mensajeDescargaFotos: '',
            fechaprogramacion: '',
            LoadData: false,
            ListaProgramacion: [],
            ProgramacionDetalleDia: [],
            ListaPaginacion: [],
            valorEstadoBusqueda: 'TODOS',
            ListaBusqueda: [],
            ListaSuministrosProgramadoTecnico: [],
            ListaTrabajadoresActivos: [],
            ListaSuministrosSeleccionados: [],
            TrabajoProgramadoSeleccionado: '',
            // TipoTrabajos: [],
            TiposTrabajoFechaProgramada: [],
            cantidadElementosSeleccionados: 0,
            cantidadElementos: 0,
            filasPorPagina: 50,
            pagina: 1,
            sizeWidth: 0,
            tipofiltro: 'Todos',
            valorBuscar: '',
            ProgramacionActualizada: 0,
            ProgramacionError: 0,
            mensaCargaActualizacion: '',
            MostrarPorcentajeCarga: false,
            visualizarTodosTrabajos: null,
            visualizarTiposTrabajo: null,
            idproyecto: null,
            mostrarModalReprogramar: false,
            trabajadorSeleccionado: null,
            MostrarModalReporteTipoTrabajo: false,
            permisos: []
        };
        // Referencias
        this.inputfechaProgramacion = createRef();
        this.selectTipoFiltro = createRef();
        this.inputBuscarFiltro = createRef();
        this.selectTipoTrabajo = createRef();

        // Referencias Modal Cambiar Trabajador
        this.inputNombreTrabajador = createRef();
        this.inputElegirTrabajador = createRef();
        this.inputFechaReprogramar = createRef();
        this.inputChecktodosLosElementos = createRef();
        this.listarFotosProgramacion = [];

        //Referencias modal Descargar
        this.selectTipoTrabajoDescargar = createRef();
        this.checkRotularFotoDescarga = createRef();

        //Referencias Modal Reporte Fechas
        this.inputFechaInicioReporteFecha = createRef();
        this.inputFechaFinReporteFecha = createRef();


        this.tableBodySuministros = createRef();
        this.headCells = [
            { id: 'Ver' },
            { id: 'Edit.' },
            { id: 'Reprog.' },
            { id: 'JUO' },
            { id: 'Supervisor' },
            { id: 'Area' },
            // { id: 'Tipo' },
            { id: 'Estado' },
            { id: 'Avance' },
            { id: 'Inicio' },
            { id: 'Fin' },
            { id: 'Descarga' },
            // { id: 'Fin.' },
        ];
        //Inicializar Valores de Servidor Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});     

    }

    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                // console.log(permisos);
                this.setState({ permisos: permisos });

                await listarPermisosTipoTrabajo().then(async (respuesta) => {
                    if (respuesta) {
                        await this.setState({ visualizarTodosTrabajos: respuesta.visualizartodostrabajos, visualizarTiposTrabajo: respuesta.visualizarportipotrabajos });
                    }
                });

                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, permisos)) {

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    // Llenar los cuadros y llamar a la APi    
                    let fechaStorage = await localStorage.getItem(config.storageDateName);
                    let tipoTrabajoStorage = await localStorage.getItem(config.storageFilterTrabajo);

                    if (tipoTrabajoStorage) {
                        // console.log(tipoTrabajoStorage);
                        this.selectTipoTrabajo.current.value = tipoTrabajoStorage;
                        this.setState({ TrabajoProgramadoSeleccionado: `(${tipoTrabajoStorage})` })
                        // console.log(this.selectTipoTrabajo.current.value);
                    }

                    else {
                        this.setState({ TrabajoProgramadoSeleccionado: `` })
                    }

                    if (fechaStorage) {
                        this.inputfechaProgramacion.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: fechaStorage });
                    } else {
                        this.inputfechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
                    }

                    await this.BuscarProgramacionfecha();
                    // await this.SocketConcect();

                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }

            });


        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    // SocketConcect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.BuscarProgramacionfecha();
    //     });
    // }

    // componentWillUnmount() {
    //     this.socket.close();        
    // }

    BuscarProgramacionfecha = async () => {
        if (ValidarUsuario()) {

            // let fecha = moment(this.inputfechaProgramacion.current.value).format('YYYY-MM-DD');
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            // Buscar Datos de usuario   
            // console.log(UsuarioStorage.visualizar); 
            // let url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_v2/${this.state.fechaprogramacion}`;
            let num_consulta = 1; // Predeterminado consulta solo fecha


            if (this.state.visualizarTodosTrabajos) {
                // Mostrar todos los trabajos?
                if (this.state.visualizarTodosTrabajos === "INACTIVO") {
                    // Visualizar Solo los trabajos asociados a la cuenta                    
                    if (this.state.visualizarTiposTrabajo) {
                        if (this.state.visualizarTiposTrabajo === 'ACTIVO') {
                            //Mostrar solo mis trabajos + fecha + lista de tipos trabajo
                            num_consulta = 4;
                        } else {
                            //Mostrar solo mis trabajos + fecha
                            num_consulta = 3;
                        }
                    } else {
                        num_consulta = 3;
                    }
                } else {
                    // Visualizar todos los trabajos
                    if (this.state.visualizarTiposTrabajo) {
                        if (this.state.visualizarTiposTrabajo === 'ACTIVO') {
                            //Mostrar  fecha + lista de tipos trabajo
                            num_consulta = 2;
                        } else {
                            //Mostrar fecha
                            num_consulta = 1;
                        }
                    } else {
                        num_consulta = 1;
                    }
                }
            } else {
                if (this.state.visualizarTiposTrabajo) {
                    if (this.state.visualizarTiposTrabajo === 'ACTIVO') {
                        num_consulta = 2;
                    } else {
                        num_consulta = 1;
                    }
                } else {
                    num_consulta = 1;
                }
            }


            switch (num_consulta) {
                case 1:
                    // Consulta Fecha
                    // console.log("Consulta 1");
                    // console.log("Buscar Fecha");
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_v2/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
                    break;
                case 2:
                    // console.log("Consulta 2");
                    // console.log("Buscar Fecha + listatipotrabajos");
                    // Consulta Fecha + listatipostrabajo
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_tipostrabajo/${this.state.idproyecto}/${this.state.fechaprogramacion}/${UsuarioStorage.tiposesion}`;
                    break;
                case 3:
                    // console.log("Consulta 3");
                    // console.log("Buscar Fecha + mistrabajos");
                    // Consulta Fecha + visualizar solo mis trabajos
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_v3/${this.state.idproyecto}/${this.state.fechaprogramacion}/${UsuarioStorage.id}`;
                    break;
                case 4:
                    // console.log("Consulta 4");
                    // console.log("Buscar Fecha + mistrabajos + listatipostrabajo");
                    // Consulta Fecha + visualizar solo mis trabajos + listatipostrabajo
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_tipostrabajo_idtrabajador/${this.state.idproyecto}/${this.state.fechaprogramacion}/${UsuarioStorage.id}/${UsuarioStorage.tiposesion}`;
                    break;
            }

            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            let urlp = `${config.endPoint}api/trabajosuministro/ProgramacionDia_v2/${this.state.idproyecto}/${this.state.fechaprogramacion}`;


            if (this.state.valorBuscar.trim() !== '') {
                switch (this.state.tipofiltro) {
                    case 'Trabajador':
                        let valorTrabajador = String(this.state.valorBuscar);
                        if (valorTrabajador.trim() !== '') {
                            url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/trabajador_v2/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.valorBuscar}`;
                        }
                        break;
                    case 'Supervisor':
                        // Buscar Lista de Supervisores                    
                        let valorsupervisor = String(this.state.valorBuscar);
                        if (valorsupervisor.trim() !== '') {
                            url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/supervisor/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.valorBuscar}`;
                        }
                        break;
                    default:
                    // Por Default todos
                    // url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/${this.state.fechaprogramacion}`;
                }
            }


            await fetch(urlp, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.content) {
                    await this.setState({ ProgramacionDetalleDia: data.content });
                }
                else {
                    this.setState({ ListaProgramacion: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                console.log();
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    // console.log(data.content);
                    await this.setState({ ListaProgramacion: data.content, cantidadElementos: data.content.length });
                    await this.filtarTipoTrabajo();
                    if (this.state.valorEstadoBusqueda !== 'TODOS') {
                        await this.filtrarEstado();
                    }
                    await this.Paginacion();
                }
                else {
                    this.setState({ ListaProgramacion: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
            this.buscarTiposTrabajosFechaProgramada();
        }
        else {
            this.props.history.push('/login');
        }
    }

    filtarTipoTrabajo = async () => {
        // console.log(this.state.ListaProgramacion);
        let tipoTrabajoStorage = await localStorage.getItem(config.storageFilterTrabajo);
        let arrayFiltro = [];
        if (tipoTrabajoStorage) {
            if (tipoTrabajoStorage !== "Todos") {
                arrayFiltro = await this.state.ListaProgramacion.filter(X => X.Area === tipoTrabajoStorage);
                // console.log(arrayFiltro);
                this.setState({ ListaProgramacion: arrayFiltro, cantidadElementos: arrayFiltro.length });
            }
        }
    }

    filtrarEstado = async () => {
        //filtrar tipos de Trabajo
        let nuevoArray = [];
        // console.log(this.state.valorEstadoBusqueda);   
        // console.log(this.state.ListaProgramacion); 
        switch (this.state.valorEstadoBusqueda) {
            case 'TODOS':
                nuevoArray = await this.state.ListaProgramacion;
                break;
            case 'PROGRAMADO':
                nuevoArray = await this.state.ListaProgramacion.filter(X => X.avance === 0);
                break;
            case 'EJECUTADO':
                nuevoArray = await this.state.ListaProgramacion.filter(X => X.avance > 0);
                break;
        }
        await this.setState({ ListaProgramacion: nuevoArray });
    }

    elegirTipoTrabajoProgramado = async () => {
        //Almacenar Ajustes en LocalStorage
        await localStorage.setItem(config.storageFilterTrabajo, this.selectTipoTrabajo.current.value);
        this.setState({ TrabajoProgramadoSeleccionado: `(${this.selectTipoTrabajo.current.value})`, LoadData: false });
        //Buscar Lista Trabajos
        this.BuscarProgramacionfecha();
    }

    buscarTiposTrabajosFechaProgramada = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/listarProgramadp/${this.state.idproyecto}/${this.state.fechaprogramacion}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TiposTrabajoFechaProgramada: data.content });
                }
                else {
                    this.setState({ TiposTrabajoFechaProgramada: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ********************************
    // Listar suministros programados por trabajador
    // ********************************
    filtrarEstadoTrabajo = async (valor) => {
        await this.setState({ valorEstadoBusqueda: valor, LoadData: false });
        this.BuscarProgramacionfecha();
    }

    ListarsuministrosProgramados = (idtrabajadorasignado) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/listarSuministrosProgramados/trabajador/${this.state.idproyecto}/${this.state.fechaprogramacion}/${idtrabajadorasignado}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    // console.log(data.content);
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listar/${this.state.idproyecto}/ACTIVO`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    //Descargar Fotos 

    // BuscarListaTipoTrabajo = () => {
    //     if (ValidarUsuario()) {
    //         let url = `${config.endPoint}api/tipotrabajo/listar`;

    //         let UsuarioStorage = ObtenerUsuario();
    //         UsuarioStorage = JSON.parse(UsuarioStorage);
    //         let headers = {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': 'Bearer ' + UsuarioStorage.token,
    //             }
    //         };
    //         fetch(url, headers).then((respuesta) => {
    //             return respuesta.json();
    //         }).then((data) => {
    //             if (data.content) {
    //                 this.setState({ TipoTrabajos: data.content });
    //             }
    //             else {
    //                 this.setState({ TipoTrabajos: [] });
    //                 this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
    //             }
    //         }).catch(() => {
    //             this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
    //         });
    //     }
    //     else {
    //         this.props.history.push('/login');
    //     }
    // }

    DescargarFotosProgramacion = async () => {
        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')   
            if (this.selectTipoTrabajoDescargar.current.value.trim() !== "") {
                this.selectTipoTrabajoDescargar.current.classList.remove('is-invalid');
                let url = ";"
                if (this.selectTipoTrabajoDescargar.current.value === "TODOS") {
                    url = `${config.endPoint}api/movil/listafotosProyecto/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
                }
                else {
                    url = `${config.endPoint}api/movil/listafotosTipoTrabajoProyecto/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.selectTipoTrabajoDescargar.current.value}`;
                }
                //Ejecutar buscqueda de Fotos                
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                let cantidad = 0;

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        // console.log(data.content);
                        if (data.content.length > 0) {
                            this.CerrarModalDescargarFotos();

                            this.DecargarPorPartes(data.content);

                            // await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });
                            // for (let x = 0; x < data.content.length; x++) {
                            //     await fetch(`${config.endPoint}api/foto/DownloadFileServer/${data.content[x].foto_url}`).then(async (respuesta) => {
                            //         cantidad++;
                            //         await this.setState({ mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });
                            //         const arraybuffer = await respuesta.arrayBuffer();
                            //         zip.file(`${data.content[x].fechaProgramacion.trim()}/${data.content[x].tipotrabajo.trim()}/${data.content[x].trabajadorejecuta !== null ? data.content[x].trabajadorejecuta.trim() : data.content[x].trabajadorprogramado.trim()}/${data.content[x].suministro.trim()}/${data.content[x].foto_url}`, arraybuffer);
                            //     }
                            //     ).catch((error) => {
                            //         //console.log(error);
                            //     });
                            // }
                            // //Generar el Archivo Zip
                            // await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                            //     saveAs(contenido, `Fotos_${this.state.fechaprogramacion}.zip`);
                            // });
                            // //Reiniciar el zip
                            // zip = require('jszip')();
                            // await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
                        } else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron archivos para este filtro.", tipotoast: 'info', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                    }
                }).catch((error) => {
                    // console.log(error);
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                });

            }
            else {
                this.selectTipoTrabajoDescargar.current.classList.add('is-invalid');
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    DecargarPorPartes = async (listadatos) => {

        // let valorDivision = config.cantidadLimiteArchivoDescargaFotos;
        // console.log(listadatos);
        // let descargarFotoRotulada = this.checkRotularFotoDescarga.current.checked;
        let valorDivision = config.cantidadFotosTamañoMaximo;
        if (listadatos.length > valorDivision) {
            if (window.confirm(`La cantidad de Fotografias ${listadatos.length} que desea descargar supera los ${valorDivision} archivos, esto podria generar un colapso en el sistema.\nLa fotos seran descargadas en partes para mejorar el rendimiendo. \n¿Desea continuar con la descarga de archivos por partes?`)) {
                //Dividir en partes de 1000                
                let listas = Math.ceil(listadatos.length / valorDivision);
                let inicio = 0;
                let fin = 0;
                let cantidad = 0;
                for (let x = 1; x <= listas; x++) {
                    fin = x * valorDivision;
                    for (let y = inicio; y < fin; y++) {
                        if (y < listadatos.length) {
                            //Descargar Archivos
                            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                            
                            
                            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[y].foto_url}`).then(async (respuesta) => {
                                cantidad++;
                                await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                                const arraybuffer = await respuesta.arrayBuffer();
                                
                                // let nombreFoto = `FI_${listadatos[y].suministro.toString().padStart(11, '0')}_${moment(listadatos[y].fecha).format('YYYYMMDD')}_H${listadatos[y].hora.toString().replaceAll(':', '')}_00`;
                                zip.file(`${listadatos[y].fechaProgramacion.trim()}/${listadatos[y].tipotrabajo.trim()}/${listadatos[y].trabajadorejecuta !== null ? listadatos[y].trabajadorejecuta.trim() : listadatos[y].trabajadorprogramado.trim()}/${listadatos[y].suministro.trim()}/${listadatos[y].foto_url}`, arraybuffer);
                                // zip.file(`${listadatos[y].fechaProgramacion.trim()}/${listadatos[y].tipotrabajo.trim()}/${listadatos[y].trabajadorejecuta !== null ? listadatos[y].trabajadorejecuta.trim() : listadatos[y].trabajadorprogramado.trim()}/${listadatos[y].suministro.trim()}/${nombreFoto}.jpg`, arraybuffer);
                                // zip.file(`${listadatos[y].fechaProgramacion.trim()}/${nombreFoto}.jpg`, arraybuffer);
                            }

                            ).catch((error) => {
                                console.log(error);
                            });
                        }
                    }
                    //Generar el Archivo Zip
                    await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                        saveAs(contenido, `Fotos_${this.state.fechaprogramacion}_Part${x}.zip`);
                    });
                    //Reiniciar el zip
                    zip = require('jszip')();

                    inicio = fin;
                }
                await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }

        } else {
            let cantidad = 0;
            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });

            for (let x = 0; x < listadatos.length; x++) {
                
                await fetch(`${config.endPoint}api/foto/DownloadFileServer/${listadatos[x].foto_url}`).then(async (respuesta) => {
                    cantidad++;
                    await this.setState({ mensajeDescargaFotos: `${cantidad}/${listadatos.length} Fotos Descargadas` });
                    const arraybuffer = await respuesta.arrayBuffer();

                    // let nombreFoto = `FI_${listadatos[x].suministro.toString().padStart(11, '0')}_${moment(listadatos[x].fecha).format('YYYYMMDD')}_H${listadatos[x].hora.toString().replaceAll(':', '')}_00`;
                    zip.file(`${listadatos[x].fechaProgramacion.trim()}/${listadatos[x].tipotrabajo.trim()}/${listadatos[x].trabajadorejecuta !== null ? listadatos[x].trabajadorejecuta.trim() : listadatos[x].trabajadorprogramado.trim()}/${listadatos[x].suministro.trim()}/${listadatos[x].foto_url}`, arraybuffer);
                    // zip.file(`${listadatos[x].fechaProgramacion.trim()}/${listadatos[x].tipotrabajo.trim()}/${listadatos[x].trabajadorejecuta !== null ? listadatos[x].trabajadorejecuta.trim() : listadatos[x].trabajadorprogramado.trim()}/${listadatos[x].suministro.trim()}/${nombreFoto}.jpg`, arraybuffer);
                    // zip.file(`${listadatos[x].fechaProgramacion.trim()}/${nombreFoto}.jpg`, arraybuffer);

                }
                ).catch((error) => {
                    console.log(error);
                });
            }
            //Generar el Archivo Zip
            await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                saveAs(contenido, `Fotos_${this.state.fechaprogramacion}.zip`);
            });
            //Reiniciar el zip
            zip = require('jszip')();
            await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
        }
    }
    //****************************************** */
    //MODAL DESCARGAR FOTOS
    //****************************************** */
    AbrirModalDescargarFotos = () => {
        this.buscarTiposTrabajosFechaProgramada();
        this.setState({ MostrarModalDescargar: true });
    }
    CerrarModalDescargarFotos = () => {
        this.setState({ MostrarModalDescargar: false });
    }
    DescargarReporteProgramacion = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteReporte/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }


    // **********************************
    // Modal Reporte Rango Fechas
    // **********************************
    AbrirModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: true });
    }
    CerrarModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: false });
    }

    ValidarFormularioReporteRangoFechas = () => {
        let errores = 0;
        if (this.inputFechaInicioReporteFecha.current.value !== null && this.inputFechaInicioReporteFecha.current.value !== '') {
            this.inputFechaInicioReporteFecha.current.classList.remove('is-invalid');
        } else {
            this.inputFechaInicioReporteFecha.current.classList.add('is-invalid');
            errores++;
        }

        if (this.inputFechaFinReporteFecha.current.value !== null && this.inputFechaFinReporteFecha.current.value !== '') {
            this.inputFechaFinReporteFecha.current.classList.remove('is-invalid');
        }
        else {
            this.inputFechaFinReporteFecha.current.classList.add('is-invalid');
            errores++;
        }
        if (errores > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    DescargarReporteProgramacionFechas = () => {

        if (this.ValidarFormularioReporteRangoFechas()) {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/reporteProyecto/${this.state.idproyecto}/${this.inputFechaInicioReporteFecha.current.value}/${this.inputFechaFinReporteFecha.current.value}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        if (data.content.length > 0) {
                            await CreateJsonToExcel(data.content, `Reporte_${this.inputFechaInicioReporteFecha.current.value}_${this.inputFechaFinReporteFecha.current.value}`);
                            this.CerrarModalReporteRangoFechas();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }
            else {
                this.props.history.push('/login');
            }
        }
    }

    DescargarReporteActualizacionesProgramacionFechas = () => {

        if (this.ValidarFormularioReporteRangoFechas()) {
            if (ValidarUsuario()) {

                let url = `${config.endPoint}api/trabajosuministro/reporteActualizacionesProyecto/${this.state.idproyecto}/${this.inputFechaInicioReporteFecha.current.value}/${this.inputFechaFinReporteFecha.current.value}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        if (data.content.length > 0) {
                            await CreateJsonToExcel(data.content, `Reporte_${this.inputFechaInicioReporteFecha.current.value}_${this.inputFechaFinReporteFecha.current.value}`);
                            this.CerrarModalReporteRangoFechas();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }
            else {
                this.props.history.push('/login');
            }
        }
    }


    DescargarReporteActividades = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            if (this.selectTipoTrabajoDescargar.current.value != '') {
                this.selectTipoTrabajoDescargar.current.classList.remove('is-invalid');

                let url = `${config.endPoint}api/trabajosuministro/reporteActividades/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.selectTipoTrabajoDescargar.current.value}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                console.log(url);
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        if (data.content.length > 0) {
                            await CreateJsonToExcel(data.content, `Reporte_A+M_${this.state.fechaprogramacion}`);
                            this.CerrarModalReporteTipoTrabajo();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            } else {
                this.selectTipoTrabajoDescargar.current.classList.add('is-invalid');
            }
        }
        else {
            this.props.history.push('/login');
        }
    }


    DescargarReporteMateriales = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteMateriales/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    // ********************************
    // Modal CambiarTrabajador
    // ********************************
    AbrirModalCambiarTrabajador = async (trabajador) => {
        //console.log(trabajador);
        // BuscarLista de Trabajos
        if (ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_MODIFICAR_TRABAJO, this.state.permisos)) {
            this.setState({ ListaSuministrosSeleccionados: [], cantidadElementosSeleccionados: 0 });
            await this.setState({ OcultarLoading: false });
            await this.ListarsuministrosProgramados(trabajador.idpersonalasignado);
            await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
            await this.setState({ MostrarModalCambiarTrabajador: true, OcultarLoading: true })
            this.inputNombreTrabajador.current.value = trabajador.nombretrabajador;
        } else {
            this.setState({ mensajeToast: "No tiene permisos para acceder a este recurso", abrirToast: true, tipotoast: 'warning' });
        }
    }
    CerrarModalCambiarTrabajador = () => {
        this.setState({ MostrarModalCambiarTrabajador: false })
    }
    SeleccionarTodosSuministros = async (event) => {
        // console.log(this.tableBodySuministros.current.children.length);
        let cant = this.tableBodySuministros.current.children.length;
        for (let x = 0; x < cant; x++) {
            this.tableBodySuministros.current.children[x].children[0].children[0].checked = event.target.checked
        }
        if (event.target.checked) {
            // Agregar Todos a Array de Seleccionados
            await this.setState({ ListaSuministrosSeleccionados: [] });
            await this.setState({ ListaSuministrosSeleccionados: this.state.ListaSuministrosProgramadoTecnico, cantidadElementosSeleccionados: this.state.ListaSuministrosProgramadoTecnico.length });
        }
        else {
            await this.setState({ ListaSuministrosSeleccionados: [], cantidadElementosSeleccionados: 0 });
        }
    }
    SeleccionarElemento = async (event, suministro) => {
        // console.log(suministro);
        let listaSuministros = [];
        if (event.target.checked) {
            // Agregar Elemento
            listaSuministros = this.state.ListaSuministrosSeleccionados;
            listaSuministros.push(suministro);
            await this.setState({ ListaSuministrosSeleccionados: listaSuministros, cantidadElementosSeleccionados: listaSuministros.length });
        }
        else {
            if (this.inputChecktodosLosElementos.current.checked) {
                this.inputChecktodosLosElementos.current.checked = false;
            }
            // Eliminar elemento(Si existiera)
            listaSuministros = this.state.ListaSuministrosSeleccionados.filter(x => x !== suministro);
            await this.setState({ ListaSuministrosSeleccionados: listaSuministros, cantidadElementosSeleccionados: listaSuministros.length });
        }
    }
    ValidarModalReasignarTrabajador = () => {
        if (this.inputElegirTrabajador.current.value.trim() === "") {
            this.setState({ mensajeToast: "Debe elegir un Trabajador de la lista.", abrirToast: true, tipotoast: 'warning' });
            this.inputElegirTrabajador.current.classList.add('is-invalid');
            return false;
        }
        else {
            this.inputElegirTrabajador.current.classList.remove('is-invalid');
        }

        if (this.state.ListaSuministrosSeleccionados.length === 0) {
            this.setState({ mensajeToast: "No se ha seleccionado ningun Suministro de  la Lista", abrirToast: true, tipotoast: 'warning' });
            return false;
        }

        return true;
    }
    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador.idtrabajador;
            }
        }
        return null;
    }
    ReasignarSuministroATrabajador = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarModalReasignarTrabajador()) {
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputElegirTrabajador.current.value);
                if (IdTrabajadorReasignado) {

                    await this.setState({ OcultarLoading: false });

                    this.inputElegirTrabajador.current.classList.remove('is-invalid');
                    // Reasignar a los suministros Seleccionados
                    let url = `${config.endPoint}api/trabajosuministro/programacionreasignar/idTrabajador`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let nTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        ListaSuministros: this.state.ListaSuministrosSeleccionados
                    }
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(nTrabajoSuministro)
                    };
                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'info' });

                        this.BuscarProgramacionfecha();

                        this.CerrarModalCambiarTrabajador();
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                    await this.setState({ OcultarLoading: true });

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputElegirTrabajador.current.value = '';
                    this.inputElegirTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CambiarFecha = async () => {
        this.selectTipoFiltro.current.value = "Todos";
        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value, LoadData: false, tipofiltro: this.selectTipoFiltro.current.value, TrabajoProgramadoSeleccionado: '' });
        await localStorage.setItem(config.storageDateName, this.state.fechaprogramacion);
        this.selectTipoTrabajo.current.value = 'Todos';
        await localStorage.removeItem(config.storageFilterTrabajo);
        await this.BuscarProgramacionfecha();
    }
    CambioSelectTipoFiltro = async () => {

        await this.setState({ LoadData: false, tipofiltro: this.selectTipoFiltro.current.value, valorBuscar: '' });
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };

        switch (this.state.tipofiltro) {
            case 'Trabajador':
                // Buscar Lista de Trabajadores
                let urlTrabajadores = `${config.endPoint}api/trabajosuministro/listarNombres/trabajador/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
                fetch(urlTrabajadores, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaBusqueda: data.content });
                    }
                });
                this.inputBuscarFiltro.current.value = "";
                break;
            case 'Supervisor':
                // Buscar Lista de Supervisores
                let urlSupervisores = `${config.endPoint}api/trabajosuministro/listarNombres/supervisor/${this.state.idproyecto}/${this.state.fechaprogramacion}`;
                fetch(urlSupervisores, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaBusqueda: data.content });
                    }
                });
                this.inputBuscarFiltro.current.value = "";
                break;
            case 'Estado':
                let nuevoArray = [];
                for (let x = 0; x < this.state.ListaProgramacion.length; x++) {
                    let existe = false;
                    for (let y = 0; y < nuevoArray.length; y++) {
                        if (this.state.ListaProgramacion[x].estado === nuevoArray[y].nombretrabajador) {
                            existe = true;
                            break;
                        }
                    }
                    if (!existe) {
                        nuevoArray.push({ nombretrabajador: this.state.ListaProgramacion[x].estado });
                    }
                }
                this.setState({ ListaBusqueda: nuevoArray });
                this.inputBuscarFiltro.current.value = "";
                break;
            default:
                this.setState({ ListaBusqueda: [] });
        }
        await this.BuscarProgramacionfecha();
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarProgramacionfecha();
        }
    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarProgramacionfecha();
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.ListaProgramacion.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.ListaProgramacion.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.ListaProgramacion[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });
    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarProgramacionfecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarProgramacionfecha();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    ActualizarSocket = () => {

        this.socket.emit("TrabajoAgregado");
        console.log("Emit Socket");
        // this.BuscarProgramacionfecha();
    }
    //*************************************************
    // Modal Reprogramar
    //*************************************************
    AbrirModalReprogramarPendientes = async (trabajador) => {
        if (ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_MODIFICAR_TRABAJO, this.state.permisos)) {
            await this.setState({ OcultarLoading: false, trabajadorSeleccionado: null });
            await this.ListarsuministrosProgramados(trabajador.idpersonalasignado);
            await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
            await this.setState({ mostrarModalReprogramar: true, OcultarLoading: true, trabajadorSeleccionado: trabajador });
            this.inputNombreTrabajador.current.value = trabajador.nombretrabajador;
        } else {
            this.setState({ mensajeToast: "No tiene permisos para acceder a este recurso", abrirToast: true, tipotoast: 'warning' });
        }

    }
    CerrarModalReprogramarPendientes = () => {
        this.setState({ mostrarModalReprogramar: false });
    }
    ValidarModalReasignarPendientes = () => {
        let error = 0;
        if (this.inputElegirTrabajador.current.value.trim() == '') {
            this.inputElegirTrabajador.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputElegirTrabajador.current.classList.remove('is-invalid');
        }
        if (this.inputFechaReprogramar.current.value.trim() == '') {
            this.inputFechaReprogramar.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputFechaReprogramar.current.classList.remove('is-invalid');
        }

        if (error < 0) {
            return false;
        }
        return true;
    }
    ReprogramarTrabajosPendientes = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarModalReasignarPendientes()) {
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputElegirTrabajador.current.value);
                if (IdTrabajadorReasignado) {

                    await this.setState({ OcultarLoading: false });

                    this.inputElegirTrabajador.current.classList.remove('is-invalid');
                    // Reasignar a los suministros Seleccionados
                    let url = `${config.endPoint}api/trabajosuministro/programacionreasignarpendientesv2/idTrabajador`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let nTrabajoSuministro = {
                        idtrabajadorreasignado: IdTrabajadorReasignado,
                        idpersonalasignado: this.state.trabajadorSeleccionado.idpersonalasignado,
                        fechaProgramacion: this.inputFechaReprogramar.current.value,
                        fechaActual: this.state.fechaprogramacion,
                        idproyecto: this.state.idproyecto
                    }

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(nTrabajoSuministro)
                    };
                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {

                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'info' });
                        this.BuscarProgramacionfecha();
                        this.CerrarModalReprogramarPendientes();
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                    await this.setState({ OcultarLoading: true });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputElegirTrabajador.current.value = '';
                    this.inputElegirTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //************************ */
    //MODAL REPORTE ACT+MAT POR TIPO DE TRABAJO
    //************************ */
    AbrirModalReporteTipoTrabajo = () => {
        this.buscarTiposTrabajosFechaProgramada();
        this.setState({ MostrarModalReporteTipoTrabajo: true });
    }
    CerrarModalReporteTipoTrabajo = () => {
        this.setState({ MostrarModalReporteTipoTrabajo: false });
    }

    //************************ */
    //GENERAR LISTA DE PDF'S 
    //************************ */

    DescargarReportePDFLista = async () => {
        let usuarioLocalstorage = localStorage.getItem(config.storageName)
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);

        if (ValidarUsuario()) {

            try {
                await this.setState({ OcultarLoading: false });

                //Buscar Lista de Suministros Ejecutados

                let urlProgramacion = `${config.endPoint}api/trabajosuministro/listarTrabajosEjecutadosV2/${this.state.fechaprogramacion}/${this.state.idproyecto}`;
                console.log(urlProgramacion);

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                let url = "";

                fetch(urlProgramacion, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    console.log(data);

                    if (data.content.length > 0) {
                        await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${0}/${data.content.length} Archivos Descargados` });


                        for (let r = 0; r < data.content.length; r++) {
                            const doc = new jsPDF();
                            url = `${config.endPoint}api/trabajosuministro/buscarid/${data.content[r].idTrabajosuministro}`;

                            await fetch(url, headers).then((respuesta) => {
                                return respuesta.json();
                            }).then(async (data) => {
                                if (data.content) {
                                    // console.log(data.content);
                                    //Llenar Data
                                    // // Titulo                                    
                                    doc.setFontSize(14);
                                    doc.setFont("verdana", "bold");
                                    doc.setTextColor(8, 98, 167)
                                    doc.text(`REPORTE ${usuarioLocalstorage.proyecto} - SUMINISTRO Nº ${data.content.suministro}`, 30, 10, { maxWidth: 180 });
                                    //Linea
                                    doc.setLineWidth(0.5);
                                    doc.setDrawColor(8, 98, 167);
                                    doc.line(15, 12, 200, 12)

                                    //Data
                                    doc.setTextColor(0, 0, 0)
                                    doc.setFontSize(10);
                                    doc.setFont("verdana", "normal");

                                    doc.text("DNI/RUC:", 20, 18);
                                    doc.text(data.content.dniIngresado, 70, 18, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 22, 190, 22)

                                    doc.text("NOMBRES:", 20, 26);
                                    // doc.text(data.content.nombresIngresado, 70, 26, { maxWidth: 120 });
                                    doc.text(data.content.nombreCliente, 70, 26, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 30, 190, 30)

                                    doc.text("DIRECCIÓN:", 20, 34);
                                    doc.text(data.content.direccionCliente, 70, 34, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 38, 190, 38)

                                    doc.text("NUM.CELULAR:", 20, 42);
                                    doc.text(data.content.nroCelularIngresado, 70, 42, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 46, 190, 46)

                                    doc.text("NUM. FIJO:", 20, 50);
                                    doc.text(data.content.telefonoFijoIngresado, 70, 50, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 54, 190, 54)

                                    doc.text("CORREO ELECTRÓNICO:", 20, 58);
                                    doc.text(data.content.correoIngresado, 70, 58, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 62, 190, 62)

                                    doc.text("DISTRITO:", 20, 66);
                                    // doc.text(data.content.distritoIgresado, 70, 66, { maxWidth: 120 });
                                    doc.text(data.content.distrito, 70, 66, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 70, 190, 70)

                                    doc.text("PROVINCIA:", 20, 74);
                                    doc.text(data.content.ProvinciaIngresado, 70, 74, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 78, 190, 78)

                                    doc.text("ESTADO.:", 20, 82);
                                    doc.text(data.content.estado, 70, 82, { maxWidth: 120 });

                                    doc.text("SUB ESTADO.:", 110, 82);
                                    doc.text(data.content.EstadoEncuestaIngresado, 140, 82, { maxWidth: 120 });

                                    doc.setLineWidth(0.2);
                                    doc.setDrawColor(169, 169, 169);
                                    doc.line(18, 86, 190, 86)

                                    doc.text("OBS.:", 20, 90);
                                    doc.text(data.content.observacion_ejecucion, 70, 90, { maxWidth: 120 });

                                    doc.setLineWidth(0.5);
                                    doc.setDrawColor(8, 98, 167);
                                    doc.line(15, 105, 200, 105)

                                    doc.setFontSize(14);
                                    doc.setFont("verdana", "bold");
                                    doc.setTextColor(8, 98, 167)
                                    doc.text("Fotos", 20, 110, { maxWidth: 180 });
                                    // console.log("Iniciar Agregar Imagenes");  

                                    //Agregando Nro Pagina     
                                    doc.setTextColor(0, 0, 0)
                                    doc.setFontSize(10);
                                    doc.setFont("verdana", "normal");
                                    doc.text("Pág. 1", 190, 293, { maxWidth: 180 });

                                    // console.log("Iniciar Agregar Imagenes");
                                    //Agregando Fotografias
                                    for (let x = 0; x < data.content.t_fotos.length; x++) {

                                        await fetch(`${config.endPoint}api/foto/DownloadFileServer/${data.content.t_fotos[x].foto_url}`).then(async (respuesta) => {

                                            const arraybuffer = await respuesta.arrayBuffer();
                                            const base64 = await btoa(
                                                new Uint8Array(arraybuffer)
                                                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
                                            );
                                            // Obteniendo Informacion de imagen
                                            const blob = new Blob([arraybuffer], { type: 'image/jpeg' })
                                            const img = new Image()
                                            img.src = await URL.createObjectURL(blob)

                                            let AnchoImagenNuevo;
                                            await img.decode().then(() => {
                                                let width = img.width;
                                                let height = img.height;
                                                // let girar=width>height?true:false;

                                                if (width > height) {
                                                    AnchoImagenNuevo = (80 / width) * height;
                                                } else {
                                                    AnchoImagenNuevo = (80 / height) * width;
                                                }
                                                //Verificar Si alto es mayor que Ancho
                                                switch (x) {
                                                    case 0:

                                                        doc.addImage(base64, 'JPG', 20, 120, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 115);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 118);
                                                        break;
                                                    case 1:
                                                        doc.addImage(base64, 'JPG', 110, 120, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 115);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 118);
                                                        break;
                                                    case 2:
                                                        doc.addImage(base64, 'JPG', 20, 210, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 205);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 208);
                                                        break;
                                                    case 3:
                                                        doc.addImage(base64, 'JPG', 110, 210, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 205);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 208);
                                                        break;

                                                    case 4:
                                                        doc.addPage();
                                                        //Agregando Nro Pagina     
                                                        doc.setTextColor(0, 0, 0)
                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.text("Pág. 2", 190, 293, { maxWidth: 180 });

                                                        doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                                        break;
                                                    case 5:
                                                        doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                                        break;
                                                    case 6:
                                                        doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                                        break;
                                                    case 7:
                                                        doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                                        break;
                                                    case 8:
                                                        doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                                        break;
                                                    case 9:
                                                        doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                                        break;
                                                    case 10:
                                                        doc.addPage();
                                                        //Agregando Nro Pagina     
                                                        doc.setTextColor(0, 0, 0)
                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.text("Pág. 3", 190, 293, { maxWidth: 180 });

                                                        doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                                        break;
                                                    case 11:
                                                        doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                                        break;
                                                    case 12:
                                                        doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                                        break;
                                                    case 13:
                                                        doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                                        break;
                                                    case 14:
                                                        doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                                        break;
                                                    case 15:
                                                        doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                                        break;
                                                    case 16:
                                                        doc.addPage();
                                                        //Agregando Nro Pagina     
                                                        doc.setTextColor(0, 0, 0)
                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.text("Pág. 4", 190, 293, { maxWidth: 180 });

                                                        doc.addImage(base64, 'JPG', 20, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 18);
                                                        break;
                                                    case 17:
                                                        doc.addImage(base64, 'JPG', 110, 20, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 15);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 18);
                                                        break;
                                                    case 18:
                                                        doc.addImage(base64, 'JPG', 20, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 108);
                                                        break;
                                                    case 19:
                                                        doc.addImage(base64, 'JPG', 110, 110, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 105);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 108);
                                                        break;
                                                    case 20:
                                                        doc.addImage(base64, 'JPG', 20, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 21, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 21, 198);
                                                        break;
                                                    case 21:
                                                        doc.addImage(base64, 'JPG', 110, 200, AnchoImagenNuevo, 80);

                                                        doc.setFontSize(10);
                                                        doc.setFont("verdana", "normal");
                                                        doc.setTextColor(0, 0, 0);
                                                        doc.text(`${data.content.t_fotos[x].fecha} ${data.content.t_fotos[x].hora}`, 111, 195);
                                                        doc.text(`Lat:${data.content.t_fotos[x].lat} Lng:${data.content.t_fotos[x].lng}`, 111, 198);
                                                        break;
                                                }
                                            });
                                        }
                                        ).catch((error) => {
                                            // console.log(error);
                                        });
                                    }

                                    const bufferPdf = doc.output("blob");
                                    zip.file(`${this.state.fechaprogramacion}/${data.content.suministro}.pdf`, bufferPdf);

                                    // saveAs(bufferPdf, `${data.content.suministro}.pdf`);

                                } else {
                                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                                }

                            }).catch(() => {
                                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                            });

                            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${r + 1}/${data.content.length} Archivos Descargados` });
                        }

                        await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false, mensajeDescargaFotos: `Archivos Descargados` });
                        //Generar el Archivo Zip
                        await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                            saveAs(contenido, `Lista_PDF_${this.state.fechaprogramacion}.zip`);
                        });
                        //Reiniciar el zip
                        zip = require('jszip')();

                    } else {
                        this.setState({ mensajeToast: "No se encontraron datos para realizar esta acción.", abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                    }
                    //Recorrer Data                   
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

                // let url = `${config.endPoint}api/trabajosuministro/buscarid/${this.state.suministroActual.idTrabajosuministro}`;               

                this.setState({ OcultarLoading: true });

            } catch (error) {
                this.setState({ mensajeToast: error.toString(), abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            }
        } else {
            this.props.history.push('/login');
        }

    }

    render() {

        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                {/* <TituloPagina titulo={`MONITOREO ${this.state.TrabajoProgramadoSeleccionado}`} regresar={() => { this.props.history.push('/operacionesComerciales') }}></TituloPagina> */}
                <TituloPagina titulo={`MONITOREO ${this.state.TrabajoProgramadoSeleccionado}`} regresar={() => { this.props.history.push('/') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className="col-md-6 col-lg-3">
                            <input onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input>
                        </div>
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">
                            {/* <input onKeyPress={(ev) => { this.elegirTipoTrabajoProgramado() }} ref={this.selectTipoTrabajo} className="form-control form-control-sm" list="listaTiposTrabajoProgramado" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                            <datalist id="listaTiposTrabajoProgramado">
                                {
                                    this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                        return (<option key={index} value={row.tipotrabajo} />)
                                    })
                                }
                            </datalist> */}

                            <select onChange={() => { this.elegirTipoTrabajoProgramado() }} ref={this.selectTipoTrabajo} className="form-select form-select-sm" >
                                <option value="Todos">Todas las Areas</option>
                                {
                                    this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                        return (<option key={index} value={row.tipotrabajo} >{row.tipotrabajo}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">

                            <select onChange={() => { this.CambioSelectTipoFiltro() }} ref={this.selectTipoFiltro} className="form-select form-select-sm" >
                                <option value="Todos">Todos los Trabajadores</option>
                                <option value="Trabajador">Nombre Trabajador</option>
                                <option value="Supervisor">Supervisor</option>
                                {/* <option value="Estado">Estado</option> */}
                            </select>
                        </div>

                        {
                            this.state.tipofiltro !== "Todos" ?
                                <div className="col-md-6 col-lg-3 mt-1 mt-md-0">
                                    <input onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                                    <datalist id="listaTrabajadoresProgramados">
                                        {
                                            this.state.ListaBusqueda.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                                : <>
                                </>
                        }

                        <div className={this.state.tipofiltro !== "Todos" ? "col-md-12 text-end mt-1 mt-md-0" : "col-md-6 col-lg-3 text-end mt-1 mt-md-0"} >
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.filtrarEstadoTrabajo('TODOS') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver todos</small></li>
                                    <li onClick={() => { this.filtrarEstadoTrabajo('EJECUTADO') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Ejecutados</small></li>
                                    <li onClick={() => { this.filtrarEstadoTrabajo('PROGRAMADO') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Faltantes</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_FOTO, this.state.permisos) ?
                                            <li onClick={() => { this.AbrirModalDescargarFotos() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Fotos</small></li>
                                            : <></>
                                    }
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                            <></> :
                                            ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_REPORTE, this.state.permisos) ?
                                                <li onClick={() => { this.DescargarReporteProgramacion() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data</small></li>
                                                : <></>
                                    }
                                    {/* {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                            <></> :
                                            ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_REPORTE, this.state.permisos) ?
                                                <li onClick={() => { this.AbrirModalReporteTipoTrabajo() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data(Act+Mat)</small></li>
                                                : <></>
                                    } */}
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_MENU_DESCARGAR_REPORTE, this.state.permisos) ?
                                            <li onClick={() => { this.AbrirModalReporteRangoFechas() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data (Rango Fecha)</small></li>
                                            : <></>
                                    }
                                    {/* <li onClick={() => { this.DescargarReporteMateriales() }}><small className="dropdown-item" ><i className="fas fa-file-excel mx-2"></i>Descargar Data Materiales</small></li>                                     */}
                                    <li><hr className="dropdown-divider" /></li>
                                    {/* <li>
                                        <Link to={{ pathname: `/monitoreo/mapa/${this.state.fechaprogramacion}` }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                    </li> */}
                                    {/* <li onClick={() => { this.ActualizarSocket() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li> */}
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                            <li onClick={() => { this.DescargarReportePDFLista() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Reportes PDF </small></li>
                                            : <></>
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row p-1">
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell
                                                                    // style={{ color: 'aliceblue' }} 
                                                                    key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {
                                                    // Buscar Cantidad Programada Por trabajador
                                                    let TrabajosProgramados = this.state.ProgramacionDetalleDia.filter(x => x.idpersonalasignado === row.idpersonalasignado);
                                                    // Buscar Cantidad ejecutada por Trabajador
                                                    let TrabajosEjecutados = TrabajosProgramados.filter(x => x.estado !== 'PROGRAMADO' && x.estado !== 'NO EJECUTADO');
                                                    // Buscar Cantidad no ejecutada por Trabajador
                                                    let TrabajosNoEjecutados = TrabajosProgramados.filter(x => x.estado === 'NO EJECUTADO');

                                                    //Buscar Fecha Inicio
                                                    let MayorFecha = {};
                                                    if (TrabajosEjecutados.length > 0) {
                                                        MayorFecha = TrabajosEjecutados.reduce((prev, current) => {
                                                            return (prev.hora_ejecucion > current.hora_ejecucion) ? prev : current
                                                        });
                                                    }

                                                    // Buscar Fecha Fin     
                                                    let MenorFecha = {};
                                                    if (TrabajosEjecutados.length > 0) {
                                                        MenorFecha = TrabajosEjecutados.reduce((prev, current) => {
                                                            return (current.hora_ejecucion < prev.hora_ejecucion) ? current : prev
                                                        });
                                                    }

                                                    let FechaDescargaProgramacion = '';
                                                    if (TrabajosProgramados.length > 0) {
                                                        FechaDescargaProgramacion = TrabajosProgramados[0].fechaDescarga
                                                    }


                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                {/* Enviar el ID */}
                                                                <Link to={{ pathname: `/monitoreo/trabajador/${this.state.fechaprogramacion}/${row.idpersonalasignado}` }}>
                                                                    {/* <Link to={{pathname:`/monitoreo/trabajador/${this.fechaProgramacion}/${index}`,state:{nombreTrabajador:row.JUO,tipotrabajo:row.Tipo,fechaprogramacion:this.state.fechaprogramacion}}}> */}
                                                                    <i title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalCambiarTrabajador(row) }} title="Modificar" className="fas fa-edit  iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalReprogramarPendientes(row) }} title='Reprogramar' className="fas fa-retweet iconoAcciones"></i>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.nombretrabajador}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.supervisor}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.Area}</TableCell>

                                                            {/* <TableCell style={{ fontSize: '0.75em' }}>
                                                                {
                                                                    row.tipoProgramacion === 'VERIFICACION' ? <i title="Revisión de suministro" class="fas fa-glasses fa-2x" style={{ color: 'rgb(2017,18,116)' }}></i> :
                                                                        <i title="Ejecución de Trabajo" class="fas fa-hammer fa-2x" style={{ color: 'rgb(0,75,160)' }}></i>
                                                                }
                                                            </TableCell> */}
                                                            {/* {row.tipoProgramacion} */}

                                                            <TableCell style={{ fontSize: '0.75em' }}>{
                                                                TrabajosEjecutados.length + TrabajosNoEjecutados.length === TrabajosProgramados.length ?
                                                                    <label className="fw-bold ">FINALIZADO</label>
                                                                    : (TrabajosEjecutados.length > 0 ? (TrabajosNoEjecutados.length === TrabajosProgramados.length ?
                                                                        <label className='fw-bold text-success'>COMPLETO</label> :
                                                                        <label className='text-primary'>EN EJECUCION</label>) :
                                                                        <label >PROGRAMADO</label>)
                                                            }</TableCell>

                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${TrabajosEjecutados.length}/${TrabajosProgramados.length} (${Math.round((TrabajosEjecutados.length / TrabajosProgramados.length) * 100)}%)`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{MenorFecha.hora_ejecucion}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{MayorFecha.hora_ejecucion}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{FechaDescargaProgramacion}</TableCell>
                                                            {/* <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { window.confirm(`¿Estas seguro de finalizar los trabajos del Trabajador ${row.nombretrabajador}?`) }} title="Cerrar Tabajo" className="fas fa-check-square  iconoAcciones" ></i>
                                                            </TableCell> */}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </div>

                </div>

                {/* Modales */}

                <Modal
                    show={this.state.MostrarModalCambiarTrabajador}
                    onHide={() => { this.CerrarModalCambiarTrabajador() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Cambiar Trabajador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Trabajador</small>
                                    <input ref={this.inputNombreTrabajador} className="form-control" placeholder="Nombre Trabajador" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col">
                                    <small className="text-primary">Asignar a trabajador ...</small>
                                    <input ref={this.inputElegirTrabajador} required type="" className="form-control " list="listaTrabajadores" placeholder="Elija el Trabajador"></input>
                                    <datalist id="listaTrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                            </div>
                            {/* <hr/> */}
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Seleccione los suministros a Reasignar el Trabajador</small>
                                </div>
                                {/* Tabla de Datos */}
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader  >
                                        <TableHead >
                                            <TableRow >
                                                <TableCell><input ref={this.inputChecktodosLosElementos} onClick={(ev) => { this.SeleccionarTodosSuministros(ev) }} type="checkbox" className="form-check-input"></input></TableCell>
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Circuito</TableCell>
                                                {/* <TableCell>Distrito</TableCell> */}
                                                <TableCell>Tipo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody ref={this.tableBodySuministros}>
                                            {this.state.ListaSuministrosProgramadoTecnico.map((suministro, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell><input onChange={(ev) => { this.SeleccionarElemento(ev, suministro) }} type="checkbox" className="form-check-input"></input></TableCell>
                                                        <TableCell>{suministro.suministro}</TableCell>
                                                        {/* <TableCell>{suministro.distrito}</TableCell> */}
                                                        <TableCell>{suministro.circuito}</TableCell>
                                                        <TableCell>{suministro.tipo}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <span className="text-primary">{`${this.state.cantidadElementosSeleccionados} seleccionados de ${this.state.ListaSuministrosProgramadoTecnico.length}`}</span>
                                </div>
                                <div className="col mt-1 text-end">
                                    <button onClick={() => { this.ReasignarSuministroATrabajador() }} className=" btn  btn-outline-secondary btn-sm" >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Asignar Trabajador</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalCambiarTrabajador() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Elegir Archivos Descarga */}
                <Modal
                    show={this.state.MostrarModalDescargar}
                    onHide={() => {
                        this.CerrarModalDescargarFotos()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elegir Descarga`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Tipos de Trabajo</small>
                                    <select onChange={() => { }} ref={this.selectTipoTrabajoDescargar} className="form-select" aria-label="Default select example">
                                        <option value="TODOS">Todos</option>
                                        {this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                            return (<option key={index} value={row.tipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                            </div>

                            {/* <div className='mt-3'>
                                <Form.Check
                                    type='checkbox'                                    
                                    label={`Descargar fotos con rotulo.`}
                                    ref={this.checkRotularFotoDescarga}                                    
                                />
                            </div> */}

                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-muted">Elije el tipo de trabajo de las fotos a Descargar. Si elije TODOS se descargarán las fotos de todos los tiempos de trabajo existentes para esta fecha.</small>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarFotosProgramacion() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Reporte Rango Fecha */}
                <Modal
                    show={this.state.MostrarModalReporteFechas}
                    onHide={() => {
                        this.CerrarModalReporteRangoFechas()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Inicio</small>
                                    <input ref={this.inputFechaInicioReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Fin</small>
                                    <input ref={this.inputFechaFinReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            {
                                ValidarPermisos(LIST_SCREENS.ACCERSS_ENCUESTA_MODULO, this.state.permisos) ?
                                    <button onClick={() => { this.DescargarReporteActualizacionesProgramacionFechas() }} className="btn btn-outline-primary mx-1">Descargar Reporte Actualizaciones</button>
                                    : <button onClick={() => { this.DescargarReporteProgramacionFechas() }} className="btn btn-outline-primary mx-1">Descargar</button>

                            }

                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Reprogramar */}
                <Modal
                    show={this.state.mostrarModalReprogramar}
                    onHide={() => {
                        this.CerrarModalReprogramarPendientes()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Reprogramar`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Trabajador Actual</small>
                                    <input ref={this.inputNombreTrabajador} className="form-control" placeholder="Nombre Trabajador" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">

                                <div className="col">
                                    <small className="text-primary">Asignar a trabajador ...</small>
                                    <input ref={this.inputElegirTrabajador} required type="text" className="form-control " list="listaTrabajadores" placeholder="Elija el Trabajador"></input>
                                    <datalist id="listaTrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Reprogramar</small>
                                    <input ref={this.inputFechaReprogramar} type="date" className="form-control" placeholder="Elija la fecha a reprogramar"></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <small className='text-secondary'>{`${this.state.ListaSuministrosProgramadoTecnico.length} Trabajos pendientes de ejecución.`}</small>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className='row'>
                                <div className='col text-end'>
                                    <button onClick={() => { this.ReprogramarTrabajosPendientes() }} className='btn btn-sm btn-primary' disabled={this.state.ListaSuministrosProgramadoTecnico.length > 0 ? false : true}>Reprogramar</button>

                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Reporte act+mat por tipo trabajo */}
                <Modal
                    show={this.state.MostrarModalReporteTipoTrabajo}
                    onHide={() => {
                        this.CerrarModalReporteTipoTrabajo()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elegir Reporte`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Tipos de Trabajo</small>
                                    <select ref={this.selectTipoTrabajoDescargar} className="form-select" aria-label="Default select example" defaultValue="">
                                        <option value="" disabled>Seleccione un elemento</option>
                                        {this.state.TiposTrabajoFechaProgramada.map((row, index) => {

                                            return (<option key={index} value={row.idTipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-muted">Elije el tipo de trabajo para descargar el reporte.</small>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarReporteActividades() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>

                </Modal>

            </Fragment>
        );



    }
}

export default monitoreo;