import React, { Component, Fragment, createRef } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../utils/utils'
import LoadingModal from '../../utils/loadModal';


import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// Material Icons Elements
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

//ReactBootstral
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../../config/config';
import moment from 'moment/moment';

class monitoreoAp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            idtrabajador:null,
            permisos: [],
            objmenu: null,
            mostrarModalReprogramar: false,
            mostrarModalAgregarTrabajador: false,
            mensajeTituloModalAgregar: '',
            fechaprogramacion: null,
            OcultarLoading: true,
            listaInspeccionesFecha: [],
            columns: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Verificar detalle.">
                                    <IconButton onClick={() => {                                        
                                        switch (cellValues.row.modulo) {
                                            case 'INSPECCION_AP':
                                                this.props.history.push(`/alumbradoPublico/monitoreo/inspeccionesap/${cellValues.row.fechaejecucion}/${cellValues.row.id}`);
                                                break;
                                        }
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                {/* <Tooltip title="Reprogramar">
                                    <IconButton onClick={() => {
                                        this.AbrirModalReprogramar();
                                    }}>
                                        <LowPriorityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Agregar Trabajador">
                                    <IconButton onClick={() => {
                                        this.AbrirModalAgregarTrabajador();
                                    }}>
                                        <GroupAddIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip> */}
                            </>
                        );
                    }
                },
                { field: 'nombretrabajador', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'fechaejecucion', headerName: 'Fecha Ejecución', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Cantidad', headerName: 'Cantidad', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'tipo', headerName: 'Tipo Actividad', flex: 1, headerClassName: 'header-colum-table' },


            ],
            rows: [],
            columnsTrabajador: [
                { field: 'colum1', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>
                                <Tooltip title="Verificar detalle.">
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                    />
                                </Tooltip>

                                <Tooltip title="Eliminar Trabajador">
                                    <IconButton onClick={() => {
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
            ],
            rowsTrabajador: [
                { id: 1, colum1: 'Trabajador1' },
                { id: 2, colum1: 'Trabajador2' },
                { id: 3, colum1: 'Trabajador3' },
                { id: 4, colum1: 'Trabajador4' },
                { id: 5, colum1: 'Trabajador5' },
            ]
        };
        //Referencias
        this.inputfechaProgramacion = createRef();
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO_ALUMBRADO_PUBLICO, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);                    
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto,idtrabajador:UsuarioStorage.id });
                        }
                    }                    //Buscar Fecha

                    let fechaStorage = await localStorage.getItem(config.storageDateName);

                    if (fechaStorage) {
                        this.inputfechaProgramacion.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: fechaStorage });
                    } else {
                        this.inputfechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
                    }
                    // Acciones Aqui
                    this.BuscarResumenProgramacion();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // PROCEDIMIENTOS MONITOREO AP 
    //***************************************************** */
    BuscarResumenProgramacion = async () => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/listarResumen/${this.state.idproyecto}/${this.inputfechaProgramacion.current.value}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    this.setState({ rows: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ rows: [], OcultarLoading: true })
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }   

    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalReprogramar = () => {
        this.setState({ mostrarModalReprogramar: true });
    }
    CerrarModalReprogramar = () => {
        this.setState({ mostrarModalReprogramar: false });
    }
    AbrirModalAgregarTrabajador = () => {
        this.setState({ mostrarModalAgregarTrabajador: true });
    }
    CerrarModalAgregarTrabajador = () => {
        this.setState({ mostrarModalAgregarTrabajador: false });
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };

    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };
    //***************************************************** */
    //Acciones Fecha
    //***************************************************** */
    ModificarFechaEjecucion = async () => {
        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
        await localStorage.setItem(config.storageDateName, this.state.fechaprogramacion);
        await this.BuscarResumenProgramacion();
    }

    render() {
        return (
            <Fragment>
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                <NavBar />
                <TituloPagina titulo={`Monitoreo - Actividades AP `} regresar={() => { this.props.history.push('/alumbradoPublico') }} />
                <Container className='my-2  border rounded'>
                    <Row className='mt-1'>
                        <Col md={4}>
                            <InputGroup size='sm'>
                                <InputGroup.Checkbox defaultChecked disabled/>
                                <InputGroup.Text id="basic-addon1">Fecha</InputGroup.Text>
                                <Form.Control
                                    onChange={() => { this.ModificarFechaEjecucion() }}
                                    ref={this.inputfechaProgramacion}
                                    type='date'
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup size='sm'>
                                <InputGroup.Checkbox />
                                <InputGroup.Text id="basic-addon1">Tipo</InputGroup.Text>
                                <Form.Select placeholder='Seleccione un elemento' size='sm'>
                                    <option value="">Elija un elemento</option>
                                    <option value="INSPECCIONES_AP">INSPECCIONES AP</option>
                                    <option value="SUBSANACIONES">SUBSANACIONES</option>
                                    <option value="RECLAMOS">RECLAMOS</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        {/* <Col>
                            <InputGroup size='sm'>
                                <InputGroup.Checkbox />
                                <InputGroup.Text id="basic-addon1">Agrupación</InputGroup.Text>
                                <Form.Select placeholder='Seleccione un elemento' size='sm'>
                                    <option value="">Elija un elemento</option>
                                    <option value="1">TRABAJADOR_1</option>
                                    <option value="2">TRABAJADOR_2</option>
                                    <option value="3">TRABAJADOR_3</option>
                                    <option value="4">TRABAJADOR_4</option>
                                    <option value="5">TRABAJADOR_5</option>
                                </Form.Select>
                            </InputGroup>
                        </Col> */}
                    </Row>

                    {/* <Row className='mt-1'>
                        <Col xs={12} md={6}>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Valores a buscar</InputGroup.Text>
                                <Form.Control type="text" placeholder="Ingrese valor a buscar" size='sm' />
                                <Button variant="outline-secondary" size='sm'>Buscar</Button>
                            </InputGroup>
                        </Col>

                        <Col className="text-end" xs={12} md={12}>
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >
                                <MenuItem onClick={() => { }}>Opcion_1</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion_2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { }}>Opcion 3</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 4</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 5</MenuItem>
                            </Menu>
                        </Col>
                    </Row> */}
                    <Row className='mb-2'>
                        <div className='mt-1' style={{ height: `75vh`, width: '100%' }}>
                            <DataGrid
                                density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={100}
                                rowsPerPageOptions={[20, 50, 100]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal Reprogramar Actividad */}
                <Modal
                    show={this.state.mostrarModalReprogramar}
                    onHide={() => { this.CerrarModalReprogramar() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Reprogramar`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Trabajador</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="Elemento1">Elemento1</option>
                                        <option value="Elemento2">Elemento2</option>
                                        <option value="Elemento3">Elemento3</option>
                                        <option value="Elemento4">Elemento4</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Asignar A</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="Elemento1">Elemento1</option>
                                        <option value="Elemento2">Elemento2</option>
                                        <option value="Elemento3">Elemento3</option>
                                        <option value="Elemento4">Elemento4</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Fecha</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Control type='date' size='sm'></Form.Control>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label size='sm' className='text-danger'> 123 trabajos no ejecutados seran reprogramados</Form.Label>
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant='outline-primary' size='sm'>
                                        Cerrar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button variant='primary' size='sm'>
                                        Reprogramar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Agregar Trabajador */}
                <Modal
                    show={this.state.mostrarModalAgregarTrabajador}
                    onHide={() => { this.CerrarModalAgregarTrabajador() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Agregar trabajador`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Trabajador base</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type='text' size='sm' placeholder='Trabajador Base'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Trabajador Asignar</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="Elemento1">Elemento1</option>
                                        <option value="Elemento2">Elemento2</option>
                                        <option value="Elemento3">Elemento3</option>
                                        <option value="Elemento4">Elemento4</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={2}>
                                    <Button size='sm'>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label size='sm' className='text-danger'> 123 trabajos no ejecutados seran reprogramados</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <div className='mt-1' style={{ height: `300px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowsTrabajador}
                                        columns={this.state.columnsTrabajador}
                                        // pageSize={100}
                                        // rowsPerPageOptions={[20, 50, 100, 200]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                        hideFooterPagination
                                    />
                                </div>

                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant='outline-primary' size='sm'>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default monitoreoAp;