import config from './../../config/config';
import * as XLSX from 'xlsx';

export var ValidarUsuario = () => {
    let usuarioLocalstorage = ObtenerUsuario()
    //console.log(usuarioLocalstorage);
    if (usuarioLocalstorage != null) {
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
        let ahora = Date.now() / 1000;
        // console.log(usuarioLocalstorage);
        if (usuarioLocalstorage.token !== null) {
            let userDetails = ObtenerDetalleToken(usuarioLocalstorage.token);
            //console.log(JSON.parse(userDetails));
            if (userDetails) {
                if (JSON.parse(userDetails).exp > ahora) {
                    return true;
                } else {
                    localStorage.removeItem(config.storageName);
                }
            }
        } else {
            if (usuarioLocalstorage.exp > ahora) {
                return true;
            } else {
                localStorage.removeItem(config.storageName);
            }
        }
    }
    return false;
}
export var ObtenerUsuario = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    if (usuarioLocalstorage) {
        return usuarioLocalstorage
    } else {
        return null
    }
}
export var ObtenerDetalleToken = (token) => {
    if (token) {
        let centro = token.split(".")[1];
        return window.atob(centro);
    }
    return null;
}
export var GuardarEnStorage = (storageName, data) => {
    localStorage.setItem(storageName, JSON.stringify(data));
}
export var BorrarStorage = (storageName) => {
    // console.log('nombreStorage '+storageName);
    localStorage.removeItem(storageName)
    return storageName;
}
export var ReadExcelfileToJson = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer', cellDates: true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });

}
export var CreateJsonToExcel = (data, nombreArchivo, nombrehoja) => {
    // var data = [
    //     {"name":"John", "city": "Seattle"},
    //     {"name":"Mike", "city": "Los Angeles"},
    //     {"name":"Zach", "city": "New York"}
    // ];    
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, nombrehoja);
    XLSX.writeFile(wb, `${nombreArchivo}.xls`);
}
export var ValidarPermisos = (VISTA, permisos) => {

    // console.log(VISTA);
    // console.log(permisos);

    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    usuarioLocalstorage = JSON.parse(usuarioLocalstorage);    
    

    let acceso = false;
    if(usuarioLocalstorage){
        if (JSON.parse(ObtenerDetalleToken(usuarioLocalstorage.token)).tiposesion === null) {
            acceso = true;
        } else {
            
            for (let x = 0; x < permisos.length; x++) {
                if (permisos[x].t_pantalla.pantalla === VISTA && permisos[x].estado==="ACTIVO") {                
                    acceso = true;
                    break;
                }
            }
        }
    }
    return acceso;
}
export var listarPermisos = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
    
    return new Promise((resolve, reject) => {  
        try {
            let url = `${config.endPoint}api/permiso/listar/${usuarioLocalstorage.tiposesion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    resolve(data.content);
                }
                else {
                    resolve(null);
                }
            }).catch(() => {
                reject(null);
            });        
            
        } catch (error) {
            reject(null);
        }

        // }{
        //     console.log("False");
        //     resolve(null);
        // }
    });
}
export var listarPermisosTipoTrabajo = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
    
    return new Promise((resolve, reject) => {  
        try {
            if(usuarioLocalstorage.tiposesion){
                let url = `${config.endPoint}api/tipousuario_permisos/${usuarioLocalstorage.tiposesion}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data);
                    if (data.content) {
                        // console.log(data.content);
                        resolve(data.content);
                    }
                    else {
                        resolve(null);
                    }
                }).catch(() => {
                    reject(null);
                });       
            }
            else{
                resolve(null);
            }
            
        } catch (error) {
            reject(null);
        }        
    });
}
export var dividirArrayPartes=(array, cantidad)=>{
    var resultado = [];
    for (var i = 0; i < array.length; i += cantidad) {
        resultado.push(array.slice(i, i + cantidad));
    }
    return resultado;
}

export const LIST_SCREENS = {
    ACCESS_SCREEN_HOME: 'DASHBOARD',
    ACCESS_SCREEN_PROGRAM: 'PROGRAMACION',
    ACCESS_SCREEN_PROGRAM_MAPA: 'PROGRAMACION-MAPA',
    ACCESS_SCREEN_MONITOREO: 'MONITOREO',
    ACCESS_SCREEN_MONITOREO_LIBERAR_TRABAJO: 'MONITOREO_LIBERAR_TRABAJO',
    ACCESS_SCREEN_MONITOREO_LECTURA_INSPECCION: 'MONITOREO_LECTURA_INSPECCION',

    ACCESS_SCREEN_SEGURIDAD: 'SEGURIDAD',
    ACCESS_SCREEN_SEGURIDAD_CHARLAS: 'SEGURIDAD_CHARLAS',
    ACCESS_SCREEN_SEGURIDAD_INCIDENTES: 'SEGURIDAD_INCIDENTES',
    ACCESS_SCREEN_SEGURIDAD_COMBUSTIBLE: 'SEGURIDAD_COMBUSTIBLE',
    ACCESS_SCREEN_CONFIGURACION: 'CONFIGURACION',
    ACCESS_SCREEN_CONFIG_PERSONAL: 'CONFIGURACION-PERSONAL',
    ACCESS_SCREEN_CONFIG_PERMISOS: 'CONFIGURACION-PERMISOS',
    ACCESS_SCREEN_CONFIG_ACTIVIDADES: 'CONFIGURACION-ACTIVIDADES',
    ACCESS_SCREEN_CONFIG_MATERIALES: 'CONFIGURACION-MATERIALES',
    ACCESS_SCREEN_CONFIG_RECETA: 'CONFIGURACION-RECETA',
    ACCESS_SCREEN_CONFIG_TIPOTRABAJO: 'CONFIGURACION-TIPOTRABAJO',
    ACCESS_SCREEN_CONFIG_BASES_AP: 'CONFIGURACION-BASES-AP',
    ACCESS_SCREEN_CONFIG_MATERIALES_AP: 'CONFIGURACION-MATERIALES-AP',
    ACCESS_SCREEN_CONFIG_ACTIVIDADES_AP: 'CONFIGURACION-ACTIVIDADES-AP',

    ACCESS_SCREEN_ALMACEN: 'ALMACEN',
    ACCESS_SCREEN_ALMACEN_INVENTARIO: 'ALMACEN-INVENTARIO',
    ACCESS_SCREEN_ALMACEN_RESUMEN: 'ALMACEN-RESUMEN',
    ACCESS_SCREEN_ALMACEN_EPPS: 'ALMACEN-EPPS',
    ACCESS_SCREEN_ESTADISTICA: 'ESTADISTICAS',
    ACCESS_SCREEN_BUSCAR: 'BUSCAR',
    ACCESS_SCREEN_BUSCAR_EN_LINEA: 'BUSCAR_EN_LINEA',
    ACCESS_SCREEN_MAPA: 'MAPA-CONSULTAS',
    ACCERSS_ENCUESTA_MODULO:'ENCUESTA_MODULO',
    ACCERSS_MENU_DESCARGAR_FOTO:'DESCARGAR-FOTOS',
    ACCERSS_MENU_DESCARGAR_REPORTE:'DESCARGAR-REPORTE',
    ACCERSS_MENU_MODIFICAR_TRABAJO:'MODIFICAR-TRABAJO',
    ACCERSS_OPCION_CARGAR_FOTO:'OPCION_SUBIR_FOTOS',
    ACCESS_SCREEN_UBICACIONES:'UBICACIONES',
    ACCESS_SCREEN_OPERACIONES_COMERCIALES:'OPERACIONES_COMERCIALES',
    ACCESS_SCREEN_ALUMBRADO_PUBLICO:'ALUMBRADO_PUBLICO',
    ACCESS_SCREEN_PROGRAMACION_ALUMBRADO_PUBLICO:'PROGRAMACION_ALUMBRADO_PUBLICO',
    ACCESS_SCREEN_MONITOREO_ALUMBRADO_PUBLICO:'MONITOREO_ALUMBRADO_PUBLICO',
    ACCESS_SCREEN_REPORTE_ALUMBRADO_PUBLICO:'REPORTES_ALUMBRADO_PUBLICO',
    ACCESS_SCREEN_ESTADISTICAS_ALUMBRADO_PUBLICO:'ESTADISTICAS_ALUMBRADO_PUBLICO',

    // ACCESS_SCREEN_PROYETO: 'PROYECTO',
}

export const LIST_COLORES= [
    {
        nombre: 'rojo',
        color: 'rgb(255,199,206)',
        textColor: 'white'
    },

    {
        nombre: 'amarillo',
        color: 'rgb(255,235,156)',
        textColor: 'black'
    },

    {
        nombre: 'azul',
        color: 'rgb(155,194,230)',
        textColor: 'white'
    },

    {
        nombre: 'naranja',
        color: 'rgb(255,153,51)',
        textColor: 'white'
    },

    {
        nombre: 'verde',
        color: 'rgb(198,239,206)',
        textColor: 'white'
    },

    {
        nombre: '',
        color : 'rgb(255,255,255)',
        textColor:'black'
    }
]
