import React, { Component, Fragment, createRef } from 'react';

import config from './../../config/config';
import { BorrarStorage, GuardarEnStorage, ObtenerDetalleToken, ValidarUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from './../utils/utils';
import fotoFondo from './fondo.jpg';
import fotoFondo2 from './fondo_imagen.jpg'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { Stack } from '@mui/material';
import { FloatingLabel, Form, Container, Row, Col, Card, Spinner, Button } from 'react-bootstrap';


class login extends Component {
    constructor(props) {

        super(props);
        this.state = {
            correcto: true,
            abrirToast: false,
            mensajeToast: '',
            tipotoast: 'success',
            mostrarSpinner: false
        }
        this.usuario = createRef();
        this.password = createRef();
    }
    ValidarControles = () => {
        let error = 0;

        if (this.usuario.current.value.trim() == '') {
            this.usuario.current.classList.add('is-invalid');
            error++;
        }
        else {
            this.usuario.current.classList.remove('is-invalid');
        }

        if (this.password.current.value.trim() == '') {
            this.password.current.classList.add('is-invalid');
            error++;
        } else {
            this.password.current.classList.remove('is-invalid');
        }

        if (error < 0) {
            return false;
        }
        return true;

    }
    login = () => {
        if (this.ValidarControles()) {
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    usuario: this.usuario.current.value,
                    password: this.password.current.value
                })
            };
            this.setState({ correcto: true, mostrarSpinner: true });

            fetch(`${config.endPoint}api/usuario/loguear/web`, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content === null) {
                    BorrarStorage(config.storageName);
                    this.setState({ correcto: false });
                    this.setState({ mensajeToast: 'Usuario o contraseña no validos.', abrirToast: true, tipotoast: 'error' })
                }
                else {
                    // console.log(data);    
                    let detalleToken = JSON.parse(ObtenerDetalleToken(data.token));
                    let trabajador = {
                        nombretrabajador: detalleToken.nombretrabajador,
                        tiposesion: detalleToken.tiposesion,
                        fotografia: detalleToken.fotografia,
                        token: data.token,
                        tuser: data.tipousuario,
                        visualizar: data.visualizar,
                        VTT: data.visualizarTiposTrabajo,
                        id: data.id,
                        proyecto: data.proyecto
                    };
                    //almacenar Usuario
                    GuardarEnStorage(config.storageName, trabajador);

                    listarPermisos().then((permisos) => {
                        // console.log("Permisos");
                        // console.log(permisos);
                        if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, permisos)) {
                            localStorage.removeItem(config.storageDateName);
                            localStorage.removeItem(config.storageFilterTrabajo);
                            localStorage.removeItem(config.storageDateIniReporte);
                            localStorage.removeItem(config.storageDateFinReporte);
                            localStorage.removeItem(config.storageFilterTrabajo);

                            this.props.history.push("/dashboard");
                        }
                        else {
                            localStorage.removeItem(config.storageName);
                            this.setState({ mensajeToast: 'No tiene permisos para Iniciar Sesión', abrirToast: true, tipotoast: 'warning' })
                        }
                    });

                }
                this.setState({ mostrarSpinner: false });
            }).catch((error) => {
                console.log(error);
                this.setState({ mensajeToast: 'Error Interno en el servidor, revisa el estado de la conexion.', abrirToast: true, tipotoast: 'warning', mostrarSpinner: false })
            });

        }
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            this.props.history.push("/dashboard");
        }
    }

    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    SubmitLogin = (ev) => {
        if (ev.key === 'Enter') {
            this.login()
        }
    }

    render() {
        return (
            <Fragment>
                {/* <div className={`${ !this.state.correcto ? 'd-flex alert alert-danger ': 'd-flex alert alert-danger d-none d-flex'}`} role="alert">
            Usuario o contraseña no valido
        </div> */}
                {/* <form onSubmit={(ev) => { ev.preventDefault() }}>
                    <div className="container-fluid" style={{ backgroundImage: `url(${fotoFondo2})` }}>
                        <div className="container container-login " >
                            <div className="m-auto col-sm-12 col-md-7 col-lg-6 col-xl-5  border " style={{ backgroundColor: 'white' }}>
                                <div className="row m-2 ">
                                    <div className="login-titulo pt-2">
                                        <label style={{ height: '50px', textAlign: 'center', color: 'white' }}><h2>Iniciar Sesión</h2></label>
                                    </div>
                                </div>

                                <div className="row m-2">
                                    <label className="">Usuario</label>
                                    <div className="input-group">
                                        <input ref={this.usuario} type='text' className={`form-control ${this.state.correcto ? '' : 'is-invalid'} `} placeholder="Usuario"></input>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">*</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row m-2">
                                    <label className="">Contraseña</label>
                                    <div className="input-group">
                                        <input ref={this.password} type='password' className={`form-control ${this.state.correcto ? '' : 'is-invalid'} `} placeholder="Contraseña"></input>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">*</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <small id="emailHelp" className={`form-text text-danger ${this.state.correcto ? 'd-none' : ''}`}>Usuario o contraseña Incorrectos</small>
                                </div>
                                <div className="row mx-4">
                                    <button onClick={() => { this.login() }} type="submit" className="btn btn-primary ">Iniciar Sesión</button>
                                </div>
                                <div className="row mx-4 text-secondary ">
                                    <small>{`Version ${config.aplicationVersion}`}</small>
                                </div>

                            </div>
                        </div>
                    </div>
                </form> */}

                <Container fluid style={{ position: 'absolute', backgroundColor: 'red', height: '100%', backgroundImage: `url(${fotoFondo2})`, filter: 'grayscale(10%)', backgroundSize: 'cover' }} >
                </Container>
                <Container>
                    <Row className='vh-100 justify-content-center align-items-center'>
                        <Col sm={9} md={7} lg={6} xl={4}>
                            <Card>
                                <Card.Header>
                                    <Container>
                                        <Row className='text-center'>
                                            <h2 className='text-secondary'>Iniciar Sesión</h2>
                                        </Row>
                                    </Container>                                    
                                </Card.Header>
                               
                                <Card.Body>
                                    <Container>
                                        {/* <Row>
                                            <Col className='text-center'>
                                                <label>Iniciar Sesion</label>
                                            </Col>
                                        </Row> */}
                                        <Row className=''>
                                            <Col>
                                                <FloatingLabel label="Usuario" size='sm'>
                                                    <Form.Control ref={this.usuario} type="text" placeholder='Usuario' />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className='mt-1'>
                                            <Col>
                                                <FloatingLabel label="Contraseña" size='sm'>
                                                    <Form.Control onKeyPress={(ev) => { this.SubmitLogin(ev) }} ref={this.password} type="password" placeholder='Contraseña' />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Stack spacing={3} sx={{ mx: 3, mt: 1 }}>
                                        <Button
                                            variant='primary'
                                            onClick={() => { this.login() }}
                                        >
                                            {
                                                this.state.mostrarSpinner ?
                                                    <Spinner animation="border" variant="light" size='sm' className='mx-2' />
                                                    : <></>
                                            }
                                            Iniciar Sesión
                                        </Button>
                                        <small className='text-secondary'>{config.aplicationVersion}</small>
                                    </Stack>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

            </Fragment >
        );
    }
}

export default login;




