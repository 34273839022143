import React, { Component, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spinner from 'react-bootstrap/Spinner';
import { Container, Row, Col } from 'react-bootstrap';

class loadModal extends Component {
    render() {
        return (
            <Fragment>
                <div className='' hidden={false}
                    style={{
                        height: '100vh',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }
                    }>
                    <Container  >
                        {/* <Row>
                            <Col className='text-center'>
                                <Spinner animation="border" variant="warning" size='lg'/>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col className='text-center '>

                                <span className="loader"></span>
                                {/* <div class="spinner ">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div> */}

                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center fs-4 ' style={{ color: '#004BA0' }}>
                                <label className='mx-3 fw-semibold'>Cargando </label>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>

        );
    }
}

export default loadModal;