import React, { Component, createRef, Fragment } from 'react';
import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';
//Charts
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

import { Divider, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from '../../utils/utils';
import config from './../../../config/config';
import moment from 'moment';
import { InputGroup } from 'react-bootstrap';
import LoadingModal from '../../utils/loadModal'

//Bootstrap
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import AlertBootstrap from 'react-bootstrap/Alert';

//Material UI
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid, esES } from '@mui/x-data-grid';

//Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssessmentIcon from '@material-ui/icons/Assessment';




// import socketIOClient from 'socket.io-client';
ChartJS.register(...registerables);
class estadisticaAp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            idproyecto: null,
            pageSizeGridModalDetalleFiltro: 100,
            habilitarFecha: true,
            tipoTrabajoSeleccionado: '',
            MostrarModalDetalleEstadisticaInspeccionAp: false,
            detalleAlimentadorActual: null,
            columInspeccionAP: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Mostrar Detalle de Alimentador" className='pt-1 mx-2'>
                                    <IconButton onClick={() => {
                                        this.AbrirModalDetalleEstadisticaInspeccionAP(cellValues.row)
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Mostrar Detalle de Alimentador" className='pt-1 mx-2'>
                                    <IconButton onClick={() => {
                                        // this.buscarDetalleEstadisticasProgramacionInspccionesAP(cellValues.row);
                                    }}>
                                        <AssessmentIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'AlimentadorMT', headerName: 'Alimentador', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'Modulares', headerName: 'Cant. Modulares', flex: 0.5, align: 'center', headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <Tooltip title={`${cellValues.row.Modulares}`}>

                                <span>{`${cellValues.row.Modulares}`}</span>
                            </Tooltip>
                        )
                    }
                },
                { field: 'ejecutados', headerName: 'Cant. Programados', align: 'center', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'avance', headerName: 'Avance Programado', align: 'center', flex: 0.5, headerClassName: 'header-colum-table' },
            ],
            row: [],
            columnDetalleEstadisticas: [
                { field: 'Modular', headerName: 'Modular', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'cantidad', headerName: 'Cant. Elementos', align: 'center', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'cantprog', headerName: 'Cant. Programados', align: 'center', flex: 0.5, headerClassName: 'header-colum-table' },
                { field: 'trabajadorprogramado', headerName: 'Trabaj. Programado', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'cantejecutados', headerName: 'Cant. Ejecutados', align: 'center', flex: 0.5, headerClassName: 'header-colum-table' },
            ],
            rowDetalleEstadisticas: [],
            dataPie: {},
        };

        //Referencias
        this.inputFechaInicio = createRef();
        this.inputFechaFin = createRef();
        this.inputSelectTipoTrabajo = createRef();
        this.checkFechaReporte = createRef();

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICAS_ALUMBRADO_PUBLICO, permisos)) {

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    let fechaStorage = await localStorage.getItem(config.storageDateName);
                    if (fechaStorage) {
                        this.inputFechaInicio.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        this.inputFechaFin.current.value = moment(fechaStorage).format('YYYY-MM-DD');

                    } else {
                        this.inputFechaInicio.current.value = moment().format('YYYY-MM-DD');
                        this.inputFechaFin.current.value = moment().format('YYYY-MM-DD');
                    }
                    this.buscarEstadisticas();

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/alumbradoPublico');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    buscarEstadisticas = async () => {
        this.setState({ row: [], tipoTrabajoSeleccionado: '' });
        switch (this.inputSelectTipoTrabajo.current.value) {
            case 'INSPECCIONES':
                await this.setState({ tipoTrabajoSeleccionado: 'INSPECCIONES' });
                this.buscarEstadisticasProgramacionInspccionesAP();
                break
            case 'SUBSANACIONES':
                break
            case 'RECLAMOS':
                break
            default:
                this.setState({ mensajeToast: "Seleccione el tipo de trabajo.", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                break;
        }
    }
    // ****************************************************
    // ESTADISTICAS INSPECCIONES AP
    // ****************************************************
    buscarEstadisticasProgramacionInspccionesAP = async () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            let fechaInicioReporte = '';
            let fechaFinReporte = '';
            if (this.state.habilitarFecha) {
                fechaInicioReporte = this.inputFechaInicio.current.value;
                fechaFinReporte = this.inputFechaFin.current.value;
            }
            let url = `${config.endPoint}api/programacioninspeccionap/estadisticas/${this.state.idproyecto}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    fechainicio: fechaInicioReporte,
                    fechafin: fechaFinReporte
                })
            };
            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    let dataEstadistica = [];
                    data.content.forEach((element, index) => {
                        dataEstadistica.push({
                            id: index,
                            AlimentadorMT: element.AlimentadorMT,
                            Modulares: element.Modulares,
                            ejecutados: element.ejecutados,
                            avance: `${Math.round((element.ejecutados / element.Modulares) * 100)}%`
                        });
                    });

                    await this.setState({ row: dataEstadistica, OcultarLoading: true });
                }
                else {
                    this.setState({ dataEstadistica: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'info', OcultarLoading: true });
                }
            }).catch((error) => {
                this.setState({ dataEstadistica: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    buscarDetalleEstadisticasProgramacionInspccionesAP = async (row) => {
        return new Promise((resolve, reject) => {

            if (ValidarUsuario()) {
                this.setState({ OcultarLoading: false });

                let fechaInicioReporte = '';
                let fechaFinReporte = '';

                if (this.state.habilitarFecha) {
                    fechaInicioReporte = this.inputFechaInicio.current.value;
                    fechaFinReporte = this.inputFechaFin.current.value;
                }

                let url = `${config.endPoint}api/programacioninspeccionap/estadisticasdetalle/${this.state.idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        fechainicio: fechaInicioReporte,
                        fechafin: fechaFinReporte,
                        alimentador: row.AlimentadorMT
                    })
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        let dataEstadistica = [];
                        data.content.forEach((element, index) => {
                            dataEstadistica.push({
                                id: index,
                                Modular: element.Modular,
                                cantidad: element.cantidad,
                                cantejecutados: element.cantejecutados,
                                cantprog: element.cantprog,
                                trabajadorprogramado: element.trabajadorprogramado
                            });
                        });
                        //Llenar Datos de Estadistica
                        let dataPieEstadisticaInspeccionAPDetalle = {
                            labels: [
                                `Pendientes ${(Math.round(((row.Modulares - row.ejecutados) / row.Modulares)) * 100)}%`,
                                `Ejecutado ${Math.round((row.ejecutados / row.Modulares) * 100)}%`,
                                // `Impedimento (${cantImpedimento}) - ${Math.round((cantImpedimento / (cantProgramado)) * 100)} %`
                            ],
                            datasets: [
                                {
                                    label: 'Resumen',
                                    // data: [1, 85],
                                    data: [(row.Modulares - row.ejecutados), row.ejecutados],
                                    backgroundColor: [
                                        'rgba(54, 162, 235, 0.5)',
                                        'rgba(75, 192, 192, 0.5)',
                                        // 'rgba(255, 99, 132, 0.2)',
                                    ],
                                    borderColor: [
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        // 'rgba(255, 99, 132, 1)',                                
                                    ],

                                    borderWidth: 1,
                                },
                            ],
                        }


                        this.setState({ rowDetalleEstadisticas: dataEstadistica, dataPie: dataPieEstadisticaInspeccionAPDetalle, OcultarLoading: true });
                        resolve(true);
                    }
                    else {
                        this.setState({ rowDetalleEstadisticas: [], dataPie: {}, mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'info', OcultarLoading: true });
                        resolve(false);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.setState({ rowDetalleEstadisticas: [], dataPie: {}, mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    cambiarEstadoCheckFecha = async (ev) => {
        console.log(ev.currentTarget.checked);
        await this.setState({ habilitarFecha: ev.currentTarget.checked })
        this.buscarEstadisticas();
    }
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    //****************************************** */
    // MODALES
    //****************************************** */
    AbrirModalDetalleEstadisticaInspeccionAP = (row) => {
        console.log(row);
        this.buscarDetalleEstadisticasProgramacionInspccionesAP(row).then((respuesta) => {
            if (respuesta) {
                this.setState({
                    MostrarModalDetalleEstadisticaInspeccionAp: true,
                    detalleAlimentadorActual: row
                });
            }
        });
    }
    CerrarModalDetalleEstadisticaInspeccionAP = () => {
        this.setState({ MostrarModalDetalleEstadisticaInspeccionAp: false });
    }
    render() {
        return (
            <Fragment>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant="filled" severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="ESTADISTICAS AP" regresar={() => { this.props.history.push('/alumbradoPublico') }}></TituloPagina>
                <Container>
                    <Row className='mt-2'>
                        <Col>
                            <InputGroup size='sm'>
                                <InputGroup.Checkbox defaultChecked={true} onChange={(ev) => { this.cambiarEstadoCheckFecha(ev) }} />
                                <InputGroup.Text id="basic-addon1">Fecha Inicio</InputGroup.Text>
                                <Form.Control disabled={!this.state.habilitarFecha} ref={this.inputFechaInicio} type='date' size='sm' onChange={() => { this.buscarEstadisticas() }} ></Form.Control>
                                <InputGroup.Text id="basic-addon1">Fecha Fin</InputGroup.Text>
                                <Form.Control disabled={!this.state.habilitarFecha} ref={this.inputFechaFin} type='date' size='sm' onChange={() => { this.buscarEstadisticas() }} ></Form.Control>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Tipo Trabajo</InputGroup.Text>

                                <Form.Select ref={this.inputSelectTipoTrabajo} placeholder='Seleccione el estado' size='sm' onChange={() => { this.buscarEstadisticas() }}>
                                    <option value="">Elija un elemento</option>
                                    <option value="INSPECCIONES">INSPECCIONES</option>
                                    <option value="SUBSANACIONES">SUBSANACIONES</option>
                                    <option value="RECLAMOS">RECLAMOSS</option>
                                </Form.Select>
                            </InputGroup>

                        </Col>
                        <Col md={2}>
                            <Button size='sm' variant='primary' onClick={() => { this.buscarEstadisticas() }}>
                                {`Buscar`}
                            </Button>
                        </Col>
                    </Row>
                    <Divider></Divider>

                    {
                        this.state.tipoTrabajoSeleccionado == 'INSPECCIONES' ?
                            <Row className='my-2'>
                                <div className='mt-1' style={{ height: `60vh`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.row}
                                        columns={this.state.columInspeccionAP}
                                        pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                        onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                                    />
                                </div>
                            </Row>
                            : <></>
                    }

                    {
                        this.state.tipoTrabajoSeleccionado == '' ?
                            <Row className='mt-2'>
                                <Col>
                                    <AlertBootstrap variant="info" onClose={() => { this.setState({ mostrarAlertErrrSupervisor: false }) }} dismissible >
                                        {/* <AlertBootstrap.Link href="#" >Corregir</AlertBootstrap.Link> */}
                                        <span className="mx-1">{`Seleccione un tipo de trabajo para mostrar las estadisticas correspondientes.`}</span>
                                    </AlertBootstrap>
                                </Col>
                            </Row>
                            : <></>
                    }
                </Container>


                {/* Modal Ver Detalle Programacion*/}
                <Modal
                    show={this.state.MostrarModalDetalleEstadisticaInspeccionAp}
                    onHide={() => { this.CerrarModalDetalleEstadisticaInspeccionAP() }}
                    keyboard={false}
                    centered
                    size='lg'>

                    <Modal.Header>
                        <Modal.Title>{`Alimentador: ${this.state.detalleAlimentadorActual ? this.state.detalleAlimentadorActual.AlimentadorMT : ''}`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Container fluid>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label className='text-primary'>{`Cantidad Modulares:`}</Form.Label>
                                            </Col>
                                            <Col>
                                                <span>{this.state.detalleAlimentadorActual ? this.state.detalleAlimentadorActual.Modulares : ''}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label className='text-primary'>{`Mod. Programados:`}</Form.Label>
                                            </Col>
                                            <Col>
                                                <span>{this.state.detalleAlimentadorActual ? this.state.detalleAlimentadorActual.ejecutados : ''}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label className='text-primary'>{`Avance:`}</Form.Label>
                                            </Col>
                                            <Col>
                                                <span>{this.state.detalleAlimentadorActual ? this.state.detalleAlimentadorActual.avance : ''}</span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col>
                                    {/* Grafico aqui */}
                                    <div style={{ height: 300 }}>
                                        <Pie data={this.state.dataPie} options={
                                            {
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: {
                                                        position: 'top',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Resumen Alimentador',
                                                    }
                                                }
                                            }
                                        }></Pie>
                                    </div>
                                </Col>
                            </Row>
                            <Divider className='mt-2'></Divider>
                            <Row className='my-2'>
                                <div className='mt-1' style={{ height: `400px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowDetalleEstadisticas}
                                        columns={this.state.columnDetalleEstadisticas}
                                        pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        localeText={esES.props.MuiDataGrid.localeText}
                                    // onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                                    />
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant="outline-primary" size='sm' onClick={() => { this.CerrarModalDetalleEstadisticaInspeccionAP() }}>Cerrar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default estadisticaAp;