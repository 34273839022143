import React, { Component, Fragment, createRef } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import SpinnerCarga from './../utils/spinner';
import LoadingModal from './../utils/loadModal';
import { Modal, Carousel } from 'react-bootstrap';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from './../utils/utils';
import config from './../../config/config'
//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//Material UI
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from 'file-saver';
import moment from 'moment';

var zip = require('jszip')();

class Buscar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            MostrarModalDetalle: false,
            MostrarModalFotos: false,
            suministro: null,
            contrato: '',
            FiltroBusqueda: [],
            ListaFotosTrabajoSuministro: [],
            loadData: true,
            idproyecto: null,
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Suministro' },
            { id: 'Programación' },
            { id: 'Supervisor' },
            { id: 'Trabajador' },
            // { id: 'Ejecuta' },
            { id: 'Tipo' },
            { id: 'Estado' }
        ];
        // Referencias
        this.inputBuscar = createRef();
        //Referencias Modal
        this.inputNombreCliente = createRef();
        this.inputDireccionSuministro = createRef();
        this.inputDetalleTrabajo = createRef();
        this.inputFechaProgramacion = createRef();
        this.inputSED = createRef();
        this.inputtipo = createRef();
        this.inputEstadoEjecucion = createRef();
        this.inputFechaEjecucion = createRef();
        this.inputObsEjecucion = createRef();
        this.inputLat = createRef();
        this.inputLng = createRef();
    }

    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then((permisos) => {
                // console.log("Permisos de usuario");
                // console.log(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        } else {
            this.props.history.push('/login');
        }
    }

    RealizarBusqueda = async () => {
        if (ValidarUsuario()) {
            if (this.inputBuscar.current.value.trim() !== '') {
                await this.setState({ loadData: false, FiltroBusqueda: [] });
                let url = `${config.endPoint}api/trabajosuministro/buscar/${this.state.idproyecto}/${this.inputBuscar.current.value.trim()}`;
                // console.log(url);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data);
                    if (data.content) {
                        if (data.content.length > 0) {
                            this.setState({ FiltroBusqueda: data.content, cantidadElementos: data.count, loadData: true });
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron registros", tipotoast: 'info', loadData: true });
                        }
                    }
                    else {
                        this.setState({ FiltroBusqueda: [], cantidadElementos: data.count, loadData: true });

                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', loadData: true });
                });

            } else {
                this.setState({ mensajeToast: 'Ingrese el Codigo de suministro a Buscar', abrirToast: true, tipotoast: 'error' });
            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    buscarSuministro = (id) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {

                // let url = `${config.endPoint}api/trabajosuministro/${id}`;
                let url = `${config.endPoint}api/trabajosuministroV1/${id}`;
                // console.log(url);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await this.setState({ suministro: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ suministro: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            this.RealizarBusqueda();
        }
    }
    //************************************************ */
    // Modal Detalle Suministro
    //************************************************ */
    AbrirModalDetalle = async (id) => {
        await this.setState({ OcultarLoading: false });

        await this.buscarSuministro(id).then((respuesta) => {
            if (respuesta) {
                this.setState({ MostrarModalDetalle: true, OcultarLoading: true });
                this.setState({ contrato: this.state.suministro.suministro });

                this.inputNombreCliente.current.value = this.state.suministro.nombreCliente;
                this.inputDireccionSuministro.current.value = this.state.suministro.direccionCliente;
                this.inputDetalleTrabajo.current.value = this.state.suministro.detalle;
                this.inputFechaProgramacion.current.value = this.state.suministro.fechaProgramacion;
                this.inputSED.current.value = this.state.suministro.subEstacion;
                this.inputtipo.current.value = this.state.suministro.tipo;

                this.inputEstadoEjecucion.current.value = this.state.suministro.estado
                this.inputFechaEjecucion.current.value = this.state.suministro.fecha_ejecucion;
                this.inputObsEjecucion.current.value = this.state.suministro.observacion_ejecucion;
                this.inputLat.current.value = this.state.suministro.lat_ejecucion;
                this.inputLng.current.value = this.state.suministro.lng_ejecucion;
            }
        });

    }
    AbrirMaps = (lat, lng) => {
        let _lat = parseFloat(lat);
        let _lng = parseFloat(lng);

        // console.log(_lat);
        if (_lat !== 0 && !isNaN(_lat)) {
            let url = `http://maps.google.com/?q=${_lat},${_lng}`;
            window.open(url, '_blank');
        } else {
            this.setState({ mensajeToast: "Ubicacion GPS no valida.", abrirToast: true, tipotoast: 'warning' });
        }

    }
    CerrarModalDetalle = () => {
        this.setState({ MostrarModalDetalle: false });
    }
    //********************************************** */
    // Modal Fotos
    //********************************************** */
    AbrirModalFotos = async () => {
        // this.setState({ MostrarModalFotos: true });        
        await this.setState({ ListaFotosTrabajoSuministro: [] });
        if (this.state.suministro.t_fotos.length > 0) {
            // Llenar Lista de fotos
            await this.setState({ ListaFotosTrabajoSuministro: this.state.suministro.t_fotos });
            await this.setState({ MostrarModalFotos: true });
        }
        else {
            this.setState({ mensajeToast: "No se encontraron fotos para este suministro", abrirToast: true, tipotoast: 'info' });
        }
    }
    CerrarModalFotos = () => {
        this.setState({ MostrarModalFotos: false });
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    //*************************************************
    // Descargar Fotos
    //*************************************************
    DescargarFotosProgramacion = async () => {
        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')
            // let url = `${config.endPoint}api/movil/listafotosProyecto/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let arrayListaFotos = this.state.suministro.t_fotos;
            if (arrayListaFotos.length > 0) {                
                this.DecargarPorPartes(this.state.suministro);
                this.setState({ MostrarModalDetalle: false, MostrarModalFotos: false });
            } else {
                await this.setState({ mensajeDescargaFotos: `No se encontraron fotos para realizar esta accion`, tipotoast: 'error' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    DecargarPorPartes = async (suministro) => {
        let cantidad = 0;
        let arrayListaFotos = suministro.t_fotos;

        await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${arrayListaFotos.length} Fotos Descargadas` });

        for (let x = 0; x < arrayListaFotos.length; x++) {
            await fetch(`${config.endPoint}api/foto/DownloadFileServer/${arrayListaFotos[x].foto_url}`).then(async (respuesta) => {
                cantidad++;
                await this.setState({ mensajeDescargaFotos: `${cantidad}/${arrayListaFotos.length} Fotos Descargadas` });
                const arraybuffer = await respuesta.arrayBuffer();
                let nombreFoto = `FI_${suministro.suministro.toString().padStart(11, '0')}_${moment(arrayListaFotos[x].fecha).format('YYYYMMDD')}_H${arrayListaFotos[x].hora.toString().replaceAll(':', '')}_00`;
                
                // zip.file(`${suministro.fechaProgramacion.trim()}/${suministro.suministro.trim()}/${arrayListaFotos[x].foto_url}`, arraybuffer);
                zip.file(`${suministro.fechaProgramacion.trim()}/${suministro.suministro.trim()}/${nombreFoto}.jpg`, arraybuffer);
            }
            ).catch((error) => {
                console.log(error);
            });
        }
        //Generar el Archivo Zip
        await zip.generateAsync({ type: 'blob' }).then((contenido) => {
            saveAs(contenido, `Fotos_${suministro.suministro}.zip`);
        });
        //Reiniciar el zip
        zip = require('jszip')();
        await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
    }
    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar />
                {/* <TituloPagina titulo="BUSCAR" regresar={() => { this.props.history.push('/operacionesComerciales') }} /> */}
                <TituloPagina titulo="BUSCAR" regresar={() => { this.props.history.push('/') }} />

                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className=" col-8 col-md-4">
                            <input onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Suministro"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.RealizarBusqueda() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>
                    </div>

                    {/* Tabla de Datos */}
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.FiltroBusqueda.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalDetalle(row.idTrabajosuministro) }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.suministro}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.fechaProgramacion}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.supervisor}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.programado === row.ejecuta ? row.ejecuta : row.programado}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.tipotrabajo}</TableCell>
                                                            {/* <TableCell>{row.estado}</TableCell> */}
                                                            <TableCell style={{ fontSize: '0.8em' }}>{
                                                                row.estado === 'NO EJECUTADO' || row.estado === 'IMPEDIMENTO' ?
                                                                    <label className="text-danger">{row.estado}</label> :
                                                                    (row.estado === 'PROGRAMADO' ?
                                                                        <label >{row.estado}</label> :
                                                                        <label className="fw-bold text-success">{row.estado}</label>)

                                                            }</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="mt-3">
                                        <small className="text-muted">{`${this.state.FiltroBusqueda.length} Elemento(s) Encontrado(s)`}</small>
                                    </div>
                                </>
                                :
                                <SpinnerCarga />
                        }
                    </div>
                </div>

                {/* Modal Detalle de suministro */}
                <Modal
                    show={this.state.MostrarModalDetalle}
                    onHide={() => { this.CerrarModalDetalle() }}
                    keyboard={false}
                    centered
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>Suministro : {this.state.contrato}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.suministro ?
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary col-sm-4">Nombre Cliente</small>
                                            <input ref={this.inputNombreCliente} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary col-sm-4">Dirección Cliente</small>
                                            <input ref={this.inputDireccionSuministro} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary col-sm-4">Detalle</small>
                                            <input ref={this.inputDetalleTrabajo} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <small className="text-primary col-sm-4">Fecha Programación</small>
                                            <input ref={this.inputFechaProgramacion} className="form-control form-control-sm" disabled></input>
                                        </div>
                                        <div className="col-md-6">
                                            <small className="text-primary col-sm-4">SED</small>
                                            <input ref={this.inputSED} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <small className="text-primary col-sm-4">Tipo</small>
                                            <input ref={this.inputtipo} className="form-control form-control-sm" disabled></input>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <small className="text-primary col-sm-4">Fecha Ejecución</small>
                                            <input ref={this.inputFechaEjecucion} className="form-control form-control-sm" disabled></input>
                                        </div> */}
                                    </div>

                                    <hr></hr>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <small className="text-primary col-sm-4">Estado</small>
                                            <input ref={this.inputEstadoEjecucion} className="form-control form-control-sm" disabled></input>
                                        </div>
                                        <div className="col-md-6">
                                            <small className="text-primary col-sm-4">Fecha Ejecución</small>
                                            <input ref={this.inputFechaEjecucion} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary col-sm-4">Obs. Ejecución</small>
                                            <input ref={this.inputObsEjecucion} className="form-control form-control-sm" disabled></input>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <small className="text-primary col-sm-4">Lat</small>
                                            <input ref={this.inputLat} className="form-control form-control-sm" disabled></input>
                                        </div>
                                        <div className="col-md-4">
                                            <small className="text-primary col-sm-4">lng</small>
                                            <input ref={this.inputLng} className="form-control form-control-sm" disabled></input>
                                        </div>
                                        <div className="col-md-4 ">
                                            <div>
                                                <small className="text-primary col-sm-4">*</small>
                                            </div>
                                            <span onClick={() => { this.AbrirMaps(this.inputLat.current.value, this.inputLng.current.value) }} className="text-primary text-decoration-underline pointer-link ">Ver en Maps</span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary col-sm-4">Fotografias</small>
                                            <div onClick={() => { this.AbrirModalFotos() }}>
                                                <i className="fas fa-camera iconoAcciones"></i>
                                                <label className=" text-primary px-1 text-decoration-underline pointer-link">{`${this.state.suministro.t_fotos.length} Fotografias Tomadas`}</label>
                                            </div>
                                        </div>
                                        <div className='col text-end'>
                                            <Tooltip title="Descargar fotos.">
                                                <IconButton onClick={() => { this.DescargarFotosProgramacion() }}>
                                                    <CloudDownloadIcon style={{  }}></CloudDownloadIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-end">
                                    <button onClick={() => { this.CerrarModalDetalle() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Fotos*/}

                <Modal
                    show={this.state.MostrarModalFotos}
                    onHide={() => { this.CerrarModalFotos() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <div className="container" >
                            <Row>
                                <Col>

                                </Col>
                                <Col className='text-end'>
                                    <Tooltip title="Descargar fotos.">
                                        <IconButton onClick={() => { this.DescargarFotosProgramacion() }}>
                                            <CloudDownloadIcon style={{ color: 'white' }}></CloudDownloadIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <div className="row" style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {this.state.ListaFotosTrabajoSuministro.map((foto, index) => {
                                        return (
                                            <Carousel.Item key={index} >
                                                <div >
                                                    <div onClick={() => { this.AbrirMaps(foto.lat, foto.lng) }} className="col text-center linkButton text-secondary">
                                                        <i className="fas fa-street-view mx-1"></i>
                                                        <span>{`GPS: ${parseFloat(foto.lat)},${parseFloat(foto.lng)} `}</span>
                                                    </div>
                                                    <img
                                                        className="d-block m-auto"
                                                        src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                        alt={`Imagen ${index + 1}`}
                                                        height='550'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                    />
                                                    <div className="row ">
                                                        <div className="col-6 col-md-6 text-light">
                                                            <span>{`Foto ${index + 1} de ${this.state.suministro.t_fotos.length}`}</span>
                                                        </div>
                                                        <div className="col-6 col-md-6 text-end text-light">
                                                            <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>

                                        );
                                    })}
                                </Carousel>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default Buscar;