import React, { Component, createRef, Fragment } from 'react';
import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';
import LoadingModal from '../../utils/loadModal'
import SpinnerCarga from '../../utils/spinner'
import moment from 'moment';

import { Snackbar } from '@material-ui/core'
// Material UI
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
import { Alert } from '@material-ui/lab'
import { DataGrid, esES } from '@mui/x-data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import config from '../../../config/config'

import { ValidarUsuario, ObtenerUsuario, ReadExcelfileToJson, ValidarPermisos, LIST_SCREENS, listarPermisos, LIST_COLORES, ObtenerDetalleToken, dividirArrayPartes } from '../../utils/utils'
// Componentes Bootstrap
import Form from 'react-bootstrap/Form';
import AlertBootstrap from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Modal, Container, Row, Col, Spinner } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

// Material Icons Elements
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class programacionAp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            valorSelect: "Todos",
            colorIcon: '',
            loadSumEliminar: true,
            MostrarModalCambiarTrabajador: false,
            MostrarModalAsignarSupervisor: false,
            MostrarModalMensajes: false,
            MostrarModalElegirTipoProgramacion: false,
            MostrarModalEliminar: false,
            MostrarModalProgramarMapa: false,
            MostrarModalAsignarColorTrabajo: false,
            MostrarModalValidarDataCargada: false,
            MostrarModalDetalleRegistrosFiltro: false,
            MostrarModalAgregarTrabajadorInspeccionAp: false,
            mostrarMensajeNoEncontrado: false,
            MostrarModalDetalleProgramacionInspeccionesAp: false,
            pantallaCompleta: true,
            listaProgramacion: [],
            TipoTrabajos: [],
            ListaObservacionTecnico: [],
            CantidadObservacionesTecnico: 0,
            mostrarTodosJuoObservacion: false,
            ListaObservacionesSupervisor: [],
            CantidadObservacionesSupervisor: 0,
            mostrarTodosSupervisorObservacion: false,
            ListaTrabajadoresActivos: [],
            ListaObservacionSupervisor: [],
            ListaObservacionesPrioridad: [],
            ListaSuministrosSinGps: [],
            ListaSuministrosEliminar: [],
            ListaTecnicosTipoTrabajo: [],
            filasPorPagina: 10,
            pagina: 1,
            cantidadElementos: 0,
            loadData: false,
            fechaProgramacion: '',
            abrirToast: false,
            ListaProgramcionTecnicos: [],
            ListaProgramacionSupervisores: [],
            ListaMaterialesReceta: [],
            ListaMaterialSEAL: [],
            MostrarPorcentajeCarga: false,
            mensaCargaProgramacion: '',
            ProgramacionCargada: 0,
            ProgramacionNoCargada: 0,
            ProgramacionError: 0,
            TotalArchivos: 0,
            ListaErrores: '',
            FilasError: [],
            TextoMensajesModal: '',
            TituloMensajeModal: '',
            TextoMensajesModalPregunta: '',
            DataCargar: [],
            CodigosNoEncontrados: [],
            RecetasNoEncontradas: [],
            RecetasNoEncontradasDetalle: [],
            tipotrabajoDetalle: '',
            tipoCargaProgramacionTrabajo: 'DESDE-ARCHIVO',
            tipoProgramacionTrabajo: '',
            color: 'rgb(255,255,255)',
            colorText: 'black',
            idproyecto: null,
            listaProyectosActivos: [],
            mostrarAlertErrrSupervisor: true,
            columTrabajadorInspeccioAp: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Habilitar elemento " className='pt-1 mx-2'>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        label=""
                                        defaultChecked={cellValues.row.estado == 'ACTIVO' ? true : false}
                                        onChange={(ev) => { this.ActualizarTrabajadorProgramacionInspecionesAp(cellValues.row.id, ev.currentTarget.checked) }}
                                    />
                                </Tooltip>

                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                        this.EliminarTrabajadorProgramacionInspecionesAp(cellValues.row.id)
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'nombretrabajador', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rowTrabajadorInspeccionAp: [],
            mostrarProgramacionInspecciones: false,
            mostrarInputCargarArchivo: false,
            mostrarGridDetalleProgramacion: false,
            habilitarBusquedaPeriodo: true,
            habilitarBusquedaModular: true,
            habilitarBusquedaDistrito: false,
            arrayPeriodosActuales: null,
            arrayModularesActuales: null,
            arrayDistritosActuales: null,
            elementosEncontradosFiltroInspecciones: null,
            mostrarAlertFiltroInspecciones: false,
            arrayRegistrosEncontradosFiltro: null,
            columnsDetalleRegistrosFiltroInspecciones: [
                { field: 'codSed', headerName: 'SED', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'sistemaElectrico', headerName: 'Sistema Electrico', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'simEst', headerName: 'SimEST', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'etiEst', headerName: 'EtiEST', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'CentroTransformacion', headerName: 'Centro Transformación', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'AlimentadorMT', headerName: 'Alimentador MT', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Distrito', headerName: 'Distrito', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Direccion', headerName: 'Dirección', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Modular', headerName: 'Modular', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'Localidad', headerName: 'Localidad', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rowsDetalleRegistrosFiltroInspecciones: [],
            pageSizeGridModalDetalleFiltro: 100,
            arrayProgramacionInspecciones: [],
            columnsProgramacionInspecciones: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Habilitar elemento " className='pt-1 mx-2'>
                                    <IconButton onClick={() => {
                                        this.AbrirModalDetalleProgramacionInspeccionesAp(cellValues.row)
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'nombretrabajador', headerName: 'Nombre Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'fecha', headerName: 'Fecha', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'modulares', headerName: 'Modular', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'alimentador', headerName: 'AlimentadorMT', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <Tooltip title={`${cellValues.row.alimentador}`}>
                                <span>{`${cellValues.row.alimentador}`}</span>
                            </Tooltip>
                        )
                    }
                },
                { field: 'cantidadElementos', headerName: 'Cantidad', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rowsProgramacionInspecciones: [],

            tipoProgramacionAp: '',
            mostrarCargaAgregarModular: false,
            columDetalleProgramacionInspeccioAp: [
                {
                    field: 'acciones', headerName: '*', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                {/* <Tooltip title="Habilitar elemento " className='pt-1 mx-2'>
                                    <Form.Check 
                                        type="switch"
                                        label=""
                                        defaultChecked={cellValues.row.estado == 'ACTIVO' ? true : false}
                                        onChange={(ev) => { this.ActualizarTrabajadorProgramacionInspecionesAp(cellValues.row.id, ev.currentTarget.checked) }}
                                    />
                                </Tooltip> */}

                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                        this.EliminarModularProgramacionInspeccionAp(cellValues.row);
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'nombretrabajador', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                // { field: 'fecha', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'Modular', headerName: 'Modular', flex: 1, headerClassName: 'header-colum-table'
                },
                { field: 'AlimentadorMT', headerName: 'Alimentador', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'cantidadelementos', headerName: 'Cantidad', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rowsDetalleProgramacionInspecciones: [],
            programacionInspeccionApActual: null

        };
        // Referencias
        this.inputFechaProgramacion = createRef();
        this.inputFileSubirArchivo = createRef();
        this.selectTipoTrabajo = createRef();
        this.buttonSubirData = createRef();
        this.buttomBuscar = createRef();
        this.selectProyecto = createRef();
        //Referencias Modal Asignar Tecnico
        this.inputChekMostrarTodosTecnicos = createRef();
        this.selectListaJUOEncontrados = createRef();
        this.inputReasignarTrabajador = createRef();
        // Referencias Modal AsignatSupervisor
        this.inputCheckBoxMostrarTodosSupervisores = createRef();
        this.selectTrabajadoresSupervisorAsignado = createRef();
        this.selectSupervisorEncontrados = createRef();
        this.inputReasignarSupervidor = createRef();

        //Referencias modalEliminar
        this.selectTipoEliminar = createRef();
        this.selectTecnicoEliminar = createRef();

        //Referencia Radiobutton
        this.selectElegirTipoProgramacion = createRef();

        //referencias Selecc color
        this.selectElegirColorTrabajo = createRef();
        this.selectPrioridades = createRef();

        //referencia seleccionar Tipos Trabajo
        this.inputSelectElegirTrabajoProgramar = createRef();
        this.inputSelectPeriodoSeleccionado = createRef();
        this.inputSelectModularSeleccionado = createRef();
        this.inputSelectDistritoSeleccionado = createRef();

        //Referencias Programar Trabajador
        this.inputFechaProgramacionTrabajadorInspeccionAP = createRef();
        this.inputNombreProgramacionTrabajadorInspeccionAp = createRef();
        this.inputEstadpProgramacionTrabajadorInspeccionAp = createRef();
    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAMACION_ALUMBRADO_PUBLICO, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    let fechaProgram = new Date();
                    this.setState({ fechaProgramacion: moment(fechaProgram).format('YYYY-MM-DD') });
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Progrmacion de Trabajos
    // ***************************
    BuscarProgramacionPorFecha = async () => {
        if (ValidarUsuario()) {

            // FormatearFecha
            await this.setState({ loadData: false });
            let url = '';
            if (this.selectTipoTrabajo.current.value !== "TODOS") {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/trabajosuministro/programacionProyecto/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
                } else {
                    url = `${config.endPoint}api/trabajosuministro/programacion/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
                }
            }
            else {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/trabajosuministro/programacionProyecto/${this.state.idproyecto}/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
                } else {
                    url = `${config.endPoint}api/trabajosuministro/programacion/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
                }
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.BuscarListaObservacionTecnicos().then(async () => {
                        //Buscar Cantidad de elementos con error
                        await this.ContarObservacionesTecnico();
                    }).catch((error) => {
                        if (error) {
                            this.props.history.push('/login');
                        }
                    });

                    await this.BuscarListaObservacionSupervisor().then(async () => {
                        await this.ContarObservacionesSupervisor();
                    }).catch((error) => {
                        if (error) {
                            this.props.history.push('/login');
                        }
                    });
                    //Reprogramar
                    //await this.BuscarListaSuministrosSinGPs().then();

                    this.setState({ listaProgramacion: data.content, cantidadElementos: data.count, loadData: true });

                    // console.log(data.content);
                    // if (this.state.CantidadObservacionesTecnico === 0 && this.state.CantidadObservacionesSupervisor === 0) {
                    //     this.setState({ abrirToast: true, mensajeToast: "No se encontraron observaciones de Tecnico o Supervisor sin asingar.", tipotoast: 'info' });
                    // }
                }
                else {
                    this.setState({ listaProgramacion: [], cantidadElementos: data.count, loadData: true })
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
            await this.setState({ OcultarLoading: true });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Observaciones GPS
    // ***************************
    BuscarListaSuministrosSinGPs = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {

                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionSinGPS/${this.state.fechaProgramacion}/${this.selectTipoTrabajo.current.value}`;
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionSinGPS/${this.state.fechaProgramacion}`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaSuministrosSinGps: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaSuministrosSinGps: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    // ***************************
    // Listado Programacion Trabajadores
    // ***************************
    BuscarListaObservacionTecnicos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajadorProyecto/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajador/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                else {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajadorProyecto/${this.state.idproyecto}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajador/${this.state.fechaProgramacion}`
                    }
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionTecnico: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionTecnico: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    ContarObservacionesTecnico = async () => {
        let cant = 0;
        for await (const elem of this.state.ListaObservacionTecnico) {
            if (elem.nombretrabajador === null) {
                cant++;
            }
        }
        this.setState({ CantidadObservacionesTecnico: cant });
    }
    BuscarListaProgramacionTecnicos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.selectProyecto.current.value}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                else {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.state.idproyecto}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.selectProyecto.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaProgramcionTecnicos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaProgramcionTecnicos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Listado Programacion Supervisores
    // ***************************
    BuscarListaObservacionSupervisor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisorProyecto/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisor/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                else {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisorProyecto/${this.state.idproyecto}/${this.state.fechaProgramacion}`;
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisor/${this.state.fechaProgramacion}`;
                    }
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionSupervisor: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionSupervisor: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Listado Pbservaciones Prioridad
    // ***************************
    BuscarListaObservacionesPrioridad = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = ``;
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/prioridad/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`;
                } else {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/prioridad/${this.selectProyecto.current.value}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionesPrioridad: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionesPrioridad: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    validarModalAsignarcolorTrabajos = () => {
        let contador = 0;
        // if (this.selectPrioridades.current.value === '') {
        //     this.selectPrioridades.current.classList.add('is-invalid');
        //     contador++;
        // }
        // else {
        //     this.selectPrioridades.current.classList.remove('is-invalid');
        // }

        if (this.selectElegirColorTrabajo.current.value.trim() === '') {
            this.selectElegirColorTrabajo.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectElegirColorTrabajo.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarColorTrabajoSuministro = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalAsignarcolorTrabajos()) {

                let url = `${config.endPoint}api/trabajosuministro/programacion/cambiarpincolor`;
                let updateTrabajoSuministro = {
                    fechaProgramacion: this.state.fechaProgramacion,
                    idtipotrabajo: this.selectTipoTrabajo.current.value,
                    pinColor: this.selectElegirColorTrabajo.current.value,
                    prioridad: this.selectPrioridades.current.value,
                    idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value,
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(updateTrabajoSuministro)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await this.setState({ abrirToast: true, mensajeToast: "Color asignado correctamente.", tipotoast: 'success' });
                    }
                    else {
                        data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ContarObservacionesSupervisor = async () => {
        let cant = 0;
        for await (const elem of this.state.ListaObservacionSupervisor) {
            if (elem.nombretrabajador === null) {
                cant++;
            }
        }
        this.setState({ CantidadObservacionesSupervisor: cant });
    }
    BuscarListaProgramacionSupervisor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.state.idproyecto}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.selectProyecto.current.value}/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                else {
                    if (this.state.idproyecto) {
                        url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.state.idproyecto}/${this.state.fechaProgramacion}`
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.selectProyecto.current.value}/${this.state.fechaProgramacion}`
                    }
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaProgramacionSupervisores: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaProgramacionSupervisores: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Subir Programacion de Excel
    // ***************************
    ValidarDatosProgramacion = () => {
        let errores = 0;
        let listaErrores = '';
        if (this.state.idproyecto == null) {
            if (this.selectProyecto.current.value == '') {
                this.selectProyecto.current.classList.add('is-invalid');
                listaErrores += 'Ejija el archivo a procesar. ;'
                errores++;
            } else {
                this.selectProyecto.current.classList.remove('is-invalid');
            }
        }

        if (this.inputFileSubirArchivo.current.files.length > 0) {
            this.inputFileSubirArchivo.current.classList.remove('is-invalid');
        }
        else {
            this.inputFileSubirArchivo.current.classList.add('is-invalid');
            listaErrores += 'Ejija el archivo a procesar. ;'
            errores++;
        }

        if (this.selectTipoTrabajo.current.value !== 'TODOS') {
            this.selectTipoTrabajo.current.classList.remove('is-invalid');
        }
        else {
            this.selectTipoTrabajo.current.classList.add('is-invalid');
            listaErrores += 'Seleccione el tipo de programación. ;'
            errores++;
        }
        if (moment(this.state.fechaProgramacion).isValid()) {
            // let fecha_hoy=moment();
            // let fechaprogra=moment(this.state.fechaProgramacion);

            this.inputFechaProgramacion.current.classList.remove('is-invalid');
        } else {
            this.inputFechaProgramacion.current.classList.add('is-invalid');
            listaErrores += 'La fecha establecida no es un formato valido. ;'
            errores++;

        }
        if (errores > 0) {
            this.setState({ abrirToast: true, mensajeToast: listaErrores, tipotoast: 'warning' });
            return false;
        }
        else {
            return true;
        }
    }
    ObtenerRecetaMateriales = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/materialreceta/listarMaterialesRecetaActivos`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialesReceta: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesReceta: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });

    }
    ObtenerListaMaterialesSEAL = async () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/listamateriales/seal`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialSEAL: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialSEAL: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        })
    }
    CargarProgramacion = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarDatosProgramacion()) {
                try {

                    console.log("/**********************************/")
                    console.log("/SiscomerWeb Script version 1.1    /")
                    console.log("/**********************************/")
                    //Reiniciar Valores
                    await this.setState({ DataCargar: [], CodigosNoEncontrados: [], RecetasNoEncontradas: [] });
                    await this.setState({ OcultarLoading: false });
                    let DataExcel = null;

                    await ReadExcelfileToJson(this.inputFileSubirArchivo.current.files[0]).then(async (respuesta) => {
                        DataExcel = respuesta;
                        // console.log(respuesta);
                    }).catch((error) => {

                        DataExcel = [];
                        this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
                    });

                    //Validar Que se aya encontrado datos
                    if (DataExcel.length > 0) {
                        //Buscar Materiales de Receta
                        //await this.ObtenerRecetaMateriales().then();//Retorna ListaMaterialesReceta
                        //Buscar Recetas Para el tipo de Trabajo
                        // let RecetasTipoTrabajo = this.state.ListaMaterialesReceta.filter(receta => receta.tipo_trabajo === this.state.tipotrabajoDetalle);
                        // await this.setState({ ListaMaterialesReceta: RecetasTipoTrabajo });
                        // console.log(this.state.ListaMaterialesReceta);

                        //await this.ObtenerListaMaterialesSEAL().then();//Retorna ListaMaterialSEAL Activos SEAL

                        // if (this.state.ListaMaterialesReceta.length > 0) {
                        //Recorrer el Arreglo de Filas Excel
                        let listaCodigosNoExisten = [];
                        let listaRecetasNoEncontradas = [];
                        let DataCargar = [];

                        let ListaRecetaDetalle = [];
                        let listaRecetasNoEncontradasDetalle = [];

                        for (let x = 0; x < DataExcel.length; x++) {
                            // console.log(`***********************`);
                            // console.log(`Elemento Excel ${x+1}`);
                            // console.log(`***********************`);
                            let nuevaFila = {};
                            let ListaReceta = [];
                            //Buscar Valores que no sean cabeceras
                            for (var clave in DataExcel[x]) {
                                //Cargar Tipo Trabajo elegido
                                nuevaFila.tipoProgramacion = this.state.tipoProgramacionTrabajo;

                                //Verificando Clave Existe
                                if (DataExcel[x].hasOwnProperty(clave)) {
                                    //Verificando si la Clave es Cabecera o Material
                                    switch (clave) {
                                        case 'Tipo':
                                            nuevaFila.tipo = DataExcel[x][clave];
                                            break;
                                        case 'Prioridad':
                                            nuevaFila.prioridad = DataExcel[x][clave];
                                            break;
                                        case 'Suministro/Ruta':
                                            nuevaFila.suministro_ruta = DataExcel[x][clave];
                                            break;
                                        case 'Serie':
                                            nuevaFila.serie = DataExcel[x][clave];
                                            break;
                                        case 'Fecha de Pago':
                                            let fecha = DataExcel[x][clave];
                                            let nFecha = moment(fecha).format('YYYY-MM-DD')
                                            let nHora = moment(fecha).format('hh:mm a')
                                            // nuevaFila.fechaDePago = DataExcel[x][clave] ;
                                            nuevaFila.fechaDePago = nFecha + " " + nHora;
                                            break;
                                        case 'Orden Trabajo':
                                            nuevaFila.ordenTrabajo = DataExcel[x][clave];
                                            break;
                                        // case '':
                                        //     nuevaFila.observacion = DataExcel[x][clave] ;
                                        //     break;
                                        case 'Nota de Salida':
                                            nuevaFila.notasalida = DataExcel[x][clave];
                                            break;
                                        case 'Distrito':
                                            nuevaFila.distrito = DataExcel[x][clave];
                                            break;
                                        case 'Suministro / Contrato':
                                            nuevaFila.suministro = DataExcel[x][clave];
                                            break;
                                        case 'SED':
                                            nuevaFila.subEstacion = DataExcel[x][clave];
                                            break;
                                        case 'Circuito':
                                            nuevaFila.circuito = DataExcel[x][clave];
                                            break;
                                        case 'Tablero':
                                            nuevaFila.tablero = DataExcel[x][clave];
                                            break;
                                        case 'Nombre':
                                            nuevaFila.nombreCliente = DataExcel[x][clave];
                                            break;
                                        case 'Dirección':
                                            nuevaFila.direccionCliente = DataExcel[x][clave];
                                            break;
                                        case 'Detalle':
                                            nuevaFila.detalle = DataExcel[x][clave];
                                            break;
                                        case 'D':
                                            nuevaFila.dias = DataExcel[x][clave];
                                            break;
                                        case 'FECHA FORMATO':
                                            nuevaFila.fechaFormato = DataExcel[x][clave];
                                            break;
                                        case 'HACER NORMA':
                                            nuevaFila.hacerNorma = DataExcel[x][clave];
                                            break;
                                        case 'Tipo OT':
                                            nuevaFila.tipoOt = DataExcel[x][clave];
                                            break;
                                        case 'OT':
                                            nuevaFila.ot = DataExcel[x][clave];
                                            break;
                                        case 'JUO':
                                            nuevaFila.JUOProgramacion = DataExcel[x][clave];
                                            break;
                                        case 'Supervisor':
                                            nuevaFila.supervisor = DataExcel[x][clave].trim() === "" ? "X" : DataExcel[x][clave];
                                            break;
                                        // case '':
                                        //     nuevaFila.fechaRecepcionOt = DataExcel[x][clave] ;
                                        //     break;
                                        case 'X':
                                            nuevaFila.lat_inicio = DataExcel[x][clave];
                                            break;
                                        case 'Y':
                                            nuevaFila.lng_inicio = DataExcel[x][clave];
                                            break;
                                        case 'Potencia':
                                            nuevaFila.potencia = DataExcel[x][clave];
                                            break;
                                        case 'Acometida':
                                            nuevaFila.acometida = DataExcel[x][clave];
                                            break;
                                        case 'Suministro Referencia':
                                            nuevaFila.sumReferencia = DataExcel[x][clave];
                                            break;
                                        case 'Acometida':
                                            nuevaFila.acometida = DataExcel[x][clave];
                                            break;
                                        case 'Fases':
                                            nuevaFila.fases = DataExcel[x][clave];
                                            break;

                                        default:
                                            //Buscar el valor en lista de Receta Materiales
                                            // let valor = this.state.ListaMaterialSEAL.filter(material => material.codigoGotop === clave);
                                            // if (valor.length > 0) {
                                            //     //Existe Material y Receta
                                            //     let recetaExiste = ListaReceta.filter(receta => receta === valor[0].subtipo);
                                            //     // if (recetaExiste.length === 0) {
                                            //     ListaReceta.push(valor[0].subtipo);
                                            //     ListaRecetaDetalle.push(valor[0]);
                                            //     // }
                                            // } else {
                                            //     //"Verificar Si Clave ya Existe en lista de Codigos no encontrados"
                                            //     let codigoExistente = listaCodigosNoExisten.filter(codigo => codigo === clave);
                                            //     if (codigoExistente.length === 0) {
                                            //         console.log("Codigo de receta sub naterial");
                                            //         console.log(clave);
                                            //         listaCodigosNoExisten.push(clave);
                                            //     }
                                            // }
                                            break;
                                    }
                                }
                            }

                            //Buscar Recetas
                            let MaterialesReceta = [];
                            let MaterialesProgramar = []
                            let arraytemporal = [];
                            // console.log(`Recetas Iniciales ${x+1}`);
                            // console.log(ListaReceta);

                            //************************************************ */
                            //VALIDAR RECETAS CONVINADAS
                            //************************************************ */
                            // let _tempListaReceta = [];
                            // console.log("Recetas para item Excel");
                            // console.log(ListaReceta);

                            // for (let n = 0; n < ListaReceta.length; n++) {
                            //     // console.log("Receta Buscada");
                            //     // console.log(ListaReceta[n]);

                            //     let nMaterialReceta = this.state.ListaMaterialesReceta.filter((plantillaReceta) => {
                            //         return (plantillaReceta.tipo_material_1 === ListaReceta[n] || plantillaReceta.tipo_material_2 === ListaReceta[n] || plantillaReceta.tipo_material_3 === ListaReceta[n]);
                            //     });
                            //     // console.log(`Materiañes Receta item ${n+1}`);
                            //     // console.log(nMaterialReceta);

                            //     if (nMaterialReceta.length > 1) {
                            //         // console.log("recetas encontradas");
                            //         // console.log(nMaterialReceta);
                            //         //existe una Convinacion

                            //         let indexMarch = -1;
                            //         let _tempMaterialReceta = [];
                            //         let cantMatchSup = 0;
                            //         // console.log(nMaterialReceta);
                            //         for (let w = 0; w < nMaterialReceta.length; w++) {
                            //             let cantMatch = 0;
                            //             _tempMaterialReceta = [];
                            //             // console.log(nMaterialReceta[w]);
                            //             if (nMaterialReceta[w].tipo_material_1 !== '') {
                            //                 _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_1);
                            //             }
                            //             if (nMaterialReceta[w].tipo_material_2 !== '') {
                            //                 _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_2);
                            //             }
                            //             if (nMaterialReceta[w].tipo_material_3 !== '') {
                            //                 _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_3);
                            //             }
                            //             // console.log(_tempMaterialReceta);
                            //             for (let z = 0; z < _tempMaterialReceta.length; z++) {
                            //                 let respRecetas = ListaReceta.filter(item => item === _tempMaterialReceta[z]);
                            //                 if (respRecetas.length > 0) {
                            //                     cantMatch++;
                            //                 }
                            //             }
                            //             if (_tempMaterialReceta.length === cantMatch) {
                            //                 //Verificar si el Valor es mayor al march Anterior
                            //                 if (cantMatch > cantMatchSup) {
                            //                     cantMatchSup = cantMatch;
                            //                     indexMarch = w;
                            //                 }
                            //             }
                            //         }
                            //         // console.log("Material con mas de una receta adjunta");
                            //         // console.log(nMaterialReceta[indexMarch]);
                            //         if (indexMarch > -1) {
                            //             //No se encontro
                            //             if (_tempListaReceta.filter(item => item === nMaterialReceta[indexMarch].tipo_material_1.concat(nMaterialReceta[indexMarch].tipo_material_2, nMaterialReceta[indexMarch].tipo_material_3)).length === 0) {
                            //                 _tempListaReceta.push(nMaterialReceta[indexMarch].tipo_material_1.concat(nMaterialReceta[indexMarch].tipo_material_2, nMaterialReceta[indexMarch].tipo_material_3));
                            //             }
                            //         } else {
                            //             //Material no encontrado
                            //             let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[n]);
                            //             if (_recetaNoExistente.length === 0) {
                            //                 listaRecetasNoEncontradas.push(ListaReceta[n]);
                            //                 listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[n]);
                            //             }
                            //         }
                            //     }
                            //     else {
                            //         if (nMaterialReceta.length > 0) {
                            //             //Se encontro solo una receta para este material
                            //             _tempListaReceta.push(nMaterialReceta[0].tipo_material_1.concat(nMaterialReceta[0].tipo_material_2, nMaterialReceta[0].tipo_material_3));
                            //         } else {
                            //             //No existe Receta de Materiales: Agregar a lista de No existentes
                            //             let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[n]);
                            //             if (_recetaNoExistente.length === 0) {
                            //                 listaRecetasNoEncontradas.push(ListaReceta[n]);
                            //                 listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[n]);
                            //             }
                            //         }
                            //     }
                            // }
                            // ListaReceta = _tempListaReceta;
                            // console.log("Recetas Revisada");
                            // console.log(ListaReceta);

                            //************************************************ */
                            //BUSCAR RECETA SEGUN CONVINACION(ID)
                            //************************************************ */
                            // for (let m = 0; m < ListaReceta.length; m++) {
                            //     // console.log(`Receta ${x+1}`);
                            //     // console.log(ListaReceta[x]);
                            //     let nMaterialReceta = this.state.ListaMaterialesReceta.filter(plantillaReceta => plantillaReceta.tipo_material_1.concat(plantillaReceta.tipo_material_2, plantillaReceta.tipo_material_3) === ListaReceta[m]);
                            //     // console.log(nMaterialReceta);
                            //     if (nMaterialReceta.length > 0) {
                            //         MaterialesReceta = arraytemporal.concat(nMaterialReceta[0].t_materialesreceta);
                            //         arraytemporal = MaterialesReceta;
                            //     }
                            //     else {
                            //         //No existe Plantilla para la Receta
                            //         let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[m]);
                            //         if (_recetaNoExistente.length === 0) {
                            //             listaRecetasNoEncontradas.push(ListaReceta[m]);
                            //             listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[m]);
                            //         }
                            //     }
                            // }
                            // console.log("Lista Materiales Inicial (Sin revisar)");
                            // console.log(MaterialesReceta);


                            //Agregando Materiales
                            //Acumular Materiales Existentes
                            // for (let z = 0; z < MaterialesReceta.length; z++) {
                            //     // console.log(nMaterialReceta[0].t_materialesreceta[z]);
                            //     let existe = false;
                            //     let indiceExiste = -1;
                            //     for (let w = 0; w < MaterialesProgramar.length; w++) {
                            //         // console.log(MaterialesProgramar[w]);
                            //         if (MaterialesProgramar[w].codigo === MaterialesReceta[z].codigo) {
                            //             existe = true;
                            //             indiceExiste = w;
                            //             break;
                            //         }
                            //     }
                            //     if (existe) {
                            //         //Acumular Material
                            //         MaterialesProgramar[indiceExiste].cantidad_receta = parseFloat(MaterialesProgramar[indiceExiste].cantidad_receta) + parseFloat(MaterialesReceta[z].cantidad_receta);
                            //     } else {
                            //         //agregar Material
                            //         if (parseFloat(MaterialesReceta[z].cantidad_receta) > 0) {
                            //             MaterialesProgramar.push(JSON.parse(JSON.stringify(MaterialesReceta[z])));
                            //         }
                            //         // else{
                            //         //     console.log("No Agregado sin Cantidad");
                            //         //     console.log(MaterialesReceta[z]);
                            //         // }
                            //     }
                            // }
                            // console.log("Materiales para trabajo(Agrupado)");
                            // console.log(MaterialesProgramar);

                            // nuevaFila.listaMateriales = MaterialesProgramar;
                            nuevaFila.listaMateriales = [];
                            if (this.state.idproyecto) {
                                nuevaFila.idproyecto = this.state.idproyecto;
                            } else {
                                nuevaFila.idproyecto = this.selectProyecto.current.value;
                            }

                            DataCargar.push(nuevaFila);
                        }
                        await this.setState({ DataCargar: DataCargar, CodigosNoEncontrados: listaCodigosNoExisten, RecetasNoEncontradas: listaRecetasNoEncontradas, RecetasNoEncontradasDetalle: listaRecetasNoEncontradasDetalle });
                        this.comprobarRecetas();

                        // }
                        // else {
                        //     await this.setState({
                        //         abrirToast: true, mensajeToast: `No se encontro Recetas de Materiales para el tipo de Trabajo ${this.state.tipotrabajoDetalle},
                        //     Genere la Plantilla  y vuelva a intentarlo`, tipotoast: 'warning', OcultarLoading: true
                        //     });
                        // }
                        // await  this.setState({MostrarPorcentajeCarga:false})
                        //Cargos los GPs de los suministros segun el filtro actual
                        // this.CargarGPS();

                    } else {
                        this.setState({ mensajeToast: "No se encontraron registros en el archivo", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }

                } catch (error) {
                    // console.log(error);
                    await this.setState({ abrirToast: true, mensajeToast: error.toString(), tipotoast: 'warning', OcultarLoading: true });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    comprobarRecetas = async () => {
        // console.log("Data enviar********************")
        // console.log(this.state.DataCargar);
        // console.log(this.state.CodigosNoEncontrados);
        // console.log(this.state.RecetasNoEncontradas);
        // console.log(this.state.RecetasNoEncontradasDetalle);
        // console.log("Send Data Test...")
        // console.log(this.state.DataCargar);

        let enviardata = true;
        let mensajeAlertas = "";
        if (this.state.CodigosNoEncontrados.length > 0) {
            //aqui se Enviaran los Datos al servidor para ser almacenados
            enviardata = false;
            mensajeAlertas = `Codigo de material no Existe en base de Materiales :[${this.state.CodigosNoEncontrados.toString()}]`;
        }
        if (this.state.RecetasNoEncontradas.length > 0) {
            enviardata = false;
            mensajeAlertas = mensajeAlertas + ` Tipos de  material sin Receta : [${this.state.RecetasNoEncontradas.toString()}]`;
        }
        if (enviardata === false) {
            //Mostrar mensaje para confirmar el tipo de programacion
            await this.setState({ MostrarModalMensajes: true, TextoMensajesModal: mensajeAlertas, TextoMensajesModalPregunta: '¿Desea cargar la programacion sin tomar en cuenta estas advertencias?', TituloMensajeModal: `Aviso` });
        }
        else {
            this.GuardarRecetas();
        }
    }
    GuardarRecetas = async () => {
        if (this.state.DataCargar.length > 0) {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/trabajosuministro/cargarprogramacion`;
            let programacionTrabajos = {
                dataProgramacion: this.state.DataCargar,
                fechaprogramacion: this.state.fechaProgramacion,
                idtipotrabajo: this.selectTipoTrabajo.current.value
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(programacionTrabajos)
            };
            // console.log(programacionTrabajos);

            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.BuscarProgramacionPorFecha();
                    await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
                }
                else {
                    data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'error' }) :
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                }
            }).catch(async (error) => {
                //Error de conexion
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        } else {
            // await this.setState({ MostrarModalMensajes: true, TextoMensajesModal: mensajeAlertas, TituloMensajeModal: `Corrija las Advertencias` });
            this.setState({ mensajeToast: "No se encontraron datos para Cargar Programación", abrirToast: true, tipotoast: 'error' });
        }
        await this.setState({ OcultarLoading: true });
    }
    ValidarDatosCargarGPS = () => {
        let errores = 0;
        let listaErrores = '';

        if (this.selectTipoTrabajo.current.value !== 'TODOS') {
            this.selectTipoTrabajo.current.classList.remove('is-invalid');
        }
        else {
            this.selectTipoTrabajo.current.classList.add('is-invalid');
            listaErrores += 'Seleccione el tipo de programación. ;'
            errores++;
        }
        if (moment(this.state.fechaProgramacion).isValid()) {
            // let fecha_hoy=moment();
            // let fechaprogra=moment(this.state.fechaProgramacion);
            // console.log(fecha_hoy.diff(fechaprogra,'minute'));
            // console.log(fechaprogra.diff(fecha_hoy,'minute'));
            this.inputFechaProgramacion.current.classList.remove('is-invalid');
        } else {
            this.inputFechaProgramacion.current.classList.add('is-invalid');
            listaErrores += 'La fecha establecida no es un formato valido. ;'
            errores++;

        }
        if (errores > 0) {
            this.setState({ abrirToast: true, mensajeToast: listaErrores, tipotoast: 'warning' });
            return false;
        }
        else {
            return true;
        }

    }
    CargarGPS = async () => {
        if (this.ValidarDatosCargarGPS()) {

            await this.BuscarListaSuministrosSinGPs().then();
            // console.log(this.state.ListaSuministrosSinGps);
            if (this.state.ListaSuministrosSinGps.length > 0) {
                await this.setState({ OcultarLoading: false });

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let url = `${config.endPoint}api/trabajosuministro/cargarGps`;
                let programacionTrabajos = {
                    fecha: this.state.fechaProgramacion,
                    idtipotrabajo: this.selectTipoTrabajo.current.value
                }
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(programacionTrabajos)
                };
                // console.log("send " +elem.suministro );
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    this.setState({ mensajeToast: "Los GPS estan siendo cargados en el servidor esto puede demorar, espere un momento y actualice esta pagina.", abrirToast: true, tipotoast: 'info' });
                }).catch((error) => {
                    this.setState({ mensajeToast: error.toString, abrirToast: true, tipotoast: 'warning' });
                });

            } else {
                this.setState({ mensajeToast: `Todos los suministros de esta programación tienen GPS asignado.`, abrirToast: true, tipotoast: 'info' });
            }
            this.setState({ OcultarLoading: true });
        }
    }
    // ***************************
    // Funciones adicioonales
    // ***************************
    BuscarListaTipoTrabajo = (idproyecto) => {
        if (ValidarUsuario()) {
            // let url = `${config.endPoint}api/tipotrabajo/listar`;

            let url = ``;
            if (this.state.idproyecto) {
                url = `${config.endPoint}api/tipotrabajo/listarProyecto/${this.state.idproyecto}`;
            } else {
                url = `${config.endPoint}api/tipotrabajo/listarProyecto/${idproyecto}`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TipoTrabajos: data.content });
                }
                else {
                    this.setState({ TipoTrabajos: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = ``;
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/trabajador/listar/${this.state.idproyecto}/ACTIVO`;
                } else {
                    url = `${config.endPoint}api/trabajador/listar/${this.selectProyecto.current.value}/ACTIVO`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador;
            }
        }
        return null;
    }
    // ***************************
    // Reasignar Trabajador
    // ***************************
    validarModalReasignarTrabajador = () => {
        let contador = 0;
        if (this.selectListaJUOEncontrados.current.value === '') {
            this.selectListaJUOEncontrados.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectListaJUOEncontrados.current.classList.remove('is-invalid');
        }
        if (this.inputReasignarTrabajador.current.value.trim() === '') {
            this.inputReasignarTrabajador.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.inputReasignarTrabajador.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarTecnico = () => {
        if (this.state.CantidadObservacionesTecnico > 0) {
            this.ReasignarProgramacionTecnico();
        }
        else {
            this.ReasignarID_Tecnico();
        }

    }
    ReasignarProgramacionTecnico = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarTrabajador()) {
                this.inputReasignarTrabajador.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarTrabajador.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Trabajador/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Trabajador/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        JUOProgramacion: this.selectListaJUOEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value,
                        idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaObservacionTecnicos().then(() => {
                                //Buscar Cantidad de elementos con error
                                this.ContarObservacionesTecnico();
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarTrabajador.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "JUO reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarTrabajador.current.value = '';
                    this.inputReasignarTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ReasignarID_Tecnico = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarTrabajador()) {
                this.inputReasignarTrabajador.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarTrabajador.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/Trabajador/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/Trabajador/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idtrabajadorreasignado: IdTrabajadorReasignado,
                        idpersonalasignado: this.selectListaJUOEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value,
                        idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaProgramacionTecnicos().then().catch(() => {
                                this.props.history.push('/login');
                            });
                            this.inputReasignarTrabajador.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Progracion JUO reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarTrabajador.current.value = '';
                    this.inputReasignarTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Reasignar Supervisor
    // ***************************
    validarModalReasignarSupervisor = () => {
        let contador = 0;
        if (this.selectSupervisorEncontrados.current.value === '') {
            this.selectSupervisorEncontrados.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectSupervisorEncontrados.current.classList.remove('is-invalid');
        }
        if (this.inputReasignarSupervidor.current.value.trim() === '') {
            this.inputReasignarSupervidor.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.inputReasignarSupervidor.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarSupervisor = () => {
        if (this.state.CantidadObservacionesSupervisor > 0) {
            this.ReasignarProgramacionSupervisor();
        }
        else {
            this.ReasignarID_Supervisor();
        }
    }
    ReasignarProgramacionSupervisor = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarSupervisor()) {
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarSupervidor.current.value);
                if (IdTrabajadorReasignado) {
                    this.inputReasignarSupervidor.current.classList.remove('is-invalid');
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Supervisor/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Supervisor/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        supervisor: this.selectSupervisorEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value,
                        idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaObservacionSupervisor().then(() => {
                                //Buscar Cantidad de elementos con error
                                this.ContarObservacionesSupervisor();
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarSupervidor.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Supervisor reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    })
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarSupervidor.current.value = '';
                    this.inputReasignarSupervidor.current.classList.add('is-invalid');
                }

            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    ReasignarID_Supervisor = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarSupervisor()) {
                this.inputReasignarSupervidor.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarSupervidor.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/supervisor/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/supervisor/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idtrabajadorreasignado: IdTrabajadorReasignado,
                        idpersonalasignado: this.selectSupervisorEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value,
                        idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaProgramacionSupervisor().then(() => {
                                //Buscar Cantidad de elementos con error
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarSupervidor.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Supervisor reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                }

                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarSupervidor.current.value = '';
                    this.inputReasignarSupervidor.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    //*************************************************
    //Actualizar Fecha, Cambiar Tipo de Trabajo
    //*************************************************
    ActualizarFecha = async () => {
        let nuevaFecha = moment(this.inputFechaProgramacion.current.value).format('YYYY-MM-DD');
        await this.setState({ fechaProgramacion: nuevaFecha, loadData: false });
        // await this.BuscarProgramacionPorFecha();
    }
    buscarTipoTrabajoDetalle = async () => {
        let detalleTrabajo = "";
        for (let x = 0; x < this.state.TipoTrabajos.length; x++) {
            if (this.selectTipoTrabajo.current.value.trim() !== "") {
                if (this.state.TipoTrabajos[x].idTipotrabajo.toString() === this.selectTipoTrabajo.current.value.toString()) {
                    detalleTrabajo = this.state.TipoTrabajos[x].tipotrabajo
                    break;
                }
            }
        }
        this.setState({ tipotrabajoDetalle: detalleTrabajo });
    }
    CambioTipoTrabajo = async () => {
        await this.setState({ loadData: false });
        await this.BuscarProgramacionPorFecha();
        await this.buscarTipoTrabajoDetalle();
    }
    CambioCheckMostrarTodosTecnicos = async () => {
        await this.setState({ mostrarTodosJuoObservacion: this.inputChekMostrarTodosTecnicos.current.checked });
    }
    CambioCheckMostrarTodosSupervisores = async () => {
        await this.setState({ mostrarTodosSupervisorObservacion: this.inputCheckBoxMostrarTodosSupervisores.current.checked });
    }
    CambiarColorTrabajo = () => {
        // console.log(this.selectElegirColorTrabajo.current.value);
        let bcolor = LIST_COLORES.filter(x => x.nombre === this.selectElegirColorTrabajo.current.value);
        this.setState({ color: bcolor[0].color, colorText: bcolor[0].textColor });
    }
    BuscarColorFondo = (color) => {
        let bcolor = LIST_COLORES.filter(x => x.nombre === color);
        return bcolor[0].color;
    }
    BuscarColorTexto = (color) => {
        let bcolor = LIST_COLORES.filter(x => x.nombre === color);
        return bcolor[0].textColor;
    }
    BuscarListaProgramacionTecnicosFecha = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.state.idproyecto}/${this.selectTipoEliminar.current.value}/${this.state.fechaProgramacion}`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data);
                    if (data.content) {
                        this.setState({ ListaTecnicosTipoTrabajo: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTecnicosTipoTrabajo: [] })
                        resolve(false);
                    }

                }).catch(() => {
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    BuscarListaProgramacionTecnicosFechaTipoTrabajo = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/listarSuministrosEliminar/${this.state.idproyecto}/${this.state.fechaProgramacion}/${this.selectTipoEliminar.current.value}/${this.selectTecnicoEliminar.current.value}`;

                // console.log(url);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaSuministrosEliminar: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaSuministrosEliminar: [] })
                        resolve(false);
                    }

                }).catch(() => {
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    SelectTipoTrabajoEliminar = async () => {
        //Limpiar datos de
        this.setState({ ListaSuministrosEliminar: [], ListaTecnicosTipoTrabajo: [] });
        //Buscar Lista de Tecnicos
        if (this.selectTipoEliminar.current.value !== "TODOS") {
            await this.BuscarListaProgramacionTecnicosFecha().then();
            // console.log(this.state.ListaTecnicosTipoTrabajo);
        }
        else {

        }
    }
    SeleccionarJuoEliminar = async () => {
        this.setState({ loadSumEliminar: false });
        await this.BuscarListaProgramacionTecnicosFechaTipoTrabajo().then();
        this.setState({ loadSumEliminar: true });
        // console.log(this.state.ListaSuministrosEliminar);
    }
    EliminarSuministrosFiltrados = () => {
        if (ValidarUsuario()) {
            if (this.selectTipoEliminar.current.value !== "TODOS") {
                this.selectTipoEliminar.current.classList.remove('is-invalid');
                if (window.confirm(`¿Estas seguro que deseas eliminar los trabajos filtrados actualmente ?`)) {
                    let EliminarTrabajoSuministro = {};
                    let url = '';
                    //Verificar si se eliminaran Todos los JUO
                    if (this.selectTecnicoEliminar.current.value === "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha/tipotrabajo`;
                        EliminarTrabajoSuministro = {
                            fechaProgramacion: this.state.fechaProgramacion,
                            idtipotrabajo: this.selectTipoEliminar.current.value,
                            idproyecto: this.state.idproyecto
                        }
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha/tipotrabajo/trabajador`;
                        EliminarTrabajoSuministro = {
                            fechaProgramacion: this.state.fechaProgramacion,
                            idtipotrabajo: this.selectTipoEliminar.current.value,
                            idpersonalasignado: this.selectTecnicoEliminar.current.value,
                            idproyecto: this.state.idproyecto
                        }
                    }

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(EliminarTrabajoSuministro)
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
                            //Buscar Lista de Tecnicos para tipo de trabajo
                            this.SelectTipoTrabajoEliminar();
                            //Actualizar Lista Suministros
                            await this.BuscarProgramacionPorFecha();
                            await this.BuscarListaTipoTrabajo();
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
            }
            else {
                this.selectTipoEliminar.current.classList.add('is-invalid');
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //Revisar TipoProgramacion
    ElegirTipoCargaProgramacion = (ev) => {
        this.setState({ tipoCargaProgramacionTrabajo: ev.target.value });
    }
    //Elegir Tipo Programacion
    CargarProgramacionTipo = async () => {
        //Default 'PROGRAMACION'
        // console.log(this.selectElegirTipoProgramacion.current);


        if (this.selectElegirTipoProgramacion.current) {
            if (this.selectElegirTipoProgramacion.current.value === 'Elija una Programación') {
                await this.setState({ tipoProgramacionTrabajo: '' });
                this.selectElegirTipoProgramacion.current.classList.add('is-invalid');
            }
            else {
                //Cargar Segun la Programacion Elegida(Cambiar el Estado de la Programacion por el tipo de trabajo)
                await this.setState({ tipoProgramacionTrabajo: this.selectElegirTipoProgramacion.current.value });
                this.CerrarModalElegirTipoProgramacion();
                this.CargarProgramacion()
            }

        } else {
            await this.setState({ tipoProgramacionTrabajo: 'PROGRAMACION' });
            this.CargarProgramacion();
        }
    }
    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    // console.log(data);
                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }
    CambiarProyecto = (ev) => {
        this.BuscarListaTipoTrabajo(ev.target.value)
    }
    // '**********************************************
    // 'MODALES
    // '**********************************************

    MostrarModalProgramarMapa = async () => {
        //Buscar Lista de Trabajos
        this.setState({ MostrarModalProgramarMapa: true });
    }
    CerrarModalProgramarMapa = () => {
        this.setState({ MostrarModalProgramarMapa: false });
    }
    AbrirModalValidarDataCargar = () => {
        this.setState({ MostrarModalValidarDataCargada: true });
    }
    CerrarModalValidarDataCargar = () => {
        this.setState({ MostrarModalValidarDataCargada: false });
    }
    AbrirModalDetalleRegistrosFiltro = () => {
        this.setState({ MostrarModalDetalleRegistrosFiltro: true });
    }
    CerrarModalDetalleRegistrosFiltro = () => {
        this.setState({ MostrarModalDetalleRegistrosFiltro: false });
    }
    AbrirModalTrabajadorInspeccionAp = async () => {
        // this.BuscarProgramacionActualInspecciones();

        this.BuscarListaTrabajadoresActivos()
        this.ListarPeriodosActuales();
        this.ListarModularesActuales();
        this.ListarDistritosActuales();
        //Buscar Programacion Inspecciones AP por fecha                
        await this.setState({ MostrarModalAgregarTrabajadorInspeccionAp: true, mostrarMensajeNoEncontrado: false });
    }
    CerrarModalTrabajadorInspeccionAp = () => {
        this.setState({ MostrarModalAgregarTrabajadorInspeccionAp: false });
        this.BuscarProgramacionActualInspecciones_v2();
    }

    //Modal Elegir Tipo Programacion
    MostrarModalElegirTipoProgramacion = () => {

        this.setState({ MostrarModalElegirTipoProgramacion: true });
    }
    CerrarModalElegirTipoProgramacion = () => {
        this.setState({ MostrarModalElegirTipoProgramacion: false });
    }
    //Modal Eliminar suministros
    MostrarModalEliminar = async () => {
        // if (this.state.idproyecto == null) {
        //     if (this.selectProyecto.current.value == '') {
        //         this.selectProyecto.current.classList.add('is-invalid');
        //         return false;
        //     }
        // }
        // //Buscar Lista de Trabajos
        // await this.BuscarListaTipoTrabajo();
        this.CerrarMenu();
        this.setState({ MostrarModalEliminar: true, ListaTecnicosTipoTrabajo: [], ListaSuministrosEliminar: [] });
    }


    AbrirModalDetalleProgramacionInspeccionesAp = (row) => {
        console.log(row);
        this.BuscarDetalleProgramacionActualInspecciones_v2(row.idtrabajador).then((respuesta) => {
            console.log(respuesta);
            if (respuesta) {
                this.setState({ MostrarModalDetalleProgramacionInspeccionesAp: true, OcultarLoading: true, programacionInspeccionApActual: row });
            } else {
                this.setState({ OcultarLoading: true, programacionInspeccionApActual: null });
            }
        });
    }
    CerrarModalDetalleProgramacionInspeccionesAp = () => {
        this.setState({ MostrarModalDetalleProgramacionInspeccionesAp: false });
        this.BuscarProgramacionActualInspecciones_v2();
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };
    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };
    //***************************************************** */
    //SELECCIONAT TIPO DE TRABAJO
    //***************************************************** */

    ElegirTipoActividadProgramar = () => {
        this.setState({ mostrarProgramacionInspecciones: false, mostrarGridDetalleProgramacion: false, mostrarInputCargarArchivo: false });
        switch (this.inputSelectElegirTrabajoProgramar.current.value) {
            case 'INSPECCIONES':
                this.setState({ mostrarProgramacionInspecciones: true, mostrarGridDetalleProgramacion: true, tipoProgramacionAp: 'INSPECCIONES' });
                // this.BuscarProgramacionActualInspecciones();    
                this.BuscarProgramacionActualInspecciones_v2();
                break;
            case 'SUBSANACIONES':
                this.setState({ tipoProgramacionAp: 'SUBSANACIONES' })
                break;
            case 'RECLAMOS':
                this.setState({ tipoProgramacionAp: 'RECLAMOS' })
                break;
        }
    }
    //***************************************************** */
    //HABILITAR CHECKS PROGRAMACION
    //***************************************************** */
    CheckBuscarPeriodo = (event) => {
        this.setState({ habilitarBusquedaPeriodo: event.target.checked });
    }
    CheckBuscarModular = (event) => {
        this.setState({ habilitarBusquedaModular: event.target.checked });
    }
    CheckBuscarDistrito = (event) => {
        this.setState({ habilitarBusquedaDistrito: event.target.checked });
    }
    //***************************************************** */
    //BUSQUEDA FILTROS
    //***************************************************** */
    ListarPeriodosActuales = () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });

            let url = '';
            url = `${config.endPoint}api/basepostesap/busquedas/periodos/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    this.setState({ arrayPeriodosActuales: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ arrayPeriodosActuales: null, OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ListarModularesActuales = () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });

            let url = '';
            url = `${config.endPoint}api/basepostesap/busquedas/modulares/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    this.setState({ arrayModularesActuales: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ arrayModularesActuales: null, OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ListarDistritosActuales = () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });

            let url = '';
            url = `${config.endPoint}api/basepostesap/busquedas/distritos/${this.state.idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content.length > 0) {
                    this.setState({ arrayDistritosActuales: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ arrayDistritosActuales: null, OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ValidarBusquedaFiltrosInspeccion = () => {
        let cantErrores = 0;
        if (this.inputSelectPeriodoSeleccionado.current.value === "") {
            this.inputSelectPeriodoSeleccionado.current.classList.add("is-invalid");
            cantErrores++;
        } else {
            this.inputSelectPeriodoSeleccionado.current.classList.remove("is-invalid");
        }

        if (cantErrores > 0) {
            return false;
        }
        return true;
    }
    BuscarPeriodosActuales = () => {
        if (ValidarUsuario()) {
            if (this.ValidarBusquedaFiltrosInspeccion()) {
                this.setState({ OcultarLoading: false, mostrarAlertFiltroInspecciones: false, elementosEncontradosFiltroInspecciones: null, mostrarMensajeNoEncontrado: false });

                // let url = `${config.endPoint}api/basepostesap/filtros/buscarfiltros/${this.state.idproyecto}`;
                let url = `${config.endPoint}api/basepostesap/filtros/buscarfiltrosDetalle/${this.state.idproyecto}`;

                let sendBody = {
                    PeriodoCarga: this.inputSelectPeriodoSeleccionado.current.value,
                    Modular: this.inputSelectModularSeleccionado.current.value,
                    Distrito: this.inputSelectDistritoSeleccionado.current.value,
                    checkModular: this.state.habilitarBusquedaModular,
                    checkDistrito: this.state.habilitarBusquedaDistrito
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(sendBody)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    console.log(data.content);
                    if (data.content) {
                        if (data.content.length > 0) {
                            this.setState({ elementosEncontradosFiltroInspecciones: data.content, OcultarLoading: true, mostrarAlertFiltroInspecciones: true, mostrarMensajeNoEncontrado: false })
                        } else {
                            this.setState({ elementosEncontradosFiltroInspecciones: null, OcultarLoading: true, mostrarMensajeNoEncontrado: true })
                        }
                    }
                    else {
                        this.setState({ elementosEncontradosFiltroInspecciones: null, OcultarLoading: true })
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarDetalleBusquedaInspeccionesFiltro = () => {
        if (ValidarUsuario()) {
            if (this.ValidarBusquedaFiltrosInspeccion()) {
                this.setState({ OcultarLoading: false });

                let url = `${config.endPoint}api/basepostesap/filtros/buscarfiltrosDetalle/${this.state.idproyecto}`;
                let sendBody = {
                    PeriodoCarga: this.inputSelectPeriodoSeleccionado.current.value,
                    Modular: this.inputSelectModularSeleccionado.current.value,
                    Distrito: this.inputSelectDistritoSeleccionado.current.value,
                    checkModular: this.state.habilitarBusquedaModular,
                    checkDistrito: this.state.habilitarBusquedaDistrito
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(sendBody)

                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ rowsDetalleRegistrosFiltroInspecciones: data.content, OcultarLoading: true, MostrarModalDetalleRegistrosFiltro: true })
                    }
                    else {
                        this.setState({ rowsDetalleRegistrosFiltroInspecciones: null, OcultarLoading: true })
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarPeriodosActuales = () => {
        if (ValidarUsuario()) {
            if (this.ValidarBusquedaFiltrosInspeccion()) {
                this.setState({ OcultarLoading: false });

                let url = `${config.endPoint}api/basepostesap/actualizar/filtrodetalle/${this.state.idproyecto}`;
                const fechaactual = new Date();
                let sendBody = {
                    PeriodoCarga: this.inputSelectPeriodoSeleccionado.current.value,
                    Modular: this.inputSelectModularSeleccionado.current.value,
                    Distrito: this.inputSelectDistritoSeleccionado.current.value,
                    checkModular: this.state.habilitarBusquedaModular,
                    checkDistrito: this.state.habilitarBusquedaDistrito
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(sendBody)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ mensajeToast: "Se completo acción.", abrirToast: true, tipotoast: 'success', OcultarLoading: true, mostrarAlertFiltroInspecciones: false, arrayRegistrosEncontradosFiltro: null });
                        this.BuscarProgramacionActualInspecciones_v2();
                    }
                    else {
                        this.setState({ OcultarLoading: true })
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarProgramacionActualInspecciones_v2 = () => {
        if (ValidarUsuario()) {
            this.setState({ loadData: false, OcultarLoading: false });
            let url = `${config.endPoint}api/programacioninspeccionap/buscarprogramacion/${this.state.idproyecto}/${this.state.fechaProgramacion}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                console.log(data.content);
                if (data.content) {
                    let DataTemp = [];
                    data.content.forEach((elemento, index) => {

                        DataTemp.push(
                            {
                                id: index,
                                fecha: elemento.fecha,
                                idtrabajador: elemento.idtrabajador,
                                modulares: elemento.modulares,
                                nombretrabajador: elemento.nombretrabajador,
                                cantidadElementos: elemento.cantidadElementos,
                                alimentador: elemento.alimentador
                            }
                        );
                    });
                    // console.log(DataTemp);
                    this.setState({ rowsProgramacionInspecciones: DataTemp, loadData: true, OcultarLoading: true })
                }
                else {
                    this.setState({ arrayProgramacionInspecciones: [], loadData: true, OcultarLoading: true })
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', loadData: true, OcultarLoading: true });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarDetalleProgramacionActualInspecciones_v2 = (idtrabajador) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                this.setState({ OcultarLoading: false });
                // Reasignar a los suministros Seleccionados
                let url = `${config.endPoint}api/programacioninspeccionap/buscarprogramaciondetalle/${this.state.idproyecto}/${this.state.fechaProgramacion}/${idtrabajador}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    console.log(data);
                    if (data.content) {
                        let DataTemp = [];
                        data.content.forEach((elemento, index) => {
                            DataTemp.push(
                                {
                                    id: index,
                                    idtrabajador: elemento.idtrabajador,
                                    nombretrabajador: elemento.nombretrabajador,
                                    fecha: elemento.fecha,
                                    Modular: elemento.Modular,
                                    AlimentadorMT: elemento.AlimentadorMT,
                                    cantidadelementos: elemento.cantidadelementos
                                }
                            );
                        });
                        console.log(DataTemp);
                        this.setState({ rowsDetalleProgramacionInspecciones: DataTemp, loadData: true })

                        resolve(true);
                    }
                    else {
                        this.setState({ rowsDetalleProgramacionInspecciones: [] })
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ rowsDetalleProgramacionInspecciones: [] });
                    reject(true);
                });
            }
            else {
                this.props.history.push('/login');
            }
        });
    }
    ValidarAgregarTrabajadorInspeccionesAP = () => {
        let cantError = 0;
        if (this.inputFechaProgramacionTrabajadorInspeccionAP.current.value == "") {
            cantError++;
            this.inputFechaProgramacionTrabajadorInspeccionAP.current.classList.add('is-invalid');
        } else {
            this.inputFechaProgramacionTrabajadorInspeccionAP.current.classList.remove('is-invalid');
        }

        if (this.inputNombreProgramacionTrabajadorInspeccionAp.current.value == "") {
            cantError++;
            this.inputNombreProgramacionTrabajadorInspeccionAp.current.classList.add("is-invalid");
        } else {
            this.inputNombreProgramacionTrabajadorInspeccionAp.current.classList.remove("is-invalid");
        }

        if (this.inputEstadpProgramacionTrabajadorInspeccionAp.current.value == "") {
            cantError++;
            this.inputEstadpProgramacionTrabajadorInspeccionAp.current.classList.add("is-invalid");
        } else {
            this.inputEstadpProgramacionTrabajadorInspeccionAp.current.classList.remove("is-invalid");
        }


        if (cantError > 0) {
            return false;
        }
        return true;
    }
    BuscarListaTrabajadoresPogramadosInspeccionAp = () => {
        if (ValidarUsuario()) {
            this.setState({ loadData: false });
            let url = `${config.endPoint}api/programaciontrabajadorinspeccionesap/listar/${this.state.idproyecto}/${this.state.fechaProgramacion}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ rowTrabajadorInspeccionAp: data.content, loadData: true })
                }
                else {
                    this.setState({ rowTrabajadorInspeccionAp: [], loadData: true })
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', loadData: true });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    AgregarTrabajadorProgramacionInspecionesAp = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarAgregarTrabajadorInspeccionesAP()) {

                let trabajadorEncontrado = await this.BuscarIdTrabajadorReasignado(this.inputNombreProgramacionTrabajadorInspeccionAp.current.value);

                if (trabajadorEncontrado) {
                    // console.log(actividadEncontrada);
                    this.setState({ OcultarLoading: false });

                    let url = `${config.endPoint}api/programacioninspeccionap/agregarmasivo`;

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let baseProgramacion = [];
                    this.state.elementosEncontradosFiltroInspecciones.forEach((elemento, index) => {
                        baseProgramacion.push({
                            fecha: this.inputFechaProgramacionTrabajadorInspeccionAP.current.value,
                            estado: this.inputEstadpProgramacionTrabajadorInspeccionAp.current.value,
                            idbasepostesap: elemento.id,
                            idtrabajador: trabajadorEncontrado.idtrabajador,
                            usuarioregistra: trabajadorEncontrado.idtrabajador,
                            idproyecto: this.state.idproyecto
                        });

                    });

                    let arrayProgramacionPartes = dividirArrayPartes(baseProgramacion, 5000);
                    console.log(arrayProgramacionPartes);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            fecha: this.inputFechaProgramacionTrabajadorInspeccionAP.current.value,
                            periodo: this.inputSelectPeriodoSeleccionado.current.value,
                            modular: this.inputSelectModularSeleccionado.current.value,
                            distrito: this.inputSelectDistritoSeleccionado.current.value,
                            listaBaseProgramacion: arrayProgramacionPartes
                        })
                    };

                    //Dividir array en elementos de maximo 3mil 

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            // this.inputNombreProgramacionTrabajadorInspeccionAp.current.value = "";
                            this.inputSelectModularSeleccionado.current.value = "";
                            this.setState({ mensajeToast: "Registro Agregado", abrirToast: true, tipotoast: 'success', OcultarLoading: true, elementosEncontradosFiltroInspecciones: null });
                            //Buscar Programacion por trabajador                            
                        }
                        else {
                            this.setState({ mensajeToast: "No Agregado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                        }
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    EliminarTrabajadorProgramacionInspecionesAp = async (idprogramacionPersonalInspeccionAp) => {
        if (ValidarUsuario()) {
            if (window.confirm("¿Esta seguro de eliminar el registro?")) {

                // console.log(actividadEncontrada);
                this.setState({ OcultarLoading: false });

                let url = `${config.endPoint}api/programaciontrabajadorinspeccionesap/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        'idprogramacionPersonalInspeccionAp': idprogramacionPersonalInspeccionAp,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {

                    if (data.content) {
                        this.setState({ mensajeToast: "Registro Eliminado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                        this.BuscarListaTrabajadoresPogramadosInspeccionAp();
                    }
                    else {
                        this.setState({ mensajeToast: "No eliminado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarTrabajadorProgramacionInspecionesAp = async (idprogramacionPersonalInspeccionAp, estado) => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });

            let url = `${config.endPoint}api/programaciontrabajadorinspeccionesap/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            console.log(estado);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idprogramacionPersonalInspeccionAp': idprogramacionPersonalInspeccionAp,
                    'estado': estado ? 'ACTIVO' : 'INACTIVO',
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                    this.BuscarListaTrabajadoresPogramadosInspeccionAp();
                }
                else {
                    this.setState({ mensajeToast: "No modificado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarFechaProgramacionInspeccionAP = async (ev) => {
        console.log(ev.currentTarget.value);
        let fecha = ev.currentTarget.value;
        await this.setState({ fechaProgramacion: moment(fecha).format('YYYY-MM-DD'), elementosEncontradosFiltroInspecciones: null });
        this.BuscarProgramacionActualInspecciones_v2();
    }
    EliminarModularProgramacionInspeccionAp = (row) => {
        console.log(row);

        if (ValidarUsuario()) {
            if (window.confirm("¿Esta seguro de eliminar la programacion de este Modular?")) {

                // console.log(actividadEncontrada);
                this.setState({ OcultarLoading: false });

                let url = `${config.endPoint}api/programacioninspeccionap/modulares/eliminarprogramacion`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        fecha: row.fecha,
                        idproyecto: this.state.idproyecto,
                        idtrabajador: row.idtrabajador,
                        modular: row.Modular
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {

                    if (data.content) {
                        this.setState({ mensajeToast: "Registro Eliminado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                        // this.BuscarListaTrabajadoresPogramadosInspeccionAp();
                        this.BuscarDetalleProgramacionActualInspecciones_v2(row.idtrabajador);
                    }
                    else {
                        this.setState({ mensajeToast: "No eliminado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarProgramacionPorFecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarProgramacionPorFecha();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />

                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="Programacion Trabajos Alumbrado Publico" regresar={() => { this.props.history.push('/alumbradoPublico') }}></TituloPagina>
                {/* ***************************** */}
                {/* Alerts */}
                {/* ***************************** */}

                <Container className='mt-2'>
                    {/* <AlertBootstrap variant="danger" onClose={() => { this.setState({ mostrarAlertErrrSupervisor: false }) }} dismissible >
                        <AlertBootstrap.Link href="#">Corregir</AlertBootstrap.Link>
                        <span className="mx-1"> {`Los nombres de ${this.state.CantidadObservacionesTecnico} JUO asignados en la programacion no coinciden con la base de trabajadores. Reasigne los JUO a los nombres que existan en la base de Trabajadores.`}</span>
                    </AlertBootstrap>

                    <AlertBootstrap variant="warning" onClose={() => { this.setState({ mostrarAlertErrrSupervisor: false }) }} dismissible >
                        <AlertBootstrap.Link href="#" >Corregir</AlertBootstrap.Link>
                        <span className="mx-1">{` Los nombres de ${this.state.CantidadObservacionesSupervisor} SUPERVISOR(ES) asignados en la programacion no coinciden con la base de trabajadores. Reasigne los SUPERVISORES a los nombres que existan en la base de Trabajadores.`}</span>
                    </AlertBootstrap> */}

                </Container>


                {/* ***************************** */}
                {/* Inicio de Controles */}
                {/* ***************************** */}

                {/* Contenedor de Datos */}

                <Container className='mt-1 border'>
                    <Row className='mt-1'>
                        <Col md={4}>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Fecha</InputGroup.Text>
                                <Form.Control type='date' size='sm' defaultValue={this.state.fechaProgramacion} onChange={(ev) => { this.ActualizarFechaProgramacionInspeccionAP(ev) }}></Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Tipo Trabajo</InputGroup.Text>

                                <Form.Select ref={this.inputSelectElegirTrabajoProgramar} placeholder='Seleccione el estado' size='sm' onChange={() => { this.ElegirTipoActividadProgramar() }}>
                                    <option value="">Elija un elemento</option>
                                    <option value="INSPECCIONES">INSPECCIONES</option>
                                    <option value="SUBSANACIONES">SUBSANACIONES</option>
                                    <option value="RECLAMOS">RECLAMOSS</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>

                        <Col className="text-end" >
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >

                                {
                                    this.state.tipoProgramacionAp === 'INSPECCIONES' ?
                                        <MenuItem onClick={() => { this.AbrirModalTrabajadorInspeccionAp() }}>Asignar Trabajador Inspecciones AP</MenuItem>
                                        : <></>
                                }

                            </Menu>
                        </Col>
                    </Row>

                    <Divider className='my-2'></Divider>

                    {
                        this.state.mostrarProgramacionInspecciones ?
                            <>
                                <Row className='mt-2'>
                                    <Col >
                                        <Button variant='primary' size='sm' onClick={() => { this.AbrirModalTrabajadorInspeccionAp() }}>
                                            {`Asignar Programación`}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                            : <></>
                    }
                    {
                        this.state.mostrarInputCargarArchivo ?
                            <Row className='mt-1'>
                                <Col md={6}>
                                    <Form.Control type="file" size='sm' />
                                </Col>
                                <Col md={6}>
                                    <Button variant="outline-primary" size='sm' onClick={() => { this.AbrirModalValidarDataCargar() }}>Cargar Archivo</Button>
                                </Col>
                            </Row>
                            : <></>
                    }

                    <Divider className='mt-2'></Divider>
                    {/* Grid */}
                    {
                        this.state.mostrarGridDetalleProgramacion ?
                            this.state.loadData ?
                                <>
                                    {
                                        this.state.rowTrabajadorInspeccionAp.length > 0 ?
                                            <Row>
                                                <Col>
                                                    <small className='text-secondary'>{`${this.state.rowTrabajadorInspeccionAp.length} Trabajadores asignados para ${this.state.fechaProgramacion}`}</small>
                                                </Col>
                                            </Row>
                                            : <></>
                                    }
                                    <Row className='my-2'>
                                        <div className='mt-1' style={{ height: `60vh`, width: '100%' }}>
                                            <DataGrid
                                                density='compact'
                                                rows={this.state.rowsProgramacionInspecciones}
                                                columns={this.state.columnsProgramacionInspecciones}
                                                pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                                rowsPerPageOptions={[20, 50, 100]}
                                                // checkboxSelection
                                                localeText={esES.props.MuiDataGrid.localeText}
                                                onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                                            />
                                        </div>
                                    </Row>
                                </>
                                : <SpinnerCarga />

                            : <></>
                    }
                </Container>


                {/* Modales */}
                {/* Modal Detalle Registros Filtro*/}
                <Modal
                    show={this.state.MostrarModalDetalleRegistrosFiltro}
                    onHide={() => { this.CerrarModalDetalleRegistrosFiltro() }}
                    keyboard={false}
                    centered
                    size='xl'>

                    <Modal.Header>
                        <Modal.Title>Detalle</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row className='mb-2'>
                                <div className='mt-1' style={{ height: `500px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowsDetalleRegistrosFiltroInspecciones}
                                        columns={this.state.columnsDetalleRegistrosFiltroInspecciones}
                                        pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                        onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                                    />
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant="outline-primary" size='sm'>Cancelar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Agregar Trabajaodores a Programacion InspeccionAP */}
                <Modal
                    show={this.state.MostrarModalAgregarTrabajadorInspeccionAp}
                    onHide={() => { this.CerrarModalTrabajadorInspeccionAp() }}
                    keyboard={false}
                    centered
                    size='lg'>

                    <Modal.Header>
                        <Modal.Title>Programar Trabajador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={5}>
                                    <Form.Label className="text-primary" >Fecha</Form.Label>
                                </Col>
                                <Col md={5}>
                                    <Form.Label className="text-primary" >Periodo</Form.Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={5}>
                                    <Form.Control ref={this.inputFechaProgramacionTrabajadorInspeccionAP} type='date' size='sm' defaultValue={this.state.fechaProgramacion} onChange={(ev) => { this.ActualizarFechaProgramacionInspeccionAP(ev) }}></Form.Control>
                                </Col>

                                <Col md={5}>
                                    <InputGroup size='sm'>
                                        <InputGroup.Checkbox onChange={(v) => { this.CheckBuscarPeriodo(v) }} defaultChecked={this.state.habilitarBusquedaPeriodo} />
                                        {/* <InputGroup.Text >Periodo</InputGroup.Text> */}
                                        <Form.Control ref={this.inputSelectPeriodoSeleccionado} list='listaperiodos' size='sm' placeholder='Ingrese o seleccione el periodo.' disabled={!this.state.habilitarBusquedaPeriodo} onChange={() => { this.setState({ mostrarAlertFiltroInspecciones: false, elementosEncontradosFiltroInspecciones: null }) }}></Form.Control>
                                        <datalist id="listaperiodos">
                                            {
                                                this.state.arrayPeriodosActuales ?
                                                    this.state.arrayPeriodosActuales.map((row, index) => {
                                                        return (<option key={index} value={row.PeriodoCarga}>{row.PeriodoCarga}</option>)
                                                    })
                                                    : <></>
                                            }
                                        </datalist>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={5}>
                                    <Form.Label className="text-primary" >Modular</Form.Label>
                                </Col>
                                <Col md={5}>
                                    <Form.Label className="text-primary" >Distrito</Form.Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col md={5}>
                                    <InputGroup size='sm'>
                                        <InputGroup.Checkbox onChange={(v) => { this.CheckBuscarModular(v) }} defaultChecked={this.state.habilitarBusquedaModular} />
                                        <Form.Control ref={this.inputSelectModularSeleccionado} list='listamodular' size='sm' placeholder='Ingrese o seleccione el modular.' disabled={!this.state.habilitarBusquedaModular} onChange={() => { this.setState({ mostrarAlertFiltroInspecciones: false, elementosEncontradosFiltroInspecciones: null }) }}></Form.Control>
                                        <datalist id="listamodular">
                                            {
                                                this.state.arrayModularesActuales ?
                                                    this.state.arrayModularesActuales.map((row, index) => {
                                                        return (<option key={index} value={row.Modular}>{row.Modular}</option>)
                                                    })
                                                    : <></>
                                            }
                                        </datalist>
                                    </InputGroup>
                                </Col>
                                <Col md={5}>
                                    <InputGroup size='sm'>
                                        <InputGroup.Checkbox onChange={(v) => { this.CheckBuscarDistrito(v) }} defaultChecked={this.state.habilitarBusquedaDistrito} />
                                        <Form.Control ref={this.inputSelectDistritoSeleccionado} list='listadistrito' size='sm' placeholder='Ingrese o seleccione el Distrito.' disabled={!this.state.habilitarBusquedaDistrito} onChange={() => { this.setState({ mostrarAlertFiltroInspecciones: false, elementosEncontradosFiltroInspecciones: null }) }}></Form.Control>
                                        <datalist id="listadistrito">
                                            {
                                                this.state.arrayDistritosActuales ?
                                                    this.state.arrayDistritosActuales.map((row, index) => {
                                                        return (<option key={index} value={row.Distrito}>{row.Distrito}</option>)
                                                    })
                                                    : <></>
                                            }
                                        </datalist>
                                    </InputGroup>
                                </Col>
                                <Col md={2} className='text-end'>
                                    <Button size='sm' onClick={() => { this.BuscarPeriodosActuales() }}>
                                        <i className="fas fa-search mx-2"></i>
                                        Buscar
                                    </Button>
                                </Col>

                            </Row>
                            {
                                this.state.elementosEncontradosFiltroInspecciones ?
                                    <Row className='mt-2'>
                                        <Col>
                                            <AlertBootstrap show={this.state.mostrarAlertFiltroInspecciones} variant="success" dismissible onClose={() => { this.setState({ mostrarAlertFiltroInspecciones: false }) }}>
                                                <Container fluid>
                                                    <Row>

                                                        <Col sm={12} md={9}>
                                                            <span>Se encontraron </span><AlertBootstrap.Link href="#" onClick={(e) => { e.preventDefault(); this.BuscarDetalleBusquedaInspeccionesFiltro(); }}>{`${this.state.elementosEncontradosFiltroInspecciones.length} elementos`}</AlertBootstrap.Link>
                                                            <span className="mx-1">{` registros para la busqueda actual . ¿Desea agregar estos elementos?`}</span>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </AlertBootstrap>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                            {
                                this.state.mostrarMensajeNoEncontrado ?
                                    <Row className='mt-2'>
                                        <Col>
                                            <AlertBootstrap show={true} variant="warning" dismissible onClose={() => { this.setState({ mostrarAlertFiltroInspecciones: false }) }}>
                                                <Container fluid>
                                                    <Row>

                                                        <Col sm={12} md={9}>
                                                            <span className="mx-1">{`No se encontraron registros para la busqueda actual .`}</span>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </AlertBootstrap>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                            <Divider className='mt-2'></Divider>
                            <Row>
                                <Col>
                                    <Form.Label className="text-primary">Trabajador</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Form.Control ref={this.inputNombreProgramacionTrabajadorInspeccionAp} list='listatrabajadores' size='sm' placeholder='Ingrese el nombre de trabajador'></Form.Control>
                                    <datalist id="listatrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.nombretrabajador}></option>
                                                )
                                            })
                                        }
                                    </datalist>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="text-primary">Estado</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Form.Select defaultValue={`ACTIVO`} ref={this.inputEstadpProgramacionTrabajadorInspeccionAp} size='sm'>
                                        <option value=''>Elegir Elemento</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    {
                                        this.state.OcultarLoading ?
                                            <Button disabled={this.state.elementosEncontradosFiltroInspecciones ? false : true} size='sm' onClick={() => { this.AgregarTrabajadorProgramacionInspecionesAp() }}>
                                                Agregar
                                            </Button>
                                            :
                                            <Spinner animation="border" size="sm" className='mx-2' />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant="outline-primary" size='sm' onClick={() => { this.CerrarModalTrabajadorInspeccionAp() }}>

                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Ver Detalle Programacion*/}
                <Modal
                    show={this.state.MostrarModalDetalleProgramacionInspeccionesAp}
                    onHide={() => { this.CerrarModalDetalleProgramacionInspeccionesAp() }}
                    keyboard={false}
                    centered
                    size='lg'>

                    <Modal.Header>
                        <Modal.Title>Detalle</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className='text-primary'>{`Fecha`}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type='text' readOnly defaultValue={this.state.programacionInspeccionApActual ? this.state.programacionInspeccionApActual.fecha : ""}></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className='text-primary'>{`Trabajador`}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type='text' readOnly defaultValue={this.state.programacionInspeccionApActual ? this.state.programacionInspeccionApActual.nombretrabajador : ""}></Form.Control>
                                </Col>
                            </Row>
                            <Divider className='mt-2'></Divider>
                            <Row className='mb-2'>
                                <div className='mt-1' style={{ height: `300px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowsDetalleProgramacionInspecciones}
                                        columns={this.state.columDetalleProgramacionInspeccioAp}
                                        pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                        onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                                    />
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant="outline-primary" size='sm' onClick={() => { this.CerrarModalDetalleProgramacionInspeccionesAp() }}>Cerrar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Fragment >
        );
    }
}

export default programacionAp;