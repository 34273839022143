import React, { Fragment, Component } from "react";
// import { Document, Page, View, Text, Image } from '@react-pdf/renderer';



class PdfVista extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return (
            // <Document>
            //     <Page size={"A4"}>
            //         {/* <View className='component'>
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Suministro:</Text>
            //                 </View>
            //             </View>
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Cliente:</Text>
            //                 </View>
            //             </View>
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Dirección:</Text>
            //                 </View>
            //             </View>
                        
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Detalle Trabajo:</Text>
            //                 </View>
            //             </View>
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Estado:</Text>
            //                 </View>
            //                 <View className='col'>
            //                     <Text>Fecha Ejecución:</Text>
            //                 </View>
            //             </View>
            //             <View className='row'>
            //                 <View className='col'>
            //                     <Text>Observaciones:</Text>
            //                 </View>
            //             </View>
                        
            //             <View className='row'>
            //                 <View className='col text-center'>
            //                     <Text>Fotos:</Text>
            //                     <Image width='300' src='http://192.168.10.15:8080/api/foto/DownloadFileServer/191097_20230122_161739.jpg'></Image>
            //                 </View>
            //             </View>
            //         </View> */}

            //         <Text>Fotos:</Text>
            //         <Image width='300' src='http://192.168.10.15:8080/api/foto/DownloadFileServer/191097_20230122_161739.jpg'></Image>

            //     </Page>
            // </Document>
            <></>
        )
    }
}

export default PdfVista;