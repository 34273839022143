import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar'
import TituloPagina from './../dashboard/headerTitle'

import LoadingModal from './../utils/loadModal';
import SpinnerCarga from './../utils/spinner';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
// Bootstrap
import { Modal, Container, Row, Col, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from './../utils/utils';
import config from './../../config/config';

class configTipoTrabajo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            MostrarModalAgregar: false,
            MostrarModalActividadTipoTrabajo: false,
            MostrarModalMaterialTipoTrabajo: false,
            AccionTipoTrabjo: "",
            TipoTrabajos: [],
            TipoTrabajo: null,
            ListaActividades: [],
            listaMateriales: [],
            ActividadTipoTrabajos: [],
            MaterialTipoTrabajos: [],
            cantidadElementos: 0,
            abrirToast: false,
            loadData: false,
            nombrebuscar: '',
            idproyecto: null,
            listaProyectosActivos: [],
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Descripción' },
            { id: 'Actividades Incluidas' },
            { id: 'Materiales Incluidos' },
            { id: 'Proyecto' },
            { id: 'Estado' }
        ];
        // Referencias
        this.inputBuscar = createRef();
        // Referencias Modal Agregar
        this.inputDescripcionTipoTrabajo = createRef();
        this.inputCantidadMinFotos = createRef();
        this.selectEstadoTipoTrabajo = createRef();
        this.buttonGuardarTipoTrabajo = createRef();
        this.buttonModificarTipoTrabajo = createRef();

        this.selectHabilitarFotoAntes = createRef();
        this.selectHabilitarFotoDespues = createRef();
        this.selectHabilitarAgregarActividad = createRef();
        this.selectHabilitarAgregarMaterial = createRef();
        this.selectHabilitarAgregarLectura = createRef();
        this.selectHabilitarAgregarObservacion = createRef();

        // Referencias Modal Material Tipo Trabajo
        this.inputTipoTrabajoMaterial = createRef();
        this.inputMaterialTipoTrabajo = createRef();
        this.buttonAgregarMaterialTipoTrabajo = createRef();
        this.selectProyecto = createRef();

        // Referencias Modal Actividad Tipo Trabajo
        this.inputTipoTrabajoActividad = createRef();
        this.inputActividadTipoTrabajo = createRef();
        this.buttonAgregarActividadTipoTrabajo = createRef();

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_TIPOTRABAJO, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Cargar Tipos de Trabajo
                    await this.BuscarTiposTrabajo();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ************************************
    // Funciones tipoTrabajo
    // ************************************
    BuscarTiposTrabajo = () => {
        if (ValidarUsuario()) {

            let url;
            if (this.state.nombrebuscar.trim() === '') {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/tipotrabajo/listarTodos/${this.state.idproyecto}`;
                } else {
                    url = `${config.endPoint}api/tipotrabajo/listarTodos`;
                }
            }
            else {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/tipotrabajo/listar/${this.state.idproyecto}/${this.state.nombrebuscar}`;
                } else {
                    url = `${config.endPoint}api/tipotrabajo/listar/${this.state.nombrebuscar}`;
                }
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if (data.content) {
                    this.setState({ TipoTrabajos: data.content, loadData: true });
                }
                else {
                    this.setState({ TipoTrabajos: [], loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarTipoTrabajo = (idtipotrabajo) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/tipotrabajo/${idtipotrabajo}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ TipoTrabajo: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ TipoTrabajo: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    ActualizarTipoTrabajo = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/modificar`
            let nuevoTipoTrabajo = {
                idTipotrabajo: this.state.TipoTrabajo.idTipotrabajo,
                tipotrabajo: this.inputDescripcionTipoTrabajo.current.value.toUpperCase(),
                minfotos: this.inputCantidadMinFotos.current.value.toUpperCase(),
                estado: this.selectEstadoTipoTrabajo.current.value.toUpperCase(),
                habilitarfotoantes:this.selectHabilitarFotoAntes.current.checked,
                habilitarfotodespues:this.selectHabilitarFotoDespues.current.checked,
                habilitaractividad:this.selectHabilitarAgregarActividad.current.checked,
                habilitarmaterial:this.selectHabilitarAgregarMaterial.current.checked,
                habilitarlectura:this.selectHabilitarAgregarLectura.current.checked,
                habilitaragregarobservacion:this.selectHabilitarAgregarObservacion.current.checked
            }            
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoTipoTrabajo)
            };
            
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Tipo de Trabajo actualizado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarTiposTrabajo();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    AgregarTipoTrabajo = () => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/tipotrabajo/agregar`
            let nuevoTipoTrabajo = {
                tipotrabajo: this.inputDescripcionTipoTrabajo.current.value.toUpperCase(),
                minfotos: this.inputCantidadMinFotos.current.value.toUpperCase(),
                estado: this.selectEstadoTipoTrabajo.current.value.toUpperCase(),
                idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoTipoTrabajo)
            };
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Tipo de Trabajo Agregado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarTiposTrabajo();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    GuardarTipoTrabajo = () => {
        // ev.preventDefault();
        switch (this.state.AccionTipoTrabjo) {
            case 'Ver':
                this.ActualizarTipoTrabajo();
                break;
            case 'Agregar':
                this.AgregarTipoTrabajo();
                break;
            default:
        }
    }
    DeshabilitarModalTipoTrabajo = (valor) => {
        this.inputDescripcionTipoTrabajo.current.readOnly = valor;
        this.inputCantidadMinFotos.current.readOnly = valor;
        this.selectEstadoTipoTrabajo.current.disabled = valor;
        this.selectHabilitarFotoAntes.current.disabled = valor;
        this.selectHabilitarFotoDespues.current.disabled = valor;
        this.selectHabilitarAgregarActividad.current.disabled = valor;
        this.selectHabilitarAgregarMaterial.current.disabled = valor;
        this.selectHabilitarAgregarLectura.current.disabled = valor;
        this.selectHabilitarAgregarObservacion.current.disabled = valor;

        if (this.state.idproyecto == null) {
            this.selectProyecto.current.disabled = true;
        }
    }
    ModificarTipoTrabajo = () => {
        this.DeshabilitarModalTipoTrabajo(false);
        this.buttonGuardarTipoTrabajo.current.disabled = false;
        this.buttonModificarTipoTrabajo.current.disabled = true;
    }
    // ************************************
    // Funciones Actividad
    // ************************************
    BuscarListaActividades = (idproyecto) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = ``;
                url = `${config.endPoint}api/baseactividad/listar/${idproyecto}`;
                // if(this.state.idproyecto){
                // }else{
                //     url = `${config.endPoint}api/baseactividad/listar`;
                // }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaActividades: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaActividades: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    buscarCodigoActividadLista = (descripcion_actividad) => {
        for (const actividad of this.state.ListaActividades) {
            if (descripcion_actividad.indexOf(actividad.descripcion) > -1) {
                return actividad.idBaseActividad;
            }
        }
        return -1;
    }
    AgregarActividadTipoTrabajo = async () => {
        // Buscar codigo de  Material en lista de Materiales
        if (ValidarUsuario()) {
            if (this.inputActividadTipoTrabajo.current.value.trim() !== '') {
                this.inputActividadTipoTrabajo.current.classList.remove('is-invalid');
                let codigoActividad = await this.buscarCodigoActividadLista(this.inputActividadTipoTrabajo.current.value)
                if (codigoActividad !== -1) {
                    let url = `${config.endPoint}api/actividadtipotrabajo/agregar`;
                    let body_actividadtipoTrabajo = {
                        idbaseactividad: codigoActividad,
                        idtipotrabajo: this.state.TipoTrabajo.idTipotrabajo,
                        estado: 'ACTIVO'
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(body_actividadtipoTrabajo)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.content) {
                            this.inputActividadTipoTrabajo.current.value = '';
                            this.setState({ abrirToast: true, mensajeToast: "Actividad Agregada al Tipo de trabajo.", tipotoast: 'success' });
                            this.BuscarListaActividadTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }

                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
                else {
                    this.inputActividadTipoTrabajo.current.classList.add('is-invalid');
                    this.inputActividadTipoTrabajo.current.value = '';
                    this.setState({ abrirToast: true, mensajeToast: "Debe elegir un elemento de la lista de Actividades.", tipotoast: 'warning' });
                }
            }
            else {
                this.inputActividadTipoTrabajo.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Elija un elemento de la lista de Actividades.", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaActividadTipoTrabajo = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/actividadtipotrabajo/actividades/${this.state.TipoTrabajo.idTipotrabajo}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ActividadTipoTrabajos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ActividadTipoTrabajos: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    CambiarEstadoActividadTipoTrabajo = (idActividadTipoTrabajo, value) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/actividadtipotrabajo/modificar`;
            let bodyActividadTipoTrabajo = {
                idActividadTipoTrabajo: idActividadTipoTrabajo,
                estado: value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(bodyActividadTipoTrabajo)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Estado de Actividad Actualizado.", tipotoast: 'success' });
                    this.BuscarListaActividadTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    EliminarActividadTipoTrabajo = (idactividadtipotrabajo, descripcion) => {
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas seguro de Eliminar la Actividad ${descripcion}?`)) {
                let url = `${config.endPoint}api/actividadtipotrabajo/eliminar`;
                let bodyActividadTipoTrabajo = {
                    idActividadTipoTrabajo: idactividadtipotrabajo
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(bodyActividadTipoTrabajo)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Actividad quidata de tipo Trabajo", tipotoast: 'success' });
                        this.BuscarListaActividadTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    // ************************************
    // Funciones Material
    // ************************************
    BuscarListaMateriales = (idproyecto) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = ``;
                url = `${config.endPoint}api/basematerial/listar/${idproyecto}`;

                // if(this.state.idproyecto){
                // }else{
                //     url = `${config.endPoint}api/basematerial/listar`;
                // }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ listaMateriales: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ listaMateriales: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    buscarCodigoMaterialLista = (descripcion_material) => {
        console.log(descripcion_material);
        for (const material of this.state.listaMateriales) {
            let materialEnLista = `[${material.codigoGotop}] -> ${material.descripcion}`
            console.log(materialEnLista);
            if (descripcion_material.indexOf(materialEnLista) > -1) {
                console.log(material);
                return material.idBaseMaterial;
            }
        }
        return -1;
    }
    AgregarMaterialTipoTrabajo = async () => {
        // Buscar codigo de  Material en lista de Materiales
        if (ValidarUsuario()) {
            if (this.inputMaterialTipoTrabajo.current.value.trim() !== '') {
                this.inputMaterialTipoTrabajo.current.classList.remove('is-invalid');
                let codigoMaterial = await this.buscarCodigoMaterialLista(this.inputMaterialTipoTrabajo.current.value)
                console.log(codigoMaterial)
                if (codigoMaterial !== -1) {
                    let url = `${config.endPoint}api/materialtipotrabajo/agregar`;
                    let body_materialtipoTrabajo = {
                        idbasematerial: codigoMaterial,
                        idtipotrabajo: this.state.TipoTrabajo.idTipotrabajo,
                        estado: 'ACTIVO'
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(body_materialtipoTrabajo)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.content) {
                            this.inputMaterialTipoTrabajo.current.value = '';
                            this.setState({ abrirToast: true, mensajeToast: "Material Agregado al Tipo de trabajo.", tipotoast: 'success' });
                            this.BuscarListaMaterialTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }

                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
                else {
                    this.inputMaterialTipoTrabajo.current.classList.add('is-invalid');
                    this.inputMaterialTipoTrabajo.current.value = '';
                    this.setState({ abrirToast: true, mensajeToast: "Debe elegir un elemento de la lista de materiales.", tipotoast: 'warning' });
                }
            }
            else {
                this.inputMaterialTipoTrabajo.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Elija un elemento de la lista de materiales.", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaMaterialTipoTrabajo = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/materialtipotrabajo/material/${this.state.TipoTrabajo.idTipotrabajo}`
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ MaterialTipoTrabajos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ MaterialTipoTrabajos: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    CambiarEstadoMaterialTipoTrabajo = (idMaterialTipoTrabajo, value) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/materialtipotrabajo/modificar`;
            let bodyMaterialTipoTrabajo = {
                idmaterialTipoTrabajo: idMaterialTipoTrabajo,
                estado: value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(bodyMaterialTipoTrabajo)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Estado de Material Actualizado.", tipotoast: 'success' });
                    this.BuscarListaMaterialTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        } else {
            this.props.history.push('/login');
        }
    }
    EliminarMaterialTipoTrabajo = (idmaterialtipotrabajo, descripcion) => {
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas seguro de Eliminar el material ${descripcion}?`)) {
                let url = `${config.endPoint}api/materialtipotrabajo/eliminar`;
                let bodyMaterialTipoTrabajo = {
                    idmaterialTipoTrabajo: idmaterialtipotrabajo
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(bodyMaterialTipoTrabajo)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Material quidato de tipo Trabajo", tipotoast: 'success' });
                        this.BuscarListaMaterialTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ************************************
    // Modal agregar Tipo Trabajo
    // ************************************
    AbrirModalAgregar = async (idtipotrabajo, accion) => {
        this.BuscarListaProyectosActivos();
        this.setState({ OcultarLoading: false });
        await this.setState({ MostrarModalAgregar: true, AccionTipoTrabjo: accion });

        if (this.state.AccionTipoTrabjo === "Ver") {
            this.DeshabilitarModalTipoTrabajo(true);
            await this.BuscarTipoTrabajo(idtipotrabajo).then(() => {                

                this.inputDescripcionTipoTrabajo.current.value = this.state.TipoTrabajo.tipotrabajo;
                this.inputCantidadMinFotos.current.value = this.state.TipoTrabajo.minfotos;
                this.selectEstadoTipoTrabajo.current.value = this.state.TipoTrabajo.estado.toUpperCase();
                this.selectHabilitarFotoAntes.current.checked = this.state.TipoTrabajo.habilitarfotoantes;
                this.selectHabilitarFotoDespues.current.checked = this.state.TipoTrabajo.habilitarfotodespues;
                this.selectHabilitarAgregarActividad.current.checked = this.state.TipoTrabajo.habilitaractividad;
                this.selectHabilitarAgregarMaterial.current.checked = this.state.TipoTrabajo.habilitarmaterial;
                this.selectHabilitarAgregarLectura.current.checked = this.state.TipoTrabajo.habilitarlectura;
                this.selectHabilitarAgregarObservacion.current.checked = this.state.TipoTrabajo.habilitaragregarobservacion;

                this.buttonGuardarTipoTrabajo.current.disabled = true;
                if (this.state.idproyecto == null) {
                    this.selectProyecto.current.value = this.state.TipoTrabajo.t_proyecto.idproyecto
                }

                this.setState({ OcultarLoading: true });
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }
        else {
            this.buttonModificarTipoTrabajo.current.hidden = true
            this.setState({ OcultarLoading: true });
        }
    }

    CerrarModalAgregar = () => {
        this.setState({ MostrarModalAgregar: false, AccionTipoTrabjo: "" });
    }
    // ************************************
    // Modal agregar Actividad Tipo Trabajo
    // ************************************
    AbrirModalActividadTipoTrabajo = async (TipoTrabajo) => {
        await this.setState({ ActividadTipoTrabajos: [], OcultarLoading: false });
        await this.setState({ TipoTrabajo: TipoTrabajo })
        await this.BuscarListaActividades(TipoTrabajo.idproyecto).then(async () => {
            // Buscar Lista de Materiales Correspondientes al tipo de Trabajo
            await this.BuscarListaActividadTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then(async () => {
                await this.setState({ MostrarModalActividadTipoTrabajo: true });
                this.inputTipoTrabajoActividad.current.value = this.state.TipoTrabajo.tipotrabajo;
                this.setState({ OcultarLoading: true });
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });
    }
    CerrarModalActividadTipoTrabajo = async () => {
        this.setState({ MostrarModalActividadTipoTrabajo: false });
        await this.BuscarTiposTrabajo();
    }
    // ************************************
    // Modal agregar Material Tipo Trabajo
    // ************************************
    AbrirModalMaterialTipoTrabajo = async (TipoTrabajo) => {
        await this.setState({ MaterialTipoTrabajos: [], OcultarLoading: false });
        await this.setState({ TipoTrabajo: TipoTrabajo })
        await this.BuscarListaMateriales(TipoTrabajo.idproyecto).then(async () => {
            // Buscar Lista de Materiales Correspondientes al tipo de Trabajo
            await this.BuscarListaMaterialTipoTrabajo(this.state.TipoTrabajo.idTipotrabajo).then(async () => {
                await this.setState({ MostrarModalMaterialTipoTrabajo: true });
                this.inputTipoTrabajoMaterial.current.value = this.state.TipoTrabajo.tipotrabajo;
                this.setState({ OcultarLoading: true });
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });
    }
    CerrarModalMaterialTipoTrabajo = async () => {
        this.setState({ MostrarModalMaterialTipoTrabajo: false });
        await this.BuscarTiposTrabajo();
    }
    //*************************************************
    //Funciones InputBuscar
    //*************************************************
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value === '') {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value });
            await this.BuscarTiposTrabajo();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value });
            await this.BuscarTiposTrabajo();
        }
    }
    BuscarTipoTrabajonombre = async () => {
        await this.setState({ nombrebuscar: this.inputBuscar.current.value });
        await this.BuscarTiposTrabajo();
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    //*************************************************
    // Funciones Proyecto
    //*************************************************
    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {

                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - TIPO DE TRABAJO" regresar={() => { this.props.history.push('/configuracion') }} />
                {/* Contenedor de Datos */}
                <div className="container border mt-1">

                    <div className="row mt-1">

                        <div className=" col-8 col-md-4">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Tipo Trabajo"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.BuscarTipoTrabajonombre() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregar(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                        </div>
                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.TipoTrabajos.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregar(row.idTipotrabajo, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell>{row.tipotrabajo}</TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalActividadTipoTrabajo(row) }} className="iconoAcciones">
                                                                {<i title="Actividades incluidas" className="fas fa-archive iconoAcciones"></i>}
                                                                <span >{`(${row.t_actividadtipotrabajos.length}) Actividad(es) Incluida(s)`}</span>
                                                            </TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalMaterialTipoTrabajo(row) }} className="iconoAcciones">
                                                                {<i title="Materiales Incluidos" className="fas fa-screwdriver iconoAcciones"></i>}
                                                                <span >{`(${row.t_materialtipotrabajos.length}) Material(es) Incluido(s)`}</span>
                                                            </TableCell>
                                                            <TableCell>{row.t_proyecto.nombreproyecto}</TableCell>
                                                            <TableCell>
                                                                {row.estado.toUpperCase() === 'ACTIVO' ?
                                                                    <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                    <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="mt-3">
                                        <small >{`${this.state.TipoTrabajos.length} Elemento(s) Encontrado(s)`}</small>
                                    </div>
                                    {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={100}
                            rowsPerPage={10}
                            page={0}
                            onChangePage={() => { }}
                            onChangeRowsPerPage={() => { }}
                            labelRowsPerPage={"Filas Por Pagina"}
                        /> */}
                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* Modal Agregar */}
                <Modal
                    show={this.state.MostrarModalAgregar}
                    onHide={() => { this.CerrarModalAgregar() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Tipo Trabajo - {this.state.AccionTipoTrabjo}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            {
                                this.state.idproyecto == null ?
                                    <Row>
                                        <Col>
                                            <small className="text-primary">Proyecto</small>
                                            <Form.Select ref={this.selectProyecto} required size='sm'>
                                                <option value="" disabled>Seleccione un Proyecto</option>
                                                {
                                                    this.state.listaProyectosActivos.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                            <Row>
                                <Col>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={this.inputDescripcionTipoTrabajo} required type='text' size='sm' placeholder='Ingrese una Descripción'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className="text-primary">Cant. Min Fotos</small>
                                    <Form.Control ref={this.inputCantidadMinFotos} required size='sm' placeholder='Ingrese la cantidad minima de fotos.'></Form.Control>

                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarFotoAntes}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar toma Foto Antes"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarFotoDespues}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar toma Foto Despues"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarAgregarActividad}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar Ingreso Actividades"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarAgregarMaterial}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar Ingreso Materiales"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarAgregarLectura}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar toma lectura"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Check
                                        ref={this.selectHabilitarAgregarObservacion}
                                        size='sm'
                                        type="switch"
                                        label="Habilitar agregar Observación"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className="text-primary">Estado</small>
                                    <Form.Select ref={this.selectEstadoTipoTrabajo} required size='sm' >
                                        <option value="ACTIVO">Activo</option>
                                        <option value="INACTIVO">Inactivo</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button ref={this.buttonModificarTipoTrabajo} onClick={() => { this.ModificarTipoTrabajo() }} variant='outline-primary' size='sm'>Modificar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button onClick={() => { this.CerrarModalAgregar() }} size='sm' variant='outline-primary' className='mx-2'>Cancelar</Button>
                                    <Button ref={this.buttonGuardarTipoTrabajo} size='sm' onClick={() => { this.GuardarTipoTrabajo() }}>Guardar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Agregar Actividad a Tipo Trabajo */}
                <Modal
                    show={this.state.MostrarModalActividadTipoTrabajo}
                    onHide={() => { this.CerrarModalActividadTipoTrabajo() }}
                    keyboard={false}
                    centered
                >
                    <form onSubmit={(ev) => { ev.preventDefault() }}>
                        <Modal.Header>
                            <Modal.Title>Actividades por Tipo Trabajo</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">
                                <div className="row my-1">
                                    <div className="col-md-8">
                                        <small className="text-primary">Tipo de Trabajo</small>
                                        <input ref={this.inputTipoTrabajoActividad} required type="" className="form-control form-control-sm" placeholder="Ingrese una Descripcion" disabled></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-8">
                                        <small className="text-primary">Actividad</small>
                                        <input ref={this.inputActividadTipoTrabajo} type="" className="form-control form-control-sm" list="listaOpciones" placeholder="Ingrese el tipo de Actividad"></input>
                                        <datalist id="listaOpciones">
                                            {
                                                this.state.ListaActividades.map((row, index) => {
                                                    return (<option key={index} value={`[${row.unidad}] ${row.descripcion}`} />)
                                                })
                                            }
                                        </datalist>
                                    </div>
                                    <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                        <button onClick={() => { this.AgregarActividadTipoTrabajo() }} ref={this.buttonAgregarActividadTipoTrabajo} className="btn btn-sm btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                    </div>
                                </div>
                            </div>
                            {/* Tabla de Actividades aqui */}
                            <TableContainer style={{ maxHeight: '300px' }}>
                                <Table size='small' stickyHeader>
                                    <TableHead>
                                        <TableRow className='fondoTituloTabla'>
                                            <TableCell>Acciones</TableCell>
                                            <TableCell>Actividad</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.ActividadTipoTrabajos.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className="">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-6 ">
                                                                    <i onClick={() => { this.EliminarActividadTipoTrabajo(row.idActividadTipoTrabajo, row.t_baseactividad.descripcion) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        {
                                                                            (row.estado === 'ACTIVO') ?
                                                                                <input onClick={() => { this.CambiarEstadoActividadTipoTrabajo(row.idActividadTipoTrabajo, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                                <input onClick={() => { this.CambiarEstadoActividadTipoTrabajo(row.idActividadTipoTrabajo, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{`${row.t_baseactividad.unidad} - ${row.t_baseactividad.descripcion}`}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <small>{`${this.state.ActividadTipoTrabajos.length} Elementos`}</small>
                                    </div>
                                    <div className="col-6 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalActividadTipoTrabajo() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* Modal Agregar Material a Tipo Trabajo */}
                <Modal
                    show={this.state.MostrarModalMaterialTipoTrabajo}
                    onHide={() => { this.CerrarModalMaterialTipoTrabajo() }}
                    keyboard={false}
                    centered
                >
                    <form onSubmit={(ev) => { ev.preventDefault() }}>
                        <Modal.Header>
                            <Modal.Title>Materiales por Tipo Trabajo</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">

                                <div className="row my-1">
                                    <div className="col-md-8">
                                        <small className="text-primary">Tipo de Trabajo</small>
                                        <input ref={this.inputTipoTrabajoMaterial} type="" className="form-control form-control-sm" placeholder="Ingrese una Descripcion" disabled></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-8">
                                        <small className="text-primary">Material</small>
                                        <input ref={this.inputMaterialTipoTrabajo} type="" className="form-control form-control-sm" list="listaOpciones" placeholder="Ingrese el tipo de Material"></input>
                                        <datalist id="listaOpciones">
                                            {
                                                this.state.listaMateriales.map((row, index) => {
                                                    // return (<option key={index} value={`[${row.codigoGotop}] -> ${row.descripcion}`} />)
                                                    return (<option key={index} value={`[${row.codigoGotop}] -> ${row.descripcion}`} />)
                                                })
                                            }
                                        </datalist>
                                    </div>
                                    <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                        <button onClick={() => { this.AgregarMaterialTipoTrabajo() }} ref={this.buttonAgregarMaterialTipoTrabajo} className="btn btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                    </div>
                                </div>
                            </div>
                            {/* Tabla de Actividades aqui */}
                            <TableContainer style={{ maxHeight: '300px' }}>
                                <Table size='small' stickyHeader>
                                    <TableHead>
                                        <TableRow className="fondoTituloTabla border">
                                            <TableCell>Acciones</TableCell>
                                            <TableCell>Codigo</TableCell>
                                            <TableCell>Material</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.MaterialTipoTrabajos.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className="">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-6 ">
                                                                    <i onClick={() => { this.EliminarMaterialTipoTrabajo(row.idmaterialTipoTrabajo, row.t_basematerial.descripcion) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        {
                                                                            (row.estado === 'ACTIVO') ?
                                                                                <input onClick={() => { this.CambiarEstadoMaterialTipoTrabajo(row.idmaterialTipoTrabajo, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                                <input onClick={() => { this.CambiarEstadoMaterialTipoTrabajo(row.idmaterialTipoTrabajo, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{row.t_basematerial.codigoGotop}</TableCell>
                                                    <TableCell>{row.t_basematerial.descripcion}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <small>{`${this.state.MaterialTipoTrabajos.length} Elementos`}</small>
                                    </div>
                                    <div className="col-6 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalMaterialTipoTrabajo() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>

            </Fragment>
        );
    }
}

export default configTipoTrabajo;