// import react from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import { ThemeProvider } from '@material-ui/core';
import theme from './config/configtheme';
// Componentes
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import Programacion from './components/programacion/programacion'
import Monitoreo from './components/monitoreo/monitoreo';
import MonitoreoTrabajador from './components/monitoreo/monitoreoTrabajador';
import MonitoreoSuministro from './components/monitoreo/monitoreoSuministro';
import MonitoreoSuministroMapa from './components/monitoreo/monitoreSuministroMapa';
import MonitoreoTrabajadorMapa from './components/monitoreo/monitoreoTrabajadorMapa';
import BodyOperacionesComerciales from'./components/dashboard/bodyOperacionesComerciales';
import BodyAlumbradoPublico from './components/dashboard/bodyAlumbradoPublico';

import Estadistica from './components/estadisticas/estadistica';
// Componentes configuracion
import ConfigDashboard from './components/configuracion/configDashboard';
import Personal from './components/configuracion/configPersonal';
import Actividad from './components/configuracion/configActividad';
import Material from './components/configuracion/configMaterial';
import TipoTrabajo from './components/configuracion/configTipoTrabajo';
import Receta from './components/configuracion/configRecetaMaterial';
import Permisos from './components/configuracion/configPermisos';
// Componentes AP
import BasesAp from './components/configuracion/configAp/configBasesAp'
import BaseApDetalle from './components/configuracion/configAp/configBasesAp_detalle';
import MaterialesAp from './components/configuracion/configAp/configMaterialesAp';
import ActividadesAp from './components/configuracion/configAp/configActividadesAp';
import ProgramacionAP from './components/alumbradoPublico/Programacion/programacionAp';
import ProgramacionApMapa from './components/alumbradoPublico/Programacion/programacionApMapa';
import ProgramacionApMateriales from './components/alumbradoPublico/Programacion/programacionMateriales';
import ReporteAp from './components/alumbradoPublico/reportes/reportesAp';
import MonitoreoAp from './components/alumbradoPublico/monitoreo/monitoreoAp';
import MonitoreoApDetalle from './components/alumbradoPublico/monitoreo/monitoreoDetalle';
import MonitoreoInspeccionApDetalle from './components/alumbradoPublico/monitoreo/InspeccionesAP/monitoreoInspeecionAp';



import NotFound from './components/notFound/notFound'
import Almacen from './components/almacen/almacen';
import AlmacenInventario from './components/almacen/almacenInventario';
import AlmacenMaterialProgramado from './components/almacen/almacenMaterialProgramado';
import Buscar from './components/buscar/buscar';
import ProgramacionMapa from './components/programacion/programacionMapa';
import Fotografia from './components/monitoreo/fotografia';
import Mapa from './components/mapa/mapa'
import AlmacenEpps from './components/almacen/almacenepps';
import Seguridadmenu from './components/seguridad/seguridadmenu';
import Charlas from './components/seguridad/charlas';
import Incidentes from './components/seguridad/incidentes'; 
import Combustible from './components/seguridad/combustible';
import AlmacenEppDetalle from './components/almacen/almacenepps_detalle';
import Proyecto from './components/configuracion/configProyecto';
import PdfVista from './components/utils/pdfvista';
import BuscarEnLinea from './components/buscar/buscarEnLinea';
import EstadisticaAp from './components/alumbradoPublico/estadisticas/estadisticasAp';

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* aGREGAR rOUTER */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard}/>
          <Route exact path="/login"  component={Login}/>
          <Route exact path="/almacen"  component={Almacen}/>
          <Route exact path="/almacenEpps" component={AlmacenEpps}/>
          <Route exact path="/almacenEppsdetalle/:idregistroepp/:idproyecto" component={AlmacenEppDetalle}/>
          <Route exact path="/seguridad" component={Seguridadmenu}/>
          <Route exact path="/seguridad/charlas" component={Charlas}/>
          <Route exact path="/seguridad/indidentes" component={Incidentes}/>
          <Route exact path="/seguridad/combustible" component={Combustible}/>
          <Route exact path="/almacenInventario"  component={AlmacenInventario}/>
          <Route exact path="/almacenMaterialProgramado"  component={AlmacenMaterialProgramado}/>
          <Route exact path="/programacion" component={Programacion}/>                  
          <Route exact path="/programacionMapa" component={ProgramacionMapa}/>
          <Route exact path="/operacionesComerciales" component={BodyOperacionesComerciales}/>
          {/* alumbrado Publico */}
          <Route exact path="/alumbradoPublico" component={BodyAlumbradoPublico}/>
          <Route exact path="/alumbradoPublico/monitoreoap" component={MonitoreoAp}/>
          <Route exact path="/alumbradoPublico/monitoreoapdetalle" component={MonitoreoApDetalle}/>       
          <Route exact path="/alumbradoPublico/monitoreo/inspeccionesap/:fechaejecucion/:idtrabajador" component={MonitoreoInspeccionApDetalle}/>       

          <Route exact path="/alumbradoPublico/programacionap" component={ProgramacionAP}/>
          <Route exact path="/alumbradoPublico/programacionmapaap" component={ProgramacionApMapa}/>
          <Route exact path="/alumbradoPublico/programacionmapaap/materiales" component={ProgramacionApMateriales}/>
          <Route exact path="/alumbradoPublico/reportesap" component={ReporteAp}/>
          <Route exact path="/alumbradoPublico/estadisticas" component={EstadisticaAp}/>

          <Route exact path="/monitoreo/mapa" component={MonitoreoTrabajadorMapa}/>
          <Route exact path="/monitoreo/trabajador/:fecha/:idtrabajador" component={MonitoreoTrabajador}/>
          <Route exact path="/monitoreo/trabajador/mapa/:fecha/:idtrabajador" component={MonitoreoSuministroMapa}/>
          <Route exact path="/monitoreo/suministro/mapa/:idtrabajosuministro" component={MonitoreoSuministro}/>
          <Route exact path="/monitoreo/suministro/:idtrabajosuministro" component={MonitoreoSuministro}/>
          <Route exact path="/monitoreo" component={Monitoreo}/>          
          <Route exact path="/estadistica" component={Estadistica}/>  
          <Route exact path="/configuracion/personal" component={Personal}/>
          <Route exact path="/configuracion/actividad" component={Actividad}/>
          <Route exact path="/configuracion/material" component={Material}/>
          <Route exact path="/configuracion/receta" component={Receta}/>
          <Route exact path="/configuracion/tipotrabajo" component={TipoTrabajo}/>
          <Route exact path="/configuracion/permisos" component={Permisos}/>
          <Route exact path="/configuracion/proyecto" component={Proyecto}/>
          <Route exact path="/configuracion/basesap" component={BasesAp}/>
          <Route exact path="/configuracion/basesap/detalle/:base" component={BaseApDetalle}/>          
          <Route exact path="/configuracion/materialesap" component={MaterialesAp}/>
          <Route exact path="/configuracion/actividadesap" component={ActividadesAp}/>


          <Route exact path="/configuracion" component={ConfigDashboard}/>
          <Route exact path="/buscar" component={Buscar}/>
          <Route exact path="/buscar/consultasenlinea" component={BuscarEnLinea}/>
          <Route exact path="/mapa" component={Mapa}/>
          <Route exact path="/fotografia/:fotografia" component={Fotografia}/>
          <Route exact path="/vistaPdf" component={PdfVista}/>
          <Route exact path="/" component={Dashboard}/>
          <Route path='*'component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
