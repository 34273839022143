import React, { Component, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';

import { ValidarUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from './../utils/utils'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Accordion from 'react-bootstrap/Accordion';

import { Link } from 'react-router-dom';
class configDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abrirToast: false,
            permisos: []
        };
    }
    componentDidMount() {
        if (!ValidarUsuario()) {
            this.props.history.push("/login");
        } else {
            listarPermisos().then((permisos) => {
                this.setState({ permisos: permisos });
                if (!ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, permisos)) {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        }
    }

    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    render() {
        return (
            <Fragment>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN" regresar={() => { this.props.history.push('/') }} />
                <div className="container mt-1">
                    <Accordion defaultActiveKey="1" >
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <i title="Regresar" className="fas fa-sliders-h  iconotitulo " ></i>
                                <label className="primary">Configuración - General</label>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row ">
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROYETO, this.state.permisos) ?
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                        <i className="fas fa-sitemap fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                    </div>
                                                    <Link to="/configuracion/proyecto" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Proyecto</Link>
                                                </div>
                                            </div>
                                            : <></>
                                    }

                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PERSONAL, this.state.permisos) ?
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                        <i className="fas fa-user-cog fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                    </div>
                                                    <Link to="/configuracion/personal" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Personal</Link>
                                                </div>
                                            </div>
                                            : <></>
                                    }

                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PERMISOS, this.state.permisos) ?
                                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                    <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                        <i className="fas fa-lock-open fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                    </div>
                                                    <Link to="/configuracion/permisos" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Permisos</Link>
                                                </div>
                                            </div>
                                            : <></>
                                    }


                                </div>

                            </Accordion.Body>
                        </Accordion.Item>

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_OPERACIONES_COMERCIALES, this.state.permisos) ?
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <i title="Regresar" className="fas fa-sliders-h  iconotitulo " ></i>
                                        <label className="primary">Configuración - Operaciones Comerciales</label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row ">
                                            {/* Bases de configuracion de Operaciones Comerciales */}
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_ACTIVIDADES, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-pencil-ruler fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/actividad" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Actividades</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_MATERIALES, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-screwdriver fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/material" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Materiales</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_RECETA, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-project-diagram fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/receta" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Recetas</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_TIPOTRABAJO, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-tasks fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/tipotrabajo" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Tipos de Trabajo</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                : <></>
                        }
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALUMBRADO_PUBLICO, this.state.permisos) ?

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <i title="Regresar" className="fas fa-sliders-h  iconotitulo " ></i>
                                        <label className="primary">Configuración - Alumbrado Publico</label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row ">
                                            {/* Bases de configuracion de alumbrados publicos */}
                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-database fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/basesap" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Bases AP</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_MATERIALES_AP, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-tools fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/materialesap" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Material AP</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_ACTIVIDADES_AP, this.state.permisos) ?
                                                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                                        <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                                            <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                                                <i className="fas fa-people-carry fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                                            </div>
                                                            <Link to="/configuracion/actividadesap" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Actividad AP</Link>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                : <></>
                        }

                    </Accordion>


                </div>

            </Fragment>
        );
    }
}

export default configDashboard;