import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../utils/utils'

import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
import Badge from '@material-ui/core/Badge';
import LinkUI from '@material-ui/core/Link';

// Material Icons Elements
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

//ReactBootstral
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Image from 'react-bootstrap/Image';
import { Label } from '@material-ui/icons';

class monitoreoApDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            permisos: [],
            objmenu: null,
            mostrarModalDetalleTrabajo: false,
            mostrarModalLibearTrabajo: false,
            mostrarModalFotografias: false,
            mostrarModalMateriales: false,
            mostrarModalValidarMaterial: false,
            mensajeTituloModalAgregar: '',
            columns: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>
                                <Tooltip title="Verificar detalle.">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle Actividad" });
                                        this.AbrirModalDetalleTrabajo();
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                {/* 
                                <Tooltip title="Modificar Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle Actividad" });
                                        this.AbrirModalDetalleTrabajo();
                                    }}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                                 */}

                                <Tooltip title="Liberar trabajo">
                                    <IconButton onClick={() => {
                                        this.AbrirModalLiberarTrabajo()
                                    }}>
                                        <OpenInNewIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'colum1', headerName: 'Valor', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'colum2', headerName: 'Materiales', flex: 1, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            < >
                                <LinkUI color='primary' href="#" onClick={(e) => { e.preventDefault(); this.AbrirModalMateriales() }}>
                                    (1) Materiales
                                </LinkUI>
                                <Tooltip title="cantidad">
                                    <IconButton onClick={() => {
                                    }}>
                                        <Badge badgeContent={4} color='primary' style={{ transform: 'translate(25px, -10px)' }} >
                                        </Badge>
                                        <CheckIcon fontSize='small' color="primary" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="cantidad">
                                    <IconButton onClick={() => {
                                    }}>
                                        <Badge badgeContent={3} color='primary' style={{ transform: 'translate(25px, -10px)' }} >
                                        </Badge>
                                        <ClearIcon fontSize='small' color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'colum3', headerName: 'Validado', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'colum4', headerName: 'Foto', flex: 1, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>
                                <Tooltip title="Mostrar Fotografias">
                                    <IconButton onClick={() => {
                                        this.AbrirModalFotografias();
                                    }}>
                                        <PhotoLibraryIcon fontSize='small' color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <small>(1/3)</small>
                            </>
                        );
                    }
                },
                { field: 'colum5', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rows: [
                { id: 1, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 2, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 3, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 4, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 5, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 6, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 7, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 8, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 9, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 10, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 11, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 12, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 13, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 14, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 15, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 16, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 17, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 18, colum1: 'AAAAAAAAA', colum2: '', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },

            ],
            columnsMateriales: [
                {
                    field: 'acciones', headerName: 'Fotos', flex: 0.25, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Fotografias">
                                    <IconButton onClick={() => {
                                        this.AbrirModalValidarMaterial();
                                    }}>
                                        <PhotoLibraryIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <small>{`(${cellValues.row.acciones})`}</small>
                            </>
                        );
                    }
                },
                {
                    field: 'colum1', headerName: 'Estado', flex: 0.25, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        if (cellValues.row.colum1 === 'REVISADO') {
                            return (
                                <small className='text-primary '>{`${cellValues.row.colum1}`}</small>
                            );
                        } else {
                            return (
                                <small className='text-danger'>{`${cellValues.row.colum1}`}</small>
                            );
                        }
                    }
                },
                { field: 'colum2', headerName: 'Descripción', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum3', headerName: 'Cantidad', flex: 0.25, headerClassName: 'header-colum-table', type: 'number' },
            ],
            rowMateriales: [
                { id: 1, acciones: '2/2', colum1: 'REVISADO', colum2: 'Material 1 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '2' },
                { id: 2, acciones: '3/3', colum1: 'REVISADO', colum2: 'Material 2 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '4' },
                { id: 3, acciones: '4/4', colum1: 'SIN REVISADO', colum2: 'Material 3 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '1' },
                { id: 4, acciones: '5/9', colum1: 'SIN REVISADO', colum2: 'Material 4 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '5' },
                { id: 5, acciones: '1/4', colum1: 'SIN REVISADO', colum2: 'Material 5 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '10' },
                { id: 6, acciones: '2/3', colum1: 'REVISADO', colum2: 'Material 6 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '21' },
                { id: 7, acciones: '1/1', colum1: 'REVISADO', colum2: 'Material 7 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '4' },
                { id: 8, acciones: '0/3', colum1: 'REVISADO', colum2: 'Material 8 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '2' },
                { id: 9, acciones: '4/5', colum1: 'REVISADO', colum2: 'Material 9 - it amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', colum3: '1' },
            ]
        };
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Acciones Aqui

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalDetalleTrabajo = () => {
        this.setState({ mostrarModalDetalleTrabajo: true });
    }
    CerrarModalDetalleTrabajo = () => {
        this.setState({ mostrarModalDetalleTrabajo: false });
    }
    AbrirModalLiberarTrabajo = () => {
        this.setState({ mostrarModalLibearTrabajo: true });
    }
    CerrarModalLiberarTrabajo = () => {
        this.setState({ mostrarModalLibearTrabajo: false });
    }
    AbrirModalFotografias = () => {
        this.setState({ mostrarModalFotografias: true });
    }
    CerrarModalFotografias = () => {
        this.setState({ mostrarModalFotografias: false });
    }
    AbrirModalMateriales = () => {
        this.setState({ mostrarModalMateriales: true });
    }
    CerrarModalMateriales = () => {
        this.setState({ mostrarModalMateriales: false });
    }
    AbrirModalValidarMaterial = () => {
        this.setState({ mostrarModalValidarMaterial: true });
    }
    CerrarModalValidarMaterial = () => {
        this.setState({ mostrarModalValidarMaterial: false });
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };
    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };

    render() {
        return (
            <Fragment>
                <NavBar />
                <TituloPagina titulo={`Configuracion - Actividades AP Detalle`} regresar={() => { this.props.history.push('/alumbradoPublico/monitoreoap') }} />
                <Container className='my-2  border rounded'>

                    <Row className='mt-3'>
                        <Col xs={12} md={6}>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Valores a buscar</InputGroup.Text>
                                <Form.Control type="text" placeholder="Ingrese valor a buscar" size='sm' />
                                <Button variant="outline-secondary" size='sm'>Buscar</Button>
                            </InputGroup>
                        </Col>

                        <Col className="text-end" xs={12} md={6}>
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >
                                <MenuItem onClick={() => { }}>Opcion_1</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion_2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { }}>Opcion 3</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 4</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 5</MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <div className='mt-1' style={{ height: `78vh`, width: '100%' }}>
                            <DataGrid
                                // density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={100}
                                rowsPerPageOptions={[20, 50, 100, 200]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal  Liberar Trabajo*/}
                <Modal
                    show={this.state.mostrarModalLibearTrabajo}
                    onHide={() => { this.CerrarModalLiberarTrabajo() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Liberar Trabajo`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Valor</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Cambiar Estado</Form.Label>
                                </Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col >
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="ACTIVO">PROGRAMADO</option>
                                        <option value="INACTIVO">EJECUTADO</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <Form.Check label="Eliminar data al liberar asignación." />
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant='outline-secondary' size='sm'>Cerrar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button variant='primary' size='sm'>Liberar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Fotografias */}
                <Modal
                    show={this.state.mostrarModalFotografias}
                    onHide={() => { this.CerrarModalFotografias() }}
                    size='lg'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <Container>
                            <Row>
                                <Col>

                                </Col>
                                <Col className='text-end'>
                                    <Tooltip title="Descargar fotos.">
                                        <IconButton>
                                            <CloudDownloadIcon style={{ color: 'white' }}></CloudDownloadIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row className='text-center' style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    <Carousel.Item >
                                        <Container fluid>
                                            <Image
                                                src="https://placehold.co/600x900"
                                                className="d-block m-auto"
                                                alt="imagen1"
                                                height={550}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                            />
                                            {/* <img
                                                className="d-block m-auto"
                                                src="https://placehold.co/600x900"
                                                alt="imagen1"
                                                height='550'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                            /> */}
                                            <Row className='text-light'>
                                                <Col >
                                                    <span>{`Foto 1 de 3`}</span>
                                                </Col>
                                                <Col>
                                                    <span>{`21-05-2024 10:10:10`}</span>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <Container fluid>
                                            <Image
                                                src="https://placehold.co/600x900"
                                                className="d-block m-auto"
                                                alt="imagen1"
                                                height={550}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                            />
                                            {/* <img
                                                className="d-block m-auto"
                                                src="https://placehold.co/600x900"
                                                alt="imagen1"
                                                height='550'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                            /> */}
                                            <Row className='text-light'>
                                                <Col >
                                                    <span>{`Foto 2 de 3`}</span>
                                                </Col>
                                                <Col>
                                                    <span>{`21-05-2024 10:10:10`}</span>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <Container fluid>
                                            <Image
                                                src="https://placehold.co/600x900"
                                                className="d-block m-auto"
                                                alt="imagen1"
                                                height={550}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                            />
                                            {/* <img
                                                className="d-block m-auto"
                                                src="https://placehold.co/600x900"
                                                alt="imagen1"
                                                height='550'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                            /> */}
                                            <Row className='text-light'>
                                                <Col >
                                                    <span>{`Foto 3 de 3`}</span>
                                                </Col>
                                                <Col>
                                                    <span>{`21-05-2024 10:10:10`}</span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Carousel.Item>
                                </Carousel>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

                {/* Modal Detalle Trabajo*/}
                <Modal
                    show={this.state.mostrarModalDetalleTrabajo}
                    onHide={() => { this.CerrarModalDetalleTrabajo() }}
                    size='lg'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Detalle Trabajo`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Valor1</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Valor2</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Valor1</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Valor2</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Valor1</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Valor2</Form.Label>
                                </Col>
                            </Row>

                            <Row className='mb-1'>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        defaultChecked
                                        label="Aprobar Trabajo al guardar"
                                    />
                                </Col>
                            </Row>

                            <Divider></Divider>

                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label className='fs-4'>
                                        FOTOS DESPUES
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label className='fs-4'>
                                        FOTOS DESPUES
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary'>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary'>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal  Revisar Materiales*/}
                <Modal
                    show={this.state.mostrarModalMateriales}
                    onHide={() => { this.CerrarModalMateriales() }}
                    size='xl'
                // backdrop="static"
                // keyboard={false}
                centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Detalle de Materiales`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <div className='mt-1' style={{ height: `500px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowMateriales}
                                        columns={this.state.columnsMateriales}
                                        pageSize={100}
                                        rowsPerPageOptions={[20, 50, 100, 200]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                    />
                                </div>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button size='sm' variant='outline-primary'>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Validar Material */}

                <Modal
                    show={this.state.mostrarModalValidarMaterial}
                    onHide={() => { this.CerrarModalValidarMaterial() }}
                    size='lg'
                    backdrop="static"
                    // keyboard={false}
                    centered
                >
                    {/* <Modal.Header>
                        <Modal.Title>{`Detalle de Materiales`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body style={{ borderStyle: 'solid', borderColor: 'red', borderWidth: '1px' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <label className='fs-3'>Validar Material</label>
                                </Col>
                            </Row>
                            <Divider className='mb-4'></Divider>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>MATERIAL</Form.Label>
                                </Col>                                
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>                                
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CANTIDAD</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>SERIE</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="number" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>OBSERVACION</Form.Label>
                                </Col>                                
                            </Row>

                            <Row className='mb-1'>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>                                
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        defaultChecked
                                        label="Aprobar material al guardar"
                                        size='sm'
                                    />
                                </Col>
                            </Row>

                            <Divider></Divider>

                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label className='fs-5'>
                                        FOTOS MATERIAL
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                            </Row>
                            <Divider className='mb-2'></Divider>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary'>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary' onClick={()=>{this.CerrarModalValidarMaterial()}}>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary'>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary'>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer> */}
                </Modal>


            </Fragment>
        );
    }
}

export default monitoreoApDetalle;