import React, { Component, Fragment, createRef } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../../utils/utils'
import LoadingModal from '../../../utils/loadModal';

import NavBar from '../../../dashboard/navBar';
import TituloPagina from '../../../dashboard/headerTitle';

// Material UI Elements
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
import Badge from '@material-ui/core/Badge';
import LinkUI from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

// Material Icons Elements
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import TouchAppIcon from '@material-ui/icons/TouchApp';

//ReactBootstral
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Image from 'react-bootstrap/Image';
import { EventRounded, Label, Room } from '@material-ui/icons';
import Accordion from 'react-bootstrap/Accordion';

import config from '../../../../config/config';

class MonitoreoInspeccionApDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            permisos: [],
            objmenu: null,
            mostrarModalDetalleTrabajo: false,
            mostrarModalLibearTrabajo: false,
            mostrarModalFotografias: false,
            mostrarModalActividades: false,
            mostrarModalValidarMaterial: false,
            mostrarModalCambiarPosteAP: false,
            mostrarModalBuscarEstructuraAP: false,
            mensajeTituloModalAgregar: '',
            fechaejecucion: '',
            idtrabajador: '',
            pageSizeGridModalDetalleFiltro: 100,
            inspeccionApActual: null,
            arrayFotosActuales: [],
            arrayBasActividadesInspeccionAp: [],
            arrayActividadesInspeccionActual: [],
            abrirPopover: false,
            anchorEl: null,
            modificarInspeccionAp: false,
            modificarPosteAP: false,
            columns: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Verificar detalle.">
                                    <IconButton onClick={() => {                                        
                                        this.setState({ mensajeTituloModalAgregar: "Detalle Actividad" });
                                        this.AbrirModalDetalleTrabajo(cellValues.row);
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Modificar Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle Actividad" });
                                        this.AbrirModalDetalleTrabajo();
                                    }}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip> */}
                                {/* <Tooltip title="Liberar trabajo">
                                        this.AbrirModalLiberarTrabajo()
                                    }}>
                                        <OpenInNewIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip> */}
                            </>
                        );
                    }
                },
                {
                    field: 'etiEst', headerName: 'EtiEst', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Form.Label>{`${cellValues.row.etiEst}`}</Form.Label>
                                {
                                    cellValues.row.tipoprogramacion == 'PROGRAMADO_ADICIONAL' ?
                                        <Tooltip title="Agregado Adicional">
                                            <Badge badgeContent={`AD`} color='error' style={{ transform: 'translate(25px, -10px)', }} >
                                            </Badge>
                                        </Tooltip>
                                        : <></>
                                }
                            </>
                        )
                    }
                },
                { field: 'AlimentadorMT', headerName: 'AlimentadorMT', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'fechaejecucion', headerName: 'Fecha Ejecución', flex: 1, headerClassName: 'header-colum-table' },
                {
                    field: 'actividades', headerName: 'Actividades', flex: 1.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {

                        return (
                            < >
                                <LinkUI color='primary' href="#" onClick={(e) => { e.preventDefault(); this.AbrirModalActividades(cellValues.row) }}>
                                    {`(${cellValues.row.t_actividadInspeccionAPs.length}) Actividades`}
                                </LinkUI>
                                {
                                    cellValues.row.actividadAprobados > 0 ?
                                        <Tooltip title="Cantidad de Actividades Aprobadas">
                                            <IconButton onClick={() => {
                                            }}>
                                                <Badge badgeContent={cellValues.row.actividadAprobados} color='primary' style={{ transform: 'translate(25px, -10px)' }} >
                                                </Badge>
                                                <CheckIcon fontSize='small' color="primary" style={{ color: 'green' }} />
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <></>
                                }
                                {
                                    cellValues.row.actividadNoaprobado > 0 ?
                                        <Tooltip title="Cantidad Actividades no Aprobadas">
                                            <IconButton onClick={() => {
                                            }}>
                                                <Badge badgeContent={cellValues.row.actividadNoaprobado} color='primary' style={{ transform: 'translate(25px, -10px)' }} >
                                                </Badge>
                                                <ClearIcon fontSize='small' style={{ color: 'red' }} />
                                            </IconButton>
                                        </Tooltip>
                                        : <></>
                                }
                            </>
                        );
                    }
                },
                {
                    field: 'fotos', headerName: 'Foto', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                <Tooltip title="Mostrar Fotografias">
                                    <IconButton onClick={() => {
                                        //Cargar Fotos Actuales
                                        this.setState({ arrayFotosActuales: cellValues.row.t_fotoInspeccionesAps })
                                        this.AbrirModalFotografias();
                                    }}>
                                        <PhotoLibraryIcon fontSize='small' color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <small>{`${cellValues.row.cantidadFotosServidor}`}</small>
                            </>
                        );
                    }
                },
                {
                    field: 'estado', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        switch (cellValues.row.estado) {
                            case 'EJECUTADO':
                                return (
                                    <label className='text-success'>{`${cellValues.row.estado}`}</label>
                                );
                                break;
                            default:
                                return (
                                    <label>{`${cellValues.row.estado}`}</label>
                                );
                                break
                        }
                    }
                },
                {
                    field: 'aprabado', headerName: 'Sub Estado', flex: 1, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        if (cellValues.row.aprabado) {
                            return (
                                <label className='text-success'>{`APROBADO`}</label>
                            );
                        } else {
                            return (
                                <label className='text-danger'>{`NO APROBADO`}</label>
                            );
                        }
                    }
                },
            ],
            rows: [],
            columnsActividades: [
                {
                    field: 'aprabado', headerName: 'Validado', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            < Form.Check
                                type="switch"
                                defaultChecked={cellValues.row.aprabado}
                                onChange={(ev) => { this.ActualizarEstadoActividadInspeccionAp(cellValues.row, ev) }}
                            />
                        );
                    }
                },
                { field: 'descripcion', headerName: 'Descripción', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'cantidad', headerName: 'Cantidad', flex: 0.5, headerClassName: 'header-colum-table', type: 'number' },
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-table', renderCell: (cellValues) => {
                        return (
                            <>
                                {/* <Tooltip title="Editar">
                                    <IconButton onClick={() => {
                                        // this.AbrirModalValidarMaterial();
                                    }}>
                                        <EditIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title="Eliminar">
                                    <IconButton onClick={() => {
                                        this.EliminarActividadInspeccionAp(cellValues);
                                    }}>
                                        <DeleteIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
            ],
            rowActividades: [],
            rowBuscarEstructura: []

        };

        //Referencias
        this.inputSelectActividades = createRef();
        this.inputCantidadActividad = createRef();
        //Referencias Detalle InspeccionAp
        this.inputNroPoste = createRef();
        this.inputNroModular = createRef();
        this.inputFecha = createRef();
        this.selectEstado = createRef();
        this.inputObservaciones = createRef();
        this.checkAprobarInspeccion = createRef();
        //Referencias Busqeuda Estructura
        this.inputValorBuscarEstructura = createRef();
        //Referencias Estructura Buscar
        this.inputcodNod = createRef();
        this.inputcodSed = createRef();
        this.inputsistemaElectrico = createRef();
        this.inputsimEst = createRef();
        this.inputetiEst = createRef();
        this.inputsed = createRef();
        this.inputEtiEAPotencia = createRef();
        this.inputCanLamPorEBT = createRef();
        this.inputCentroTransformacion = createRef();
        this.inputAlimentadorMT = createRef();
        this.inputDistrito = createRef();
        this.inputDireccion = createRef();
        this.inputCantidadLamparasPorSed = createRef();
        this.inputModular = createRef();
        this.inputPotInstalada = createRef();
        this.inputTotLampPormodular = createRef();
        this.inputLocalidad = createRef();
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    //Obtener Params
                    await this.setState({ fechaejecucion: this.props.match.params.fechaejecucion, idtrabajador: this.props.match.params.idtrabajador })
                    // Acciones Aqui                    
                    this.BuscarListaInspeccionesApFecha();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/alumbradoPublico/monitoreoap');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Procedimientos Detalle Inspeccion
    //***************************************************** */
    BuscarListaInspeccionesApFecha = async () => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/listar/${this.state.idproyecto}/${this.state.fechaejecucion}/${this.state.idtrabajador}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    
                    let DataTemp = [];
                    data.content.forEach((elemento, index) => {
                        let actValidado = 0;
                        let ActividadNoValidado = 0;
                        elemento.t_actividadInspeccionAPs.forEach((actEl, index) => {
                            if (actEl.aprabado) {
                                actValidado++;
                            } else {
                                ActividadNoValidado++;
                            }
                        });

                        //Revisar Actividades Validado No Validado

                        DataTemp.push(
                            {
                                id: elemento.id,
                                codNod: elemento.codNod,
                                codSed: elemento.codSed,
                                sistemaElectrico: elemento.sistemaElectrico,
                                simEst: elemento.simEst,
                                etiEst: elemento.etiEst,
                                sed: elemento.sed,
                                EtiEAPotencia: elemento.EtiEAPotencia,
                                CanLamPorEBT: elemento.CanLamPorEBT,
                                CentroTransformacion: elemento.CentroTransformacion,
                                AlimentadorMT: elemento.AlimentadorMT,
                                Distrito: elemento.Distrito,
                                Direccion: elemento.Direccion,
                                CantidadLamparasPorSed: elemento.CantidadLamparasPorSed,
                                Modular: elemento.Modular,
                                PotInstalada: elemento.PotInstalada,
                                TotLampPormodular: elemento.TotLampPormodular,
                                Localidad: elemento.Localidad,
                                lat: elemento.lat,
                                lng: elemento.lng,
                                estado: elemento.estado,
                                poste_ingresado: elemento.poste_ingresado,
                                modular_ingresado: elemento.modular_ingresado,
                                observacion: elemento.observacion,
                                aprabado: elemento.aprabado,
                                fechaejecucion: elemento.fechaejecucion,
                                fecharegistro: elemento.fecharegistro,
                                fechamodificacion: elemento.fechamodificacion,
                                fechaaprobacion: elemento.fechaaprobacion,
                                cantfotos: elemento.cantfotos,
                                idproyecto: elemento.idproyecto,
                                usuarioregistra: elemento.usuarioregistra,
                                usuariomodifica: elemento.usuariomodifica,
                                usuarioaprueba: elemento.usuarioaprueba,
                                cantidadFotosServidor: `${elemento.t_fotoInspeccionesAps.length}/${elemento.cantfotos}`,
                                cantidadActividades: elemento.t_actividadInspeccionAPs.length,
                                t_actividadInspeccionAPs: elemento.t_actividadInspeccionAPs,
                                t_fotoInspeccionesAps: elemento.t_fotoInspeccionesAps,
                                tipoprogramacion: elemento.tipoprogramacion,
                                actividadAprobados: actValidado,
                                actividadNoaprobado: ActividadNoValidado,
                            }
                        );
                    });
                    this.setState({ rows: DataTemp, OcultarLoading: true });
                }
                else {
                    this.setState({ rows: [], OcultarLoading: true })
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarInspeccionApId = (id) => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                this.setState({ OcultarLoading: false });
                let url = `${config.endPoint}api/inspeccionesAp/${id}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ inspeccionApActual: data.content, OcultarLoading: true });
                        resolve(true);
                    }
                    else {
                        this.setState({ inspeccionApActual: null })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning', OcultarLoading: true });
                        resolve(false);
                    }

                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    BuscarBaseActividadesInspeccionAp = async () => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/baseActividadAp/listarBases/Actividades`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idproyecto': this.state.idproyecto,
                    'area': "INSPECCIONES_AP",
                    'estado': "ACTIVO"
                })
            };
            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    this.setState({ arrayBasActividadesInspeccionAp: data.content, OcultarLoading: true });
                }
                else {
                    this.setState({ arrayBasActividadesInspeccionAp: [], OcultarLoading: true })
                }
            }).catch(() => {
                this.setState({ arrayBasActividadesInspeccionAp: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarFotografiaInspecion = (row, revisado) => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });

            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/fotoInspeccionAp/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idfotoInspeccionesAp': row.idfotoInspeccionesAp,
                    'revisado': revisado.currentTarget.checked,
                    'usuariomodifica': UsuarioStorage.id
                })
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content > 0) {
                    //Convertir                                         
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                }
                else {
                    this.setState({ mensajeToast: "No actualizado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarEstadoActividadInspeccionAp = (row, ev) => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/ActiviadInspeccionAp/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idactividadInspeccionAP': row.id,
                    'aprabado': ev.currentTarget.checked,
                    'usuariomodifica': UsuarioStorage.id
                })
            };            

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content > 0) {
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                }
                else {
                    this.setState({ mensajeToast: "No actualizado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarListaActividadesInspeccionAp = (id) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                this.BuscarInspeccionApId(id).then((respuesta) => {
                    let arrayActividadesInspeccion = this.state.inspeccionApActual.t_actividadInspeccionAPs;
                    if (respuesta) {
                        let DataTemp = [];
                        arrayActividadesInspeccion.forEach((elemento, index) => {
                            DataTemp.push(
                                {
                                    id: elemento.idactividadInspeccionAP,
                                    aprabado: elemento.aprabado,
                                    cantidad: elemento.cantidad,
                                    codigo: elemento.codigo,
                                    descripcion: elemento.descripcion,
                                    estado: elemento.estado,
                                    fecha: elemento.fecha,
                                    fechaaprobacion: elemento.fechaaprobacion,
                                    fechamodificacion: elemento.fechamodificacion,
                                    fecharegistro: elemento.fecharegistro,
                                    idinspeccionesAp: elemento.idinspeccionesAp,
                                    observacion: elemento.observacion,
                                }
                            );
                        });
                        this.setState({ rowActividades: DataTemp });
                        resolve(false);
                    } else {
                        resolve(false);
                    }
                })
            }
            else {
                reject(true);
            }
        });


    }
    EliminarActividadInspeccionAp = (row) => {
        if (ValidarUsuario()) {
            if (window.confirm("¿Estas seguro de eliminar la actividad actual?")) {
                this.setState({ OcultarLoading: false });
                // Reasignar a los suministros Seleccionados
                let url = `${config.endPoint}api/ActiviadInspeccionAp/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        'idactividadInspeccionAP': row.id,
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content > 0) {
                        this.setState({ mensajeToast: "Registro Eliminado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                        //Actualizar Actividades                        
                        this.ActualizarListaActividadesInspeccionAp(this.state.inspeccionApActual.idinspeccionesAp)
                    }
                    else {
                        this.setState({ mensajeToast: "No eliminado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarIdActividadSeleccionada = (id) => {
        let actividadSeleccionado = this.state.arrayBasActividadesInspeccionAp.find((act) => act.idBaseActividadAp == id);
        return actividadSeleccionado;
    }
    ValidarAgregarActividadInspeccionAp = () => {
        let error = 0;
        if (this.inputSelectActividades.current.value == "") {
            error++;
            this.inputSelectActividades.current.classList.add("is-invalid");
        } else {
            this.inputSelectActividades.current.classList.remove("is-invalid");
        }

        if (this.inputCantidadActividad.current.value == "") {
            error++;
            this.inputCantidadActividad.current.classList.add("is-invalid");
        } else {
            this.inputCantidadActividad.current.classList.remove("is-invalid");
        }
        if (error > 0) {
            return false;
        }
        return true;
    }
    AgregarActividadInspeccionAp = () => {
        if (ValidarUsuario()) {
            if (this.ValidarAgregarActividadInspeccionAp()) {
                let actividadEncontrada = this.BuscarIdActividadSeleccionada(this.inputSelectActividades.current.value);
                if (actividadEncontrada) {
                    
                    this.setState({ OcultarLoading: false });

                    let url = `${config.endPoint}api/ActiviadInspeccionAp/agregar`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify({
                            'codigo': actividadEncontrada.codigoActividad,
                            'descripcion': actividadEncontrada.descripcion,
                            'cantidad': this.inputCantidadActividad.current.value,
                            'fecha': this.state.inspeccionApActual.fechaejecucion,
                            'observacion': "",
                            'estado': "",
                            'idinspeccionesAp': this.state.inspeccionApActual.idinspeccionesAp,
                            'usuarioregistra': UsuarioStorage.id,
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        
                        if (data.content) {
                            this.setState({ mensajeToast: "Registro Agregado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                            this.ActualizarListaActividadesInspeccionAp(this.state.inspeccionApActual.idinspeccionesAp);
                        }
                        else {
                            this.setState({ mensajeToast: "No Agregado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                        }
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarInspeccionAP = () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idinspeccionesAp': this.state.inspeccionApActual.idinspeccionesAp,
                    'poste_ingresado': this.inputNroPoste.current.value,
                    'modular_ingresado': this.inputNroModular.current.value,
                    'fechaejecucion': this.inputFecha.current.value,
                    'estado': this.selectEstado.current.value,
                    'observacion': this.inputObservaciones.current.value,
                    'usuariomodifica': UsuarioStorage.id
                })
            };
            
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content > 0) {
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true, modificarInspeccionAp: false });
                    this.CerrarModalValidarMaterial();
                }
                else {
                    this.setState({ mensajeToast: "No actualizado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    AprobarInspeccionAP = (ev) => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/aprobar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let estadoAprobacion = ev.currentTarget.checked;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idinspeccionesAp': this.state.inspeccionApActual.idinspeccionesAp,
                    'usuariomodifica': UsuarioStorage.id,
                    'aprabado': estadoAprobacion,
                })
            };
            
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content > 0) {
                    if (estadoAprobacion) {
                        this.setState({ mensajeToast: "Se aprobó Inspeccion AP", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                    } else {
                        this.setState({ mensajeToast: "Se cambio estado de Inspeccion AP", abrirToast: true, tipotoast: 'info', OcultarLoading: true });
                    }
                }
                else {
                    this.setState({ mensajeToast: "No actualizado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch((error) => {
                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ValidarBuscarEstructuraAP = () => {
        let cantErrores = 0
        if (this.inputValorBuscarEstructura.current.value == "") {
            cantErrores++;
            this.inputValorBuscarEstructura.current.classList.add("is-invalid");
        } else {
            this.inputValorBuscarEstructura.current.classList.remove("is-invalid");
        }

        if (cantErrores > 0) {
            return false;
        }
        return true;
    }
    BuscarEstructuraAp = () => {
        if (ValidarUsuario()) {
            if (this.ValidarBuscarEstructuraAP()) {
                this.setState({ OcultarLoading: false });

                // Reasignar a los suministros Seleccionados
                let url = `${config.endPoint}api/basepostesap/busquedas/estructuraAp/${this.state.idproyecto}`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        'nroestructura': this.inputValorBuscarEstructura.current.value,
                    })
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    
                    if (data.content) {
                        this.setState({ rowBuscarEstructura: data.content, OcultarLoading: true });
                    }
                    else {
                        this.setState({ rowBuscarEstructura: [], OcultarLoading: true })
                    }
                }).catch(() => {
                    this.setState({ rowBuscarEstructura: [], mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ElegirEstructuraAP = async (row) => {
        await this.setState({ mostrarModalBuscarEstructuraAP: false });        
        // Cambiando Elementos
        this.inputcodNod.current.value = row.codNod;
        this.inputcodSed.current.value = row.codSed;
        this.inputsistemaElectrico.current.value = row.sistemaElectrico;
        this.inputsimEst.current.value = row.simEst;
        this.inputetiEst.current.value = row.etiEst;
        this.inputsed.current.value = row.sed;
        this.inputEtiEAPotencia.current.value = row.EtiEAPotencia;
        this.inputCanLamPorEBT.current.value = row.CanLamPorEBT;
        this.inputCentroTransformacion.current.value = row.CentroTransformacion;
        this.inputAlimentadorMT.current.value = row.AlimentadorMT;
        this.inputDistrito.current.value = row.Distrito;
        this.inputDireccion.current.value = row.Direccion;
        this.inputCantidadLamparasPorSed.current.value = row.CantidadLamparasPorSed;
        this.inputModular.current.value = row.Modular;
        this.inputPotInstalada.current.value = row.PotInstalada;
        this.inputTotLampPormodular.current.value = row.TotLampPormodular;
        this.inputLocalidad.current.value = row.Localidad;
        this.setState({ modificarPosteAP: true });
    }
    ActualizarEstructuraDeInspeccionAP = () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/inspeccionesAp/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    'idinspeccionesAp': this.state.inspeccionApActual.idinspeccionesAp,
                    'usuariomodifica': UsuarioStorage.id,
                    'codNod': this.inputcodNod.current.value,
                    'codSed': this.inputcodSed.current.value,
                    'sistemaElectrico': this.inputsistemaElectrico.current.value,
                    'simEst': this.inputsimEst.current.value,
                    'etiEst': this.inputetiEst.current.value,
                    'sed': this.inputsed.current.value,
                    'EtiEAPotencia': this.inputEtiEAPotencia.current.value,
                    'CanLamPorEBT': this.inputCanLamPorEBT.current.value,
                    'CentroTransformacion': this.inputCentroTransformacion.current.value,
                    'AlimentadorMT': this.inputAlimentadorMT.current.value,
                    'Distrito': this.inputDistrito.current.value,
                    'Direccion': this.inputDireccion.current.value,
                    'CantidadLamparasPorSed': this.inputCantidadLamparasPorSed.current.value,
                    'Modular': this.inputModular.current.value,
                    'PotInstalada': this.inputPotInstalada.current.value,
                    'TotLampPormodular': this.inputTotLampPormodular.current.value,
                    'Localidad': this.inputLocalidad.current.value,
                })
            };            
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content > 0) {
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true, modificarInspeccionAp: false,mostrarModalCambiarPosteAP:false });
                    // Actualizar
                    this.BuscarInspeccionApId(this.state.inspeccionApActual.idinspeccionesAp).then((respuesta) => {                        
                        if (respuesta) {
                            // this.BuscarBaseActividadesInspeccionAp();
                            this.ActualizarListaActividadesInspeccionAp(this.state.inspeccionApActual.idinspeccionesAp).then((respuesta) => { });
                            this.setState({ modificarInspeccionAp: false, mostrarModalDetalleTrabajo: true });                            
                        }
                    });
                }
                else {
                    this.setState({ mensajeToast: "No actualizado", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalDetalleTrabajo = (row) => {
        this.BuscarInspeccionApId(row.id).then((respuesta) => {            
            if (respuesta) {
                this.BuscarBaseActividadesInspeccionAp();
                this.ActualizarListaActividadesInspeccionAp(row.id).then((respuesta) => { });
                this.setState({ modificarInspeccionAp: false, mostrarModalDetalleTrabajo: true });
            }
        });
    }
    CerrarModalDetalleTrabajo = () => {
        this.setState({ mostrarModalDetalleTrabajo: false });
        this.BuscarListaInspeccionesApFecha();
    }
    AbrirModalLiberarTrabajo = () => {
        this.setState({ mostrarModalLibearTrabajo: true });
    }
    CerrarModalLiberarTrabajo = () => {
        this.setState({ mostrarModalLibearTrabajo: false });
    }
    AbrirModalFotografias = () => {
        this.setState({ mostrarModalFotografias: true });
    }
    CerrarModalFotografias = () => {
        this.setState({ mostrarModalFotografias: false, arrayFotosActuales: [] });
    }
    AbrirModalActividades = (row) => {
        this.BuscarBaseActividadesInspeccionAp();
        this.ActualizarListaActividadesInspeccionAp(row.id).then((respuesta) => {
            this.setState({ mostrarModalActividades: true });
            if (respuesta) {
            }
        });
    }
    CerrarModalActividades = () => {
        this.setState({ mostrarModalActividades: false, inspeccionApActual: false });
        this.BuscarListaInspeccionesApFecha();
    }
    AbrirModalValidarMaterial = () => {
        this.setState({ mostrarModalValidarMaterial: true });
    }
    CerrarModalValidarMaterial = () => {
        this.setState({ mostrarModalValidarMaterial: false });
    }
    AbrirModalCambiarPosteAP = () => {
        this.setState({ mostrarModalCambiarPosteAP: true, abrirPopover: false, anchorEl: null, modificarPosteAP:false});
    }
    CerrarModalCambiarPosteAP = () => {
        this.setState({ mostrarModalCambiarPosteAP: false });
    }
    AbrirModalBuscarEstructuraAp = () => {
        this.setState({ mostrarModalBuscarEstructuraAP: true });
    }
    CerrarModalBuscarEstructuraAp = () => {
        this.setState({ mostrarModalBuscarEstructuraAP: false });
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };
    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo={`Configuracion - Actividades AP Detalle`} regresar={() => { this.props.history.push('/alumbradoPublico/monitoreoap') }} />
                <Container className='my-2  border rounded'>

                    <Row className='mt-3'>
                        {/* <Col xs={12} md={6}>
                            <InputGroup size='sm'>
                                <InputGroup.Text id="basic-addon1">Valores a buscar</InputGroup.Text>
                                <Form.Control type="text" placeholder="Ingrese valor a buscar" size='sm' />
                                <Button variant="outline-secondary" size='sm'>Buscar</Button>
                            </InputGroup>
                        </Col> */}

                        <Col className="text-end" xs={12} md={12}>
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >
                                <MenuItem onClick={() => { }}>Descargar XLS</MenuItem>
                                {/* <MenuItem onClick={() => { }}>Opcion_2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { }}>Opcion 3</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 4</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 5</MenuItem> */}
                            </Menu>
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <div className='mt-1' style={{ height: `78vh`, width: '100%' }}>
                            <DataGrid
                                // density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={this.state.pageSizeGridModalDetalleFiltro}
                                rowsPerPageOptions={[20, 50, 100]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                                onPageSizeChange={(pagina) => { this.setState({ pageSizeGridModalDetalleFiltro: pagina }) }}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal  Liberar Trabajo*/}
                <Modal
                    show={this.state.mostrarModalLibearTrabajo}
                    onHide={() => { this.CerrarModalLiberarTrabajo() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Liberar Trabajo`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Valor</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Cambiar Estado</Form.Label>
                                </Col>
                            </Row>

                            <Row className='mb-3'>
                                <Col >
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="ACTIVO">PROGRAMADO</option>
                                        <option value="INACTIVO">EJECUTADO</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <Form.Check label="Eliminar data al liberar asignación." />
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant='outline-secondary' size='sm'>Cerrar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button variant='primary' size='sm'>Liberar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Fotografias */}
                <Modal
                    show={this.state.mostrarModalFotografias}
                    onHide={() => { this.CerrarModalFotografias() }}
                    size='lg'
                >
                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <Container>
                            {/* <Row>
                                <Col>

                                </Col>
                                <Col className='text-end'>
                                    <Tooltip title="Descargar fotos.">
                                        <IconButton>
                                            <CloudDownloadIcon style={{ color: 'white' }}></CloudDownloadIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Col>
                            </Row> */}
                            <Row className='text-center' style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {
                                        this.state.arrayFotosActuales.map((foto, index) => {
                                            return (

                                                <Carousel.Item key={index}>
                                                    <Container fluid>
                                                        <Image
                                                            src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                            className="d-block m-auto"
                                                            alt={`Foto ${foto.tipo}`}
                                                            height={550}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                        />
                                                        <Row className='text-light'>
                                                            <Col >
                                                                <span>{`${index + 1} de ${this.state.arrayFotosActuales.length}`}</span>
                                                            </Col>
                                                            <Col>
                                                                <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                </Carousel.Item>
                                            );
                                        })
                                    }
                                </Carousel>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

                {/* Modal Detalle Trabajo*/}
                <Modal
                    show={this.state.mostrarModalDetalleTrabajo}
                    onHide={() => { this.CerrarModalDetalleTrabajo() }}
                    size='lg'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>
                            {`Detalle Inspeccion AP`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Check // prettier-ignore
                                        // disabled={!this.state.modificarInspeccionAp}
                                        ref={this.checkAprobarInspeccion}
                                        type='switch'
                                        defaultChecked={this.state.inspeccionApActual ? this.state.inspeccionApActual.aprabado : ''}
                                        label="Aprobado"
                                        onChange={(ev) => { this.AprobarInspeccionAP(ev) }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='border border-rounded'>
                                    {
                                        this.state.inspeccionApActual ?
                                            <>
                                                <strong className='text-primary'>{`etiEst:`}</strong><span>{this.state.inspeccionApActual.etiEst}</span>
                                                <strong className='text-primary'>{`Modular:`}</strong><span>{this.state.inspeccionApActual.Modular}</span>
                                                <strong className='text-primary'>{`Direccion:`}</strong><span>{this.state.inspeccionApActual.Direccion}</span>
                                                <strong className='text-primary'>{`Distrito:`}</strong><span>{this.state.inspeccionApActual.Distrito}</span>
                                                <strong className='text-primary'>{`AlimentadorMT:`}</strong><span>{this.state.inspeccionApActual.AlimentadorMT}</span>
                                            </>
                                            : <></>
                                    }
                                </Col>
                                <Col md={1} className='text-end'>
                                    {/* <IconButton onClick={(ev) => { this.setState({ abrirPopover: true, anchorEl: ev.currentTarget }) }}>
                                        <InfoIcon size='sm' style={{ color: '#004BA0' }}></InfoIcon>
                                    </IconButton> */}
                                    <IconButton onClick={(ev) => { this.AbrirModalCambiarPosteAP() }}>
                                        <InfoIcon size='sm' style={{ color: '#004BA0' }}></InfoIcon>
                                    </IconButton>

                                    <Popover
                                        open={this.state.abrirPopover}
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        onClose={() => { this.setState({ abrirPopover: false, anchorEl: null }) }}
                                        disableRestoreFocus
                                    >
                                        <Container className='my-2'>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>CodNod</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.codNod : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>CodNod</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.codSed : ''}</Form.Label>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>sistemaElectrico</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.sistemaElectrico : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>simEst</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.simEst : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>etiEst</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.etiEst : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>EtiEAPotencia</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.EtiEAPotencia : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>CanLamPorEBT</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.CanLamPorEBT : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>CentroTransformacion</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.CentroTransformacion : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>AlimentadorMT</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.AlimentadorMT : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>Distrito</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.Distrito : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>Direccion</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.Direccion : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>CantidadLamparasPorSed</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.CantidadLamparasPorSed : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>Modular</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.Modular : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>PotInstalada</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.PotInstalada : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className='fw-bold text-primary'>TotLampPormodular</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className='fw-light text-secondary'>{this.state.inspeccionApActual ? this.state.inspeccionApActual.TotLampPormodular : ''}</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='text-center'>
                                                    <Button variant='outline-primary' onClick={() => { this.AbrirModalCambiarPosteAP() }}>Cambiar</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Popover>
                                </Col>
                            </Row>
                            <Divider className='mt-2'></Divider>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Nro Poste</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Nro Modular</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control disabled={!this.state.modificarInspeccionAp} ref={this.inputNroPoste} type="text" size='sm' placeholder='Ingrese Nro de Poste' defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.poste_ingresado : ''} />
                                </Col>
                                <Col >
                                    <Form.Control disabled={!this.state.modificarInspeccionAp} ref={this.inputNroModular} type="text" size='sm' placeholder='Ingrese Nro Modular' defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.modular_ingresado : ''} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Fecha</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Estado</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control disabled={!this.state.modificarInspeccionAp} ref={this.inputFecha} type="date" size='sm' placeholder='Ingrese la fecha' defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.fechaejecucion : ''} />
                                </Col>
                                <Col >
                                    <Form.Select disabled={!this.state.modificarInspeccionAp} ref={this.selectEstado} size='sm' defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.estado : ''}>
                                        <option>Seleccione un Elemento</option>
                                        <option value="EJECUTADO">EJECUTADO</option>
                                        <option value="IMPEDIMIENTO">IMPEDIMENTO</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Observación</Form.Label>
                                </Col>
                            </Row>

                            <Row className='mb-1'>
                                <Col >
                                    <Form.Control disabled={!this.state.modificarInspeccionAp} ref={this.inputObservaciones} type="text" size='sm' placeholder='Ingrese observación' defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.observacion : ''} />
                                </Col>
                            </Row>
                            <Row>
                                {
                                    this.state.modificarInspeccionAp ?
                                        <Col>
                                            <Button size='sm' variant='outline-secondary' onClick={() => { this.setState({ modificarInspeccionAp: false }) }}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                        : <></>
                                }
                                <Col className='text-end'>
                                    {
                                        this.state.modificarInspeccionAp ?
                                            <Button size='sm' variant='primary' onClick={() => { this.ActualizarInspeccionAP() }}>
                                                Guardar
                                            </Button>
                                            :
                                            <Button size='sm' variant='primary' onClick={() => { this.setState({ modificarInspeccionAp: true }) }}>
                                                Modificar
                                            </Button>
                                    }
                                </Col>
                            </Row>
                            {
                                this.state.inspeccionApActual ?
                                    <Row>
                                        <Col>
                                            <small className='fw-bold text-secondary'>{`Fecha Registro:`}</small>
                                            <small className='text-secondary'>{`${this.state.inspeccionApActual.fecharegistro ? this.state.inspeccionApActual.fecharegistro : ''}`}</small>
                                        </Col>
                                        <Col className='text-end'>
                                            <small className='fw-bold text-secondary'>{`Fecha Modificación:`}</small>
                                            <small className='text-secondary'>{`${this.state.inspeccionApActual.fechamodificacion ? this.state.inspeccionApActual.fechamodificacion : ''}`}</small>
                                        </Col>
                                    </Row>
                                    : <></>
                            }

                            <Divider className='mt-2'></Divider>

                            <Accordion defaultActiveKey="" alwaysOpen className='mt-2'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <Form.Label className='fs-5'>
                                            Fotos
                                        </Form.Label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            {
                                                this.state.inspeccionApActual ?
                                                    this.state.inspeccionApActual.t_fotoInspeccionesAps.map((foto, index) => {
                                                        return (
                                                            <Col className='m-1 text-center' key={index}>
                                                                <Image
                                                                    src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                                    className="d-block m-auto"
                                                                    alt={`Foto ${foto.tipo}`}
                                                                    height={200}
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                                />
                                                                <Form.Check
                                                                    type="switch"
                                                                    defaultChecked={foto.revisado}
                                                                    onChange={(ev) => {
                                                                        this.ActualizarFotografiaInspecion(foto, ev)
                                                                    }}
                                                                />
                                                            </Col>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <Form.Label className='fs-5'>
                                            Actividades
                                        </Form.Label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='mt-1'>
                                            <div className='mt-1' style={{ height: `200px`, width: '100%' }}>
                                                <DataGrid
                                                    density='compact'
                                                    rows={this.state.rowActividades}
                                                    columns={this.state.columnsActividades}
                                                    // pageSize={100}
                                                    // rowsPerPageOptions={[20, 50, 100, 200]}
                                                    // checkboxSelection
                                                    hideFooterPagination
                                                    localeText={esES.props.MuiDataGrid.localeText}
                                                />
                                            </div>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button size='sm' variant='outline-primary' onClick={() => { this.CerrarModalDetalleTrabajo() }}>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal  Revisar Actividades*/}
                <Modal
                    show={this.state.mostrarModalActividades}
                    onHide={() => { this.CerrarModalActividades() }}
                    size='lg'
                    // backdrop="static"
                    // keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Detalle de Actividades`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className='text-primary'>Actividades</Form.Label>
                                </Col>
                            </Row>

                            <Row >
                                <Col md={6}>
                                    <Form.Select size='sm' ref={this.inputSelectActividades}>
                                        <option value="">Elija un elemento</option>
                                        {
                                            this.state.arrayBasActividadesInspeccionAp.map((actividad, index) => {
                                                return (
                                                    <option key={index} value={actividad.idBaseActividadAp}>{actividad.descripcion}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className='text-primary'>Cantidad</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control ref={this.inputCantidadActividad} type='number' size='sm' placeholder='Ingrese la cantidad' defaultValue={1}></Form.Control>
                                </Col>
                                <Col>
                                    <Button variant='outline-primary' size='sm' onClick={() => { this.AgregarActividadInspeccionAp() }}>Agregar</Button>
                                </Col>
                            </Row>
                            <Divider className='mt-4'></Divider>


                            <Row className='mt-4'>
                                <div className='mt-1' style={{ height: `300px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowActividades}
                                        columns={this.state.columnsActividades}
                                        // pageSize={100}
                                        // rowsPerPageOptions={[20, 50, 100, 200]}
                                        // checkboxSelection
                                        hideFooterPagination
                                        localeText={esES.props.MuiDataGrid.localeText}
                                    />
                                </div>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button size='sm' variant='outline-primary' onClick={() => { this.CerrarModalActividades() }}>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Validar Material */}
                <Modal
                    show={this.state.mostrarModalValidarMaterial}
                    onHide={() => { this.CerrarModalValidarMaterial() }}
                    size='lg'
                    backdrop="static"
                    // keyboard={false}
                    centered
                >
                    {/* <Modal.Header>
                        <Modal.Title>{`Detalle de Materiales`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body style={{ borderStyle: 'solid', borderColor: 'red', borderWidth: '1px' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <label className='fs-3'>Validar Material</label>
                                </Col>
                            </Row>
                            <Divider className='mb-4'></Divider>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>MATERIAL</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CANTIDAD</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>SERIE</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control type="number" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>OBSERVACION</Form.Label>
                                </Col>
                            </Row>

                            <Row className='mb-1'>
                                <Col >
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        defaultChecked
                                        label="Aprobar material al guardar"
                                        size='sm'
                                    />
                                </Col>
                            </Row>

                            <Divider></Divider>

                            <Row className='mt-2'>
                                <Col>
                                    <Form.Label className='fs-5'>
                                        FOTOS MATERIAL
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                                <Col className='m-1 text-center'>
                                    <Image
                                        src="https://placehold.co/600x900"
                                        className="d-block m-auto"
                                        alt="imagen1"
                                        height={200}
                                        onClick={() => { window.open(`https://placehold.co/600x900`) }}
                                    />
                                    <Form.Check
                                        type="switch"
                                    />
                                </Col>
                            </Row>
                            <Divider className='mb-2'></Divider>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary'>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary' onClick={() => { this.CerrarModalValidarMaterial() }}>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary'>
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' variant='primary'>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer> */}
                </Modal>

                {/* Modal Cambiar Poste AP */}
                <Modal
                    show={this.state.mostrarModalCambiarPosteAP}
                    onHide={() => { this.CerrarModalCambiarPosteAP() }}
                    size='lg'
                    backdrop="static"
                >
                    <Modal.Body style={{ borderStyle: 'solid', borderColor: 'red', borderWidth: '1px' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <label className='fs-3'>{`Estructura / Poste`}</label>
                                </Col>
                            </Row>
                            <Divider className='mb-4'></Divider>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>etiEst</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control ref={this.inputetiEst} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.etiEst : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col md={6} className='text-end'>
                                    <Button size='sm' onClick={() => { this.AbrirModalBuscarEstructuraAp() }}>Cambiar / Buscar</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>codNod</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>codSed</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputcodNod} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.codNod : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputcodSed} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.codSed : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>sistemaElectrico</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>simEst</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputsistemaElectrico} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.sistemaElectrico : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputsimEst} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.simEst : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>sed</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>EtiEAPotencia</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputsed} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.sed : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputEtiEAPotencia} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.EtiEAPotencia : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CanLamPorEBT</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>CentroTransformacion</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputCanLamPorEBT} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.CanLamPorEBT : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputCentroTransformacion} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.CentroTransformacion : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>AlimentadorMT</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Distrito</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputAlimentadorMT} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.AlimentadorMT : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputDistrito} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.Distrito : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Direccion</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>CantidadLamparasPorSed</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputDireccion} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.Direccion : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputCantidadLamparasPorSed} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.CantidadLamparasPorSed : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>Modular</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>PotInstalada</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputModular} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.Modular : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputPotInstalada} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.PotInstalada : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>TotLampPormodular</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>Localidad</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control ref={this.inputTotLampPormodular} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.TotLampPormodular : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col >
                                    <Form.Control ref={this.inputLocalidad} disabled={!this.state.modificarPosteAP} defaultValue={this.state.inspeccionApActual ? this.state.inspeccionApActual.Localidad : ''} type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' variant='outline-primary' onClick={() => { this.CerrarModalCambiarPosteAP() }}>
                                        Cerrar
                                    </Button>
                                </Col>
                                {
                                    this.state.modificarPosteAP ?
                                        <Col className='text-end'>
                                            <Button size='sm' variant='primary' onClick={() => { this.ActualizarEstructuraDeInspeccionAP()}}>
                                                Guardar
                                            </Button>
                                        </Col>
                                        : <></>
                                }
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Buscar Estructura*/}
                <Modal
                    show={this.state.mostrarModalBuscarEstructuraAP}
                    onHide={() => { this.CerrarModalBuscarEstructuraAp() }}
                    size='md'
                    backdrop="static"
                    centered
                >
                    <Modal.Body style={{ borderStyle: 'solid', borderColor: 'blue', borderWidth: '1px' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <label className='fs-3'>{`Buscar Estructura / Poste`}</label>
                                </Col>
                            </Row>
                            <Divider className='mb-4'></Divider>
                            <Row>
                                <Col md={6}>
                                    <Form.Label className='text-primary'>Valor a Buscar</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control ref={this.inputValorBuscarEstructura} size='sm' placeholder='Ingrese el valor a buscar'></Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Button size='sm' onClick={() => { this.BuscarEstructuraAp() }}>Buscar</Button>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <div className='mt-1' style={{ height: `200px`, width: '100%' }}>
                                    <TableContainer style={{ maxHeight: 200 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell >{`Acción`}</TableCell>
                                                    <TableCell >{`Detalle`}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                this.state.OcultarLoading ?
                                                    <TableBody>
                                                        {this.state.rowBuscarEstructura.map((row, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <Tooltip title="Elegir elemento">
                                                                            <IconButton onClick={() => {
                                                                                this.ElegirEstructuraAP(row)
                                                                            }}>
                                                                                <TouchAppIcon fontSize='small' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Form.Label className='text-primary fw-bold'>{`codNod:`}</Form.Label>
                                                                        <Form.Label>{`${row.codNod} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`codSed:`}</Form.Label>
                                                                        <Form.Label>{`${row.codSed} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`etiEst:`}</Form.Label>
                                                                        <Form.Label>{`${row.etiEst} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`sistemaElectrico:`}</Form.Label>
                                                                        <Form.Label>{`${row.sistemaElectrico} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`AlimentadorMT:`}</Form.Label>
                                                                        <Form.Label>{`${row.AlimentadorMT} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`Distrito:`}</Form.Label>
                                                                        <Form.Label>{`${row.Distrito} `}</Form.Label>

                                                                        <Form.Label className='text-primary fw-bold'>{`Direccion:`}</Form.Label>
                                                                        <Form.Label>{`${row.Direccion} `}</Form.Label>

                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                    : <LoadingModal></LoadingModal>
                                            }

                                        </Table>
                                    </TableContainer>

                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-secondary'>{`${this.state.rowBuscarEstructura.length} Elementos.`}</small>
                                </Col>
                            </Row>
                            <Divider className='mb-4'></Divider>
                            <Row>
                                <Col className='text-end'>
                                    <Button size='sm' variant='outline-primary' onClick={() => { this.CerrarModalBuscarEstructuraAp() }}>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default MonitoreoInspeccionApDetalle;
