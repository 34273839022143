import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../utils/utils'

import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
// Material Icons Elements
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//ReactBootstral
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PhotoIcon from '@material-ui/icons/Photo';


class configProgramacionApMateriales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            permisos: [],
            objmenu: null,
            mostrarModalAgregarMaterial: false,
            mostrarModalVerDetalleMaterial:false,
            mensajeTituloModalAgregar: '',
            columns: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>
                                <Tooltip title="Modificar Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Agregar Programacion Materiales" });
                                        this.AbrirModalVerDetalleMateriales();
                                    }}>
                                        <VisibilityIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Modificar Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle Programacion Material" });
                                        this.AbrirModalAgregarElemento();
                                    }}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'colum1', headerName: 'Trabajador', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum2', headerName: 'Materiales', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum3', headerName: 'Estado', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rows: [
                { id: 1, colum1: 'trabajador1', colum2: '(2)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 2, colum1: 'trabajador2', colum2: '(3)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 3, colum1: 'trabajador3', colum2: '(8)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 4, colum1: 'trabajador4', colum2: '(6)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 5, colum1: 'trabajador5', colum2: '(4)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 6, colum1: 'trabajador6', colum2: '(2)Material(es) agregado(s).', colum3: 'ACTIVO' },
                { id: 7, colum1: 'trabajador7', colum2: '(3)Material(es) agregado(s).', colum3: 'ACTIVO' },               

            ],
            columnsAgregarMaterial: [
                { field: 'colum1', headerName: 'Material', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum2', headerName: 'Cantidad', flex: 0.5, headerClassName: 'header-colum-table' },                
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.5, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>                                
                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },                
            ],
            rowsAgregarMAterial: [
                { id: 1, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 2, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 3, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 4, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 5, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 6, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
                { id: 7, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', acciones: '' },
            ],
            columnsDetalleMaterial: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 0.25, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>                                
                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                    }}>
                                        <PhotoIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },  
                { field: 'colum1', headerName: 'Material', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum2', headerName: 'Asignado', flex: 0.5, headerClassName: 'header-colum-table' },                
                { field: 'colum3', headerName: 'Usado', flex: 0.5, headerClassName: 'header-colum-table' },       
                { field: 'colum4', headerName: 'Restante', flex: 0.5, headerClassName: 'header-colum-table' },       
                              
            ],
            rowsDetalleMAterial: [
                { id: 1, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 2, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 3, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 4, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 5, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 6, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
                { id: 7, acciones: '', colum1: 'Material', colum2: '120',colum3:'81',colum4:'39' },
            ]
        };
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Acciones Aqui
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalAgregarElemento = () => {
        this.setState({ mostrarModalAgregarMaterial: true });
    }
    CerrarModalAgregarElemento = () => {
        this.setState({ mostrarModalAgregarMaterial: false });
    }
    AbrirModalVerDetalleMateriales=()=>{
        this.setState({mostrarModalVerDetalleMaterial:true});
    }
    CerrarModalVerDetalleMateriales=()=>{
        this.setState({mostrarModalVerDetalleMaterial:false});
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };

    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };

    render() {
        return (
            <Fragment>
                <NavBar />
                <TituloPagina titulo={`Programacion - Materiales AP`} regresar={() => { this.props.history.push('/alumbradoPublico/programacionap') }} />
                <Container className='my-2  border rounded'>
                    <Row className='mt-2'>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="date" placeholder="Ingrese valor a buscar" size='sm' />
                                </Col>
                                <Col xs={4}>
                                    <Button variant="outline-primary" size='sm' onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Agregar Actividad" });
                                        this.AbrirModalAgregarElemento()
                                    }}>Agregar</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="text-end" xs={12} md={6}>
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >
                                <MenuItem onClick={() => { }}>Opcion_1</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion_2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { }}>Opcion 3</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 4</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 5</MenuItem>
                            </Menu>
                        </Col>
                    </Row>

                    <Row className='mb-2'>
                        <div className='mt-1' style={{ height: `78vh`, width: '100%' }}>
                            <DataGrid
                                density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={100}
                                rowsPerPageOptions={[20, 50, 100, 200]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal agregar Elemento */}
                <Modal
                    show={this.state.mostrarModalAgregarMaterial}
                    onHide={() => { this.CerrarModalAgregarElemento() }}
                    size='lg'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`${this.state.mensajeTituloModalAgregar}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>FECHA</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type="date" size='sm' placeholder='Ingrese la Fecha' />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>TRABAJADOR PROGRAMADO</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="1">TRABAJADOR_1</option>
                                        <option value="2">TRABAJADOR_2</option>
                                        <option value="3">TRABAJADOR_3</option>
                                        <option value="4">TRABAJADOR_4</option>
                                        <option value="5">TRABAJADOR_5</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>MATERIAL</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Control list='listaSupervisores' size='sm' placeholder='Ingrese el material.'></Form.Control>
                                    <datalist id="listaSupervisores">
                                        <option key='1' value={'Material1'} />
                                        <option key='2' value={'Material2'} />
                                        <option key='3' value={'Material3'} />
                                        <option key='4' value={'Material4'} />
                                        <option key='5' value={'Material5'} />
                                        <option key='6' value={'Material6'} />
                                    </datalist>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CANTIDAD</Form.Label>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={6}>
                                    <Form.Control type='number' size='sm' defaultValue={'1'}></Form.Control>
                                </Col>
                                <Col>
                                    <Button size='sm'>
                                        <i className="fas fa-plus mx-2 " ></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <div className='mt-1' style={{ height: `300px`, width: '100%' }}>
                                    <DataGrid
                                        // density='compact'
                                        rows={this.state.rowsAgregarMAterial}
                                        columns={this.state.columnsAgregarMaterial}
                                        pageSize={100}
                                        rowsPerPageOptions={[20, 50, 100, 200]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                    />
                                </div>

                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row >
                                <Col className='text-end'>
                                    <Button variant="outline-primary" size='sm'>Cerrar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal Detalle Material */}
                <Modal
                    show={this.state.mostrarModalVerDetalleMaterial}
                    onHide={() => { this.CerrarModalVerDetalleMateriales() }}
                    size='lg'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`Detalle Material - Tecnico XX`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>FECHA</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>TRABAJADOR </Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type="date" size='sm' placeholder='Ingrese la Fecha' />
                                </Col>
                                <Col>
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="1">TRABAJADOR_1</option>
                                        <option value="2">TRABAJADOR_2</option>
                                        <option value="3">TRABAJADOR_3</option>
                                        <option value="4">TRABAJADOR_4</option>
                                        <option value="5">TRABAJADOR_5</option>
                                    </Form.Select>
                                </Col>
                            </Row>                                                                                                                                            
                            <Row className='mt-4'>
                                <div className='mt-1' style={{ height: `300px`, width: '100%' }}>
                                    <DataGrid
                                        density='compact'
                                        rows={this.state.rowsDetalleMAterial}
                                        columns={this.state.columnsDetalleMaterial}
                                        pageSize={20}
                                        rowsPerPageOptions={[20, 50, 100, 200]}
                                        // checkboxSelection
                                        localeText={esES.props.MuiDataGrid.localeText}
                                    />
                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row >
                                <Col className='text-end'>
                                    <Button variant="outline-primary" size='sm'>Cerrar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default configProgramacionApMateriales;