import React, { Component, createRef } from 'react';
import { Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal';
import SpinnerCarga from './../utils/spinner';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Modal } from 'react-bootstrap';

import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS, ObtenerDetalleToken } from './../utils/utils';
import config from './../../config/config';


class configPermisos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            TiposUsuario: [],
            ListaPantallas: [],
            ListaTiposTrabajo: [],
            TipoUsuarioActual: {},
            PermisosTipoUsuario: [],
            PermisosTipoTrabajo: [],
            MostrarModalPermisos: false,
            MostrarModalPermisosTipoTrabajo: false,
            MostrarModalAgregarTipoUsuario: false,
            AccionTipoUsuario: "",
            TipoUsuario: null,
            idproyecto: null,
            listaProyectosActivos: []
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Tipo Usuario' },
            { id: 'Permisos Pantalla' },
            { id: 'Permisos Tipo Trabajo' },
            { id: 'Proyecto' },
            { id: 'Estado' }
        ];

        //Referencias
        this.inputBuscar = createRef();

        //Referencias Modal Permisos
        this.inputTipoUsuario = createRef();
        this.selectPantallas = createRef();

        //Referencias Modal Permisos - tipo Trabajo
        this.inputTipoUsuario_1 = createRef();
        this.selectTipoTrabajos = createRef();

        // Referencias Modal Agregar tipousuario
        this.inputDescripcionTipoUsuario = createRef();
        this.selectEstadoTipoUsuario = createRef();
        this.selectProyecto=createRef();
        this.buttonGuardarTipoUsuario = createRef();
        this.buttonModificarTipoUsuario = createRef();
    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PERMISOS, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);                    
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Cargar Tipos de Usuario                
                    await this.listarTiposUsuario();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    listarTiposUsuario = () => {
        

        if (ValidarUsuario()) {
            let url;
            if (this.inputBuscar.current.value.trim() === '') {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/tipousuario/listarproyecto/${this.state.idproyecto}`;
                } else {
                    url = `${config.endPoint}api/tipousuario/listarproyectoTodo`;
                }
            }
            else {
                if (this.state.idproyecto) {
                    url = `${config.endPoint}api/tipousuario/listar/${this.state.idproyecto}/${this.inputBuscar.current.value.trim()}`;
                } else {
                    url = `${config.endPoint}api/tipousuario/listarTodo/${this.inputBuscar.current.value.trim()}`;
                }
            }            

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                
                if (data.content) {
                    this.setState({ TiposUsuario: data.content, loadData: true });
                }
                else {
                    this.setState({ TiposUsuario: [], loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    listarPantallas = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/pantalla/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ ListaPantallas: data.content, loadData: true });
                }
                else {
                    this.setState({ ListaPantallas: [], loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    listarPermisos = () => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/permiso/listar/${this.state.TipoUsuarioActual.idtipousuario}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {

                        this.setState({ PermisosTipoUsuario: data.content, loadData: true });
                        resolve(true);
                    }
                    else {
                        this.setState({ PermisosTipoUsuario: [], loadData: true });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                this.props.history.push('/login');
                reject(true)
            }
        });

    }
    listarPermisosTipoTrabajo = () => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/permisoTipoTrabajo/listar/${this.state.TipoUsuarioActual.idtipousuario}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        
                        this.setState({ PermisosTipoTrabajo: data.content, loadData: true });
                        resolve(true);
                    }
                    else {
                        this.setState({ PermisosTipoTrabajo: [], loadData: true });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                this.props.history.push('/login');
                reject(true)
            }
        });

    }

    listarTiposTrabajo = (idproyecto) => {
        if (ValidarUsuario()) {
            let url;
            url = `${config.endPoint}api/tipotrabajo/listarProyecto/${idproyecto}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    
                    this.setState({ ListaTiposTrabajo: data.content });
                }
                else {
                    this.setState({ ListaTiposTrabajo: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    //*************************************** */
    //MODAL PERMISOS
    //*************************************** */
    AbrirModalPermisos = async (tipousuario) => {
        // cargar datos de permiso
        await this.buscarTipoUsuario(tipousuario.idtipousuario).then();
        //Cargar Lista Pantallas        
        await this.setState({ OcultarLoading: false, TipoUsuarioActual: tipousuario });
        await this.listarPantallas();
        //Buscar Permisos
        await this.listarPermisos().then(async () => {
            await this.setState({ MostrarModalPermisos: true });
            this.inputTipoUsuario.current.value = tipousuario.tipoUsuario;
        });
        await this.setState({ OcultarLoading: true });
    }
    buscarTipoUsuario = (idtipousuario) => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/tipousuario/${idtipousuario}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        
                        this.setState({ TipoUsuarioActual: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ TipoUsuarioActual: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    CerrarModalPermisos = () => {
        this.setState({ MostrarModalPermisos: false });
        this.listarTiposUsuario();
    }
    AgregarPantallaTipoUsuario = () => {
        if (ValidarUsuario()) {
            if (this.selectPantallas.current.value.trim() !== 'TODOS') {
                this.selectPantallas.current.classList.remove('is-invalid');

                let url = `${config.endPoint}api/permiso/agregar`;
                let body_permisos = {
                    idpantalla: this.selectPantallas.current.value,
                    idtipousuario: this.state.TipoUsuarioActual.idtipousuario,
                    estado: 'ACTIVO'
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Agregado", tipotoast: 'success' });
                        this.listarPermisos();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
            else {
                this.selectPantallas.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Seleccione una Pantalla de la lista", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CambiarEstadoPermisoPantalla = (idpermiso, estado) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/permiso/modificar`;
            let body_permisos = {
                idpermiso: idpermiso,
                estado: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Permiso Actualizado", tipotoast: 'success' });
                    this.listarPermisos();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    EliminarPermiso = (permiso) => {
        if (ValidarUsuario()) {
            if (window.confirm(`¿Desea Eliminar el permiso asociado a la Pantalla [${permiso.t_pantalla.pantalla}]?`)) {
                let url = `${config.endPoint}api/permiso/eliminar`;
                let body_permisos = {
                    idpermiso: permiso.idpermiso,
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Eliminado", tipotoast: 'success' });
                        this.listarPermisos();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    // Permisos Tipo Trabajo
    //*************************************** */
    //MODAL PERMISOS TIPO TRABAJO
    //*************************************** */
    AbrirModalPermisosTipoTrabajo = async (tipousuario) => {
        // cargar datos de permiso
        await this.buscarTipoUsuario(tipousuario.idtipousuario).then();

        //Cargar Lista Pantallas        
        await this.setState({ OcultarLoading: false, TipoUsuarioActual: tipousuario });
        await this.listarTiposTrabajo(tipousuario.idproyecto);

        //Buscar Permisos        
        await this.listarPermisosTipoTrabajo().then(async () => {
            await this.setState({ MostrarModalPermisosTipoTrabajo: true });
            this.inputTipoUsuario_1.current.value = tipousuario.tipoUsuario;
        });
        await this.setState({ OcultarLoading: true });
    }
    CerrarModalPermisosTipoTrabajo = () => {
        this.setState({ MostrarModalPermisosTipoTrabajo: false });
        this.listarTiposUsuario();
    }
    AgregarTipoTrabajoTipoUsuario = () => {
        if (ValidarUsuario()) {
            if (this.selectTipoTrabajos.current.value.trim() !== 'TODOS') {
                this.selectTipoTrabajos.current.classList.remove('is-invalid');

                let url = `${config.endPoint}api/permisoTipoTrabajo/agregar`;
                let body_permisos = {
                    idtipotrabajo: this.selectTipoTrabajos.current.value,
                    idtipousuario: this.state.TipoUsuarioActual.idtipousuario,
                    estado: 'ACTIVO'
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Agregado", tipotoast: 'success' });
                        this.listarPermisosTipoTrabajo();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
            else {
                this.selectPantallas.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Seleccione una Pantalla de la lista", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CambiarEstadoPermisoTipoTrabajo = (id, estado) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/permisoTipoTrabajo/modificar`;
            let body_permisos = {
                id: id,
                estado: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Permiso Actualizado", tipotoast: 'success' });
                    this.listarPermisos();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    EliminarPermisoTipoTrabajo = (permiso) => {
        if (ValidarUsuario()) {
            if (window.confirm(`¿Desea Eliminar el permiso asociado al tipo trabajo [${permiso.t_tipotrabajo.tipotrabajo}]?`)) {
                let url = `${config.endPoint}api/permisoTipoTrabajo/eliminar`;
                let body_permisos = {
                    id: permiso.id,
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Eliminado", tipotoast: 'success' });
                        this.listarPermisosTipoTrabajo();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************** */
    //MODAL AGREGAR TIPO USUARIO
    //*************************************** */
    AbrirModalAgregarTipoUsuario = async (idtipousuario, accion) => {
        this.BuscarListaProyectosActivos();
        this.setState({ OcultarLoading: false });
        await this.setState({ MostrarModalAgregarTipoUsuario: true, AccionTipoUsuario: accion });
        if (this.state.AccionTipoUsuario === "Ver") {
            this.DeshabilitarModalTipoUsuario(true);
            await this.BuscarTipoUsuario(idtipousuario).then(() => {
                this.inputDescripcionTipoUsuario.current.value = this.state.TipoUsuario.tipoUsuario;
                this.selectEstadoTipoUsuario.current.value = this.state.TipoUsuario.estado.toUpperCase();
                this.buttonGuardarTipoUsuario.current.disabled = true;
                if(this.state.idproyecto==null)
                {
                    this.selectProyecto.current.value=this.state.TipoUsuario.idproyecto;
                    this.selectProyecto.current.disabled=true;
                }
                this.setState({ OcultarLoading: true });
            }).catch((error) => {
                
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }
        else {
            
            this.buttonModificarTipoUsuario.current.hidden = true
            this.setState({ OcultarLoading: true });
        }
    }
    CerrarModalAgregarTipoUsuario = () => {
        this.setState({ MostrarModalAgregarTipoUsuario: false, AccionTipoUsuario: '' });
    }
    DeshabilitarModalTipoUsuario = (valor) => {
        this.inputDescripcionTipoUsuario.current.readOnly = valor;
        this.selectEstadoTipoUsuario.current.disabled = valor
    }
    BuscarTipoUsuario = (idtipousuario) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/tipousuario_permisos/${idtipousuario}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ TipoUsuario: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ TipoUsuario: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    ModificarTipoUsuario = () => {
        this.DeshabilitarModalTipoUsuario(false);
        this.buttonGuardarTipoUsuario.current.disabled = false;
        this.buttonModificarTipoUsuario.current.disabled = true;
    }
    GuardarTipoUsuario = (ev) => {
        ev.preventDefault();
        switch (this.state.AccionTipoUsuario) {
            case 'Ver':
                this.ActualizarTipoUsuario();
                break;
            case 'Agregar':
                this.AgregarTipoUsuario();
                break;
            default:
        }
    }
    ActualizarTipoUsuario = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipousuario/modificar`
            let nuevoTipoUsuario = {
                idtipousuario: this.state.TipoUsuario.idtipousuario,
                tipoUsuario: this.inputDescripcionTipoUsuario.current.value.toUpperCase(),
                estado: this.selectEstadoTipoUsuario.current.value.toUpperCase()
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoTipoUsuario)
            };
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Tipo de Usuario actualizado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregarTipoUsuario();
                    this.listarTiposUsuario();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    AgregarTipoUsuario = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipousuario/agregar`
            let nuevoTipoUsuario = {
                tipoUsuario: this.inputDescripcionTipoUsuario.current.value.toUpperCase(),
                estado: this.selectEstadoTipoUsuario.current.value.toUpperCase(),
                idproyecto:this.state.idproyecto?this.state.idproyecto:this.selectProyecto.current.value
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoTipoUsuario)
            };
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Tipo de Usuario Agregado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregarTipoUsuario();
                    this.listarTiposUsuario();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************************
    //Funciones InputBuscar
    //*************************************************
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value.trim() === '') {
            await this.listarTiposUsuario();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.listarTiposUsuario();
        }
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    CambiarVisualizacionTrabajosPantalla = async (idtipousuario, estado) => {
        

        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/tipousuario/modificar`;
            let body_permisos = {
                idtipousuario: idtipousuario,
                visualizartodostrabajos: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Permiso Actualizado", tipotoast: 'success' });
                    this.buscarTipoUsuario(idtipousuario).then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
        // await this.setState({ desactivarDescargaListaTipoTrabajo: !this.state.desactivarDescargaListaTipoTrabajo })
        // if(!this.state.desactivarDescargaListaTipoTrabajo){
        //     this.BuscarListaTipoTrabajoActivos().then();
        // }
    }
    CambiarVisualizacionTipoTrabajo = async (idtipousuario, estado) => {
        

        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/tipousuario/modificar`;
            let body_permisos = {
                idtipousuario: idtipousuario,
                visualizarportipotrabajos: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Permiso Actualizado", tipotoast: 'success' });
                    this.buscarTipoUsuario(idtipousuario).then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
        // await this.setState({ desactivarDescargaListaTipoTrabajo: !this.state.desactivarDescargaListaTipoTrabajo })
        // if(!this.state.desactivarDescargaListaTipoTrabajo){
        //     this.BuscarListaTipoTrabajoActivos().then();
        // }
    }

    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    
                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }

    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - PERMISOS" regresar={() => { this.props.history.push('/configuracion') }} />

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className=" col-8 col-md-4">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Tipo Usuario"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.listarTiposUsuario() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregarTipoUsuario(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                        </div>

                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.TiposUsuario.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregarTipoUsuario(row.idtipousuario, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell>{row.tipoUsuario}</TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalPermisos(row) }} className="iconoAcciones">
                                                                {<i title="Permisos" className="fas fa-screwdriver iconoAcciones"></i>}
                                                                <span >{`(${row.t_permisos.length}) Permiso(s) Incluido(s)`}</span>
                                                            </TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalPermisosTipoTrabajo(row) }} className="iconoAcciones">
                                                                {<i title="Permisos" className="fas fa-screwdriver iconoAcciones"></i>}
                                                                <span >{`(${row.t_permisotipotrabajos.length}) Tipo(s) Trabajo Incluido(s)`}</span>
                                                            </TableCell>
                                                            <TableCell>{row.t_proyecto.nombreproyecto}</TableCell>
                                                            <TableCell>
                                                                {row.estado.toUpperCase() === 'ACTIVO' ?
                                                                    <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                    <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="mt-3">
                                        <small >{`${this.state.TiposUsuario.length} Elemento(s) Encontrado(s)`}</small>
                                    </div>

                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>



                {/* Modal Permisos */}
                <Modal
                    show={this.state.MostrarModalPermisos}
                    onHide={() => { this.CerrarModalPermisos() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Permisos</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">

                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Tipo de Usuario</small>
                                    <input ref={this.inputTipoUsuario} className="form-control form-control-sm" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Screen</small>
                                    <select ref={this.selectPantallas} className="form-select form-select-sm" >
                                        <option value="TODOS">Seleccione Pantalla</option>
                                        {this.state.ListaPantallas.map((row, index) => {
                                            return (<option key={index} value={row.idpantalla}>{row.pantalla}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                    <button onClick={() => { this.AgregarPantallaTipoUsuario() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                </div>
                            </div>
                            {/* MOSTRAR SOLO TRABAJOS Y PROGRAMACIONES DEL USUARIO */}
                            <div className='row '>
                                <div className='col'>
                                    <small className="text-primary">{'Visualizacion de Trabajos'}</small>
                                    <div className="form-check form-switch">
                                        {
                                            this.state.TipoUsuarioActual.visualizartodostrabajos == 'ACTIVO' ?
                                                <input onChange={() => { this.CambiarVisualizacionTrabajosPantalla(this.state.TipoUsuarioActual.idtipousuario, 'INACTIVO') }} className="form-check-input" type="checkbox" value="" id='chechvisualizaciontrabajo' /> :
                                                <input onChange={() => { this.CambiarVisualizacionTrabajosPantalla(this.state.TipoUsuarioActual.idtipousuario, 'ACTIVO') }} className="form-check-input" type="checkbox" value="" id='chechvisualizaciontrabajo' defaultChecked />


                                        }
                                        <label className="form-check-label" htmlFor="chechvisualizaciontrabajo">
                                            Mostrar solo trabajos asociados a la cuenta del usuario.
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Tabla de Actividades aqui */}
                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow className='fondoTituloTabla'>
                                        <TableCell>Acciones</TableCell>
                                        <TableCell>Pantalla</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.PermisosTipoUsuario.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-6 ">
                                                                <i onClick={() => { this.EliminarPermiso(row) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                    {
                                                                        (row.estado === 'ACTIVO') ?
                                                                            <input onClick={() => { this.CambiarEstadoPermisoPantalla(row.idpermiso, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                            <input onClick={() => { this.CambiarEstadoPermisoPantalla(row.idpermiso, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{row.t_pantalla.pantalla}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <small>{`${this.state.PermisosTipoUsuario.length} Elementos`}</small>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-end">
                                    <button onClick={() => { this.CerrarModalPermisos() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Permisos Tipo Trabajo */}
                <Modal
                    show={this.state.MostrarModalPermisosTipoTrabajo}
                    onHide={() => { this.CerrarModalPermisosTipoTrabajo() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Permisos</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">

                            {/* MOSTRAR SOLO TRABAJOS Y PROGRAMACIONES DEL USUARIO */}
                            <div className='row '>
                                <div className='col'>
                                    <small className="text-primary">{'Visualizacion de Trabajos'}</small>
                                    <div className="form-check form-switch">
                                        {
                                            this.state.TipoUsuarioActual.visualizarportipotrabajos == 'ACTIVO' ?
                                                <input onChange={() => { this.CambiarVisualizacionTipoTrabajo(this.state.TipoUsuarioActual.idtipousuario, 'INACTIVO') }} className="form-check-input" type="checkbox" value="" id='chechvisualizaciontrabajo' defaultChecked /> :
                                                <input onChange={() => { this.CambiarVisualizacionTipoTrabajo(this.state.TipoUsuarioActual.idtipousuario, 'ACTIVO') }} className="form-check-input" type="checkbox" value="" id='chechvisualizaciontrabajo' />

                                        }
                                        <label className="form-check-label" htmlFor="chechvisualizaciontrabajo">
                                            Mostrar solo trabajos asociados a la lista de tipos de trabajo.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Tipo de Usuario</small>
                                    <input ref={this.inputTipoUsuario_1} className="form-control form-control-sm" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Tipo Trabajo</small>
                                    <select ref={this.selectTipoTrabajos} className="form-select form-select-sm" >
                                        <option value="TODOS">Seleccione Tipo Trabajo</option>
                                        {this.state.ListaTiposTrabajo.map((row, index) => {
                                            return (<option key={index} value={row.idTipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                    <button onClick={() => { this.AgregarTipoTrabajoTipoUsuario() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                </div>
                            </div>

                        </div>
                        {/* Tabla de Actividades aqui */}
                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow className='fondoTituloTabla'>
                                        <TableCell>Acciones</TableCell>
                                        <TableCell>Tipo Trabajo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.PermisosTipoTrabajo.map((row, index) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell className="">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-6 ">
                                                                <i onClick={() => { this.EliminarPermisoTipoTrabajo(row) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                    {
                                                                        (row.estado === 'ACTIVO') ?
                                                                            <input onClick={() => { this.CambiarEstadoPermisoTipoTrabajo(row.id, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                            <input onClick={() => { this.CambiarEstadoPermisoTipoTrabajo(row.id, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{row.t_tipotrabajo.tipotrabajo}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <small>{`${this.state.PermisosTipoTrabajo.length} Elementos`}</small>
                                </div>
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col text-end">
                                    <button onClick={() => { this.CerrarModalPermisosTipoTrabajo() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Agregar Tipo Usuario*/}
                <Modal
                    show={this.state.MostrarModalAgregarTipoUsuario}
                    onHide={() => { this.CerrarModalAgregarTipoUsuario() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <form onSubmit={(ev) => { this.GuardarTipoUsuario(ev) }}>
                        <Modal.Header>
                            <Modal.Title>Tipo Usuario - {this.state.AccionTipoUsuario}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">
                                {
                                    this.state.idproyecto == null ?
                                    <div className="row my-1">
                                        <div className="col-md-6">
                                            <small className="text-primary">Proyecto</small>
                                            <select ref={this.selectProyecto} className="form-select form-select-sm" required >
                                                <option value="" disabled>Seleccione un Proyecto</option>
                                                {
                                                    this.state.listaProyectosActivos.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                        : <></>
                                }
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDescripcionTipoUsuario} required type="" className="form-control form-control-sm" placeholder="Ingrese una Descripción"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.selectEstadoTipoUsuario} required className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        <button ref={this.buttonModificarTipoUsuario} onClick={() => { this.ModificarTipoUsuario() }} className="btn btn-sm btn-outline-primary">Modificar</button>
                                    </div>
                                    <div className="col-7 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregarTipoUsuario() }} className="btn btn-sm btn-outline-secondary mx-1">Cancelar</button>
                                        <button ref={this.buttonGuardarTipoUsuario} type="submit" className="btn btn-sm btn-primary mx-1">Guardar</button>
                                    </div>
                                </div>

                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>

            </Fragment >
        );
    }
}

export default configPermisos;