import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
// import socketIOClient from 'socket.io-client';

import { MapContainer, TileLayer, Marker, Popup, Polyline, Tooltip, Circle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';

// Floating Button
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { IconoPinRojo, IconoPinAzul, IconoPinVerde, IconoFind } from './../../config/icono'
import SpinnerCarga from './../utils/spinner'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS,ObtenerDetalleToken } from '../utils/utils'
import config from './../../config/config';

export default class monitoreSuministroMapa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nombreTrabajador: '',
            idtrabajador: '',
            fechaprogramacion: '',
            sizecontent: 0,
            widthcontent: 0,
            mostrarEtiqueta: true,
            mostraLisuministros: '',
            mostraBotonLisuministros: 'none',
            loadData: false,
            ListaTrabajos: [],
            ListaFiltro: [],
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            zoomMapa: 13,
            centroInicial: {
                lat: -16.398821,
                lng: -71.536862
            },
            centroCiculo: {
                lat: 0,
                lng: 0
            },
            mostrarCirculo: false,
            idproyecto:null,
        }
        // Referencias
        this.divcontenedor = createRef();
        this.maparef = createRef();
        this.inputBuscarFiltro = createRef();
        this.circleLeaflet = createRef();

        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});
    }
    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async(permisos) => {
                // console.log(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO,permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if(UsuarioStorage.token){
                        let DetalleToken=JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if(DetalleToken.idproyecto){
                            this.setState({idproyecto:DetalleToken.idproyecto});                            
                        }                        
                    }

                    await this.setState({
                        nombreTrabajador: this.props.location.state.nombretrabajador,
                        fechaprogramacion: this.props.match.params.fecha,
                        idtrabajador: this.props.match.params.idtrabajador
                        , loadData: true
                    });
                    // console.log("Buscando Suministros");
                    await this.BuscarSuministros();
                    // await this.SocketConect();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });

        } else {
            this.props.history.push('/login');
        }        
    }
    
    // SocketConect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.BuscarSuministros();
    //     });
    // }

    // componentWillUnmount() {
    //     this.socket.close();        
    // }

    BuscarSuministros = async () => {

        await this.setState({ ListaTrabajos: [] });
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    let sumSinGPS = data.content.rows.filter(suministro => suministro.lat_inicio === 0);
                    this.setState({ ListaTrabajos: data.content.rows, ListaFiltro: sumSinGPS });
                }
                else {
                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarSuministrosEjecutados = async () => {
        await this.setState({ ListaTrabajos: [] });
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    let suministrosejecutados = data.content.rows.filter(suministro => suministro.estado != "PROGRAMADO");
                    this.setState({ ListaTrabajos: suministrosejecutados });
                }
                else {
                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarSuministrosNoEjecutados = async () => {
        await this.setState({ ListaTrabajos: [] });
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.idproyecto}/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    let suministrosejecutados = data.content.rows.filter(suministro => suministro.estado === "PROGRAMADO");
                    this.setState({ ListaTrabajos: suministrosejecutados });
                }
                else {
                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    MapearTodosSuministros = async () => {
        await this.setState({ zoomMapa: 13, loadData: false, mostrarCirculo: false, centro: this.state.centroInicial });
        await this.setState({ loadData: true });
        this.BuscarSuministros();
    }

    MapearSuministrosNoEjecutados = async () => {
        await this.setState({ zoomMapa: 13, loadData: false, mostrarCirculo: false, centro: this.state.centroInicial });
        await this.setState({ loadData: true });
        this.BuscarSuministrosNoEjecutados();
    }
    MapearSuministrosEjecutados = async () => {
        await this.setState({ zoomMapa: 13, loadData: false, mostrarCirculo: false, centro: this.state.centroInicial });
        await this.setState({ loadData: true });
        this.BuscarSuministrosEjecutados();
    }

    CambioInputBuscar = async () => {
        if (this.inputBuscarFiltro.current.value.trim() === '') {
            await this.setState({ zoomMapa: 13, loadData: false, mostrarCirculo: false });
            await this.setState({ loadData: true });
        }
    }

    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {

            if (this.inputBuscarFiltro.current.value.trim() !== '') {
                let TrabajosTemp = this.state.ListaTrabajos;

                let SuministrosBuscados = this.state.ListaTrabajos.filter(suministro => suministro.suministro === this.inputBuscarFiltro.current.value.trim())
                if (SuministrosBuscados.length > 0) {
                    await this.setState({ loadData: false });

                    if (SuministrosBuscados[0].lat_ejecucion != null) {
                        await this.setState({
                            centroCiculo: {
                                lat: SuministrosBuscados[0].lat_ejecucion,
                                lng: SuministrosBuscados[0].lng_ejecucion
                            },
                            centro: {
                                lat: SuministrosBuscados[0].lat_ejecucion,
                                lng: SuministrosBuscados[0].lng_ejecucion
                            }, zoomMapa: 17, loadData: true, mostrarCirculo: true
                        });
                    }
                    else {
                        await this.setState({
                            centroCiculo: {
                                lat: SuministrosBuscados[0].lat_inicio,
                                lng: SuministrosBuscados[0].lng_inicio
                            },
                            centro: {
                                lat: SuministrosBuscados[0].lat_inicio,
                                lng: SuministrosBuscados[0].lng_inicio
                            }, zoomMapa: 17, loadData: true, mostrarCirculo: true
                        });

                    }
                    //this.circleLeaflet.current.hidden = false;
                }
                else {
                    this.setState({ mensajeToast: "No se encontro suministro en el Mapa.", abrirToast: true, tipotoast: 'warning' });
                }
            }
        }
    }

    CambioSizeWindows = () => {
        this.setState({ sizecontent: window.innerHeight, widthcontent: window.innerWidth });
        if (window.innerWidth < 768) {
            this.setState({ mostraLisuministros: 'none', mostraBotonLisuministros: '' });
        }
        else {
            this.setState({ mostraLisuministros: '', mostraBotonLisuministros: 'none' });
        }
    }

    toogleEtiquetaMarcador = () => {
        // this.setState({mostrarEtiqueta:!this.state.mostrarEtiqueta});
        // console.log(this.maparef.current);
    }
    toggleListaSuministros = () => {
        if (this.state.mostraLisuministros === 'none') {
            this.setState({ mostraLisuministros: '' });
        }
        else {
            this.setState({ mostraLisuministros: 'none' });
        }

    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`MONITOREO - ${this.state.nombreTrabajador}`} regresar={() => { this.props.history.push(`/monitoreo/trabajador/${this.state.fechaprogramacion}/${this.state.idtrabajador}`) }}></TituloPagina>

                <div className="container">
                    <div className="row my-1">
                        <div className="col-md-3 ">
                            {/* <input onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input> */}
                            <small className="text-primary">{`Fecha Programación : `}</small><span>{`${this.state.fechaprogramacion}`}</span>
                        </div>
                        <div className="col-md-4 mt-1 mt-md-0">
                            <input onChange={() => { this.CambioInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar Suministro`}></input>
                            {/* <datalist id="listaTrabajadoresProgramados">
                                    {
                                        this.state.ListaTrabajadores.map((row, index) => {
                                            return (<option key={index} value={row.nombretrabajador} />)
                                        })
                                    }
                                </datalist> */}

                        </div>
                        <div className="col-md-5 text-end mt-1 mt-md-0">
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                    </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.MapearTodosSuministros() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Mapear todos los Suministros</small></li>
                                    {/* Crear WebSocket Para Generar la conexion en tiempo Real */}
                                    {/* <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-sync-alt mx-2"></i>Ver en Tiempo Real</small></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li>
                                        <li onClick={() => { this.toogleAgrupar(true) }}><small className="dropdown-item" ><i className="far fa-object-group mx-2"></i>Mostrar Agrupado</small></li>
                                        <li onClick={() => { this.toogleAgrupar(false) }}><small className="dropdown-item" ><i className="fas fa-map-marked-alt mx-2"></i>Mostrar Sin Agrupar</small></li>
                                        <li onClick={() => { this.toogleEtiqueta(true) }}><small className="dropdown-item" ><i className="fas fa-tags mx-2"></i>Mostrar Con Etiquetas</small></li>
                                        <li onClick={() => { this.toogleEtiqueta(false) }}><small className="dropdown-item" ><i className="fas fa-comment-slash mx-2"></i>Mostrar Sin Etiquetas</small></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="container-fluid mt-1 " style={{ height: `80vh` }}>
                    {
                        this.state.loadData ?
                            <>
                                <MapContainer center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `82vh` }}>
                                    {/* <TileLayer
                                        url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                        maxZoom={20}
                                        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                    /> */}
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MarkerClusterGroup >
                                        {
                                            this.state.ListaTrabajos.map((row, index) => {
                                                // console.log(row);
                                                if (row.lat_inicio !== 0) {
                                                    return (
                                                        <Marker key={index} title="" position={row.lat_ejecucion != null ? [row.lat_ejecucion, row.lng_ejecucion] : [row.lat_inicio, row.lng_inicio]} icon={row.estado === "PROGRAMADO" ? IconoPinAzul : (row.estado === "EJECUTADO" ? IconoPinVerde : IconoPinRojo)}>
                                                            <Popup>
                                                                <div>
                                                                    <small className="text-primary">Suministro :</small><span>{row.suministro}</span>
                                                                </div>
                                                                <div>
                                                                    <small className="text-primary">Cliente :</small><span>{row.nombreCliente}</span>
                                                                </div>
                                                                <div>
                                                                    <small className="text-primary">Dirección :</small><span>{row.direccionCliente}</span>
                                                                </div>
                                                                <div>
                                                                    <small className="text-primary">Estado :</small><span>{row.estado}</span>
                                                                </div>
                                                                <div>
                                                                    <small className="text-primary">Detalle :</small><span>{row.detalle}</span>
                                                                </div>
                                                            </Popup>
                                                        </Marker>
                                                    )
                                                }
                                                // else {
                                                //     return (< ></>)
                                                // }
                                            })
                                        }
                                    </MarkerClusterGroup>

                                    {
                                        this.state.mostrarCirculo ?
                                            <Circle
                                                center={this.state.centroCiculo}
                                                fillColor="red"
                                                color="red"
                                                radius={50} />
                                            : <></>
                                    }

                                </MapContainer>

                            </>
                            : <SpinnerCarga />

                    }
                </div>
                {/* ************************* */}
                {/* Boton Flotante  */}
                {/* ************************* */}
                <Fab alwaysShowTitle={true}
                    icon={<i className="fas fa-plus"></i>}
                    mainButtonStyles={{ backgroundColor: 'rgb(230,83,97)', outline: 'none' }}
                    style={{ bottom: 10, right: 10 }}>
                    <Action text="Ver Suministros Ejecutados"
                        onClick={() => { this.MapearSuministrosEjecutados() }}
                        style={{ backgroundColor: 'rgb(25,118,210)' }}
                    >
                        <i className="fas fa-street-view "></i>
                    </Action>
                    <Action text="Ver Suministros no Ejecutados"
                        style={{ backgroundColor: 'rgb(25,118,210)' }}
                        onClick={() => { this.MapearSuministrosNoEjecutados() }}>
                        <i className="fas fa-draw-polygon"></i>
                    </Action>
                    <Action text="Ver Todos"
                        onClick={() => { this.MapearTodosSuministros() }}
                        style={{ backgroundColor: 'rgb(25,118,210)' }}

                    >
                        <i className="fas fa-thumbtack"></i>
                        {/* <i className="fas fa-street-view "></i> */}
                    </Action>
                </Fab>

                <Snackbar
                    open={this.state.ListaFiltro.length > 0 ? true : false}
                    message={`${this.state.ListaFiltro.length} Suministros sin ubicación GPS`}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    ref={this.circleLeaflet}
                />

            </Fragment>
        );
    }
}