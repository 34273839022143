import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS,ValidarUsuario } from './../utils/utils'

import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';

class bodyOperacionesComerciales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permisos: []
        };

    }
    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then( (permisos) => {                
                this.setState({ permisos: permisos });                
            });
        }
        else {            
            this.props.history.push('/login');
        }
    }

    render() {
        return (
            <Fragment>
                <NavBar />
                <TituloPagina titulo="Operaciones Comerciales" regresar={() => { this.props.history.push('/') }} />

                <div className="container">
                    <div className="row mt-3">
                        {/* Elementos */}


                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro" >
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }} >
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-file-excel fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/programacion" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Programación</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-globe-europe fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/monitoreo" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Monitoreo</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-search fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/buscar" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Buscar</Link>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICA, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-chart-line fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/estadistica" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Estadisticas</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR_EN_LINEA, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-file-invoice fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>

                                        </div>
                                        <Link to="buscar/consultasenlinea" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Buscar Online</Link>
                                    </div>
                                </div>
                                : <></>
                        }


                    </div>
                </div>
            </Fragment>
        );
    }
}

export default bodyOperacionesComerciales;