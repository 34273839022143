import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos, CreateJsonToExcel, ObtenerDetalleToken } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
// import socketIOClient from 'socket.io-client';

import { Stack } from '@mui/material';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal, Container, Row, Col, Carousel, Form, Button } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';


class AlmacenEpps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            LoadData: false,
            cantidadElementos: 0,
            filasPorPagina: 100,
            pagina: 1,
            ListaPaginacion: [],
            DataEpps: [],
            MostrarModalMaestroEpps: false,
            MostrarModalRegistroEpps: false,
            MostrarModalRegistroEppsModificar: false,
            DataRegistrosEpps: [],
            DataMaestroEpps: [],
            ListaMaestroEppFiltrado: [],
            ListaTrabajadoresActivos: [],
            RegistroEppsActual: {},
            AccionActual: '',
            idproyecto: null,
            listaProyectosActivos: []
        };

        this.inputfechaProgramacion = createRef();
        this.inputValorBuscar = createRef();
        this.inputFechaInicioReporteFecha = createRef();
        this.inputFechaFinReporteFecha = createRef();

        //Ref Maestro Epp
        this.inputNombreMaestroEpp = createRef();
        this.inputUnidadMaestroEpp = createRef();
        this.inputEstadoMaestroEpp = createRef();
        this.inputBuscarMaestroEpp = createRef();

        //Ref Registro EPPs
        this.inputNombreRegistroEpps = createRef();
        this.selectEstadoRegistroEpps = createRef();
        this.selectProyecto = createRef();
        this.inputObservacionRegistroEpps = createRef();
    }
    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {

                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN_EPPS, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }

                    this.BuscarListaRegistrosEpp();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }
            });
        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    //Registro Epps
    ValidarControlsRegistroEpps = () => {
        let error = 0;
        if (this.inputNombreRegistroEpps.current.value == '') {
            this.inputNombreRegistroEpps.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputNombreRegistroEpps.current.classList.remove('is-invalid');
        }

        if (this.selectEstadoRegistroEpps.current.value == '') {
            this.selectEstadoRegistroEpps.current.classList.add('is-invalid');
            error++;
        } else {
            this.selectEstadoRegistroEpps.current.classList.remove('is-invalid');
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    AgregarRegistroEpps = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarControlsRegistroEpps()) {

                let IdTrabajador = await this.BuscarIdTrabajadorReasignado(this.inputNombreRegistroEpps.current.value);

                if (IdTrabajador) {
                    let url = '';
                    url = `${config.endPoint}api/registroepps/agregar`;

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        }, body: JSON.stringify({
                            estado: this.selectEstadoRegistroEpps.current.value,
                            idtrabajador: IdTrabajador,
                            idproyecto: this.state.idproyecto ? this.state.idproyecto : this.selectProyecto.current.value
                        })
                    };

                    

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', MostrarModalRegistroEpps: false });
                            this.BuscarListaRegistrosEpp();
                        } else {
                            this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                } else {
                    this.setState({ mensajeToast: "No se encontro ID del trabajador ingresado.", abrirToast: true, tipotoast: 'error' });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ModificarRegistroEpps = async () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/registroepps/modificar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }, body: JSON.stringify({
                    idregistroepp: this.state.RegistroEppsActual.idregistroepp,
                    estado: this.selectEstadoRegistroEpps.current.value,
                    observacion: this.inputObservacionRegistroEpps.current.value
                })
            };

            

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', MostrarModalRegistroEppsModificar: false });
                    this.BuscarListaRegistrosEpp();
                } else {
                    this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error' });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaTrabajadoresActivos = (idproyecto) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listar/${idproyecto}/ACTIVO`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(false);
            }
        });
    }
    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador.idtrabajador;
            }
        }
        return null;
    }
    BuscarListaRegistrosEpp = async () => {
        if (ValidarUsuario()) {
            this.setState({ LoadData: false });
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            if (this.state.idproyecto) {
                url = `${config.endPoint}api/registroepps/listarProyecto`;
            } else {
                url = `${config.endPoint}api/registroepps/listar`;
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    valorbuscar: this.inputValorBuscar.current.value,
                    idproyecto: this.state.idproyecto,
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                
                if (data.content) {
                    await this.setState({ DataRegistrosEpps: data.content, cantidadElementos: data.content.length });
                    await this.Paginacion();
                }
                else {
                    this.setState({ DataEpps: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', LoadData: true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.DataRegistrosEpps.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.DataRegistrosEpps.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.DataRegistrosEpps[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });

    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarEppsFecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarEppsFecha();
    }
    //*************************************************
    //Funciones para Controles
    //*************************************************    
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.BuscarListaRegistrosEpp();
        }
    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.BuscarListaRegistrosEpp();
        }
    }
    //*************************************************
    //Funciones para Modales
    //*************************************************
    // Modal Detalle Epps
    AbrirModalRegistroEpps = () => {
        this.BuscarListaProyectosActivos();
        if (this.state.idproyecto) {
            this.BuscarListaTrabajadoresActivos(this.state.idproyecto);
        }
        this.setState({ MostrarModalRegistroEpps: true });
    }
    CerrarModalRegistroEpps = () => {
        this.setState({ MostrarModalRegistroEpps: false });
    }
    CambiarProyecto = (ev) => {
        this.BuscarListaTrabajadoresActivos(ev.target.value);
    }

    //*************************************************
    //Reportes
    //*************************************************
    DescargarReporteFecha = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = ``;
            if(this.state.idproyecto){
                url = `${config.endPoint}api/registroEppDetalle/reporteCompleto/${this.state.idproyecto}`;
            }else{
                url = `${config.endPoint}api/registroEppDetalle/reporteCompleto`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Resumen_Epps_`, `Epps_`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    //Modales
    AbrirModalMaestroEpps = () => {
        this.BuscarListaProyectosActivos();
        this.ListarMaestroEpps();
        this.setState({ MostrarModalMaestroEpps: true });
    }
    CerrarModalMaestroEpps = () => {
        this.setState({ MostrarModalMaestroEpps: false });
    }
    //Registro Epps Modificar
    AbrirModalRegistroEppModificar = async (row) => {
        await this.setState({ MostrarModalRegistroEppsModificar: true, RegistroEppsActual: row, AccionActual: '' });
        this.inputNombreRegistroEpps.current.value = row.nombretrabajador;
        this.selectEstadoRegistroEpps.current.value = row.estado;
        this.inputObservacionRegistroEpps.current.value = row.observacion;

        this.inputNombreRegistroEpps.current.disabled = true;
        this.selectEstadoRegistroEpps.current.disabled = true;
        this.inputObservacionRegistroEpps.current.disabled = true;
    }
    CerrarModalRegistroEppModificar = () => {
        this.setState({ MostrarModalRegistroEppsModificar: false });
    }
    HabilitarModificarRegistroEpps = (estado) => {
        if (estado) {
            this.setState({ AccionActual: 'MODIFICAR_REG_EPPS' });
        } else {
            this.setState({ AccionActual: '' });
        }
        this.selectEstadoRegistroEpps.current.disabled = !estado;
        this.inputObservacionRegistroEpps.current.disabled = !estado;
    }
    //Maestro Epps
    ListarMaestroEpps = async () => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            if (this.state.idproyecto) {
                url = `${config.endPoint}api/MaestroEpps/listar/${this.state.idproyecto}`;
            } else {
                url = `${config.endPoint}api/MaestroEpps/listar`;
            }

            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                
                if (data.content) {
                    await this.setState({ DataMaestroEpps: data.content, OcultarLoading: true });
                    this.FiltrarListaMaestroEpps(data.content);
                }
                else {
                    this.setState({ DataMaestroEpps: [], cantidadElementos: 0, OcultarLoading: true });
                }
            }).catch((error) => {
                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });

        }
        else {
            this.props.history.push('/login');
        }

    }
    FiltrarListaMaestroEpps = (contentMaestroEpps) => {
        if (this.inputBuscarMaestroEpp.current.value !== '') {
            let arrayFiltro = [];
            contentMaestroEpps.forEach(element => {
                if (element.descripcion.includes(this.inputBuscarMaestroEpp.current.value.toUpperCase())) {
                    arrayFiltro.push(element);
                }
            });
            this.setState({ ListaMaestroEppFiltrado: arrayFiltro });
        } else {
            this.setState({ ListaMaestroEppFiltrado: contentMaestroEpps });
        }
    }
    ValidarControlesMaestroEpp = () => {
        let error = 0;
        if (this.inputNombreMaestroEpp.current.value == '') {
            this.inputNombreMaestroEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputNombreMaestroEpp.current.classList.remove('is-invalid');
        }

        if (this.inputUnidadMaestroEpp.current.value == '') {
            this.inputUnidadMaestroEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputUnidadMaestroEpp.current.classList.remove('is-invalid');
        }

        if (this.inputEstadoMaestroEpp.current.value == '') {
            this.inputEstadoMaestroEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputEstadoMaestroEpp.current.classList.remove('is-invalid');
        }
        if(this.state.idproyecto==null){
            if (this.selectProyecto.current.value == '') {
                this.selectProyecto.current.classList.add('is-invalid');
                error++;
            } else {
                this.selectProyecto.current.classList.remove('is-invalid');
            }
        }

        if (error > 0) {
            return false;
        }

        return true;
    }
    AgregarMaestroEpp = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarControlesMaestroEpp()) {
                await this.setState({ OcultarLoading: false });

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = '';
                url = `${config.endPoint}api/MaestroEpps/agregar`;

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        descripcion: this.inputNombreMaestroEpp.current.value.toUpperCase(),
                        unidad: this.inputUnidadMaestroEpp.current.value.toUpperCase(),
                        estado: this.inputEstadoMaestroEpp.current.value.toUpperCase(),
                        idproyecto:this.state.idproyecto?this.state.idproyecto:this.selectProyecto.current.value
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.inputNombreMaestroEpp.current.value = '';
                        this.inputUnidadMaestroEpp.current.value = '';
                        this.inputEstadoMaestroEpp.current.value = '';
                        if(this.state.idproyecto==null){
                            this.selectProyecto.current.value = '';
                        }

                        await this.setState({ mensajeToast: "Registro Agregado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                        this.ListarMaestroEpps();
                    }
                    else {
                        this.setState({ OcultarLoading: true });
                    }

                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CambiarEstadoMaestroEpps = async (estado, row) => {
        if (!ValidarUsuario()) {
            this.props.history.push('/login');
        } else {
            await this.setState({ OcultarLoading: false });

            let url = "";
            url = `${config.endPoint}api/MaestroEpps/modificar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idmaestroepp: row.idmaestroepp,
                    estado: estado
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                
                if (data.content > 0) {
                    this.ListarMaestroEpps();
                    this.setState({ mensajeToast: "Registro Actualizado", abrirToast: true, tipotoast: 'success', OcultarLoading: true });
                }
                this.setState({ OcultarLoading: true });
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });

        }
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    //*************************************************
    // Funciones de Pantalla
    //*************************************************

    componentDidUpdate() {
        window.addEventListener('resize', this.CambioSizeWindows);
    }
    CambioSizeWindows = () => {
        this.setState({ innerHeight: window.innerHeight, innerWidth: window.innerWidth });
    }

    BuscarListaProyectosActivos = () => {
        if (ValidarUsuario()) {
            let url = '';
            url = `${config.endPoint}api/Proyecto/listarActivos`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    
                    await this.setState({ listaProyectosActivos: data.content });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning', loadData: true });
            });
        }
        else {
            this.props.history.push('/login')
        }
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`Registro  de EPP's `} regresar={() => { this.props.history.push('/almacen') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <Container className='border mt-1'>
                    <Row className='mt-1'>
                        {/* <Col sm={12} md={4}>
                        <input ref={this.inputfechaProgramacion} onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ></input>
                    </Col> */}
                        <Col sm={12} md={3} className='mt-md-0 mt-1'>
                            <input ref={this.inputValorBuscar} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} className="form-control form-control-sm" placeholder={`Buscar Trabajador`}></input>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1 '}>
                            <Button onClick={() => { this.BuscarListaRegistrosEpp() }} size='sm'>Buscar</Button>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1 text-end'}>
                            <Button onClick={() => { this.AbrirModalRegistroEpps() }} size='sm'>Agregar Registro</Button>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'mt-2 text-end' : 'mt-md-0 mt-1 text-end'}>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.DescargarReporteFecha() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Descargar Resumen actual</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li onClick={() => { this.BuscarListaRegistrosEpp() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li onClick={() => { this.AbrirModalMaestroEpps() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Ver Maestro Epp's</small></li>

                                </ul>
                            </div>
                        </Col>

                    </Row>

                    <Row className='mt-2'>
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell>Ver</TableCell>
                                                    <TableCell>Fecha Registro</TableCell>
                                                    <TableCell>Trabajador</TableCell>
                                                    <TableCell>Area</TableCell>
                                                    <TableCell>Observaciones</TableCell>
                                                    <TableCell>Lista EPP's</TableCell>
                                                    <TableCell>Proyecto</TableCell>
                                                    <TableCell>Estado</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {

                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalRegistroEppModificar(row) }} title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.fecha} ${row.hora}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.nombretrabajador}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.area}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.observacion}`}</TableCell>
                                                            <TableCell onClick={() => { this.props.history.push(`/almacenEppsdetalle/${row.idregistroepp}/${row.idproyecto}`) }} style={{ fontSize: '0.75em' }} className="iconoAcciones">
                                                                {
                                                                    <i title="Materiales Incluidos" className="fas fa-screwdriver iconoAcciones"></i>
                                                                }
                                                                <span >{`(${row.cantidad}) EPP's Registrado(s)`}</span>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${row.nombreproyecto}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }} className={row.estado == 'ACTIVO' ? 'text-primary' : 'text-danger'}>{`${row.estado}`}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </Row>
                </Container>

                {/* Modal RegistroEpps Modificar */}
                <Modal
                    show={this.state.MostrarModalRegistroEppsModificar}
                    onHide={() => { this.CerrarModalRegistroEppModificar() }}
                    keyboard={false}
                    size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Modificar Registro
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Trabajador</small>
                                    <Form.Control ref={this.inputNombreRegistroEpps} size='sm' placeholder='Ingrese el nombre del trabajador'></Form.Control>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Observaciones</small>
                                    <Form.Control ref={this.inputObservacionRegistroEpps} size='sm' placeholder='Ingrese Observacion'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Estado</small>
                                    <Form.Select ref={this.selectEstadoRegistroEpps}>
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>

                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    {
                                        this.state.AccionActual == 'MODIFICAR_REG_EPPS' ?
                                            <Button size='sm' onClick={() => { this.CerrarModalRegistroEppModificar(false) }} variant='outline-primary'>Cancelar</Button>
                                            : <Button size='sm' onClick={() => { this.HabilitarModificarRegistroEpps(true) }} variant='outline-primary'>Modificar</Button>
                                    }
                                </Col>
                                <Col className='text-end'>
                                    {
                                        this.state.AccionActual == 'MODIFICAR_REG_EPPS' ?
                                            <Button size='sm' onClick={() => { this.ModificarRegistroEpps() }} variant='primary'>Modificar</Button>
                                            : <></>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Modal RegistroEpps */}
                <Modal
                    show={this.state.MostrarModalRegistroEpps}
                    onHide={() => { this.CerrarModalRegistroEpps() }}
                    keyboard={false}
                    size="md"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Agregar Registro
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            {
                                this.state.idproyecto == null ?
                                    <Row>
                                        <Col>
                                            <small className="text-primary">Proyecto</small>
                                            <Form.Select onChange={(ev) => { this.CambiarProyecto(ev) }} ref={this.selectProyecto} size='sm' defaultValue="">
                                                <option value="" disabled>Seleccione un Proyecto</option>
                                                {
                                                    this.state.listaProyectosActivos.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    : <></>
                            }

                            <Row >
                                <Col >
                                    <small className='text-primary'>Trabajador</small>
                                    <Form.Control ref={this.inputNombreRegistroEpps} size='sm' list='dataTrabajadores' placeholder='Ingrese el nombre del trabajador'></Form.Control>
                                    <datalist id='dataTrabajadores'>
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.nombretrabajador} />
                                                )
                                            })
                                        }
                                    </datalist>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Estado</small>
                                    <Form.Select ref={this.selectEstadoRegistroEpps} size='sm'>
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </Form.Select>

                                </Col>
                            </Row>
                            <small className='text-secondary'>Ingrese y seleccione el nombre del trabajador para crear el registro de EPP's, si el trabajador ya existe no es necesario volver a registrarlo.</small>

                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.AgregarRegistroEpps() }} variant='primary'>Agregar Registro</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Reporte Rango Fecha */}
                <Modal
                    show={this.state.MostrarModalReporteFechas}
                    onHide={() => {
                        this.CerrarModalReporteRangoFechas()
                    }}
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">

                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Inicio</small>
                                    <input ref={this.inputFechaInicioReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Fin</small>
                                    <input ref={this.inputFechaFinReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarReporteRangoFecha() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Maestro EPPs */}
                <Modal
                    show={this.state.MostrarModalMaestroEpps}
                    onHide={() => { this.CerrarModalMaestroEpps() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Maestro EPP's
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            {
                                this.state.idproyecto == null ?
                                    <Row>
                                        <Col>
                                            <small className="text-primary">Proyecto</small>
                                            <Form.Select ref={this.selectProyecto} size='sm' defaultValue="">
                                                <option value="" disabled>Seleccione un Proyecto</option>
                                                {
                                                    this.state.listaProyectosActivos.map((row, index) => {
                                                        return (
                                                            <option key={index} value={row.idproyecto}>{row.nombreproyecto}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    : <></>
                            }

                            <Row >
                                <Col >
                                    <small className='text-primary'>Nombre</small>
                                    <Form.Control ref={this.inputNombreMaestroEpp} size='sm' placeholder='Ingrese el nombre del EPP'></Form.Control>
                                </Col>
                            </Row>

                            <Row >
                                <Col >
                                    <small className='text-primary'>Unidad</small>
                                    <Form.Select ref={this.inputUnidadMaestroEpp} size='sm' defaultValue=''>
                                        <option value="" disabled>Seleccione Unidad</option>
                                        <option value="Pza.">Pza.</option>
                                        <option value="Und.">Und.</option>
                                        <option value="Caja">Caja</option>
                                        <option value="Bolsa">Bolsa</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Estado</small>
                                    <Stack direction='row' spacing={1}>
                                        <select ref={this.inputEstadoMaestroEpp} className="form-select form-select-sm" defaultValue="">
                                            <option value="" disabled>Seleccione Estado</option>
                                            <option value="ACTIVO">ACTIVO</option>
                                            <option value="INACTIVO">INACTIVO</option>
                                        </select>
                                        <Button size='sm' onClick={() => { this.AgregarMaestroEpp() }}>
                                            Agregar
                                        </Button>
                                    </Stack>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className='mb-2'>
                                <Col >
                                    <small className='text-primary'>Buscar</small>
                                    <Form.Control onChange={() => { this.FiltrarListaMaestroEpps(this.state.DataMaestroEpps) }} ref={this.inputBuscarMaestroEpp} size='sm' placeholder='Buscar Epp'></Form.Control>
                                </Col>
                            </Row>
                        </Container>

                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow className='fondoTituloTabla'>
                                        <TableCell>*</TableCell>
                                        <TableCell>Epp</TableCell>
                                        <TableCell>Unidad</TableCell>
                                        <TableCell>Proyecto</TableCell>
                                        <TableCell>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.ListaMaestroEppFiltrado.length > 0 ?
                                            this.state.ListaMaestroEppFiltrado.map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell className="">
                                                            {
                                                                row.estado === 'ACTIVO' ?
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        <input onClick={() => { this.CambiarEstadoMaestroEpps('INACTIVO', row) }} className="form-check-input" type="checkbox" defaultChecked />
                                                                    </div> :
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        <input onClick={() => { this.CambiarEstadoMaestroEpps('ACTIVO', row) }} className="form-check-input" type="checkbox" />
                                                                    </div>
                                                            }
                                                        </TableCell>
                                                        <TableCell>{row.descripcion}</TableCell>
                                                        <TableCell>{row.unidad}</TableCell>
                                                        <TableCell>{row.t_proyecto.nombreproyecto}</TableCell>
                                                        <TableCell className={row.estado == 'ACTIVO' ? 'text-primary' : 'text-danger'}>{row.estado}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.CerrarModalMaestroEpps() }} variant='outline-primary'>Cerrar</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default AlmacenEpps; 