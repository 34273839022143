import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { listarPermisos, ValidarPermisos, LIST_SCREENS, ValidarUsuario, ObtenerUsuario, ObtenerDetalleToken } from '../../utils/utils'

import NavBar from '../../dashboard/navBar';
import TituloPagina from '../../dashboard/headerTitle';

// Material UI Elements
import { DataGrid, esES } from '@mui/x-data-grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMUI from '@mui/material/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@mui/material/Divider';
// Material Icons Elements
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';

//ReactBootstral
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

class configMaterialesAp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idproyecto: null,
            permisos: [],
            objmenu: null,
            mostrarModalAgregarMaterial: false,
            mensajeTituloModalAgregar: '',
            columns: [
                {
                    field: 'acciones', headerName: 'Acciones', flex: 1, headerClassName: 'header-colum-table', renderCell: () => {
                        return (
                            <>
                                <Tooltip title="Ver Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle material" });
                                        this.AbrirModalAgregarElemento();
                                    }}>
                                        <VisibilityIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Modificar Elemento">
                                    <IconButton onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Detalle material" });
                                        this.AbrirModalAgregarElemento();
                                    }}>
                                        <EditIcon fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Eliminar Elemento">
                                    <IconButton onClick={() => {
                                    }}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    }
                },
                { field: 'colum1', headerName: 'AAAAAAAAA', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum2', headerName: 'BBBBBBBBB', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum3', headerName: 'CCCCCCCCC', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum4', headerName: 'DDDDDDDDD', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum5', headerName: 'EEEEEEEEE', flex: 1, headerClassName: 'header-colum-table' },
                { field: 'colum6', headerName: 'FFFFFFFFF', flex: 1, headerClassName: 'header-colum-table' },
            ],
            rows: [
                { id: 1, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 2, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 3, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 4, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 5, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 6, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 7, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 8, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 9, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 10, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 11, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 12, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 13, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 14, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 15, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 16, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 17, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },
                { id: 18, colum1: 'AAAAAAAAA', colum2: 'BBBBBBBBB', colum3: 'CCCCCCCCC', colum4: 'DDDDDDDDD', colum5: 'EEEEEEEEE', colum6: 'FFFFFFFFF' },

            ]
        };
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_BASES_AP, permisos)) {
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    //Extraer el codigo de Proyecto
                    if (UsuarioStorage.token) {
                        let DetalleToken = JSON.parse(ObtenerDetalleToken(UsuarioStorage.token));
                        if (DetalleToken.idproyecto) {
                            await this.setState({ idproyecto: DetalleToken.idproyecto });
                        }
                    }
                    // Acciones Aqui

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //***************************************************** */
    // Modales
    //***************************************************** */
    AbrirModalAgregarElemento = () => {
        this.setState({ mostrarModalAgregarMaterial: true });
    }
    CerrarModalAgregarElemento = () => {
        this.setState({ mostrarModalAgregarMaterial: false });
    }
    //***************************************************** */
    //Acciones de Menu
    //***************************************************** */
    AbrirMenu = (event) => {
        this.setState({ objmenu: event.currentTarget })
        // setobjetoMenu(event.currentTarget);
    };

    CerrarMenu = () => {
        this.setState({ objmenu: null })
    };

    render() {
        return (
            <Fragment>
                <NavBar />
                <TituloPagina titulo={`Configuracion - Materiales AP `} regresar={() => { this.props.history.push('/configuracion') }} />
                <Container className='mt-2 border rounded'>
                    <Row className='mt-2'>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Control type="text" placeholder="Ingrese valor a buscar" size='sm' />
                                </Col>
                                <Col xs={4}>
                                    <Button variant="outline-primary" size='sm' onClick={() => {
                                        this.setState({ mensajeTituloModalAgregar: "Agregar material" });
                                        this.AbrirModalAgregarElemento()
                                    }}>Agregar</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2} className={window.outerWidth < 768 ? 'd-grid gap-2 mt-1' : ''}>
                            <Button variant="outline-primary" size='sm'>Buscar</Button>
                        </Col>
                        <Col className="text-end" xs={12} md={4}>
                            <ButtonMUI variant="text" onClick={(event) => { this.AbrirMenu(event) }}>
                                <label className='text-secondary'>Opciones</label>
                                <ExpandMoreIcon color='primary' />
                            </ButtonMUI>
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.objmenu}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                // keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(this.state.objmenu)}
                                onClose={() => { this.CerrarMenu() }}
                            >
                                <MenuItem onClick={() => { }}>Opcion_1</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion_2</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => { }}>Opcion 3</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 4</MenuItem>
                                <MenuItem onClick={() => { }}>Opcion 5</MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    <Row className='mb-2 '>
                        <div className='mt-1' style={{ height: `78vh`, width: '100%' }}>
                            <DataGrid
                                density='compact'
                                rows={this.state.rows}
                                columns={this.state.columns}
                                pageSize={100}
                                rowsPerPageOptions={[20, 50, 100, 200]}
                                // checkboxSelection
                                localeText={esES.props.MuiDataGrid.localeText}
                            />
                        </div>
                    </Row>
                </Container>

                {/* Modal agregar Material */}
                <Modal
                    show={this.state.mostrarModalAgregarMaterial}
                    onHide={() => { this.CerrarModalAgregarElemento() }}
                    size='md'
                // backdrop="static"
                // keyboard={false}
                // centered
                >
                    <Modal.Header>
                        <Modal.Title>{`${this.state.mensajeTituloModalAgregar}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CODIGO</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>ORIGEN</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Control type="text" size='sm' placeholder='Ingrese El codigo' />
                                </Col>
                                <Col md={6}>
                                    <Form.Select placeholder='Ingrese el Origen' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="SEAL">SEAL</option>
                                        <option value="EMPRESA">EMPRESA</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>DESCRIPCION</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type='text' size='sm' placeholder='Ingrese la Descripción'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>TIPO</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Form.Select placeholder='Ingrese el Origen' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="SEAL">CABLE</option>
                                        <option value="EMPRESA">TERMICO</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>UNIDAD</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label className='text-primary'>ESTADO</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Select placeholder='Seleccione la unidad' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="SEAL">CAJA</option>
                                        <option value="EMPRESA">KG</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}>
                                    <Form.Select placeholder='Seleccione el estado' size='sm'>
                                        <option value="">Elija un elemento</option>
                                        <option value="SEAL">SEAL</option>
                                        <option value="EMPRESA">EMPRESA</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Form>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        label="Requiere Foto"
                                        defaultChecked={true}
                                    />
                                </Form>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className='text-primary'>CANTIDAD MINIMA DE ELEMENTOS</Form.Label>
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col>
                                    <Form.Control type='number' size='sm' placeholder='0'></Form.Control>
                                </Col>
                            </Row>
                            <Divider></Divider>
                            <Row className='mt-2'>
                                <Col>
                                    <Button variant="outline-primary">Cancelar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button variant="primary">Guardar</Button>
                                </Col>

                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default configMaterialesAp;