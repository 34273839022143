import React, { Component, Fragment, createRef } from 'react';
import { Link } from 'react-router-dom';

import { ObtenerUsuario, listarPermisos, LIST_SCREENS, ValidarUsuario, ValidarPermisos } from './../utils/utils';
import config from './../../config/config'
import { BorrarStorage } from './../utils/utils'
import { Form, NavbarBrand, Stack } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Alert } from '@material-ui/lab'
import Snackbar from '@material-ui/core/Snackbar';
//Importar Logo
import fondo_logo from './logo_ss360.jpeg'

class navBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: {},
            permisos: [],
            mostrarModalCambiarPassword: false,
            mostrarModalAcercaDe: false,
        }
        this.inputPasswordAnterior = createRef();
        this.inputNuevoPassword = createRef();
        this.inputRepetirPassword = createRef();

    }
    componentDidMount() {
        if (ValidarUsuario()) {
            this.LlenarUsuario();
            listarPermisos().then((permisos) => {
                this.setState({ permisos: permisos });
            });
        }
    }

    LlenarUsuario = () => {
        let usuario = ObtenerUsuario();
        //    console.log("pidiendo usuario");       
        if (usuario) {
            usuario = JSON.parse(usuario);
            this.setState({
                usuario: {
                    usuario: usuario.nombretrabajador,
                    tipousuario: usuario.tiposesion,
                    proyecto: usuario.proyecto
                }
            })
        }
        else {
            this.setState({
                usuario: {
                    usuario: "No User",
                    tipousuario: "No Session"
                }
            })
        }
    }
    CerrarSesion = () => {
        // console.log(window.location);
        BorrarStorage(config.storageName);
        window.location.replace(`${window.location.origin}`);
    }
    ///********************************** */
    // Modal
    ///********************************** */
    AbrirModalCambiarPassword = () => {
        this.setState({ mostrarModalCambiarPassword: true });
    }
    CerrarModalCambiarPassword = () => {
        this.setState({ mostrarModalCambiarPassword: false });
    }
    ValidarCambiarPassword = () => {
        let cantidadError = 0;
        if (this.inputPasswordAnterior.current.value === "") {
            cantidadError++;
            this.inputPasswordAnterior.current.classList.add("is-invalid");
        } else {
            this.inputPasswordAnterior.current.classList.remove("is-invalid");
        }

        if (this.inputNuevoPassword.current.value === "") {
            cantidadError++;
            this.inputNuevoPassword.current.classList.add("is-invalid");
        } else {
            this.inputNuevoPassword.current.classList.remove("is-invalid");
        }

        if (this.inputRepetirPassword.current.value === "") {
            cantidadError++;
            this.inputRepetirPassword.current.classList.add("is-invalid");
        } else {
            this.inputRepetirPassword.current.classList.remove("is-invalid");
        }

        if (cantidadError > 0) {
            return false;
        } else {
            if (this.inputNuevoPassword.current.value === this.inputRepetirPassword.current.value) {
                this.inputNuevoPassword.current.classList.remove("is-invalid");
                this.inputRepetirPassword.current.classList.remove("is-invalid");
            } else {
                this.inputNuevoPassword.current.classList.add("is-invalid");
                this.inputRepetirPassword.current.classList.add("is-invalid");
                this.setState({ abrirToast: true, mensajeToast: "Las contraseñas deben ser iguales.", tipotoast: 'error' });
            }
        }
        return true;
    }
    CambiarPassword = () => {
        if (ValidarUsuario()) {
            if (this.ValidarCambiarPassword()) {

                this.setState({ OcultarLoading: false });
                let url = `${config.endPoint}api/usuario/cambiarpassword`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let sendBody = {
                    idtrabajador: UsuarioStorage.id,
                    password: this.inputPasswordAnterior.current.value,
                    new_password: this.inputNuevoPassword.current.value,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(sendBody)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ mensajeToast: "Se cambio Contraseña", abrirToast: true, tipotoast: 'success', OcultarLoading: true, mostrarModalCambiarPassword: false });
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning', OcultarLoading: true, });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer + `\n` + error.toString, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    AbrirModalAcercaDe = () => {
        this.setState({ mostrarModalAcercaDe: true });
    }
    CerrarModalAcercaDe = () => {
        this.setState({ mostrarModalAcercaDe: false });
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }


    render() {
        return (
            <Fragment>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: 'rgb(0,75,160)' }} className='navbar-dark' >
                    <Container fluid>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Navbar.Brand className='text-center'>
                                <Link className="navbar-brand mx-2" to="/">
                                    <img src="https://cdn2.iconfinder.com/data/icons/hard-work-colored/512/electrician-256.png" alt="" width="30" height="30" />
                                </Link>
                            </Navbar.Brand>
                            <Nav className="me-auto">
                                {/* {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link linknavbar" aria-current="page" to="/" >Inicio</Link>
                                        </li>
                                        : <></>
                                } */}

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_OPERACIONES_COMERCIALES, this.state.permisos) ?
                                        <li className="nav-item linknavbar">
                                            <Link className="nav-link" to="/operacionesComerciales" >Operaciones Comerciales</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALUMBRADO_PUBLICO, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/alumbradoPublico" >Alumbrado Publico</Link>
                                        </li>
                                        : <></>
                                }
                                {/* {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/programacion" >Programación</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/monitoreo" >Monitoreo</Link>
                                        </li>
                                        : <></>
                                }
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/buscar" >Buscar</Link>
                                        </li>
                                        : <></>
                                } */}

                                {/* {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/almacen" >Almacén</Link>
                                        </li>
                                        : <></>
                                } */}

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/configuracion" >Configuración</Link>
                                        </li>
                                        : <></>
                                }

                                {/* {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICA, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/estadistica" >Estadisticas</Link>
                                        </li>
                                        : <></>
                                } */}


                            </Nav>

                        </Navbar.Collapse>
                        <div>
                            <div className="btn-group ">
                                <Stack direction='horizontal' gap={2}>
                                    <button type="button" className="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white' }}>
                                        <span style={{ color: 'white' }}>
                                            <small style={{ color: 'white', fontSize: '1em' }}>{this.state.usuario.usuario}</small>
                                            <small style={{ color: 'white', fontSize: '0.6em' }}> {this.state.usuario.proyecto}</small>
                                        </span>
                                        <i className="mx-1 far fa-user " style={{ color: 'white' }}></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item" type="button" onClick={() => { this.AbrirModalAcercaDe() }}>Acerca de...</button></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><button className="dropdown-item " type="button" onClick={() => { this.AbrirModalCambiarPassword() }}>Cambiar Password</button></li>
                                        {/* <li><button className="dropdown-item" type="button">Ver Perfil</button></li> */}
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><button className="dropdown-item" type="button" onClick={() => { this.CerrarSesion() }}>Cerrar Sesión</button></li>
                                    </ul>
                                </Stack>
                            </div>

                        </div>
                    </Container>
                </Navbar>

                {/* Modal Cambiar Contraseña */}
                <Modal
                    show={this.state.mostrarModalCambiarPassword}
                    onHide={() => { this.CerrarModalCambiarPassword() }}
                    keyboard={false}
                    size='md'>

                    <Modal.Header>
                        <Modal.Title>Cambiar Contraseña</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row >
                                <Col>
                                    <Form.Label className='text-primary'>Contraseña Anterior</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size='sm'>
                                        <InputGroup.Text >
                                            <i className="fas fa-key"></i>
                                        </InputGroup.Text>
                                        <Form.Control ref={this.inputPasswordAnterior} type='password' placeholder='Ingrese Contraseña anterior'></Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Form.Label className='text-primary'>Nueva Contraseña</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size='sm'>
                                        <InputGroup.Text >
                                            <i className="fas fa-key"></i>
                                        </InputGroup.Text>
                                        <Form.Control ref={this.inputNuevoPassword} type='password' placeholder='Ingrese Nueva Contraseña'></Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Form.Label className='text-primary'>Repetir Contraseña</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup size='sm'>
                                        <InputGroup.Text >
                                            <i className="fas fa-key"></i>
                                        </InputGroup.Text>
                                        <Form.Control ref={this.inputRepetirPassword} type='password' placeholder='Reingrese Contraseña'></Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button variant="outline-primary" size='sm' onClick={() => { this.CerrarModalCambiarPassword() }}>Cancelar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button variant="primary" size='sm' onClick={() => { this.CambiarPassword() }}>Cambiar Contraseña</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>

                </Modal>

                {/* Acerca de  */}
                <Modal
                    show={this.state.mostrarModalAcercaDe}
                    onHide={() => { this.CerrarModalAcercaDe() }}
                    keyboard={false}
                    size='sm'>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='border mx-auto' style={{ border: 'solid 1px #DEE2E6', height: '150px', width: '150px', borderRadius: '50%', display: 'grid', placeItems: 'center', zIndex: '100' }}>
                                        <div className='mx-auto' style={{ height: '140px', width: '140px' }} >
                                            <img src={fondo_logo} style={{ width: '100%', borderRadius: '50%' }}></img>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='text-center'>
                                    <Form.Label className='fs-3 fw-bold' style={{ color: '#2E87DB' }}>{`Siscomer Web`}</Form.Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='text-center'>
                                    <Form.Label className=''>{`${config.aplicationVersion}`}</Form.Label>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='text-center'>
                                    <label className='fw-bold text-secondary'>{`Desarrollado por:`}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <small className=''>{`Magno I. Taipe Charca`}</small>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='text-center'>
                                    <label className='fw-bold text-secondary'>{`Contacto:`}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <small className=''>{`mtaipe@360softsolutions.com`}</small>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='text-center'>
                                    <label className='fw-bold text-secondary'>{`Sitio Web:`}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <a href='https://www.360softsolutions.com/' target='_blank'>www.360softsolutions.com</a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className='text-end'>
                                    <Button variant="outline-secondary" size='sm' onClick={() => { this.CerrarModalAcercaDe() }}>Aceptar</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>

        );
    }
}

export default navBar;