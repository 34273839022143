import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos, CreateJsonToExcel, listarPermisosTipoTrabajo } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
// import socketIOClient from 'socket.io-client';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal, Container, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';

class AlmacenEppDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idregistroepp: null,
            dataMaestroEpps: [],
            DataDetalleEpps: [],
            ListaPaginacion: [],
            registroDetalleEpp: null,
            listaFotoEntregaEPP: [],
            listaFotoDevolucionEPP: [],
            DetalleEppActual: {},
            MostrarSpinnerCarga: false,
            idproyecto: null,


            OcultarLoading: true,
            colorIcon: '',
            LoadData: false,
            mensaCargaActualizacion: '',
            MostrarPorcentajeCarga: false,
            cantidadElementos: 0,
            filasPorPagina: 50,
            pagina: 1,
            MostrarModalFotos: false,
            ListaFotosDetalleEpp: [],
            MostrarModalDetalle: false,
            detalletrabajadorDetalleEpp: {},
            listaFotosDetallaDetalleEpp: [],
            MostrarModalReporteFechas: false,
            MostrarModalEntregaEPP: false,
            MostrarModalDevolucionEPP: false,
            MostrarModalMensajeConfirmacion: false,
            modificarDetalleEpp: false
        };

        this.inputValorBuscar = createRef();
        this.inputfechaProgramacion = createRef();
        this.inputFechaInicioReporteFecha = createRef();
        this.inputFechaFinReporteFecha = createRef();

        //ref Registro Detalle Epps
        this.inputNombreEpp = createRef();
        this.inputUnidadEpp = createRef();
        this.inputCantidadEpp = createRef();
        this.inputCantidadDevueltaEpp = createRef();
        this.inputObservacionEpp = createRef();
        this.inputObservacionDevolucionEpp = createRef();
        this.inputFotoEpp = createRef();

    }
    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {

                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN_EPPS, permisos)) {
                    await this.setState({ idregistroepp: this.props.match.params.idregistroepp, idproyecto: this.props.match.params.idproyecto })

                    this.BuscarDetalleEpps_IdRegistroEpp();
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }

            });
        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    //******************************************* */
    //Programacion Detalle EPP's
    //******************************************* */
    BuscarListaMaestroEppsActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/MaestroEpps/listaractivos/${this.state.idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        this.setState({ dataMaestroEpps: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ dataMaestroEpps: [] })
                        reject(false);
                    }

                }).catch(() => {
                    reject(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarIdMaestroEpp = async (eppSeleccionado) => {
        for await (const MaestroEpp of this.state.dataMaestroEpps) {
            if (eppSeleccionado.indexOf(MaestroEpp.descripcion) > -1) {
                return MaestroEpp.idmaestroepp;
            }
        }
        return null;
    }
    ValidarControlesAgregarDetalleEPP = () => {
        let error = 0;
        if (this.inputNombreEpp.current.value == '') {
            this.inputNombreEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputNombreEpp.current.classList.remove('is-invalid');
        }

        if (this.inputUnidadEpp.current.value == '') {
            this.inputUnidadEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputUnidadEpp.current.classList.remove('is-invalid');
        }

        let cantidadEpp = parseFloat(this.inputCantidadEpp.current.value);
        if (isNaN(cantidadEpp)) {
            this.inputCantidadEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputCantidadEpp.current.classList.remove('is-invalid');
        }

        // if (this.inputFotoEpp.current.files.length <= 0) {
        //     this.inputFotoEpp.current.classList.add('is-invalid');
        //     error++;
        // } else {
        //     this.inputFotoEpp.current.classList.remove('is-invalid');
        // }

        if (error > 0) {
            return false;
        }
        return true;
    }
    VerificarMaterial = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarControlesAgregarDetalleEPP()) {
                let _idmaestroEppFilter = await this.BuscarIdMaestroEpp(this.inputNombreEpp.current.value);
                this.BuscarMaterialEPPExistente(_idmaestroEppFilter).then((respuesta) => {
                    if (!respuesta) {
                        this.GuardarDetalleEpp();
                    } else {
                        this.setState({ MostrarModalMensajeConfirmacion: true });
                    }
                });
            }
        } else {
            this.props.history.push('/login');
        }
    }

    GuardarDetalleEpp = () => {
        if (this.inputFotoEpp.current.files.length > 0) {
            this.AgregarDetalleEpp()
        } else {
            this.AgregarDetalleEppsSinFoto()
        }
    }

    AgregarDetalleEpp = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarControlesAgregarDetalleEPP()) {
                this.setState({ OcultarLoading: false, MostrarSpinnerCarga: true });
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = '';
                url = `${config.endPoint}api/registroEppDetalle/agregar`;

                let formData = new FormData();

                formData.append('file', this.inputFotoEpp.current.files[0], this.inputFotoEpp.current.files[0].name);


                let _idmaestroEppFilter = await this.BuscarIdMaestroEpp(this.inputNombreEpp.current.value);

                if (_idmaestroEppFilter) {
                    //Tabla DetalleEpp
                    formData.append('Unidad', this.inputUnidadEpp.current.value);
                    formData.append('cantidadEntrega', this.inputCantidadEpp.current.value);
                    formData.append('estado', 'REGISTRADO');
                    formData.append('tRegistroEppIdregistroepp', this.state.idregistroepp);
                    formData.append('tMaestroEppIdmaestroepp', _idmaestroEppFilter);
                    formData.append('observacion', this.inputObservacionEpp.current.value);

                    // Tabla FotoEPPSDetalle
                    formData.append('tipo', 'FOTO_ENTREGA_EPP');
                    // formData.append('estado','REGISTRADO');

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: formData
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', OcultarLoading: true, MostrarModalEntregaEPP: false, MostrarModalMensajeConfirmacion: false, MostrarSpinnerCarga: false });
                            this.BuscarDetalleEpps_IdRegistroEpp();
                        }
                        else {
                            this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: false });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: false });
                    });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    AgregarDetalleEppsSinFoto = async () => {

        if (ValidarUsuario()) {
            if (this.ValidarControlesAgregarDetalleEPP()) {
                this.setState({ OcultarLoading: false, MostrarSpinnerCarga: true });

                let url = '';
                url = `${config.endPoint}api/registroEppDetalle/agregarSinFoto`;

                let _idmaestroEppFilter = await this.BuscarIdMaestroEpp(this.inputNombreEpp.current.value);

                if (_idmaestroEppFilter) {

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        }, body: JSON.stringify({
                            'Unidad': this.inputUnidadEpp.current.value,
                            'cantidadEntrega': this.inputCantidadEpp.current.value,
                            'estado': 'REGISTRADO',
                            'tRegistroEppIdregistroepp': this.state.idregistroepp,
                            'tMaestroEppIdmaestroepp': _idmaestroEppFilter,
                            'observacion': this.inputObservacionEpp.current.value,
                        })
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', OcultarLoading: true, MostrarModalEntregaEPP: false, MostrarModalMensajeConfirmacion: false, MostrarSpinnerCarga: false });
                            this.BuscarDetalleEpps_IdRegistroEpp();
                        }
                        else {
                            this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: false });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: false });
                    });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarMaterialEPPExistente = (idmaestroepp) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/registroEppDetalle/detallematerial/buscar/${this.state.idregistroepp}/${idmaestroepp}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    reject(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarDetalleEpps_IdRegistroEpp = async () => {
        if (ValidarUsuario()) {
            this.setState({ OcultarLoading: false });
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = '';
            url = `${config.endPoint}api/registroEppDetalle/listardetalle`;

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    idregistroepp: this.state.idregistroepp,
                    valorbuscar: this.inputValorBuscar.current.value.trim()
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ DataDetalleEpps: data.content, cantidadElementos: data.content.length, OcultarLoading: true });
                    await this.Paginacion();
                }
                else {
                    this.setState({ DataDetalleEpps: [], cantidadElementos: 0, LoadData: true, OcultarLoading: true });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarDetalleDetalleEpp_IdEppDetalle = (idDetalleEpp) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/registroEppDetalle/detalle/${idDetalleEpp}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        let arrayFotos = data.content.t_foto_epp_detalles;
                        //Buscar Fotos Entrega
                        let ListaFotosEntregadas = arrayFotos.filter(x => x.tipo == 'FOTO_ENTREGA_EPP');
                        //Buscar fotos Devolucion
                        let ListaFotosDevolucion = arrayFotos.filter(x => x.tipo == 'FOTO_DEVOLUCION_EPP');

                        this.setState({ registroDetalleEpp: data.content, listaFotoEntregaEPP: ListaFotosEntregadas, listaFotoDevolucionEPP: ListaFotosDevolucion });
                        resolve(true);
                    }
                    else {
                        this.setState({ registroDetalleEpp: null, listaFotoEntregaEPP: [], listaFotoDevolucionEPP: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    reject(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    ValidarDevolucionDetalleEPP = () => {
        let error = 0;
        let cantidadEpp = parseFloat(this.inputCantidadDevueltaEpp.current.value);
        if (isNaN(cantidadEpp)) {
            this.inputCantidadDevueltaEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputCantidadDevueltaEpp.current.classList.remove('is-invalid');
        }
        // if (this.inputFotoEpp.current.files.length <= 0) {
        //     this.inputFotoEpp.current.classList.add('is-invalid');
        //     error++;
        // } else {
        //     this.inputFotoEpp.current.classList.remove('is-invalid');
        // }

        if (error > 0) {
            return false;
        }
        return true;
    }
    GuardarDevolucionEPP = () => {
        if (this.inputFotoEpp.current.files.length > 0) {
            this.RegistrarDevolucionDetalleEpp();
        } else {
            this.RegistrarDevolucionDetalleEppSinFoto();
        }
    }

    RegistrarDevolucionDetalleEpp = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarDevolucionDetalleEPP()) {

                this.setState({ OcultarLoading: false, MostrarSpinnerCarga: true });
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let url = '';
                url = `${config.endPoint}api/registroEppDetalle/registrarDevolucion`;

                let formData = new FormData();
                formData.append('file', this.inputFotoEpp.current.files[0], this.inputFotoEpp.current.files[0].name);

                //Tabla DetalleEpp                    
                formData.append('cantidadDevolucion', this.inputCantidadDevueltaEpp.current.value);
                formData.append('observacionDevolucion', this.inputObservacionDevolucionEpp.current.value);
                formData.append('ideppdetalle', this.state.DetalleEppActual.ideppdetalle);

                // Tabla FotoEPPSDetalle
                formData.append('tipo', 'FOTO_DEVOLUCION_EPP');

                let headers = {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: formData
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', OcultarLoading: true, MostrarModalDevolucionEPP: false, MostrarSpinnerCarga: true });
                        this.BuscarDetalleEpps_IdRegistroEpp();
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true });
                });

            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    RegistrarDevolucionDetalleEppSinFoto = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarDevolucionDetalleEPP()) {

                this.setState({ OcultarLoading: false, MostrarSpinnerCarga: true });

                let url = '';
                url = `${config.endPoint}api/registroEppDetalle/registrarDevolucionSinFoto`;


                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }, body: JSON.stringify({
                        'cantidadDevolucion': this.inputCantidadDevueltaEpp.current.value,
                        'observacionDevolucion': this.inputObservacionDevolucionEpp.current.value,
                        'ideppdetalle': this.state.DetalleEppActual.ideppdetalle,
                    })
                };


                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', OcultarLoading: true, MostrarModalDevolucionEPP: false, MostrarSpinnerCarga: true });
                        this.BuscarDetalleEpps_IdRegistroEpp();
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true });
                });

            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.DataDetalleEpps.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.DataDetalleEpps.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.DataDetalleEpps[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });
    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarDetalleEpps_IdRegistroEpp();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarDetalleEpps_IdRegistroEpp();
    }
    //*************************************************
    //Funciones para Controles
    //*************************************************        
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.BuscarDetalleEpps_IdRegistroEpp();
        }
    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.BuscarDetalleEpps_IdRegistroEpp();
        }
    }
    //*************************************************
    //Funciones para Modales
    //*************************************************

    // Modal Detalle DetalleEpp
    AbrirModalDetalleDetalleEpp = async (idDetalleEpp) => {
        this.setState({ OcultarLoading: false, registroDetalleEpp: null, listaFotoEntregaEPP: [], listaFotoDevolucionEPP: [], modificarDetalleEpp: false });
        await this.BuscarDetalleDetalleEpp_IdEppDetalle(idDetalleEpp).then(async (respuesta) => {
            if (respuesta) {
                await this.setState({ MostrarModalDetalle: true });
            }
        });
        this.setState({ OcultarLoading: true })
    }
    CerrarModalDetalleDetalleEpp = () => {
        this.setState({ MostrarModalDetalle: false });
    }
    ModificarDetalleEpp = (valor) => {
        this.setState({ modificarDetalleEpp: valor });        

        this.inputCantidadEpp.current.disabled = !valor;
        this.inputObservacionEpp.current.disabled = !valor;

        if (this.state.registroDetalleEpp.fechaDevolucion) {
            this.inputCantidadDevueltaEpp.current.disabled = !valor;
            this.inputObservacionDevolucionEpp.current.disabled = !valor;
        }
    }
    ValidaModificarDetalleEPP = () => {
        let error = 0;
        let cantidadEpp = parseFloat(this.inputCantidadEpp.current.value);
        if (isNaN(cantidadEpp)) {
            this.inputCantidadEpp.current.classList.add('is-invalid');
            error++;
        } else {
            this.inputCantidadEpp.current.classList.remove('is-invalid');
        }

        if (this.state.registroDetalleEpp.fechaDevolucion) {
            cantidadEpp = parseFloat(this.inputCantidadDevueltaEpp.current.value);
            if (isNaN(cantidadEpp)) {
                this.inputCantidadDevueltaEpp.current.classList.add('is-invalid');
                error++;
            } else {
                this.inputCantidadDevueltaEpp.current.classList.remove('is-invalid');
            }
        }

        if (error > 0) {
            return false;
        }
        return true;
    }
    ActualizarDetalleEpp = () => {
        if (ValidarUsuario()) {
            if (this.ValidaModificarDetalleEPP()) {

                this.setState({ OcultarLoading: false, MostrarSpinnerCarga: true });

                let url = '';
                url = `${config.endPoint}api/registroEppDetalle/modificar`;

                let nDetalleEpp = {
                    'ideppdetalle': this.state.registroDetalleEpp.ideppdetalle,
                    'cantidadEntrega': this.inputCantidadEpp.current.value,
                    'observacion': this.inputObservacionEpp.current.value,
                }

                if (this.state.registroDetalleEpp.fechaDevolucion) {
                    nDetalleEpp.cantidadDevolucion = this.inputCantidadDevueltaEpp.current.value;
                    nDetalleEpp.observacionDevolucion = this.inputObservacionDevolucionEpp.current.value;
                }


                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }, body: JSON.stringify(nDetalleEpp)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success', OcultarLoading: true, MostrarModalDevolucionEPP: false, MostrarSpinnerCarga: true,modificarDetalleEpp:false });
                        this.ModificarDetalleEpp(false);
                        this.BuscarDetalleEpps_IdRegistroEpp();
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true,modificarDetalleEpp:false });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSpinnerCarga: true,modificarDetalleEpp:false });
                });

            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    //Modal Reporte Fechas
    AbrirModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: true });
    }
    CerrarModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: false });
    }
    //Modal Mensaje confirmacion
    CerrarModarMensajeConfirmacion = () => {
        this.setState({ MostrarModalMensajeConfirmacion: false });
    }
    //Modal entrega EPPs
    AbrirModalEntregaEpps = () => {
        this.BuscarListaMaestroEppsActivos().then(() => {
            this.setState({ MostrarModalEntregaEPP: true });
        }).catch(() => {
            this.setState({ abrirToast: true, mensajeToast: "No se pudo cargar data.", tipotoast: 'warning' });
        });
    }
    CerrarModalEntregaEPPs = () => {
        this.setState({ MostrarModalEntregaEPP: false });
    }
    //Devolucion EPP
    AbrirModalDevolucionEpps = async (row) => {
        this.setState({ DetalleEppActual: row });
        await this.setState({ MostrarModalDevolucionEPP: true });
        this.inputNombreEpp.current.value = row.descripcion;
        this.inputUnidadEpp.current.value = row.Unidad;
        this.inputCantidadEpp.current.value = row.cantidadEntrega;
        this.inputObservacionEpp.current.value = row.observacion;
    }
    CerrarModalDevolucionEPPs = () => {
        this.setState({ MostrarModalDevolucionEPP: false });
    }
    //*************************************************
    //Reportes
    //*************************************************
    DescargarReporteResumenEPP = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/registroEppDetalle/reporte/${this.state.idregistroepp}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {

                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Resumen EPPs_ `, `Resumen`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    //*************************************************
    // Funciones de Pantalla
    //*************************************************

    componentDidUpdate() {
        window.addEventListener('resize', this.CambioSizeWindows);
    }
    CambioSizeWindows = () => {
        this.setState({ innerHeight: window.innerHeight, innerWidth: window.innerWidth });
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`Trabajador 1`} regresar={() => { this.props.history.push('/almacenEpps') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <Container className='border mt-1'>
                    <Row className='mt-1'>
                        <Col sm={12} md={3} className={'mt-md-0 mt-1'}>
                            <input ref={this.inputValorBuscar} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} className="form-control form-control-sm" placeholder={`Buscar`}></input>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1'}>
                            <Button onClick={() => { this.BuscarDetalleEpps_IdRegistroEpp() }} size='sm'>Buscar</Button>
                        </Col>
                        <Col sm={12} md={3} className={window.innerWidth < 768 ? 'd-grid gap-2 mt-1' : 'mt-md-0 mt-1 text-end'}>
                            <Button onClick={() => { this.AbrirModalEntregaEpps() }} size='sm'>Agregar</Button>
                        </Col>
                        <Col sm={12} md={3} className='text-end mt-md-0 mt-1'>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.DescargarReporteResumenEPP() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Descargar Resumen</small></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Imprimir Reporte EPP's</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    {/* <li>
                                    <Link to={{ pathname: `/monitoreo/mapa/${this.state.fechaprogramacion}` }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                </li> */}
                                    <li onClick={() => { this.BuscarDetalleEpps_IdRegistroEpp() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li>

                                </ul>
                            </div>
                        </Col>

                    </Row>

                    <Row className='mt-2'>
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell>Ver</TableCell>
                                                    <TableCell>Detalle</TableCell>
                                                    <TableCell>Unidad</TableCell>
                                                    <TableCell>cantidad Entregada</TableCell>
                                                    <TableCell>Fecha Entrega</TableCell>
                                                    <TableCell>cantidad Devuelto</TableCell>
                                                    <TableCell>Fecha Devolucion</TableCell>
                                                    <TableCell>Registrar Devolución</TableCell>
                                                    <TableCell>Estado</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.ListaPaginacion.map((row, index) => {

                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ fontSize: '0.75em' }}>
                                                                    <i onClick={() => { this.AbrirModalDetalleDetalleEpp(row.ideppdetalle) }} title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.descripcion}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.Unidad}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.cantidadEntrega}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{`${row.fechaEntrega} ${row.horaEntrega}`}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.cantidadDevolucion}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.fechaDevolucion}</TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }} className="iconoAcciones">
                                                                    {
                                                                        row.estado === 'REGISTRADO' ?
                                                                            <i onClick={() => { this.AbrirModalDevolucionEpps(row) }} title="Registrar Devolución" className="fas fa-exchange-alt iconoAcciones text-primary"></i>
                                                                            : <i className="fas fa-stream iconoAcciones text-success"></i>
                                                                    }
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: '0.75em' }} className={row.estado == 'REGISTRADO' ? 'text-primary' : row.estado == 'DEVUELTO' ? 'text-success' : 'text-danger'}>{row.estado}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </Row>
                </Container>

                {/* Modal Detale */}
                <Modal
                    show={this.state.MostrarModalDetalle}
                    onHide={() => { this.CerrarModalDetalleDetalleEpp() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Detalle EPP
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.registroDetalleEpp ?
                                <Container>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Descripción</small>
                                            <Form.Control size='sm' placeholder='' value={this.state.registroDetalleEpp.t_maestro_epp.descripcion} readOnly></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col >
                                            <small className='text-primary'>Unidad</small>
                                            <Form.Control size='sm' placeholder='' readOnly value={this.state.registroDetalleEpp.Unidad}></Form.Control>
                                        </Col>
                                        <Col >
                                            <small className='text-primary'>Estado</small>
                                            <Form.Control size='sm' placeholder='' readOnly value={this.state.registroDetalleEpp.estado}></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col className='text-center text-secondary'>
                                            <h4>Entregado</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} lg={6}>
                                            <Container fluid>
                                                <Row >
                                                    <Col >
                                                        <small className='text-primary'>Fecha Entrega</small>
                                                        <Form.Control size='sm' placeholder='' disabled value={`${this.state.registroDetalleEpp.fechaEntrega} ${this.state.registroDetalleEpp.horaEntrega}`}></Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <small className='text-primary'>Cantidad Entregado</small>
                                                        <Form.Control ref={this.inputCantidadEpp} size='sm' placeholder='' disabled defaultValue={this.state.registroDetalleEpp.cantidadEntrega}></Form.Control>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <small className='text-primary'>Observacion Entrega</small>
                                                        <Form.Control ref={this.inputObservacionEpp} size='sm' placeholder='' disabled as="textarea" rows={3} defaultValue={this.state.registroDetalleEpp.observacion}></Form.Control>
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Container>
                                                <Row className='mt-1'>
                                                    <Col>
                                                        <Card>
                                                            <Card.Body>
                                                                <div className='mt-1 mb-1'>
                                                                    {
                                                                        this.state.listaFotoEntregaEPP.length > 0 ?
                                                                            <img
                                                                                className="d-block m-auto"
                                                                                src={`${config.endPoint}api/foto/DownloadFileServer/${this.state.listaFotoEntregaEPP[0].foto_url}`}
                                                                                alt={`Imagen`}
                                                                                width='100%'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${this.state.listaFotoEntregaEPP[0].foto_url}`, '_blank'); }}
                                                                            />
                                                                            : <img
                                                                                className="d-block m-auto"
                                                                                src='/img/Sin_imagen_disponible.jpg'
                                                                                alt={`Foto Epp Devuelto`}
                                                                                width='100%'
                                                                            />
                                                                    }
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                            </Container>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.registroDetalleEpp.fechaDevolucion ?
                                            <>
                                                <hr></hr>
                                                <Row>
                                                    <Col className='text-center text-secondary'>
                                                        <h4>Devuelto</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} lg={6}>
                                                        <Container>
                                                            <Row >
                                                                <Col >
                                                                    <small className='text-primary'>Fecha Revolucion</small>
                                                                    <Form.Control size='sm' placeholder='' disabled value={`${this.state.registroDetalleEpp.fechaDevolucion} ${this.state.registroDetalleEpp.horaDevolucion}`}></Form.Control>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col >
                                                                    <small className='text-primary'>Cantida Devolucion</small>
                                                                    <Form.Control ref={this.inputCantidadDevueltaEpp} size='sm' placeholder='' disabled defaultValue={this.state.registroDetalleEpp.cantidadDevolucion}></Form.Control>
                                                                </Col>
                                                            </Row>
                                                            <Row >
                                                                <Col >
                                                                    <small className='text-primary'>Observacion Devolución</small>
                                                                    <Form.Control ref={this.inputObservacionDevolucionEpp} size='sm' placeholder='' disabled as="textarea" rows={3} defaultValue={this.state.registroDetalleEpp.observacionDevolucion}></Form.Control>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>

                                                    <Col sm={12} lg={6}>
                                                        <Container>
                                                            <Row className='mt-1'>
                                                                <Col>
                                                                    <Card>
                                                                        <Card.Body>
                                                                            <div className='mt-1 mb-1'>
                                                                                {
                                                                                    this.state.listaFotoDevolucionEPP.length > 0 ?
                                                                                        <img
                                                                                            className="d-block m-auto"
                                                                                            src={`${config.endPoint}api/foto/DownloadFileServer/${this.state.listaFotoDevolucionEPP[0].foto_url}`}
                                                                                            alt={`Foto Epp Devuelto`}
                                                                                            width='100%'
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${this.state.listaFotoDevolucionEPP[0].foto_url}`, '_blank'); }}
                                                                                        />
                                                                                        :
                                                                                        <img
                                                                                            className="d-block m-auto"
                                                                                            src='/img/Sin_imagen_disponible.jpg'
                                                                                            alt={`Foto Epp Devuelto`}
                                                                                            width='100%'
                                                                                        />
                                                                                }
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            </>
                                            : <></>
                                    }
                                </Container>
                                : <></>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                {
                                    !this.state.modificarDetalleEpp ?
                                        <Col>
                                            <Button size='sm' onClick={() => { this.ModificarDetalleEpp(true) }} variant='outline-primary'>Modificar</Button>
                                        </Col>
                                        :
                                        <Col>
                                            <Button size='sm' onClick={() => { this.CerrarModalDetalleDetalleEpp() }} variant='outline-primary'>Cancelar</Button>
                                        </Col>
                                }
                                {
                                    !this.state.modificarDetalleEpp ?
                                        <Col className='text-end'>
                                            <Button size='sm' onClick={() => { this.CerrarModalDetalleDetalleEpp() }} variant='primary'>Cerrar</Button>
                                        </Col>
                                        :
                                        <Col className='text-end'>
                                            <Button size='sm' onClick={() => { this.ActualizarDetalleEpp() }} variant='primary'>Guardar</Button>
                                        </Col>
                                }
                            </Row>

                        </Container>

                    </Modal.Footer>
                </Modal>

                {/* Modal entrega EPP*/}
                <Modal
                    show={this.state.MostrarModalEntregaEPP}
                    onHide={() => { this.CerrarModalEntregaEPPs() }}
                    keyboard={false}
                    backdrop='static'
                    size="md"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Registro EPP - Agregar
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Epp</small>
                                    <Form.Control ref={this.inputNombreEpp} size='sm' list='dataMaestroEpps' placeholder='Ingrese el nombre del EPP'></Form.Control>
                                    <datalist id='dataMaestroEpps'>
                                        {
                                            this.state.dataMaestroEpps.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.descripcion} />
                                                )
                                            })
                                        }
                                    </datalist>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Unidad</small>
                                    <Form.Select ref={this.inputUnidadEpp} size='sm' >
                                        <option value="Pza.">Pza.</option>
                                        <option value="Und.">Und.</option>
                                        <option value="Caja">Caja</option>
                                        <option value="Bolsa.">Bolsa.</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Cantidad</small>
                                    <Form.Control ref={this.inputCantidadEpp} size='sm' type='number' placeholder='Ingrese Cantidad'></Form.Control>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Observacion</small>
                                    <Form.Control ref={this.inputObservacionEpp} size='sm' placeholder='Ingrese Observacion'></Form.Control>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <small className='text-primary'>Foto</small>
                                    <Form.Control ref={this.inputFotoEpp} size='sm' type='file' placeholder='Foto'></Form.Control>
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.VerificarMaterial() }} variant='primary' disabled={this.state.MostrarSpinnerCarga}>
                                {
                                    this.state.MostrarSpinnerCarga ?
                                        <Spinner animation="border" size="sm" role="status" className='mx-1' />
                                        : <></>
                                }
                                Agregar Registro
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Devolucion EPP*/}
                <Modal
                    show={this.state.MostrarModalDevolucionEPP}
                    onHide={() => { this.CerrarModalDevolucionEPPs() }}
                    keyboard={false}
                    backdrop='static'
                    size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Registro EPP - Devolución
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row >
                                <Col >
                                    <small className='text-primary'>Epp</small>
                                    <Form.Control ref={this.inputNombreEpp} type='numeric' placeholder='Ingrese Cantidad' disabled size='sm'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Unidad</small>
                                    <Form.Control ref={this.inputUnidadEpp} type='numeric' placeholder='Ingrese Cantidad' disabled size='sm'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Cantidad Entregada</small>
                                    <Form.Control ref={this.inputCantidadEpp} type='numeric' placeholder='Ingrese Cantidad' disabled size='sm'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Obs. Entrega</small>
                                    <Form.Control ref={this.inputObservacionEpp} type='numeric' placeholder='Ingrese Cantidad' disabled size='sm'></Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary' >Cantidad Devuelta</small>
                                    <Form.Control ref={this.inputCantidadDevueltaEpp} type='number' placeholder='Ingrese Cantidad' size='sm'></Form.Control>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small className='text-primary'>Observacion Devolucion</small>
                                    <Form.Control ref={this.inputObservacionDevolucionEpp} placeholder='Ingrese Observacion' size='sm'></Form.Control>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <small className='text-primary'>Foto</small>
                                    <Form.Control ref={this.inputFotoEpp} type='file' placeholder='Foto' size='sm'></Form.Control>
                                </Col>
                            </Row>

                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button size='sm' onClick={() => { this.GuardarDevolucionEPP() }} variant='primary' disabled={this.state.MostrarSpinnerCarga}>
                                {
                                    this.state.MostrarSpinnerCarga ?
                                        <Spinner animation="border" size="sm" role="status" className='mx-1' />
                                        : <></>
                                }
                                Actualizar Registro
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Modal Mensaje*/}
                <Modal
                    show={this.state.MostrarModalMensajeConfirmacion}
                    onHide={() => { this.CerrarModarMensajeConfirmacion() }}
                    keyboard={false}
                    size="sm"
                    centered
                    backdrop='static'
                    className='border border-primary'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Confirmación
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <span>
                                        {`El EPP que desea agregar ya se encuentra agregado y aun no se ha devuelto el EPP correspondiente. ¿Desea agregar otro registro de este material?`}
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col>
                                    <Button size='sm' onClick={() => { this.CerrarModarMensajeConfirmacion() }} variant='outline-primary'>Cerrar</Button>
                                </Col>
                                <Col className='text-end'>
                                    <Button size='sm' onClick={() => { this.GuardarDetalleEpp() }} variant='primary' disabled={this.state.MostrarSpinnerCarga}>
                                        {
                                            this.state.MostrarSpinnerCarga ?
                                                <Spinner animation="border" size="sm" role="status" className='mx-1' />
                                                : <></>
                                        }
                                        Agregar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default AlmacenEppDetalle;